import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';

export interface FilteringSelectValue {
  name?: string;
  value: string;
}

interface Props {
  label: string;
  data: FilteringSelectValue[];
  initial?: string;
  onChange?: (value: string) => void;
}

export const SelectInput: React.FunctionComponent<Props> = (props: Props) => {
  const [submittedValue, setSubmittedValue] = React.useState<string>(props.initial === undefined ? '' : props.initial);
  const handleChange = () => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = '' + event.target.value;
    setSubmittedValue(value);
    if (props.onChange) props.onChange(value);
  };
  const id = `filtering-${props.label}`;
  return (
    <form autoComplete="off" onSubmit={handleChange}>
      <FormControl fullWidth>
        <InputLabel htmlFor={id}>{props.label}</InputLabel>
        <Select
          value={submittedValue}
          onChange={handleChange()}
          inputProps={{
            name: props.label,
            id
          }}
        >
          {props.data.map(row => (
            <MenuItem value={row.value} key={`filtering-rosw-${row.name}-${row.value}`}>
              {row.name || row.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};

import { Action } from 'redux';

import { ErrorAction } from '../../../redux/actions/actions';

import { ProofreaderRow, Scale } from './store';

export enum ScheduleViewActionType {
  /* Loading the page */
  InitialLoading = 'schedule/start-initial-loading',
  SoftLoad = 'schedule/soft-load',
  StartLoading = 'schedule/start-loading',
  StopLoading = 'schedule/stop-loading',
  LoadingSuccess = 'schedule/loading-success',
  LoadingFailure = 'schedule/loading-failure',

  /* Toggling the scale */
  DailyScale = 'schedule/daily-schedule',
  WeeklyScale = 'schedule/weekly-schedule',

  /* Browsing the calendar */
  NextPeriod = 'schedule/next-period',
  PreviousPeriod = 'schedule/prev-period',

  /* Filters */
  ToggleColumn = 'schedule/toggle-column',
  ToggleShowNoShifts = 'schedule/toggle-show-no-shifts',
  SetProofreaderFilter = 'schedule/set-proofreader-filter',
  SetPermissionFilter = 'schedule/set-permission-filter'
}

export interface ScheduleStartInitialLoadingAction extends Action {
  type: ScheduleViewActionType.InitialLoading;
  payload: { asOfDate: Date; scale: Scale };
}

export interface ScheduleStartSoftLoadAction extends Action {
  type: ScheduleViewActionType.SoftLoad;
}

export interface ScheduleStartLoadingAction extends Action {
  type: ScheduleViewActionType.StartLoading;
  payload: { from: Date; to: Date };
}

interface ScheduleStopLoadingAction extends Action {
  type: ScheduleViewActionType.StopLoading;
}

interface LoadingSuccessAction extends Action {
  type: ScheduleViewActionType.LoadingSuccess;
  payload: ProofreaderRow[];
}

interface LoadingFailureAction extends ErrorAction {
  type: ScheduleViewActionType.LoadingFailure;
}

interface ToggleColumnAction extends Action {
  type: ScheduleViewActionType.ToggleColumn;
  payload: {
    columnIndex: number;
  };
}

type DailyScaleAction = { type: ScheduleViewActionType.DailyScale };
type WeeklyScaleAction = { type: ScheduleViewActionType.WeeklyScale };
type NextPeriodAction = { type: ScheduleViewActionType.NextPeriod };
type PreviousPeriodAction = { type: ScheduleViewActionType.PreviousPeriod };
type ToggleShowNoShiftsAction = { type: ScheduleViewActionType.ToggleShowNoShifts };

interface SetProofreaderFilter extends Action {
  type: ScheduleViewActionType.SetProofreaderFilter;
  payload: {
    value: string;
  };
}

interface SetPermissionFilter extends Action {
  type: ScheduleViewActionType.SetPermissionFilter;
  payload: { value: string };
}

export const scheduleActions = {
  initialLoad: (asOfDate: Date, scale: Scale): ScheduleStartInitialLoadingAction => ({
    type: ScheduleViewActionType.InitialLoading,
    payload: { asOfDate, scale }
  }),

  softLoad: (): ScheduleStartSoftLoadAction => ({ type: ScheduleViewActionType.SoftLoad }),

  startLoading: (from: Date, to: Date): ScheduleStartLoadingAction => ({
    type: ScheduleViewActionType.StartLoading,
    payload: { from: from, to: to }
  }),

  stopLoading: (): ScheduleStopLoadingAction => ({ type: ScheduleViewActionType.StopLoading }),

  loadingFailure: (reason: string): LoadingFailureAction => ({
    type: ScheduleViewActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading proofreader schedule: ${reason}`)
  }),

  loadingSuccess: (proofreaders: ProofreaderRow[]): LoadingSuccessAction => ({
    type: ScheduleViewActionType.LoadingSuccess,
    payload: proofreaders || []
  }),

  next: (): NextPeriodAction => ({ type: ScheduleViewActionType.NextPeriod }),
  previous: (): PreviousPeriodAction => ({ type: ScheduleViewActionType.PreviousPeriod }),

  toDaily: (): DailyScaleAction => ({ type: ScheduleViewActionType.DailyScale }),
  toWeekly: (): WeeklyScaleAction => ({ type: ScheduleViewActionType.WeeklyScale }),

  toggleColumn: (columnIndex: number): ToggleColumnAction => ({
    type: ScheduleViewActionType.ToggleColumn,
    payload: { columnIndex }
  }),

  toggleShowNoShifts: (): ToggleShowNoShiftsAction => ({ type: ScheduleViewActionType.ToggleShowNoShifts }),
  setProofreaderFilter: (value: string): SetProofreaderFilter => ({
    type: ScheduleViewActionType.SetProofreaderFilter,
    payload: { value }
  }),
  setPermissionFilter: (value: string): SetPermissionFilter => ({
    type: ScheduleViewActionType.SetPermissionFilter,
    payload: { value }
  })
};

/**
 * All module actions in one place
 */
export type ScheduleViewActions =
  | ScheduleStartInitialLoadingAction
  | ScheduleStartSoftLoadAction
  | ScheduleStartLoadingAction
  | ScheduleStopLoadingAction
  | LoadingSuccessAction
  | LoadingFailureAction
  | DailyScaleAction
  | WeeklyScaleAction
  | ToggleColumnAction
  | NextPeriodAction
  | PreviousPeriodAction
  | ToggleShowNoShiftsAction
  | SetProofreaderFilter
  | SetPermissionFilter;

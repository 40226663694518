import React from 'react';
import { Box } from '@material-ui/core';

import { Filtering, ProofitLog } from '../proofit-log.store';
import TableWithHeader, { Column } from '../../../components/TableWithHeader/TableWithHeader';
import { FilteringText } from '../../../components/FilteringText/FilteringText';
import { isValidId } from '../../../utils/validation';
import { localDateTimeUTCTooltip } from '../../../utils/date';
import { idfy } from '../../../utils/types';

interface Props {
  logs: ProofitLog[];
  filtering: Filtering;
  onFilterChange: (key: keyof Filtering, value?: string) => void;
}

const isValidIdForFiltering = (value: string): boolean => !value || isValidId(value);

export default ({ logs, filtering, onFilterChange }: Props) => {
  const { jobId, assigneeId, taskId } = filtering;

  const columns: Column<ProofitLog>[] = [
    {
      id: 'when',
      label: 'When',
      showTitle: false,
      format: (log: ProofitLog) => <Box whiteSpace="nowrap">{localDateTimeUTCTooltip(log.when)}</Box>
    },
    {
      id: 'jobId',
      label: 'Job',
      showTitle: false,
      format: (log: ProofitLog) => <span style={{ fontStyle: 'italic' }}>{log.jobId || ''}</span>,
      filter: () => (
        <div style={{ width: 120, minWidth: 120 }}>
          <FilteringText
            label="Job ID"
            initial={jobId}
            isValid={isValidIdForFiltering}
            submit={v => onFilterChange('jobId', idfy(v))}
          />
        </div>
      )
    },
    {
      id: 'assigneeId',
      label: 'Assignee',
      showTitle: false,
      format: (log: ProofitLog) => {
        if (!log.assigneeId) {
          return null;
        }

        return (
          <Box whiteSpace="nowrap">
            <span style={{ fontStyle: 'italic' }}>{log.assigneeId}</span>
            <span style={{ margin: '0 .5em' }}>|</span>
            <span>{log.assigneeName || log.assigneeEmail}</span>
          </Box>
        );
      },
      filter: () => (
        <FilteringText
          label="Proofreader ID"
          initial={assigneeId}
          isValid={isValidIdForFiltering}
          submit={v => onFilterChange('assigneeId', idfy(v))}
        />
      )
    },
    {
      id: 'taskType',
      label: 'Task Type',
      showTitle: true,
      align: 'center',
      minWidth: 80,
      format: (log: ProofitLog) => (
        <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{log.taskType || ''}</span>
      )
    },
    {
      id: 'taskId',
      label: 'Task',
      showTitle: false,
      format: (log: ProofitLog) => <span style={{ fontStyle: 'italic' }}>{log.taskId || ''}</span>,
      filter: () => (
        <div style={{ width: 120, minWidth: 120 }}>
          <FilteringText
            label="Task ID"
            initial={taskId}
            isValid={isValidIdForFiltering}
            submit={v => onFilterChange('taskId', idfy(v))}
          />
        </div>
      )
    },
    {
      id: 'category',
      label: 'Category',
      showTitle: false,
      format: (log: ProofitLog) => <span style={{ fontWeight: 'bold' }}>{log.category}</span>
    },
    {
      id: 'message',
      label: 'Message',
      showTitle: false,
      format: (log: ProofitLog) => log.message
    }
  ];

  return <TableWithHeader columns={columns} data={logs} />;
};

import Container from '@material-ui/core/Container';
import React from 'react';
import { Typography } from '@material-ui/core';

import { useGeneralStyles } from '../../assets/general-styles';

const AccessDeniedError = () => {
  const generalClasses = useGeneralStyles();
  return (
    <div className={generalClasses.textAlignCenter}>
      <Container maxWidth="sm">
        <Typography variant="h1">403</Typography>
        <Typography variant="h4">Access Denied</Typography>
      </Container>
    </div>
  );
};

export default AccessDeniedError;

import moment from 'moment';

export type Timestamp = number;

export type Hours = number;
export type Seconds = number;
export type Milliseconds = number;
export type NanoSeconds = number;

export interface EpochTime {
  epochSecond: Timestamp;
  nano: NanoSeconds;
}

export interface ITimeInterval {
  begin: Timestamp;
  end: Timestamp;
}
export const fromDuration = (from: Timestamp, duration: Milliseconds): ITimeInterval => ({
  begin: from,
  end: from + duration
});

export const modifyInterval = (i: ITimeInterval, offset: Milliseconds, durationDiff: Milliseconds): ITimeInterval => ({
  begin: i.begin + offset,
  end: i.end + offset + durationDiff
});

export const intersection = (i1: ITimeInterval, i2: ITimeInterval): ITimeInterval => {
  const begin = Math.max(i1.begin, i2.begin);
  const end = Math.min(i1.end, i2.end);

  return new TimeInterval(begin, end);
};

export const split = (i: ITimeInterval, unit: moment.unitOfTime.Base): ITimeInterval[] => {
  const result: ITimeInterval[] = [];

  const mBegin = moment.utc(i.begin);
  const mEnd = moment.utc(i.end);

  for (let begin = mBegin.clone(); begin.isBefore(mEnd); begin.add(1, unit)) {
    const splitEnd = begin
      .clone()
      .add(1, unit)
      .valueOf();

    result.push({
      begin: begin.valueOf(),
      end: Math.min(splitEnd, i.end)
    });
  }

  return result;
};

export const valid = (i: ITimeInterval): boolean => i.begin < i.end;

export const duration = (i: ITimeInterval): Milliseconds => (valid(i) ? i.end - i.begin : 0);

// TODO: Replace this class completely with functions as above
export class TimeInterval implements ITimeInterval {
  public begin: Timestamp;
  public end: Timestamp;

  constructor(begin: Timestamp, end: Timestamp) {
    this.begin = begin;
    this.end = end;
  }

  valid() {
    return valid(this);
  }

  intersection(that: ITimeInterval): TimeInterval {
    const result = intersection(this, that);
    return new TimeInterval(result.begin, result.end);
  }

  intersects(that: ITimeInterval): boolean {
    return this.intersection(that).valid();
  }

  duration(): Milliseconds {
    return duration(this);
  }

  contains(time: Timestamp): boolean {
    return this.valid() && time > this.begin && time < this.end;
  }

  modify(beginOffset: Milliseconds, durationDiff: Milliseconds): TimeInterval {
    const interval = modifyInterval(this, beginOffset, durationDiff);
    return new TimeInterval(interval.begin, interval.end);
  }
}

export const Millis = {
  quarterHour: 1000 * 60 * 15,
  halfHour: 1000 * 60 * 30,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
  week: 1000 * 60 * 60 * 24 * 7
};

import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

import { practiceGeneralActions } from '../practice.actions';
import { AppsActions } from '../../../redux/actions/actions';

import PracticeDocs from './PracticeDocs';
import PracticeJobs from './PracticeJobs';

export class PracticePage extends React.Component<Dispatches> {
  constructor(props: Readonly<Dispatches>) {
    super(props);
  }

  componentDidMount() {
    this.props.load();
  }

  componentWillUnmount() {
    this.props.stopLoading();
  }

  render() {
    return (
      <Container maxWidth="xl" className="PracticeWrapper">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <PracticeDocs />
          </Grid>
          <Grid item xs={9}>
            <PracticeJobs />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

interface Dispatches {
  load: () => void;
  stopLoading: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  load: () => dispatch(practiceGeneralActions.startLoading()),
  stopLoading: () => dispatch(practiceGeneralActions.stopLoading())
});

export default connect(null, mapDispatchToProps)(PracticePage);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createStyles, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import { capitalize } from 'lodash';

import { actions } from '../../jobs-list.actions';
import { isJavaInteger } from '../../../../../utils/validation';
import appConfig from '../../../../../config';
import { LoadingStatusEnum } from '../../../../../utils/enums';
import { getGuideline, getOwnership, JobType } from '../../../../../model/job/jobType';
import useAppSelector from '../../../../../hooks/useAppSelector';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { prettyPrint, TurnaroundOption } from '../../../../../model/job/turnaroundOption';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      paddingBottom: theme.spacing(2)
    },
    input: {
      display: 'none'
    },
    container: {
      width: 450
    }
  })
);

type SupportedOwnership = Exclude<ReturnType<typeof getOwnership>, 'test_run'>;

const CreateJobDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(state => state.jobsList.lastLoadingStatus === LoadingStatusEnum.Loading);

  const [queue, setQueue] = useState<SupportedOwnership>('user');
  const [userId, setUserId] = useState(appConfig.grammarlyUserId);
  const [jobType, setJobType] = useState<JobType>(JobType.USER_GEC);
  const [turnaround, setTurnaround] = useState<TurnaroundOption>(TurnaroundOption.TwentyFourHours);
  const [proofreaderId, setProofreaderId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const isUserIdValid = useCallback(() => queue === 'corpus' || (userId && isJavaInteger(userId)), [userId, queue]);
  const isProofreaderIdValid = useCallback(() => !proofreaderId || isJavaInteger(proofreaderId), [proofreaderId]);
  const hasErrors = useCallback(() => !isUserIdValid() || !isProofreaderIdValid(), [
    isUserIdValid,
    isProofreaderIdValid
  ]);

  const onFileSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const files = event.target.files;
      if (files && files.length == 1 && files[0]) {
        setFile(files[0]);
      }
    },
    [setFile]
  );

  const isUserQueue = useMemo(() => queue === 'user', [queue]);

  const supportedJobTypes = useMemo(
    () =>
      Object.values(JobType)
        .filter(jt => ![JobType.USER_REALTIME, JobType.TEST_RUN].includes(jt))
        .filter(jt => getOwnership(jt) === queue),
    [queue]
  );

  const supportedTurnarounds = useMemo<TurnaroundOption[]>(() => {
    switch (jobType) {
      case JobType.USER_GEC:
        return [
          TurnaroundOption.TwentyFourHours,
          TurnaroundOption.SixHours,
          TurnaroundOption.ThreeHours,
          TurnaroundOption.Instant
        ];

      case JobType.USER_CLARITY:
        return [TurnaroundOption.TwentyFourHours, TurnaroundOption.SixHours, TurnaroundOption.ThreeHours];

      default:
        return [TurnaroundOption.TwentyFourHours];
    }
  }, [jobType]);

  useEffect(() => {
    setJobType(supportedJobTypes[0]);
  }, [queue, supportedJobTypes[0]]);

  const onClose = useCallback(() => dispatch(actions.hideCreateJobPopup()), [dispatch]);
  const onSave = useCallback(() => dispatch(actions.createJob(jobType, turnaround, userId, proofreaderId, file)), [
    dispatch,
    jobType,
    turnaround,
    userId,
    proofreaderId,
    file
  ]);

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.container }}>
      <DialogTitle>Create new job</DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Queue</InputLabel>
            <Select
              value={queue}
              onChange={e => setQueue(e.target.value as SupportedOwnership)}
              disabled={jobType === JobType.USER_REALTIME}
              inputProps={{ name: 'queue' }}
            >
              {['user', 'corpus'].map(ownership => (
                <MenuItem value={ownership} key={ownership}>
                  {capitalize(ownership)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              value={userId}
              error={!isUserIdValid()}
              onChange={e => setUserId(e.target.value)}
              inputProps={{ name: 'userId' }}
              label="User id"
              required={isUserQueue}
              disabled={!isUserQueue}
              type="text"
              margin="none"
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Job type</InputLabel>
            <Select value={jobType} onChange={e => setJobType(e.target.value as JobType)} inputProps={{ name: 'type' }}>
              {supportedJobTypes.map(jt => (
                <MenuItem value={jt} key={jt}>
                  {getGuideline(jt)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Turnaround</InputLabel>
            <Select
              value={turnaround}
              onChange={e => setTurnaround(e.target.value as TurnaroundOption)}
              required={isUserQueue}
              disabled={!isUserQueue}
              inputProps={{ name: 'turnaround' }}
            >
              {supportedTurnarounds.map(opt => (
                <MenuItem value={opt} key={opt}>
                  {prettyPrint(opt)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              value={proofreaderId}
              error={!isProofreaderIdValid()}
              onChange={e => setProofreaderId(e.target.value)}
              inputProps={{ name: 'proofreaderId' }}
              label="Proofreader ID (optional)"
              type="text"
              margin="none"
              fullWidth
            />
          </FormControl>
          <div>
            <input
              accept=".txt,.doc,.docx"
              className={classes.input}
              onChange={onFileSelected}
              id="upload-doc-button"
              type="file"
            />
            {file ? file.name : 'Choose file: '}
            <IconButton color="primary" size="small">
              <label htmlFor="upload-doc-button">
                <CloudUploadIcon />
              </label>
            </IconButton>
          </div>
          <Grid container direction="row" justify="flex-end">
            <Grid item>
              <Button color="primary" onClick={() => onSave()} disabled={hasErrors() || loading}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={onClose} disabled={loading}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateJobDialog;

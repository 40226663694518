import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import SchoolIcon from '@material-ui/icons/School';
import React from 'react';

import { SecurityGroup } from '../../modules/auth/okta';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { AppRoute } from '../../routes';

import PracticePage from './components/PracticePage';

const practiceRoute: AppRoute = {
  path: '/practice',
  component: PracticePage,
  parent: indexRoute,
  pathMatch: /\/practice/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-practiceRoute">
      <NavLink to="/practice">
        <SchoolIcon className="AppBreadcrumbsIcon" />
        Practice
      </NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

export default [practiceRoute];

import { Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { ChangeEvent } from 'react';
import moment from 'moment';

import { Job, JobAction, JobStatus } from '../../../job.interface';
import { OpsAndAdvSupportOnly, OpsOnly } from '../../../../../components/security/SecurityGroupFilter';
import { JobSource } from '../../../../../model/job/source';

interface Props {
  job: Job;
  changeJob: (job: Job, action: JobAction) => void;
  uploadRevisedResult: (job: Job, file: File) => void;
}

export const JobMenu: React.FunctionComponent<Props> = ({ job, changeJob, uploadRevisedResult }: Props) => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }

  function changeJobStatus(action: JobAction) {
    setAnchorElement(null);
    changeJob(job, action);
  }

  function onSelectedFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setAnchorElement(null);
    const files = event.target.files;
    if (files && files.length == 1 && files[0]) {
      uploadRevisedResult(job, files[0]);
    }
  }

  function shouldShowResendEmailButton(job: Job) {
    if (job.status != JobStatus.Completed || !job.completed) {
      return false;
    }

    const daysFromCompleted = moment.utc(Date.now()).diff(moment.utc(job.completed), 'days');

    if (job.source == JobSource.OfficeAddIn && daysFromCompleted > 14) {
      return false;
    }

    return true;
  }

  const id = `more-menu-${job.id}`;

  if (job.status === JobStatus.Cancelled) {
    return null;
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls={id} aria-haspopup="true" size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        {(job.status == JobStatus.New || job.status == JobStatus.Accepted) && (
          <MenuItem onClick={() => changeJobStatus(JobAction.complete)}>Complete job</MenuItem>
        )}
        <MenuItem onClick={() => changeJobStatus(JobAction.cancel)}>Cancel job</MenuItem>
        {shouldShowResendEmailButton(job) && (
          <OpsAndAdvSupportOnly>
            <MenuItem onClick={() => changeJobStatus(JobAction.resend)}>Send completion email</MenuItem>
          </OpsAndAdvSupportOnly>
        )}
        {job.status == JobStatus.Completed && !job.corpus && (
          <OpsOnly>
            <MenuItem>
              <input
                id={`revised-result-upload-${job.id}`}
                type="file"
                hidden={true}
                onChange={onSelectedFileChange}
                accept=".rtf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text"
              />
              <label htmlFor={'revised-result-upload-' + job.id}>Upload revised result</label>
            </MenuItem>
          </OpsOnly>
        )}
      </Menu>
    </div>
  );
};

import { ENVS, EnvType, MODES, ModeType, WindowConfig } from '../utils/declarations/window';

import { APP_CONFIG, AppConfig } from './app.config';
import LOCAL_CONFIG from './app.config.local';
import QA_CONFIG from './app.config.qa';
import PP_CONFIG from './app.config.pp';

declare global {
  interface Window {
    balto: WindowConfig;
  }
}

const balto: WindowConfig = window.balto || { mode: 'production', env: 'prod' };

if (balto.env !== 'prod' && URLSearchParams) {
  const urlParams = new URLSearchParams(window.location.search);
  const mode: ModeType = urlParams.get('mode') as ModeType;
  if (MODES.includes(mode)) {
    balto.mode = mode;
  }
  const env: EnvType = urlParams.get('env') as EnvType;
  if (ENVS.includes(env)) {
    balto.env = env;
  }
}

let config: AppConfig;

switch (balto.env) {
  case 'local':
    config = LOCAL_CONFIG;
    break;
  case 'qa':
    config = QA_CONFIG;
    break;
  case 'preprod':
    config = PP_CONFIG;
    break;
  case 'prod':
  default:
    config = APP_CONFIG;
    break;
}

config.mode = balto.mode || config.mode || 'production';

export default config;

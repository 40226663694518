import { all, call, cancel, fork, join, put, select, take, takeLatest } from '@redux-saga/core/effects';

import appConfig from '../../config';
import fetch from '../../utils/fetch';
import { ID } from '../../utils/types';
import { Proofreader } from '../../apis/proofreaders.api';
import { getProofreaders } from '../../redux/saga/get-proofreaders.saga';

import {
  PracticeAcceptJobAction,
  PracticeActionType,
  PracticeCreateJobAction,
  PracticeDeleteDocumentAction,
  practiceDocumentsActions,
  practiceGeneralActions,
  practiceJobsActions,
  PracticeRejectJobAction,
  PracticeUploadDocumentAction
} from './practice.actions';
import { PracticeJobFiltering } from './practice.interface';
import { PracticeDocumentDto, PracticeJobDto } from './practice.api.interface';
import { toPracticeDocument, toPracticeJob } from './_utils';

const ANY_FIN_ACTIONS = [
  PracticeActionType.StopLoading,
  PracticeActionType.DocsLoadingSuccess,
  PracticeActionType.DocsLoadingFailure,
  PracticeActionType.JobsLoadingSuccess,
  PracticeActionType.JobsLoadingFailure
];

const CANCEL_FIN_ACTIONS = [
  PracticeActionType.StopLoading,
  PracticeActionType.DocsLoadingFailure,
  PracticeActionType.JobsLoadingFailure
];

function* requestPracticeJobs() {
  try {
    const filtering: PracticeJobFiltering = yield select(store => store.practice.jobsFiltering);
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(filtering)
    };
    const url = `${appConfig.api.practice.runs}/filtered`;
    const response: PracticeJobDto[] = yield call(fetch.request.bind(fetch), url, config, 'application/json');
    yield put(practiceJobsActions.loadingSuccess(response.map(toPracticeJob)));
  } catch (error) {
    yield put(practiceJobsActions.loadingFailure(error.message));
  }
}

function* requestPracticeDocuments() {
  try {
    const url = `${appConfig.api.practice.documents}`;
    const response: PracticeDocumentDto[] = yield call(fetch.request.bind(fetch), url);
    yield put(practiceDocumentsActions.loadingSuccess(response.map(toPracticeDocument)));
  } catch (error) {
    yield put(practiceDocumentsActions.loadingFailure(error.message));
  }
}

function* requestProofreaders() {
  const proofreaders: Proofreader[] = yield getProofreaders();
  yield put(practiceGeneralActions.proofreadersLoadingSuccess(proofreaders));
}

function* practiceStartLoadingAsync() {
  let proofreaders = yield select(store => store.proofreadersList.proofreaders);
  if (!(proofreaders && proofreaders.length)) {
    proofreaders = yield select(store => store.snippets.filterProofreaders);
  }
  if (proofreaders && proofreaders.length) {
    yield put(practiceGeneralActions.proofreadersLoadingSuccess(proofreaders));
  } else {
    yield fork(requestProofreaders);
  }

  const practiceTasks = yield all([fork(requestPracticeDocuments), fork(requestPracticeJobs)]);

  for (let i = 0; i < 2; i++) {
    // jobs and documents
    let actionTask = yield take(ANY_FIN_ACTIONS);
    if (CANCEL_FIN_ACTIONS.includes(actionTask.type)) {
      yield cancel(practiceTasks);
      yield put(practiceGeneralActions.loadingFailure('fetch error'));
      return;
    }
  }

  yield join(practiceTasks);
  yield put(practiceGeneralActions.stopLoading());
}

function* requestUploadingPracticeDocument(file: File) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const config: RequestInit = {
      method: 'POST',
      body: formData
    };
    const response: PracticeDocumentDto = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.practice.documents}`,
      config
    );
    yield put(practiceDocumentsActions.uploadingSuccess(toPracticeDocument(response)));
  } catch (error) {
    yield put(practiceDocumentsActions.uploadingFailure(error.message));
  }
}

function* practiceDocStartUploadingAsync(action: PracticeUploadDocumentAction) {
  const practiceUploadDocTask = yield fork(requestUploadingPracticeDocument, action.payload.file);
  const actionTask = yield take([
    PracticeActionType.StopLoading,
    PracticeActionType.DocUploadingSuccess,
    PracticeActionType.DocUploadingFailure
  ]);
  if (actionTask.type === PracticeActionType.StopLoading) {
    yield cancel(practiceUploadDocTask);
  }
}

function* requestDeletionPracticeDocument(id: ID) {
  try {
    const config: RequestInit = {
      method: 'DELETE'
    };
    yield call(fetch.request.bind(fetch), `${appConfig.api.practice.documents}/${id}`, config);
    yield put(practiceDocumentsActions.deletionSuccess());
  } catch (error) {
    yield put(practiceDocumentsActions.deletionFailure(error.message));
  }
}

function* practiceDocStartDeletionAsync(action: PracticeDeleteDocumentAction) {
  const practiceUploadDocTask = yield fork(requestDeletionPracticeDocument, action.payload.id);
  const actionTask = yield take([
    PracticeActionType.StopLoading,
    PracticeActionType.DocUploadingSuccess,
    PracticeActionType.DocUploadingFailure
  ]);
  if (actionTask.type === PracticeActionType.StopLoading) {
    yield cancel(practiceUploadDocTask);
  }
}

function* practiceJobsStartLoadingAsync() {
  const practiceGetJobsTask = yield fork(requestPracticeJobs);
  const actionTask = yield take([
    PracticeActionType.StopLoading,
    PracticeActionType.JobsLoadingSuccess,
    PracticeActionType.JobsLoadingFailure
  ]);
  if (actionTask.type === PracticeActionType.StopLoading) {
    yield cancel(practiceGetJobsTask);
  }
  yield put(practiceGeneralActions.stopLoading());
}

function* requestJobAssessment(id: ID, action: string) {
  try {
    const config: RequestInit = {
      method: 'POST'
    };
    const url = `${appConfig.api.practice.runs}/${id}/${action}`;
    const response: PracticeJobDto = yield call(fetch.request.bind(fetch), url, config);
    yield put(practiceJobsActions.assessmentSuccess(toPracticeJob(response)));
  } catch (error) {
    yield put(practiceJobsActions.assessmentFailure(error.message));
  }
}

function* practiceJobStartAssessmentAsync(action: PracticeAcceptJobAction | PracticeRejectJobAction) {
  const actionString = action.type === PracticeActionType.AcceptJob ? 'accept' : 'reject';
  const practiceGetJobsTask = yield fork(requestJobAssessment, action.payload.id, actionString);
  const actionTask = yield take([
    PracticeActionType.StopLoading,
    PracticeActionType.JobAssessmentSuccess,
    PracticeActionType.JobAssessmentFailure
  ]);
  if (actionTask.type === PracticeActionType.StopLoading) {
    yield cancel(practiceGetJobsTask);
  } else if (actionTask.type === PracticeActionType.JobAssessmentSuccess) {
    yield put(practiceJobsActions.reloadJobs());
  }
}

function* requestJobCreation(documentId: string, proofreaderId: string) {
  try {
    const config: RequestInit = {
      method: 'POST'
    };
    const url = `${appConfig.api.practice.runs}?documentId=${documentId}&proofreaderId=${proofreaderId}`;
    yield call(fetch.request.bind(fetch), url, config);
    yield put(practiceJobsActions.creationSuccess());
  } catch (error) {
    yield put(practiceJobsActions.creationFailure(error.message));
  }
}

function* practiceJobStartCreatingAsync(action: PracticeCreateJobAction) {
  const practiceCreateJobTask = yield fork(requestJobCreation, action.payload.documentId, action.payload.proofreaderId);
  const actionTask = yield take([
    PracticeActionType.StopLoading,
    PracticeActionType.JobCreationSuccess,
    PracticeActionType.JobCreationFailure
  ]);
  if (actionTask.type === PracticeActionType.StopLoading) {
    yield cancel(practiceCreateJobTask);
  } else if (actionTask.type === PracticeActionType.JobCreationSuccess) {
    yield put(practiceJobsActions.reloadJobs());
  }
}

export default function* watchPracticeRootSaga() {
  yield takeLatest(PracticeActionType.StartLoading, practiceStartLoadingAsync);
  yield takeLatest(PracticeActionType.UploadDocument, practiceDocStartUploadingAsync);
  yield takeLatest(PracticeActionType.DeleteDocument, practiceDocStartDeletionAsync);
  yield takeLatest(PracticeActionType.FilterJobs, practiceJobsStartLoadingAsync);
  yield takeLatest(PracticeActionType.ReloadJobs, practiceJobsStartLoadingAsync);
  yield takeLatest([PracticeActionType.AcceptJob, PracticeActionType.RejectJob], practiceJobStartAssessmentAsync);
  yield takeLatest(PracticeActionType.CreateJob, practiceJobStartCreatingAsync);
}

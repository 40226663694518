import React from 'react';
import { NavLink } from 'react-router-dom';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';

import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { AppRoute } from '../../routes';
import { SecurityGroup } from '../../modules/auth/okta';

import SchedulePage from './components/SchedulePage';

const scheduleRoute: AppRoute = {
  path: '/schedule',
  component: SchedulePage,
  parent: indexRoute,
  pathMatch: /\/schedule/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-scheduleRoute">
      <NavLink to="/schedule">
        <Inbox className="AppBreadcrumbsIcon" />
        Schedule
      </NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.Scheduler],
  private: true,
  exact: true
};

export default [scheduleRoute];

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IconButton, Link, Modal } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { green, grey } from '@material-ui/core/colors';
import { ClipboardCheck, ContentCopy } from 'mdi-material-ui';

import { JobRevisedResultPopup } from '../../jobs-list.store';
import { AppsActions } from '../../../../../redux/actions/actions';
import { actions } from '../../jobs-list.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(2),
      width: 500,
      maxWidth: '90%',
      maxHeight: '90%',
      overflowY: 'auto'
    },
    title: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    newParagraph: {
      marginTop: theme.spacing(2)
    },
    linkParagraph: {
      whiteSpace: 'nowrap',
      textAlign: 'center'
    },
    copyIcon: {
      color: grey[700]
    },
    copiedIcon: {
      color: green[500]
    }
  })
);

const RevisedResultModal: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => {
  function calculateExpiration() {
    let expiration = new Date();
    expiration.setUTCDate(expiration.getUTCDate() + 14);
    return expiration.toUTCString();
  }

  const [state, setState] = useState({
    copied: false,
    expirationDateTime: calculateExpiration()
  });

  const classes = useStyles();

  return (
    <Modal open={true} onClose={props.closePopup} className={classes.modal}>
      <Paper className={classes.paper}>
        {props.popup.error && (
          <>
            <Typography variant="h6" className={classes.title}>
              Failed to upload correct revised result file
            </Typography>
            <Typography className={classes.newParagraph}>
              Please ensure the revised result file&apos;s filename contains the correct job ID:{' '}
              <b>{props.popup.jobId}</b>
            </Typography>
          </>
        )}
        {!props.popup.error && (
          <>
            <Typography variant="h6" className={classes.title}>
              Revised result uploaded
            </Typography>
            <Typography className={classes.linkParagraph}>
              <Link href={props.popup.message}>{props.popup.message}</Link>
              <CopyToClipboard
                text={props.popup.message}
                onCopy={() => setState(prevState => ({ ...prevState, copied: true }))}
              >
                <IconButton>
                  {state.copied ? (
                    <ClipboardCheck className={classes.copiedIcon} fontSize="small" titleAccess="Copied" />
                  ) : (
                    <ContentCopy className={classes.copyIcon} fontSize="small" titleAccess="Copy to clipboard" />
                  )}
                </IconButton>
              </CopyToClipboard>
            </Typography>
            <Typography>
              Please copy the link above and send it to the user so they can download the revised result document.
            </Typography>
            <Typography className={classes.newParagraph}>
              This revised document is not visible to the user yet, and no email has been sent to the user so far.
            </Typography>
            <Typography className={classes.newParagraph}>
              The link will be active until <b>{state.expirationDateTime}</b>.
            </Typography>
          </>
        )}
      </Paper>
    </Modal>
  );
};

interface Props {
  popup: JobRevisedResultPopup;
}

interface Dispatches {
  closePopup: () => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    closePopup: () => dispatch(actions.hideRevisedResultPopup())
  })
)(RevisedResultModal);

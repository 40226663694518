import { Reducer } from 'redux';

import { INIT_ONSHIFT_PROOFREADERS_STORE, OnshiftProofreadersStore } from './onshift-proofreaders.store';
import { OnshiftProofreadersActions, OnshiftProofreadersActionType } from './onshift-proofreaders.actions';

export const onshiftProofreadersReducer: Reducer<OnshiftProofreadersStore, OnshiftProofreadersActions> = (
  state = INIT_ONSHIFT_PROOFREADERS_STORE,
  action
) => {
  switch (action.type) {
    case OnshiftProofreadersActionType.StartLoading:
      return { ...state, loading: true };

    case OnshiftProofreadersActionType.LoadingSuccess:
      return { ...state, ...action.payload, loading: false };

    case OnshiftProofreadersActionType.LoadingFailure:
      return { ...state, loading: false };

    default:
      return state;
  }
};

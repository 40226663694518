import { call, put, take, takeLatest, race } from 'redux-saga/effects';

import { hideSpinner, showSpinner } from '../../pages/App/app.actions';
import fetch from '../../utils/fetch';
import { AppsActions } from '../actions/actions';

import { UploadFileStart, UploadFileAction } from './upload-file.actions';

function* request(
  url: string,
  file: File,
  onFailAction: (reason: string) => AppsActions,
  onSuccessAction: (response: any) => AppsActions
) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const config: RequestInit = {
      method: 'POST',
      body: formData
    };

    const response: string = yield call(fetch.request.bind(fetch), url, config);
    yield put(onSuccessAction(response));
  } catch (error) {
    yield put(onFailAction(error.message));
  }
}

function* uploadFileStartAsync(action: UploadFileStart) {
  try {
    yield put(showSpinner('upload-file'));
    yield race({
      task: call(
        request,
        action.payload.url,
        action.payload.file,
        action.payload.onFailAction,
        action.payload.onSuccessAction
      ),
      cancel: take(UploadFileAction.Stop)
    });
  } finally {
    yield put(hideSpinner('upload-file'));
  }
}

export default function*() {
  yield takeLatest(UploadFileAction.Start, uploadFileStartAsync);
}

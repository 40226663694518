import {
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  Assessment,
  Inbox,
  LibraryBooks,
  ListAlt,
  PowerSettingsNew,
  Schedule,
  School,
  Settings,
  Subject,
  ThumbsUpDown
} from '@material-ui/icons';
import GroupIcon from '@material-ui/icons/Group';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory } from 'react-router';

import { APP_ROUTES } from '../../app.routes';
import { SecurityGroup } from '../../../../modules/auth/okta';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20
    },
    menu: {
      width: 250,
      paddingTop: 0
    },
    topLevelItem: {
      textTransform: 'capitalize'
    },
    toolbar: theme.mixins.toolbar
  })
);

interface MenuItem {
  id: string;
  href?: string;
  icon?: typeof SvgIcon;
  text: string;
  children?: MenuItem[];
  securityGroups: SecurityGroup[];
}

const applicationMenu: MenuItem[] = [
  {
    id: 'jobs',
    href: '/jobs',
    text: 'jobs',
    icon: Inbox,
    securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.AdvancedSupport, SecurityGroup.Support]
  },
  // TODO: Remove these lines if no one complains about missing page
  // {
  //   id: 'snippets',
  //   href: '/snippets',
  //   text: 'Snippets/Slices',
  //   icon: ListAlt,
  //   securityGroups: [SecurityGroup.ProofitOps]
  // },
  {
    id: 'proofreaders',
    href: '/proofreaders',
    text: 'proofreaders',
    icon: GroupIcon,
    securityGroups: [SecurityGroup.ProofitOps]
  },
  {
    id: 'proofreaders-groups',
    href: '/groups',
    text: 'proofreaders groups',
    icon: GroupIcon,
    securityGroups: [SecurityGroup.ProofitOps]
  },
  {
    id: 'dashboard',
    href: '/onshift-proofreaders',
    text: 'dashboard',
    icon: DashboardIcon,
    securityGroups: [SecurityGroup.ProofitOps]
  },
  { id: 'peer-votes', text: 'peer votes', icon: ThumbsUpDown, securityGroups: [SecurityGroup.ProofitOps] },
  {
    id: 'votee-results',
    href: '/voting/votee-results',
    text: 'votee results',
    securityGroups: [SecurityGroup.ProofitOps]
  },
  {
    id: 'voter-results',
    href: '/voting/voter-results',
    text: 'voter results',
    securityGroups: [SecurityGroup.ProofitOps]
  },
  {
    id: 'voting-progress',
    href: '/voting/progress',
    text: 'voting progress',
    securityGroups: [SecurityGroup.ProofitOps]
  },
  {
    id: 'schedule',
    href: '/schedule',
    text: 'schedule',
    icon: Schedule,
    securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.Scheduler]
  },
  {
    id: 'corpora',
    href: '/corpora',
    text: 'corpora',
    icon: LibraryBooks,
    securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.AL]
  },
  { id: 'practice', href: '/practice', text: 'practice', icon: School, securityGroups: [SecurityGroup.ProofitOps] },
  { id: 'logs', href: '/logs', text: 'log', icon: Subject, securityGroups: [SecurityGroup.ProofitOps] },
  {
    id: 'settings',
    href: '/settings',
    text: 'settings',
    icon: Settings,
    securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.AdvancedSupport, SecurityGroup.Support]
  }
];

const disabledForProduction = ['proofreaders-groups'];

export default (props: { open: boolean; roles: SecurityGroup[]; logout: () => void }) => {
  const classes = useStyles();

  const history = useHistory();
  const find = APP_ROUTES.find(r => r.pathMatch.test(history.location.pathname));
  const menuItemsToDisplay = applicationMenu
    .filter(item => !disabledForProduction.includes(item.id))
    .filter(item => !!item.securityGroups.find(sg => props.roles.includes(sg)));

  return (
    <Drawer variant="persistent" open={props.open} anchor="right">
      <div className={classes.toolbar} />
      <List dense className={classes.menu}>
        {menuItemsToDisplay.map(item =>
          item.href ? (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.href}
              selected={find && item.href === find.path}
              data-name={item.id}
            >
              <ListItemIcon>{item.icon ? <item.icon className={classes.icon} /> : <div />}</ListItemIcon>
              <ListItemText className={classes.topLevelItem}>{item.text}</ListItemText>
            </ListItem>
          ) : (
            <ListItem key={item.text}>
              <ListItemIcon>
                <ListItemIcon>{item.icon ? <item.icon className={classes.icon} /> : <div />}</ListItemIcon>
              </ListItemIcon>
              <ListItemText className={classes.topLevelItem}>{item.text}</ListItemText>
            </ListItem>
          )
        )}
        <ListItem button onClick={props.logout}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

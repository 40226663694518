import { v4 as uuid } from 'uuid';

import { AppsActions, ErrorAction, isErrorAction } from '../actions/actions';
import { NotificationInterface } from '../../modules/notifications/notifications.interface';
import { notificationsAdd } from '../../modules/notifications/notifications.actions';

import { MiddlewareType } from './middleware';

// init
export const error: MiddlewareType<AppsActions> = ({ dispatch }) => next => action => {
  next(action);
  if (!isErrorAction(action)) {
    return;
  }

  const key = uuid();
  const error = (action as ErrorAction).payload;
  const notification: NotificationInterface = {
    key,
    message: error.message,
    options: {
      key,
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    },
    isClose: true
  };
  dispatch(notificationsAdd(notification));
  console.error(error);
};

export const errorsMiddleware = [error];

import { fork, join, put, takeEvery } from '@redux-saga/core/effects';

import appConfig from '../../../../config';
import { ActionType, GradeProofreaderStart, resultsActions } from '../results.actions';

import * as requests from './request';

function* gradeProofreader(action: GradeProofreaderStart) {
  const url = appConfig.api.voting.create.replace('{voteJobType}', action.payload.snippetType);

  const params = {
    proofreaderId: action.payload.proofreader.id,
    votingJobs: action.payload.voteJobs
  };

  const task = yield fork(requests.post, url, params, resultsActions.gradeProofreaderFailure);
  const result = yield join(task);

  if (result > 0) {
    yield put(resultsActions.gradeProofreaderSuccess(action.payload.proofreader, result));
  } else {
    yield put(resultsActions.gradeProofreaderFailure('no jobs were created'));
  }
}

export default function*() {
  yield takeEvery(ActionType.GradeProofreaderStart, gradeProofreader);
}

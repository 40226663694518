import { Reducer } from 'redux';

import { ProofitLogActions, ProofitLogActionType } from './proofit-log.actions';
import { INIT_PROOFIT_LOG_STORE, ProofitLogStore } from './proofit-log.store';

type ProofitLogReducer = Reducer<ProofitLogStore, ProofitLogActions>;

export const proofitLogReducer: ProofitLogReducer = (state = INIT_PROOFIT_LOG_STORE(), action) => {
  switch (action.type) {
    case ProofitLogActionType.LoadLog:
      return { ...state, loading: true, filtering: action.payload };

    case ProofitLogActionType.LoadingSuccess:
      return { ...state, loading: false, logs: action.payload.logs };

    case ProofitLogActionType.LoadingFailed:
      return { ...state, loading: false };

    case ProofitLogActionType.Reset:
      return INIT_PROOFIT_LOG_STORE();

    default:
      return state;
  }
};

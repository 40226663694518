import { Action } from 'redux';

import { Milliseconds, Timestamp } from '../../../utils/types';
import { ProofreaderRow, Shift } from '../view/store';
import { ErrorAction } from '../../../redux/actions/actions';
import { ShiftView } from '../ShiftView';

import { ApplyMode, ChangeOps, RepeatMode } from './store';

export enum ScheduleChangesActionType {
  /* In-table behavior */
  AddShift = 'schedule/change/add-shift',
  ChangeShift = 'schedule/change/change-shift',
  MoveShift = 'schedule/change/move-shift',

  /* Inline shift modification */
  ToggleSelection = 'schedule/change/toggle-selection',
  ResetSelection = 'schedule/change/reset-selection',

  /* Popper actions */
  RemoveShift = 'schedule/change/remove-shift',

  /* Journal actions */
  ChangeApplyMode = 'schedule/change/change-apply-mode',
  ChangeRepeatMode = 'schedule/change/change-repeat',
  UndoOperation = 'schedule/change/undo-operation',

  /* Submit */
  Submit = 'schedule/change/submit',
  SubmitSuccessful = 'schedule/change/submit-success',
  SubmitError = 'schedule/change/submit-error'
}

interface ChangeShift extends Action {
  type: ScheduleChangesActionType.ChangeShift;
  payload: {
    shift: ShiftView;
    begin: Timestamp;
    end: Timestamp;
  };
}

interface MoveShift extends Action {
  type: ScheduleChangesActionType.MoveShift;
  payload: {
    shift: Shift;
    offset: Milliseconds;
    durationDelta: Milliseconds;
  };
}

interface AddShift extends Action {
  type: ScheduleChangesActionType.AddShift;
  payload: {
    proofreader: ProofreaderRow;
    dayStart: Timestamp;
    offsetWithinDay: Milliseconds;
  };
}

interface ToggleSelection extends Action {
  type: ScheduleChangesActionType.ToggleSelection;
  payload: {
    shift: Shift;
  };
}

interface ResetSelection extends Action {
  type: ScheduleChangesActionType.ResetSelection;
}

interface RemoveShift {
  type: ScheduleChangesActionType.RemoveShift;
  payload: { shift: Shift };
}

interface ResetChange {
  type: ScheduleChangesActionType.UndoOperation;
  payload: { key: string };
}

interface ChangeApplyMode {
  type: ScheduleChangesActionType.ChangeApplyMode;
  payload: {
    opKey: string;
    applyTo: ApplyMode;
  };
}

interface ChangeRepeatMode {
  type: ScheduleChangesActionType.ChangeRepeatMode;
  payload: { opKey: string; changes: Partial<RepeatMode> };
}

export interface Submit {
  type: ScheduleChangesActionType.Submit;
  payload: {
    ops: ChangeOps;
  };
}

interface SubmitError extends ErrorAction {
  type: ScheduleChangesActionType.SubmitError;
}

type SubmitSuccessful = { type: ScheduleChangesActionType.SubmitSuccessful };

export const actions = {
  changeShift: (shift: ShiftView, begin: Timestamp, end: Timestamp): ChangeShift => ({
    type: ScheduleChangesActionType.ChangeShift,
    payload: { shift, begin, end }
  }),

  moveShift: (shift: Shift, offset: Milliseconds): MoveShift => ({
    type: ScheduleChangesActionType.MoveShift,
    payload: { shift, offset, durationDelta: 0 }
  }),

  addShift: (proofreader: ProofreaderRow, dayStart: Timestamp, offsetWithinDay: Milliseconds): AddShift => ({
    type: ScheduleChangesActionType.AddShift,
    payload: { proofreader, dayStart, offsetWithinDay }
  }),

  toggleSelection: (shift: Shift): ToggleSelection => ({
    type: ScheduleChangesActionType.ToggleSelection,
    payload: { shift }
  }),

  resetSelection: (): ResetSelection => ({ type: ScheduleChangesActionType.ResetSelection }),
  removeShift: (shift: Shift): RemoveShift => ({ type: ScheduleChangesActionType.RemoveShift, payload: { shift } }),
  resetChange: (key: string): ResetChange => ({ type: ScheduleChangesActionType.UndoOperation, payload: { key } }),

  changeModificationMode: (opKey: string, applyTo: ApplyMode): ChangeApplyMode => ({
    type: ScheduleChangesActionType.ChangeApplyMode,
    payload: { opKey, applyTo }
  }),

  changeRepeat: (opKey: string, changes: Partial<RepeatMode>): ChangeRepeatMode => ({
    type: ScheduleChangesActionType.ChangeRepeatMode,
    payload: { opKey, changes }
  }),

  submit: (ops: ChangeOps): Submit => ({
    type: ScheduleChangesActionType.Submit,
    payload: { ops }
  }),

  submitSuccessful: (): SubmitSuccessful => ({ type: ScheduleChangesActionType.SubmitSuccessful }),

  submitError: (reason: string): SubmitError => ({
    type: ScheduleChangesActionType.SubmitError,
    error: true,
    payload: new Error(`Failed loading proofreader schedule: ${reason}`)
  })
};

export type ScheduleChangesActions =
  | ChangeShift
  | MoveShift
  | AddShift
  | ToggleSelection
  | ResetSelection
  | RemoveShift
  | ResetChange
  | ChangeApplyMode
  | ChangeRepeatMode
  | Submit
  | SubmitSuccessful;

import { combineReducers } from 'redux';

import { ActionType as VoteeResultsActionType, Actions as VoteeResultsActions } from './VoteeResults/results.actions';
import { VotingProgressActions, VotingProgressActionType } from './VotingProgress/progress.actions';
import { ActionType as VoterResultsActionType, Actions as VoterResultsActions } from './VoterResults/actions';
import { resultsReducer } from './VoteeResults/results.reducer';
import { reducer as voterResultsReducer } from './VoterResults/reducer';
import { progressReducer } from './VotingProgress/progress.reducer';
import watchVotingResultsStartSaga from './VoteeResults/saga';
import watchVoterProgressStartSaga from './VoterResults/saga';
import watchVotingProgressStartSaga from './VotingProgress/progress.saga';
import * as VotingResultsStore from './VoteeResults/results.store';
import * as VoterResultsStore from './VoterResults/store';
import * as VotingProgressStore from './VotingProgress/progress.store';

export {
  VotingResultsStore,
  VotingProgressStore,
  VoterResultsStore,
  watchVotingResultsStartSaga,
  watchVoterProgressStartSaga,
  watchVotingProgressStartSaga
};

export const votingReducer = combineReducers({
  results: resultsReducer,
  voterResults: voterResultsReducer,
  progress: progressReducer
});

export type VotingActions = VoteeResultsActions | VoterResultsActions | VotingProgressActions;
export type VotingActionTypes = VoteeResultsActionType | VoterResultsActionType | VotingProgressActionType;

export type VotingErrorTypes =
  | VoteeResultsActionType.GradeProofreaderFailure
  | VoteeResultsActionType.LoadingFailure
  | VotingProgressActionType.LoadingFailure
  | VoterResultsActionType.LoadingFailure;

import { ID } from '../../utils/types';
import { CorpusType } from '../../model/corpus/corpusType';

export enum CorpusStatus {
  INITIALIZING = 'Initializing',
  UPLOAD_GOING = 'Uploading Going',
  UPLOAD_DONE = 'Uploading Done',
  PAUSED = 'Paused',
  FAILED_TO_INIT = 'Failed to initialize',
  COMPLETED = 'Completed',
  REMOVED = 'Removed'
}

export interface Corpus {
  id: ID;
  type: CorpusType;
  status: CorpusStatus;
  originalName: string;
  created: Date | null;
  redundancy: number;
  rawCount: number;
  processedCount: number;
  label: string | null;
  rank: number;
  wordsTotal: number;
  wordsProcessed: number;
  estimatedEndDate: Date | null;
  daysRemaining: number | null;
  jobsCompleted: number;
  jobsCancelled: number;
  jobsTotal: number;
}

export enum CorpusModificationOperation {
  PAUSE = 'pause',
  RESUME = 'resume',
  REMOVE = 'remove',
  EDIT_LABEL = 'editLabel'
}

export interface CorpusCreationInfo {
  type?: CorpusType;
  name?: string;
  artifact?: string;
  redundancy: number;
  forceAdditionalPass: boolean;
  doublePass: boolean;
}

export type CorpusTypeCount = {
  [key in CorpusType]: number;
};

export interface QueuesStats {
  wordsProcessedLastWeek: CorpusTypeCount;
}

import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import deepOrange from '@material-ui/core/colors/deepOrange';

import { StoreInterface } from '../../../redux/store/store';
import { Timestamp } from '../../../utils/types';
import { Scale } from '../view/store';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    currentTimeIndicator: {
      position: 'absolute',

      /* TODO: this should eventually reference row height of the parent table styles */
      top: 72,
      bottom: 0,
      width: 2,

      backgroundColor: deepOrange[400]
    }
  })
);

export const CurrentTimeIndicator: React.FunctionComponent<IndicatorProps> = (props: IndicatorProps) => {
  if (props.now < props.from.getTime() || props.now > props.to.getTime()) {
    return null;
  }

  const classes = styles();
  const relativeFromMs = props.now - props.from.getTime();
  const relativeFromPx = relativeFromMs / props.scale.scalingProps.millisPerPixel;
  const position = relativeFromPx + props.scale.scalingProps.baseOffset;

  return <div className={classes.currentTimeIndicator} style={{ left: position }} />;
};

interface ParentProps {
  now: Timestamp;
  scale: Scale;
}

interface Props {
  from: Date;
  to: Date;
}

export type IndicatorProps = Props & ParentProps;

const mapStateToProps = ({ schedule: { view } }: StoreInterface): Props => ({
  from: view.from,
  to: view.to
});

export default connect(mapStateToProps)(CurrentTimeIndicator);

import { JobDownloadInfo, JobStatus } from '../Jobs';
import { OnboardingStatus, ProofreaderStatus } from '../../apis/proofreaders.api';
import { str2enumStrict } from '../../utils/enums/str2enum';

import { PracticeDocumentDto, PracticeJobDto } from './practice.api.interface';
import { PracticeDocument, PracticeJob } from './practice.interface';

export const fileNameToLetterAvatar = (fileName: string) => {
  if (fileName.endsWith('.doc')) {
    return 'DC';
  } else if (fileName.endsWith('.docx')) {
    return 'DX';
  } else if (fileName.endsWith('.txt')) {
    return 'TX';
  } else {
    return '?';
  }
};

export const toJobDownloadInfo = (practiceJob: PracticeJob): JobDownloadInfo => ({
  id: practiceJob.jobId,
  hasOriginal: practiceJob.jobDownloadable,
  hasResult: practiceJob.jobDownloadable
});

export const toPracticeDocument = (dto: PracticeDocumentDto): PracticeDocument => ({
  id: dto.id,
  fileName: dto.fileName
});

export const toPracticeJob = (dto: PracticeJobDto): PracticeJob => ({
  id: dto.id,
  testDocumentName: dto.testDocumentName,
  proofreader: {
    name: dto.proofreaderName,
    shiftStatus: str2enumStrict(ProofreaderStatus, dto.proofreaderShiftStatus, 'proofreader status'),
    onboardingStatus: str2enumStrict(OnboardingStatus, dto.proofreaderOnboardingStatus, 'onboarding status')
  },
  jobId: dto.jobId,
  jobStatus: str2enumStrict(JobStatus, dto.jobStatus, 'job status'),
  jobDownloadable: dto.jobDownloadable
});

import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  AppBar,
  Badge,
  Button,
  ClickAwayListener,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import appConfig from '../../../../config';
import { StoreInterface } from '../../../../redux/store/store';

import AppMenu from './AppMenu';
import './AppNavbar.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20
    },
    toolbar: theme.mixins.toolbar
  })
);

export default () => {
  const { oktaAuth } = useOktaAuth();
  const authStore = useSelector((store: StoreInterface) => store.auth);
  const logout = async () => oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <div className="AppNavBar">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className="Title">
              <NavLink to="/">
                {appConfig.mode === 'production' && appConfig.env === 'prod' ? (
                  <span>Stanza Admin</span>
                ) : (
                  <Badge
                    badgeContent={`${appConfig.env.toLocaleUpperCase()}${
                      appConfig.mode !== 'production' ? ':' + appConfig.mode : ''
                    }`}
                    color="secondary"
                  >
                    Stanza Admin
                  </Badge>
                )}
              </NavLink>
            </Typography>

            {!!authStore.profile ? (
              <>
                <nav>
                  {
                    <Grid container alignItems="center">
                      <Person className={classes.icon} />
                      {authStore.profile.name}
                    </Grid>
                  }
                </nav>
                <IconButton style={{ color: 'inherit' }} onClick={() => setMenuOpen(!menuOpen)} data-name="menu-button">
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <nav>
                <Button component={Link} to="/login" color="inherit" data-name="AppNavLogin">
                  Login
                </Button>
              </nav>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <AppMenu
          roles={!!authStore.profile ? authStore.profile.groups : []}
          open={!!authStore.profile && menuOpen}
          logout={logout}
        />
      </div>
    </ClickAwayListener>
  );
};

import { Action, ActionType } from '../actions';

const formStateReducer = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case ActionType.CommenceUpdate:
      return true;

    case ActionType.ResetUpdate:
    case ActionType.UpdateSuccess:
    case ActionType.UpdateFailure:
      return false;

    default:
      return state;
  }
};

export default formStateReducer;

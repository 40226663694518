import { Reducer } from 'redux';

import { CorporaStoreInterface, INIT_CORPORA_STORE } from './corpora.store';
import { CorporaActions, CorporaActionType } from './corpora.actions';
import { clarityReportsComparator, corporaComparator, recalculateCorporaEndDates } from './_utils';

export type CorporaReducer = Reducer<CorporaStoreInterface, CorporaActions>;
export const corporaReducer: CorporaReducer = (state = INIT_CORPORA_STORE, action) => {
  let newCorpora = [];
  switch (action.type) {
    case CorporaActionType.StartLoading:
      return { ...state, loadingCorpora: true, loadingStats: true };

    case CorporaActionType.StopLoading:
      return { ...state, loadingCorpora: false, loadingStats: false };

    case CorporaActionType.CorporaLoadingSuccess:
      return {
        ...state,
        corpora: recalculateCorporaEndDates(action.payload.corpora.sort(corporaComparator), state.corpusQueuesStats),
        loadingCorpora: false
      };

    case CorporaActionType.CorporaLoadingFailure:
      return { ...state, corpora: [], loadingCorpora: false };

    case CorporaActionType.CreateCorpus:
    case CorporaActionType.ModifyCorpus:
    case CorporaActionType.SwapRanks:
      return { ...state, modifyingCorpus: true };

    case CorporaActionType.CorpusCreationFailure:
    case CorporaActionType.CorpusModificationFailure:
    case CorporaActionType.SwapRanksFailure:
      return { ...state, modifyingCorpus: false };

    case CorporaActionType.CorpusCreationSuccess:
      newCorpora = state.corpora;
      newCorpora.push(action.payload.corpus);
      newCorpora.sort(corporaComparator);
      return {
        ...state,
        corpora: recalculateCorporaEndDates(newCorpora, state.corpusQueuesStats),
        modifyingCorpus: false
      };

    case CorporaActionType.CorpusModificationSuccess:
      newCorpora = state.corpora.map(c => (c.id === action.payload.corpus.id ? action.payload.corpus : c));
      return {
        ...state,
        corpora: recalculateCorporaEndDates(newCorpora, state.corpusQueuesStats),
        modifyingCorpus: false
      };

    case CorporaActionType.SwapRanksSuccess:
      newCorpora = state.corpora
        .map(c =>
          c.id === action.payload.first.id
            ? action.payload.first
            : c.id === action.payload.second.id
            ? action.payload.second
            : c
        )
        .sort(corporaComparator);
      return {
        ...state,
        corpora: recalculateCorporaEndDates(newCorpora, state.corpusQueuesStats),
        modifyingCorpus: false
      };

    case CorporaActionType.ClarityReportsLoading:
      return { ...state, clarityReportsLoading: true };

    case CorporaActionType.ClarityReportsLoadingSuccess:
      return {
        ...state,
        clarityReports: action.payload.clarityReports.sort(clarityReportsComparator),
        clarityReportsLoading: false
      };

    case CorporaActionType.ClarityReportsLoadingFailure:
      return { ...state, clarityReportsLoading: false };

    case CorporaActionType.CorpusQueuesStatsLoadingSuccess:
      return {
        ...state,
        corpusQueuesStats: action.payload.stats,
        corpora: recalculateCorporaEndDates(state.corpora, action.payload.stats),
        loadingStats: false
      };

    case CorporaActionType.CorpusQueuesStatsLoadingFailure:
      return { ...state, loadingStats: false };

    default:
      return state;
  }
};

import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from '../../../../components/Errors/ErrorBoundary';
import AppNotifier from '../../../../modules/notifications/components/AppNotifier/AppNotifier';
import { AppLayout } from '../AppLayout/AppLayout';
import './App.css';
import { initialize, terminate } from '../../app.actions';
import { actions as authActions } from '../../../../modules/auth/actions';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(new Date()));
    return () => {
      dispatch(terminate(new Date()));
    };
  }, []);

  const { authState, oktaAuth } = useOktaAuth();
  useEffect(() => {
    if (authState == null || !authState.isAuthenticated) {
      dispatch(authActions.resetUser());
    } else {
      dispatch(authActions.getUserInfo(oktaAuth.getUser.bind(oktaAuth)));
    }
  }, [authState, oktaAuth]);

  return (
    <ErrorBoundary>
      <div className="App">
        <AppNotifier />
        <AppLayout />
      </div>
    </ErrorBoundary>
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FilteringSelect } from '../../../components/FilteringSelect/FilteringSelect';
import { CorpusType, prettyPrint } from '../../../model/corpus/corpusType';
import { str2enumStrict } from '../../../utils/enums/str2enum';
import { corpusActions } from '../corpora.actions';
import { CorpusCreationInfo } from '../corpora.interface';

const DEFAULT_INFO: CorpusCreationInfo = {
  redundancy: 1,
  doublePass: false,
  forceAdditionalPass: false
};

const corpusTypes = Object.values(CorpusType).map(corpusType => ({
  name: prettyPrint(corpusType),
  value: corpusType
}));

const useStyles = makeStyles(() =>
  createStyles({
    createInput: {
      minWidth: '400px'
    }
  })
);

interface Props {
  open: boolean;
  uploading: boolean;

  closeHandler: () => void;
}

export const CreateCorpusDialog = (props: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [corpus, setCorpus] = useState<CorpusCreationInfo>(DEFAULT_INFO);

  const artifactRegex = /^[\w-\\.]+:\d+$/i;
  const artifactErrorDisplayed = corpus.artifact !== undefined && !artifactRegex.test(corpus.artifact);
  const artifactInput = (
    <TextField
      fullWidth
      label="Datacat artifact"
      helperText={artifactErrorDisplayed ? 'Should match format <artifact_name>:<artifact_id>' : ''}
      value={corpus.artifact || ''}
      onChange={event => setCorpus({ ...corpus, artifact: event.target.value })}
      error={artifactErrorDisplayed}
    />
  );

  const typeInput = (
    <FilteringSelect
      label="Corpus type"
      initial={corpus.type || ''}
      data={corpusTypes}
      submit={value =>
        setCorpus({
          ...corpus,
          type: str2enumStrict(CorpusType, value, 'corpus type'),
          forceAdditionalPass: false,
          doublePass: false
        })
      }
    />
  );

  const nameErrorDisplayed = corpus.name !== undefined && corpus.name.length < 3;
  const nameInput = (
    <TextField
      fullWidth
      label="Corpus name"
      helperText={nameErrorDisplayed ? 'Should be at least 3 symbols long' : ''}
      value={corpus.name || ''}
      onChange={event => setCorpus({ ...corpus, name: event.target.value })}
      error={nameErrorDisplayed}
    />
  );

  const redundancyErrorDisplayed = corpus.redundancy < 1 || corpus.redundancy > 10;
  const redundancyInput = (
    <TextField
      fullWidth
      label="Redundancy"
      helperText={redundancyErrorDisplayed ? 'Should be between 1 and 10' : ''}
      value={corpus.redundancy}
      onChange={event => {
        const r: number = Number(event.target.value);
        setCorpus({ ...corpus, redundancy: isNaN(r) ? 1 : r });
      }}
      error={redundancyErrorDisplayed}
    />
  );

  const forceAdditionalPassOptionVisible = useMemo(() => corpus.type === CorpusType.CLARITY_PLUS, [corpus.type]);
  const forceAdditionalPassInput = forceAdditionalPassOptionVisible && (
    <FormControlLabel
      style={{ display: 'block' }}
      control={
        <Checkbox
          checked={corpus.forceAdditionalPass}
          onChange={() => setCorpus({ ...corpus, forceAdditionalPass: !corpus.forceAdditionalPass })}
          name="force additional GEC pass"
        />
      }
      label="Force additional GEC pass"
    />
  );

  const doublePassOptionVisible = useMemo(
    () =>
      corpus.type &&
      [CorpusType.CLARITY_PLUS, CorpusType.GEC, CorpusType.ADVANCED_GEC, CorpusType.PURE_GEC].includes(corpus.type),
    [corpus.type]
  );
  const doublePassInput = corpus.type && doublePassOptionVisible && (
    <FormControlLabel
      style={{ display: 'block' }}
      control={
        <Checkbox
          checked={corpus.doublePass}
          onChange={() => setCorpus({ ...corpus, doublePass: !corpus.doublePass })}
          name="double pass"
        />
      }
      label={`Double ${prettyPrint(corpus.type)} pass`}
    />
  );

  const invalid = useMemo(() => {
    const artifactInvalid = !corpus.artifact || artifactErrorDisplayed;
    const typeInvalid = !corpus.type;
    const nameInvalid = !corpus.name || nameErrorDisplayed;
    const redundancyInvalid = redundancyErrorDisplayed;

    return artifactInvalid || typeInvalid || nameInvalid || redundancyInvalid;
  }, [corpus.artifact, artifactErrorDisplayed, corpus.type, corpus.name, nameErrorDisplayed, redundancyErrorDisplayed]);

  const dialogCloseHandler = useCallback(() => {
    setCorpus(DEFAULT_INFO);
    props.closeHandler();
  }, [setCorpus, props.closeHandler]);

  const createButtonClickedHandler = useCallback(() => {
    if (invalid) return;

    dispatch(corpusActions.create(corpus));
    dialogCloseHandler();
  }, [dispatch, invalid, corpus, dialogCloseHandler]);

  return (
    <Dialog open={props.open} onClose={dialogCloseHandler}>
      <DialogTitle>Create a new corpus</DialogTitle>

      <DialogContent className={classes.createInput}>{artifactInput}</DialogContent>
      <DialogContent className={classes.createInput}>{typeInput}</DialogContent>
      <DialogContent className={classes.createInput}>{nameInput}</DialogContent>
      <DialogContent className={classes.createInput}>{redundancyInput}</DialogContent>

      <DialogContent hidden={!(forceAdditionalPassOptionVisible || doublePassOptionVisible)}>
        {forceAdditionalPassInput}
        {doublePassInput}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={props.uploading} onClick={dialogCloseHandler}>
          Cancel
        </Button>
        <Button autoFocus color="primary" disabled={props.uploading || invalid} onClick={createButtonClickedHandler}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import moment from 'moment';

import { Hours, ITimeInterval, Milliseconds, TimeInterval } from '../../../utils/types';
import { calculateTimeFrame } from '../utils';
import { nameColumnWidth, timeBoxWidth } from '../styles/constants';
import { RepeatMode } from '../edit/store';
import { ShiftView } from '../ShiftView';
import { Proofreader, ProofreaderDetails } from '../../../apis/proofreaders.api';

interface ScalingProps {
  columnWidth: number;
  millisPerPixel: number;
  baseOffset: number;
}

type ScaleName = 'daily' | 'weekly';

interface IScale {
  name: ScaleName;
  duration: Milliseconds;
  gaugeSize: number;
  step: moment.unitOfTime.Base;
  unitOfTime: moment.unitOfTime.Base;
  format: string;
}

export class Scale implements IScale {
  public name: ScaleName;
  public duration: Milliseconds;
  public gaugeSize: number;
  public step: moment.unitOfTime.Base;
  public unitOfTime: moment.unitOfTime.Base;
  public format: string;

  public scalingProps: ScalingProps;

  constructor(scale: IScale) {
    this.name = scale.name;
    this.duration = scale.duration;
    this.gaugeSize = scale.gaugeSize;
    this.step = scale.step;
    this.unitOfTime = scale.unitOfTime;
    this.format = scale.format;

    this.scalingProps = {
      columnWidth: timeBoxWidth / this.gaugeSize,
      millisPerPixel: this.duration / timeBoxWidth,
      baseOffset: nameColumnWidth
    };
  }
}

export const DAILY_SCALE = new Scale({
  name: 'daily',
  duration: 24 * 60 * 60 * 1000,
  gaugeSize: 24,
  step: 'hour',
  unitOfTime: 'day',
  format: 'HH'
});

export const WEEKLY_SCALE = new Scale({
  name: 'weekly',
  duration: 7 * 24 * 60 * 60 * 1000,
  gaugeSize: 7,
  step: 'day',
  unitOfTime: 'week',
  format: 'ddd, MMM DD'
});

export class SelectedColumn {
  public readonly column: number;
  public readonly interval: TimeInterval;

  constructor(column: number, scale: Scale, displayedInterval: TimeInterval) {
    const base = moment.utc(displayedInterval.begin).add(column, scale.step);

    const lower = base
      .clone()
      .startOf(scale.step)
      .valueOf();

    const upper = base
      .clone()
      .endOf(scale.step)
      .valueOf();

    this.column = column;
    this.interval = new TimeInterval(lower, upper);
  }
}

export interface Shift extends ITimeInterval {
  proofreader: Proofreader;
  shiftKey: string;
  seriesKey: string;
  repeat: RepeatMode;
}

export interface ProofreaderRow extends ProofreaderDetails {
  shifts: ShiftView[];
  totalHours: Hours;
}

export interface Store {
  loading: boolean;

  asOfDate: Date;
  scale: Scale;
  proofreaders: ProofreaderRow[];

  /* These two fields can be calculated */
  from: Date;
  to: Date;

  /* Filters */
  showNoShifts: boolean;
  selectedColumn?: SelectedColumn;
  proofreaderFilter: string;
  cohortFilter: string;
}

export const initStore = (asOfDate?: Date, scale?: Scale): Store => {
  asOfDate = asOfDate || new Date(Date.now());
  scale = scale || DAILY_SCALE;

  const { from, to } = calculateTimeFrame(asOfDate, scale);

  const defaults = {
    loading: false,
    proofreaders: [],
    showNoShifts: true,
    proofreaderFilter: '',
    cohortFilter: '*'
  };

  return { asOfDate, scale, from, to, ...defaults };
};

import { Reducer } from 'redux';

import { TimeInterval } from '../../../utils/types';
import { calculateTimeFrame, defaultSelectedColumn } from '../utils';

import { DAILY_SCALE, initStore, SelectedColumn, Store, WEEKLY_SCALE } from './store';
import { ScheduleViewActions, ScheduleViewActionType } from './actions';

let reducer: Reducer<Store, ScheduleViewActions>;

export default reducer = (state = initStore(), action) => {
  switch (action.type) {
    case ScheduleViewActionType.InitialLoading: {
      const { asOfDate, scale } = action.payload;
      const { from, to } = calculateTimeFrame(asOfDate, scale);
      const selectedColumn = defaultSelectedColumn(scale, from.getTime(), to.getTime());

      return { ...state, loading: true, asOfDate, scale, from, to, selectedColumn };
    }

    case ScheduleViewActionType.StartLoading:
      return { ...state, loading: true, from: action.payload.from, to: action.payload.to };

    case ScheduleViewActionType.StopLoading:
      return { ...state, loading: false };

    case ScheduleViewActionType.LoadingSuccess: {
      const proofreaders = action.payload;
      return { ...state, loading: false, proofreaders };
    }

    case ScheduleViewActionType.LoadingFailure:
      return { ...state, loading: false };

    case ScheduleViewActionType.DailyScale: {
      const scale = DAILY_SCALE;
      const { from, to } = calculateTimeFrame(state.asOfDate, scale);

      return { ...state, proofreaders: [], scale, from, to, selectedColumn: undefined };
    }

    case ScheduleViewActionType.WeeklyScale: {
      const scale = WEEKLY_SCALE;
      const { from, to } = calculateTimeFrame(state.asOfDate, scale);

      return { ...state, proofreaders: [], scale, from, to, selectedColumn: undefined };
    }

    case ScheduleViewActionType.ToggleColumn: {
      const selectedColumn =
        state.selectedColumn && state.selectedColumn.column === action.payload.columnIndex
          ? undefined
          : new SelectedColumn(
              action.payload.columnIndex,
              state.scale,
              new TimeInterval(state.from.getTime(), state.to.getTime())
            );

      return { ...state, selectedColumn: selectedColumn };
    }

    case ScheduleViewActionType.NextPeriod:
      const nextAsOf = new Date(state.asOfDate.getTime() + state.scale.duration);
      return {
        ...state,
        proofreaders: [],
        asOfDate: nextAsOf,
        selectedColumn: undefined,
        ...calculateTimeFrame(nextAsOf, state.scale)
      };

    case ScheduleViewActionType.PreviousPeriod:
      const prevAsOf = new Date(state.asOfDate.getTime() - state.scale.duration);
      return {
        ...state,
        proofreaders: [],
        asOfDate: prevAsOf,
        selectedColumn: undefined,
        ...calculateTimeFrame(prevAsOf, state.scale)
      };

    case ScheduleViewActionType.ToggleShowNoShifts:
      return { ...state, showNoShifts: !state.showNoShifts };

    case ScheduleViewActionType.SetProofreaderFilter:
      return { ...state, proofreaderFilter: action.payload.value };

    case ScheduleViewActionType.SetPermissionFilter:
      return { ...state, cohortFilter: action.payload.value };

    default:
      return state;
  }
};

import { assertNever } from '../../utils/errors';

export enum CorpusType {
  GEC = 'GEC',
  ADVANCED_GEC = 'ADVANCED_GEC',
  PURE_GEC = 'PURE_GEC',
  PURE_GEC_PARALLEL = 'PURE_GEC_PARALLEL',
  REALTIME = 'REALTIME',
  CLARITY = 'CLARITY',
  PARA_CLARITY = 'PARA_CLARITY',
  CLARITY_PLUS = 'CLARITY_PLUS',
  CLARITY_PLUS_PARALLEL = 'CLARITY_PLUS_PARALLEL',
  ACADEMIC_TONE = 'ACADEMIC_TONE'
}

export const prettyPrint = (type: CorpusType) => {
  switch (type) {
    case CorpusType.GEC:
      return 'GEC';
    case CorpusType.ADVANCED_GEC:
      return 'Advanced GEC';
    case CorpusType.PURE_GEC:
      return 'Pure GEC';
    case CorpusType.PURE_GEC_PARALLEL:
      return 'Pure GEC (Parallel)';
    case CorpusType.REALTIME:
      return 'Realtime';
    case CorpusType.CLARITY:
      return 'Sentence Clarity';
    case CorpusType.PARA_CLARITY:
      return 'Para Clarity';
    case CorpusType.CLARITY_PLUS:
      return 'Clarity Plus';
    case CorpusType.CLARITY_PLUS_PARALLEL:
      return 'Clarity Plus (Parallel)';
    case CorpusType.ACADEMIC_TONE:
      return 'Academic Tone';

    default:
      assertNever(type);
  }
};

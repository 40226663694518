import React from 'react';
import { NavLink } from 'react-router-dom';
import Subject from '@material-ui/icons/Subject';
import Typography from '@material-ui/core/Typography';

import { SecurityGroup } from '../../modules/auth/okta';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';

import ProofitLogPage from './components/ProofitLogPage';

export default {
  path: '/logs',
  component: ProofitLogPage,
  parent: indexRoute,
  pathMatch: /\/logs/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-proofitLogsRoute">
      <NavLink to="/logs">
        <Subject className="AppBreadcrumbsIcon" />
        Logs
      </NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import { AppsActions } from '../../../redux/actions/actions';
import { history, StoreInterface } from '../../../redux/store/store';
import { parseParams } from '../utils';
import { scheduleActions } from '../view/actions';
import { Scale } from '../view/store';
import PaddedBox from '../../../components/layout/PaddedBox';

import ControlButtons from './ControlButtons';
import ToggleShowNoShiftsButton from './ToggleShowNoShiftsButton';
import CalendarTable from './CalendarTable';
import ChangesList from './ChangesList';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 'calc(100vh - 250px)',
      padding: theme.spacing(1),
      width: 'fit-content'
    },
    scrollable: {
      overflow: 'scroll',
      height: '100%'
    }
  })
);

const SchedulePage = (props: Props & Dispatches) => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const classes = useStyles();

  if (!initialized) {
    /* TODO: use react-router's `useParams` hook instead direct access to history API */
    props.load(history.location.search);
    setInitialized(true);
  }

  return (
    <PaddedBox>
      <ControlButtons />

      {initialized && !props.loading && (
        <Grid container spacing={2} justify="center" className={classes.root} wrap="nowrap">
          <Grid item className={classes.scrollable}>
            <CalendarTable />
          </Grid>
          <Grid item className={classes.scrollable}>
            <ChangesList />
          </Grid>
        </Grid>
      )}

      <Grid container justify="center">
        <Grid item>
          <ToggleShowNoShiftsButton />
        </Grid>
      </Grid>
    </PaddedBox>
  );
};

interface Props {
  scale: Scale;
  loading: boolean;
}

interface Dispatches {
  load: (query: string) => void;
}

export default connect(
  ({ schedule: { view } }: StoreInterface): Props => ({
    scale: view.scale,
    loading: view.loading
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    load: (query: string) => {
      const { asOf, scale } = parseParams(query);
      dispatch(scheduleActions.initialLoad(asOf, scale));
    }
  })
)(SchedulePage);

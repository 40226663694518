import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects';

import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { hideSpinner, showSpinner } from '../App/app.actions';

import {
  AvailabilitiesUpdateDto,
  Dictionary,
  GeneralSettingsDto,
  Settings,
  SettingType,
  Turnaround
} from './settings.interface';
import {
  AvailabilityUpdateAction,
  GeneralSettingUpdateAction,
  MaxPricesUpdateAction,
  PricesUpdateAction,
  settingsActions,
  SettingsActionType,
  SettingsStartLoadingAction
} from './settings.actions';

export function* initialLoad() {
  try {
    const settings: Settings = yield call(fetch.request.bind(fetch), `${appConfig.api.settings.getSettings}`);
    yield put(settingsActions.loadingSuccess(settings));
  } catch (error) {
    yield put(settingsActions.loadingFailure(error.message));
  }
}

export function* updateGeneralSettings(maxCapacity: number) {
  try {
    const dto: GeneralSettingsDto = { maxCapacity };

    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(dto)
    };

    const generalSettings: GeneralSettingsDto = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.settings.generalSettingsUpdate}`,
      config,
      'application/json'
    );

    yield put(settingsActions.generalSettingUpdateSuccess(generalSettings.maxCapacity));
  } catch (error) {
    yield put(settingsActions.updateFailure(error.message));
  }
}

export function* updateAvailabilities(priceOptionsState: Dictionary<boolean>, type: SettingType) {
  try {
    const dto: AvailabilitiesUpdateDto = { availabilities: priceOptionsState };

    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(dto)
    };

    const clarity = type === SettingType.CLARITY;

    const options: Dictionary<Turnaround> = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.settings.availabilitiesUpdate}?clarity=${clarity}`,
      config,
      'application/json'
    );

    yield put(settingsActions.availabilityUpdateSuccess(options, type));
  } catch (error) {
    yield put(settingsActions.updateFailure(error.message));
  }
}

export function* updatePrices(prices: Dictionary<Dictionary<number>>, type: SettingType) {
  try {
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(prices)
    };

    const clarity = type === SettingType.CLARITY;

    const options: Dictionary<Dictionary<number>> = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.settings.pricesUpdate}?clarity=${clarity}`,
      config,
      'application/json'
    );

    yield put(settingsActions.pricesUpdateSuccess(options, type));
  } catch (error) {
    yield put(settingsActions.updateFailure(error.message));
  }
}

export function* updateMaxPrices(maxPrices: Dictionary<number>) {
  try {
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(maxPrices)
    };

    const prices: Dictionary<number> = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.settings.maxPricesUpdate}`,
      config,
      'application/json'
    );

    yield put(settingsActions.maxPricesUpdateSuccess(prices));
  } catch (error) {
    yield put(settingsActions.updateFailure(error.message));
  }
}

export function* settingsLoadStartAsync(action: SettingsStartLoadingAction) {
  try {
    yield put(showSpinner('load-settings'));
    const task = yield fork(initialLoad);
    const actionTask = yield take([SettingsActionType.LoadingFailure, SettingsActionType.LoadingSuccess]);
    if (actionTask.type === SettingsActionType.LoadingFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('load-settings'));
  }
}

export function* updateGeneralSettingsAsync(action: GeneralSettingUpdateAction) {
  try {
    yield put(showSpinner('update-general-settings'));
    const task = yield fork(updateGeneralSettings, action.payload);
    const actionTask = yield take([SettingsActionType.GeneralSettingsUpdateSuccess, SettingsActionType.UpdateFailure]);
    if (actionTask.type === SettingsActionType.UpdateFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('update-general-settings'));
  }
}

export function* updateAvailabilitiesAsync(action: AvailabilityUpdateAction) {
  try {
    yield put(showSpinner('update-availabilities-settings'));
    const task = yield fork(updateAvailabilities, action.payload.options, action.payload.type);
    const actionTask = yield take([SettingsActionType.AvailabilityUpdateSuccess, SettingsActionType.UpdateFailure]);
    if (actionTask.type === SettingsActionType.UpdateFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('update-availabilities-settings'));
  }
}

export function* updatePricesAsync(action: PricesUpdateAction) {
  try {
    yield put(showSpinner('update-prices-settings'));
    const task = yield fork(updatePrices, action.payload.prices, action.payload.type);
    const actionTask = yield take([SettingsActionType.PricesUpdateSuccess, SettingsActionType.UpdateFailure]);
    if (actionTask.type === SettingsActionType.UpdateFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('update-prices-settings'));
  }
}

export function* updateMaxPricesAsync(action: MaxPricesUpdateAction) {
  try {
    yield put(showSpinner('update-surge-prices-settings'));
    const task = yield fork(updateMaxPrices, action.payload);
    const actionTask = yield take([SettingsActionType.MaxPricesUpdateSuccess, SettingsActionType.UpdateFailure]);
    if (actionTask.type === SettingsActionType.UpdateFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('update-surge-prices-settings'));
  }
}

export function* watchSettingsSaga() {
  yield takeLatest(SettingsActionType.StartLoading, settingsLoadStartAsync);
  yield takeLatest(SettingsActionType.GeneralSettingsUpdate, updateGeneralSettingsAsync);
  yield takeLatest(SettingsActionType.AvailabilityUpdate, updateAvailabilitiesAsync);
  yield takeLatest(SettingsActionType.PricesUpdate, updatePricesAsync);
  yield takeLatest(SettingsActionType.MaxPricesUpdate, updateMaxPricesAsync);
}

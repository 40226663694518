import { Reducer } from 'redux';

import { duration, ITimeInterval, Millis, Milliseconds, Timestamp } from '../../../utils/types';
import { Shift } from '../view/store';
import { ScheduleViewActionType } from '../view/actions';
import { roundToBase } from '../../../utils/math';
import { display, ShiftView } from '../ShiftView';
import { ScheduleActions } from '..';
import { Proofreader } from '../../../apis/proofreaders.api';

import {
  Addition,
  ApplyMode,
  ChangeOps,
  initStore,
  Modification,
  Operation,
  OperationType,
  Removal,
  RepeatMode,
  repeatModes,
  RepeatType,
  Store
} from './store';
import { ScheduleChangesActionType } from './actions';

const newRepeat = (op: Operation, applyTo: ApplyMode): RepeatMode => {
  if (op.type !== OperationType.Addition) {
    switch (applyTo) {
      case ApplyMode.SingleShift:
        return { ...op.repeatMode, from: new Date(op.shift.begin), to: new Date(op.shift.begin) };
      case ApplyMode.FutureShifts:
        return { ...op.repeatMode, from: new Date(op.shift.begin), to: op.shift.repeat.to };
      case ApplyMode.AllShifts:
        return { ...op.repeatMode, from: op.shift.repeat.from, to: op.shift.repeat.to };
    }
  }

  return op.repeatMode;
};

const newModification = (shift: Shift, offset: Milliseconds, durationDiff: Milliseconds): Modification => ({
  type: OperationType.Modification,
  applyMode: ApplyMode.FutureShifts,
  repeatMode: { ...shift.repeat, from: new Date(shift.begin), to: shift.repeat.to },
  shift,
  offset,
  durationDiff
});

const newAddition = (key: string, proofreader: Proofreader, dayStart: Timestamp, offset: Milliseconds): Addition => ({
  type: OperationType.Addition,
  key,
  proofreader,
  begin: roundToBase(offset, Millis.quarterHour),
  duration: Millis.hour * 2,
  repeatMode: repeatModes.once(dayStart)
});

const newRemoval = (shift: Shift): Removal => ({
  type: OperationType.Removal,
  applyMode: ApplyMode.SingleShift,
  repeatMode: repeatModes.once(shift.begin),
  shift
});

const moveShift = (ops: ChangeOps, shift: Shift, diffBegin: Milliseconds, diffDuration: Milliseconds): ChangeOps => {
  const { [shift.seriesKey]: oldOp, ...rest } = ops;

  diffBegin = roundToBase(diffBegin, Millis.quarterHour);
  diffDuration = roundToBase(diffDuration, Millis.quarterHour);

  if (!oldOp) {
    /* Modifying existing shift */
    const modification = newModification(shift, diffBegin, diffDuration);
    const hasActualChange = modification.offset !== 0 || modification.durationDiff !== 0;
    return hasActualChange ? { ...rest, [shift.seriesKey]: modification } : rest;
  } else if (oldOp.type === OperationType.Addition) {
    /* Modifying not yet existing added shift */
    const newOp = { ...oldOp, begin: oldOp.begin + diffBegin, duration: oldOp.duration + diffDuration };
    return { ...rest, [shift.seriesKey]: newOp };
  } else if (oldOp.type === OperationType.Modification) {
    /* Modifying already modified existing shift */
    const newDiffBegin = oldOp.offset + diffBegin;
    const newDiffDuration = oldOp.durationDiff + diffDuration;
    const modification = { ...oldOp, offset: newDiffBegin, durationDiff: newDiffDuration };
    const hasActualChange = modification.offset !== 0 || modification.durationDiff !== 0;
    return hasActualChange ? { ...rest, [shift.seriesKey]: modification } : rest;
  } else if (oldOp.type === OperationType.Removal) {
    /* Modifying removed shift. Do nothing */
    return ops;
  } else {
    throw new Error('Unknown change type in the stored change: ' + JSON.stringify(oldOp));
  }
};

const setShift = (ops: ChangeOps, shiftView: ShiftView, values: ITimeInterval): ChangeOps => {
  const shift = display(shiftView);

  const offset = values.begin - shift.begin;
  const durationDiff = duration(values) - duration(shift);

  return moveShift(ops, shift, offset, durationDiff);
};

const updateRepeatMode = (repeatMode: RepeatMode, updates: Partial<RepeatMode>): RepeatMode => {
  const result: RepeatMode = { ...repeatMode, ...updates };

  if (result.type === RepeatType.Once) {
    result.to = result.from;
  }

  return result;
};

let reducer: Reducer<Store, ScheduleActions>;
export default reducer = (state = initStore(), action) => {
  switch (action.type) {
    case ScheduleChangesActionType.ToggleSelection: {
      const shift = action.payload.shift;
      return { ...state, selected: state.selected === shift.shiftKey ? undefined : shift.shiftKey };
    }

    case ScheduleChangesActionType.ResetSelection: {
      return { ...state, selected: undefined };
    }

    case ScheduleChangesActionType.ChangeShift: {
      const { shift, begin, end } = action.payload;
      return { ...state, operations: setShift(state.operations, shift, { begin, end }) };
    }

    case ScheduleChangesActionType.MoveShift: {
      const { shift, offset, durationDelta } = action.payload;
      return { ...state, operations: moveShift(state.operations, shift, offset, durationDelta) };
    }

    case ScheduleChangesActionType.AddShift: {
      const key = Date.now().toString();
      const { proofreader, dayStart, offsetWithinDay } = action.payload;

      const addition = newAddition(key, proofreader, dayStart, offsetWithinDay);
      return { ...state, operations: { ...state.operations, [key]: addition } };
    }

    case ScheduleChangesActionType.RemoveShift: {
      const { shift } = action.payload;
      const { [shift.seriesKey]: oldChange, ...restOps } = state.operations;
      const removingAddition = oldChange && oldChange.type === OperationType.Addition;
      const operations = removingAddition ? restOps : { ...restOps, [shift.seriesKey]: newRemoval(shift) };

      return { ...state, selected: undefined, operations };
    }

    case ScheduleChangesActionType.UndoOperation: {
      const { [action.payload.key]: toUndo, ...otherChanges } = state.operations;
      return { ...state, operations: otherChanges };
    }

    case ScheduleChangesActionType.ChangeApplyMode: {
      const { opKey, applyTo } = action.payload;
      const { [opKey]: op, ...rest } = state.operations;

      const repeatMode = newRepeat(op, applyTo);
      const modifiedChange = { ...op, applyMode: applyTo, repeatMode };

      return { ...state, operations: { ...rest, [opKey]: modifiedChange } };
    }

    case ScheduleChangesActionType.ChangeRepeatMode: {
      const { opKey, changes } = action.payload;
      const { [opKey]: changeToModify, ...rest } = state.operations;

      const modifiedChange = { ...changeToModify, repeatMode: updateRepeatMode(changeToModify.repeatMode, changes) };

      return { ...state, operations: { ...rest, [opKey]: modifiedChange } };
    }

    case ScheduleChangesActionType.SubmitSuccessful:
    case ScheduleViewActionType.NextPeriod:
    case ScheduleViewActionType.PreviousPeriod:
    case ScheduleViewActionType.DailyScale:
    case ScheduleViewActionType.WeeklyScale:
      return initStore();

    default:
      return state;
  }
};

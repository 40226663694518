import { Action } from 'redux';

import { ErrorAction } from '../actions/actions';
import appConfig from '../../config';
import { JobFileType, StepLabel } from '../../pages/Jobs';

export enum DownloadFileActionType {
  StartLoading = 'download-file/start-loading',
  StopLoading = 'download-file/stop-loading',
  LoadingSuccess = 'download-file/loading-success',
  LoadingFailed = 'download-file/loading-failed'
}

/**
 * DownloadFileStart
 */
export interface DownloadFileStartLoadingAction extends Action {
  type: DownloadFileActionType.StartLoading;
  payload: {
    url: string;
    // please avoid passing non-null as this field should be deprecated in favor of getting filename from the server
    filename: string | null;
  };
}

export const downloadReport = (url: string, filename: string): DownloadFileStartLoadingAction => ({
  type: DownloadFileActionType.StartLoading,
  payload: {
    url,
    filename
  }
});

export const downloadStepFileStartLoading = (
  jobId: number,
  stepId: string,
  fileType: JobFileType,
  additionalPass: boolean
): DownloadFileStartLoadingAction => {
  /* TODO: move URL generation to saga */
  let url = `${appConfig.api.jobsList}/${jobId}/steps/${stepId}/download?type=${fileType}&additionalPass=${additionalPass}`;

  return {
    type: DownloadFileActionType.StartLoading,
    payload: { url, filename: null }
  };
};

export const downloadFileStartLoading = (jobId: number, fileType: JobFileType): DownloadFileStartLoadingAction => {
  /* TODO: move URL generation to saga */
  const url = `${appConfig.api.jobsList}/${jobId}/download?type=${fileType}`;

  return {
    type: DownloadFileActionType.StartLoading,
    payload: { url, filename: null }
  };
};

/**
 * DownloadFileStop
 */
export interface DownloadFileStopLoadingAction extends Action {
  type: DownloadFileActionType.StopLoading;
}

/**
 * DownloadFileSuccess
 */
export interface DownloadFileLoadingSuccessAction extends Action {
  type: DownloadFileActionType.LoadingSuccess;
}

export const downloadFileLoadingSuccess = (): DownloadFileLoadingSuccessAction => ({
  type: DownloadFileActionType.LoadingSuccess
});

/**
 * DownloadFileFailed
 */
export interface DownloadFileLoadingFailedAction extends ErrorAction {
  type: DownloadFileActionType.LoadingFailed;
}

export const downloadFileLoadingFailed = (reason: string): DownloadFileLoadingFailedAction => ({
  type: DownloadFileActionType.LoadingFailed,
  payload: new Error(`Download failed: ${reason}`),
  error: true
});

/**
 * All App Actions
 */
export type DownloadFileActions =
  | DownloadFileStartLoadingAction
  | DownloadFileStopLoadingAction
  | DownloadFileLoadingSuccessAction
  | DownloadFileLoadingFailedAction;

import React, { FunctionComponent, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

import { AppsActions } from '../../../redux/actions/actions';
import { clarityReportActions, corporaGeneralActions } from '../corpora.actions';
import { StoreInterface } from '../../../redux/store/store';

import ClarityReportsTable from './ClarityReportsTable';
import { CorporaPaper } from './CorporaPaper';

export const CorporaPage: FunctionComponent<CorporaPageProps> = (props: CorporaPageProps) => {
  useEffect(() => {
    props.load();
    return props.stopLoading;
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} lg={10}>
          <CorporaPaper />
        </Grid>
        <Grid item xs={12} lg={10}>
          <ClarityReportsTable />
        </Grid>
      </Grid>
    </Container>
  );
};

interface Props {
  modifying: boolean;
}

interface Dispatches {
  load: () => void;
  stopLoading: () => void;
}

type CorporaPageProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface): Props => ({
  modifying: state.corpora.modifyingCorpus
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  load: () => {
    dispatch(corporaGeneralActions.startLoading());
    dispatch(clarityReportActions.clarityReportsLoad());
  },
  stopLoading: () => dispatch(corporaGeneralActions.stopLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(CorporaPage);

import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';

import { Dictionary, Settings, SettingType, Turnaround } from './settings.interface';

export enum SettingsActionType {
  StartLoading = 'settings/start-loading',
  LoadingSuccess = 'settings/loading-success',
  LoadingFailure = 'settings/loading-failure',
  UpdateFailure = 'settings/update-failure',

  GeneralSettingsUpdate = 'settings/general/update',
  GeneralSettingsUpdateSuccess = 'settings/general/update-success',

  AvailabilityUpdate = 'settings/availability/update',
  AvailabilityUpdateSuccess = 'settings/availability/update-success',

  PricesUpdate = 'settings/prices/update',
  PricesUpdateSuccess = 'settings/prices/update-success',

  MaxPricesUpdate = 'settings/max-prices/update',
  MaxPricesUpdateSuccess = 'settings/max-prices/update-success'
}

export interface SettingsStartLoadingAction extends Action {
  type: SettingsActionType.StartLoading;
}

interface SettingsLoadingSuccessAction extends Action {
  type: SettingsActionType.LoadingSuccess;
  payload: Settings;
}

interface SettingsLoadingFailureAction extends ErrorAction {
  type: SettingsActionType.LoadingFailure;
}

interface UpdateFailureAction extends ErrorAction {
  type: SettingsActionType.UpdateFailure;
}

export interface GeneralSettingUpdateAction extends Action {
  type: SettingsActionType.GeneralSettingsUpdate;
  payload: number;
}

interface GeneralSettingUpdateSuccessAction extends Action {
  type: SettingsActionType.GeneralSettingsUpdateSuccess;
  payload: number;
}

export interface AvailabilityUpdateAction extends Action {
  type: SettingsActionType.AvailabilityUpdate;
  payload: {
    options: Dictionary<boolean>;
    type: SettingType;
  };
}

interface AvailabilityUpdateSuccessAction extends Action {
  type: SettingsActionType.AvailabilityUpdateSuccess;
  payload: {
    options: Dictionary<Turnaround>;
    type: SettingType;
  };
}

export interface PricesUpdateAction extends Action {
  type: SettingsActionType.PricesUpdate;
  payload: {
    prices: Dictionary<Dictionary<number>>;
    type: SettingType;
  };
}

interface PricesUpdateSuccessAction extends Action {
  type: SettingsActionType.PricesUpdateSuccess;
  payload: {
    prices: Dictionary<Dictionary<number>>;
    type: SettingType;
  };
}

export interface MaxPricesUpdateAction extends Action {
  type: SettingsActionType.MaxPricesUpdate;
  payload: Dictionary<number>;
}

interface MaxPricesUpdateSuccessAction extends Action {
  type: SettingsActionType.MaxPricesUpdateSuccess;
  payload: Dictionary<number>;
}

export const settingsActions = {
  loadingSuccess: (settings: Settings): SettingsLoadingSuccessAction => ({
    type: SettingsActionType.LoadingSuccess,
    payload: settings
  }),

  loadingFailure: (reason: string): SettingsLoadingFailureAction => ({
    type: SettingsActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading settings: ${reason}`)
  }),

  updateFailure: (reason: string): UpdateFailureAction => ({
    type: SettingsActionType.UpdateFailure,
    error: true,
    payload: new Error(`Failed to update settings: ${reason}`)
  }),

  startLoading: (): SettingsStartLoadingAction => ({
    type: SettingsActionType.StartLoading
  }),

  generalSettingUpdate: (maxCapacity: number): GeneralSettingUpdateAction => ({
    type: SettingsActionType.GeneralSettingsUpdate,
    payload: maxCapacity
  }),

  generalSettingUpdateSuccess: (maxCapacity: number): GeneralSettingUpdateSuccessAction => ({
    type: SettingsActionType.GeneralSettingsUpdateSuccess,
    payload: maxCapacity
  }),

  availabilityUpdate: (options: Dictionary<boolean>, type: SettingType): AvailabilityUpdateAction => ({
    type: SettingsActionType.AvailabilityUpdate,
    payload: {
      options: options,
      type: type
    }
  }),

  availabilityUpdateSuccess: (options: Dictionary<Turnaround>, type: SettingType): AvailabilityUpdateSuccessAction => ({
    type: SettingsActionType.AvailabilityUpdateSuccess,
    payload: {
      options: options,
      type: type
    }
  }),

  pricesUpdate: (prices: Dictionary<Dictionary<number>>, type: SettingType): PricesUpdateAction => ({
    type: SettingsActionType.PricesUpdate,
    payload: {
      prices: prices,
      type: type
    }
  }),

  pricesUpdateSuccess: (prices: Dictionary<Dictionary<number>>, type: SettingType): PricesUpdateSuccessAction => ({
    type: SettingsActionType.PricesUpdateSuccess,
    payload: {
      prices: prices,
      type: type
    }
  }),

  maxPricesUpdate: (prices: Dictionary<number>): MaxPricesUpdateAction => ({
    type: SettingsActionType.MaxPricesUpdate,
    payload: prices
  }),

  maxPricesUpdateSuccess: (prices: Dictionary<number>): MaxPricesUpdateSuccessAction => ({
    type: SettingsActionType.MaxPricesUpdateSuccess,
    payload: prices
  })
};

export type SettingsActions =
  | SettingsStartLoadingAction
  | SettingsLoadingSuccessAction
  | SettingsLoadingFailureAction
  | UpdateFailureAction
  | GeneralSettingUpdateAction
  | GeneralSettingUpdateSuccessAction
  | AvailabilityUpdateAction
  | AvailabilityUpdateSuccessAction
  | PricesUpdateAction
  | PricesUpdateSuccessAction
  | MaxPricesUpdateAction
  | MaxPricesUpdateSuccessAction;

import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { connect } from 'react-redux';

import { AuthStore } from '../../modules/auth/store';
import { SecurityGroup } from '../../modules/auth/okta';
import { StoreInterface } from '../../redux/store/store';

const genericFilter: FunctionComponent<Props & ReduxProps> = (props: PropsWithChildren<Props & ReduxProps>) => {
  if (!!props.allowed === !!props.restricted) {
    throw new Error('SecurityGroupFilter supports usage of exactly one of `allowed` or `restricted` properties');
  }

  const groups = props.auth.profile ? props.auth.profile.groups : [];
  const isAllowed = !props.allowed || props.allowed.filter(sg => groups.includes(sg)).length > 0;
  const isRestricted = props.restricted && props.restricted.filter(sg => groups.includes(sg)).length > 0;

  if (!isAllowed || isRestricted) {
    return null;
  }

  return <>{props.children}</>;
};

interface ReduxProps {
  auth: AuthStore;
}

interface Props {
  allowed?: SecurityGroup[];
  restricted?: SecurityGroup[];
}

const SecurityGroupFilter = connect((store: StoreInterface) => ({ auth: store.auth }))(genericFilter);

export const OpsOnly: FunctionComponent = (props: PropsWithChildren<{}>) => {
  const filterProps = {
    allowed: [SecurityGroup.ProofitOps],
    children: props.children
  };
  return <SecurityGroupFilter {...filterProps} />;
};

export const ExceptOps: FunctionComponent = (props: PropsWithChildren<{}>) => {
  const filterProps = {
    restricted: [SecurityGroup.ProofitOps],
    children: props.children
  };

  return <SecurityGroupFilter {...filterProps} />;
};

export const OpsAndAdvSupportOnly: FunctionComponent = (props: PropsWithChildren<{}>) => {
  const filterProps = {
    allowed: [SecurityGroup.ProofitOps, SecurityGroup.AdvancedSupport],
    children: props.children
  };

  return <SecurityGroupFilter {...filterProps} />;
};

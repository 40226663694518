import { Reducer } from 'redux';

import { initStore, Store } from './progress.store';
import { VotingProgressActions, VotingProgressActionType } from './progress.actions';

export const progressReducer: Reducer<Store, VotingProgressActions> = (state = initStore(), action) => {
  switch (action.type) {
    case VotingProgressActionType.Init:
      return initStore();

    case VotingProgressActionType.StartLoading:
      return { ...state, loading: true };

    case VotingProgressActionType.LoadingSuccess:
      return { ...state, loading: false, counts: action.payload.counts, progress: action.payload.progress };

    case VotingProgressActionType.ChangeTab:
      return { ...state, selectedTab: action.payload.tab };

    case VotingProgressActionType.ChangeSnippetTypeFilter:
      return { ...state, voteTypeFilter: action.payload.filter };

    default:
      return { ...state };
  }
};

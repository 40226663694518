import { Action } from 'redux';

import { ErrorAction } from '../../../redux/actions/actions';

import { VoterResult } from './interface.api';

export enum ActionType {
  /* Loading the page */
  StartLoading = 'peer-votes/voter-results/start-loading',
  StopLoading = 'peer-votes/voter-results/stop-loading',
  LoadingSuccess = 'peer-votes/voter-results/loading-success',
  LoadingFailure = 'peer-votes/voter-results/loading-failure'
}

export interface StartLoading {
  type: ActionType.StartLoading;
  payload: { from: Date; to: Date };
}

type StopLoading = { type: ActionType.StopLoading };

interface LoadingFailure extends ErrorAction {
  type: ActionType.LoadingFailure;
}

interface LoadingSuccess extends Action {
  type: ActionType.LoadingSuccess;
  payload: { voterResults: VoterResult[] };
}

export const actions = {
  startLoading: (from: Date, to: Date): StartLoading => ({
    type: ActionType.StartLoading,
    payload: { from, to }
  }),
  loadingSuccess: (voterResults: VoterResult[]): LoadingSuccess => ({
    type: ActionType.LoadingSuccess,
    payload: { voterResults }
  }),
  loadingFailure: (reason: string): LoadingFailure => ({
    type: ActionType.LoadingFailure,
    error: true,
    payload: new Error('Failed to load voter results: ' + reason)
  })
};

export type Actions = StartLoading | StopLoading | LoadingFailure | LoadingSuccess;

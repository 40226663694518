import { VoteJobType } from '../../../model/voting/voteJobType';

import { VotingStatus } from './progress.interface.api';

export const initCounts = () => ({
  completed: 0,
  proofreading: 0,
  voting: 0,
  cancelled: 0
});

export const initAllCounts = () =>
  Object.values(VoteJobType).reduce((acc, voteJobType) => {
    acc[voteJobType] = initCounts();
    return acc;
  }, {} as Record<VoteJobType, Counts>);

export type Counts = ReturnType<typeof initCounts>;
export type AllCounts = ReturnType<typeof initAllCounts>;
export type VotingProgressTab = keyof Counts;

export const initProgressItems = () => ({
  completed: [] as VotingStatus[],
  proofreading: [] as VotingStatus[],
  voting: [] as VotingStatus[],
  cancelled: [] as VotingStatus[]
});

export const initProgress = () =>
  Object.values(VoteJobType).reduce((acc, voteJobType) => {
    acc[voteJobType] = initProgressItems();
    return acc;
  }, {} as Record<VoteJobType, ProgressItems>);

export type ProgressItems = Record<VotingProgressTab, VotingStatus[]>;
export type Progress = ReturnType<typeof initProgress>;

export interface Store {
  voteTypeFilter?: VoteJobType;
  selectedTab: VotingProgressTab;
  loading: boolean;

  counts: AllCounts;
  progress: Progress;
}

export const initStore = (): Store => ({
  voteTypeFilter: undefined,
  selectedTab: 'voting',
  loading: false,

  counts: initAllCounts(),
  progress: initProgress()
});

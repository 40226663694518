import moment from 'moment';

import { VoteJobType } from '../../../model/voting/voteJobType';
import { ProofreaderDetails } from '../../../apis/proofreaders.api';

export interface GradingResults {
  sentences: number;
  validPercent: number;
}

export interface GuidelineGradingResult {
  accuracy: GradingResults;
  // Not all voting guidelines support completeness votes
  completeness?: GradingResults;
}

export type OverallGradingResults = Partial<Record<VoteJobType, GuidelineGradingResult>>;

export interface Row {
  proofreader: ProofreaderDetails;
  results: OverallGradingResults;
}

export interface PopupStore {
  proofreader: ProofreaderDetails;
  creatingGrades: boolean;
}

export class Store {
  public from?: Date;
  public to?: Date;

  public loading: boolean = false;
  public ready: boolean = false;

  public popup?: PopupStore;

  public rows: Row[] = [];

  constructor(from?: Date, to?: Date) {
    this.to = to;
    this.from = from;
  }

  public static defaultTo = () =>
    moment
      .utc(Date.now())
      .startOf('day')
      .toDate();

  public static defaultFrom = (to: Date) =>
    moment
      .utc(to)
      .subtract(1, 'year')
      .startOf('day')
      .toDate();
}

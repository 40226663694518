import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { actions } from '../jobs-list.actions';
import { StoreInterface } from '../../../../redux/store/store';
import { stringifyFilteringParams } from '../urlParams';

export default function*() {
  const state: StoreInterface = yield select();
  const filtering = state.jobsList.filtering;

  yield put(push('?' + stringifyFilteringParams(filtering)));

  yield put(actions.startLoading(0, filtering));
}

import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';
import { ProofreaderDetails, ProofreaderUpdate } from '../../apis/proofreaders.api';

export enum ProofreadersListActionType {
  StartLoading = 'proofreader-list/start-loading',
  LoadingSuccess = 'proofreader-list/loading-success',
  LoadingFailure = 'proofreader-list/loading-failure',
  UpdateProofreader = 'proofreader-list/update-proofreader',
  UpdateProofreaderSuccess = 'proofreader-list/update-proofreader-success',
  UpdateProofreaderFailure = 'proofreader-list/update-proofreader-failure',
  CreateProofreader = 'proofreader-list/create-proofreader',
  CreateProofreaderSuccess = 'proofreader-list/create-proofreader-success',
  CreateProofreaderFailure = 'proofreader-list/create-proofreader-failure',
  WelcomeEmail = 'proofreader-list/send-welcome-email',
  WelcomeEmailSuccess = 'proofreader-list/send-welcome-email-success',
  WelcomeEmailFailure = 'proofreader-list/send-welcome-email-failure'
}

export interface StartLoading extends Action {
  type: ProofreadersListActionType.StartLoading;
}

interface LoadingSuccess extends Action {
  type: ProofreadersListActionType.LoadingSuccess;
  payload: ProofreaderDetails[];
}

export interface UpdateProofreader extends Action {
  type: ProofreadersListActionType.UpdateProofreader;
  payload: {
    id: number;
    proofreader: ProofreaderUpdate;
  };
}

export interface UpdateProofreaderSuccess extends Action {
  type: ProofreadersListActionType.UpdateProofreaderSuccess;
  payload: ProofreaderDetails;
}

export interface WelcomeEmail extends Action {
  type: ProofreadersListActionType.WelcomeEmail;
  payload: number;
}

export interface WelcomeEmailSuccess extends Action {
  type: ProofreadersListActionType.WelcomeEmailSuccess;
  payload: number;
}

export interface CreateProofreader extends Action {
  type: ProofreadersListActionType.CreateProofreader;
  payload: ProofreaderUpdate;
}

export interface CreateProofreaderSuccess extends Action {
  type: ProofreadersListActionType.CreateProofreaderSuccess;
  payload: ProofreaderDetails;
}

interface LoadingFailure extends ErrorAction {
  type: ProofreadersListActionType.LoadingFailure;
}

interface UpdateProofreaderFailure extends ErrorAction {
  type: ProofreadersListActionType.UpdateProofreaderFailure;
}

interface CreateProofreaderFailure extends ErrorAction {
  type: ProofreadersListActionType.CreateProofreaderFailure;
}

interface WelcomeEmailFailure extends ErrorAction {
  type: ProofreadersListActionType.WelcomeEmailFailure;
}

export const proofreaderListActions = {
  loadingSuccess: (proofreaders: ProofreaderDetails[]): LoadingSuccess => ({
    type: ProofreadersListActionType.LoadingSuccess,
    payload: proofreaders
  }),

  updateProofreaderSuccess: (proofreader: ProofreaderDetails): UpdateProofreaderSuccess => ({
    type: ProofreadersListActionType.UpdateProofreaderSuccess,
    payload: proofreader
  }),

  updateProofreader: (proofreader: ProofreaderDetails): UpdateProofreader => ({
    type: ProofreadersListActionType.UpdateProofreader,
    payload: {
      id: proofreader.id,
      proofreader: {
        name: proofreader.name,
        email: proofreader.email,
        onboardingStatus: proofreader.onboardingStatus,
        blocked: proofreader.blocked,
        inTestGroup: proofreader.inTestGroup,
        permissions: [...proofreader.permissions]
      }
    }
  }),

  createProofreader: (proofreader: ProofreaderDetails): CreateProofreader => ({
    type: ProofreadersListActionType.CreateProofreader,
    payload: proofreader
  }),

  createProofreaderSuccess: (proofreader: ProofreaderDetails): CreateProofreaderSuccess => ({
    type: ProofreadersListActionType.CreateProofreaderSuccess,
    payload: proofreader
  }),

  welcomeEmail: (id: number): WelcomeEmail => ({
    type: ProofreadersListActionType.WelcomeEmail,
    payload: id
  }),

  welcomeEmailSuccess: (id: number): WelcomeEmailSuccess => ({
    type: ProofreadersListActionType.WelcomeEmailSuccess,
    payload: id
  }),

  loadingFailure: (reason: string): LoadingFailure => ({
    type: ProofreadersListActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading proofreaders list: ${reason}`)
  }),

  proofreaderUpdateFailure: (reason: string): UpdateProofreaderFailure => ({
    type: ProofreadersListActionType.UpdateProofreaderFailure,
    error: true,
    payload: new Error(`Error while updating proofreader: ${reason}`)
  }),

  proofreaderCreateFailure: (reason: string): CreateProofreaderFailure => ({
    type: ProofreadersListActionType.CreateProofreaderFailure,
    error: true,
    payload: new Error(`Error while creating proofreader: ${reason}`)
  }),

  welcomeEmailFailure: (reason: string): WelcomeEmailFailure => ({
    type: ProofreadersListActionType.WelcomeEmailFailure,
    error: true,
    payload: new Error(`Error while sending welcome email: ${reason}`)
  }),

  startLoading: (): StartLoading => ({
    type: ProofreadersListActionType.StartLoading
  })
};

export type ProofreaderListActions =
  | LoadingSuccess
  | UpdateProofreader
  | UpdateProofreaderSuccess
  | UpdateProofreaderFailure
  | CreateProofreader
  | CreateProofreaderSuccess
  | CreateProofreaderFailure
  | WelcomeEmail
  | WelcomeEmailSuccess
  | WelcomeEmailFailure
  | LoadingFailure
  | StartLoading;

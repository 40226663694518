import moment from 'moment';

import { Milliseconds, Timestamp } from '../../../utils/types';
import { Shift } from '../view/store';
import { Proofreader } from '../../../apis/proofreaders.api';

export enum ApplyMode {
  SingleShift = 'Single',
  AllShifts = 'All',
  FutureShifts = 'Following'
}

export enum RepeatType {
  Once = 'Once',
  Everyday = 'Everyday',
  Weekday = 'Weekday',
  DayOfWeek = 'DayOfWeek'
}

export interface RepeatMode {
  type: RepeatType;
  from: Date;
  to?: Date;
}

export const repeatModes = {
  once: (date: Timestamp): RepeatMode => ({
    type: RepeatType.Once,
    from: moment
      .utc(date)
      .startOf('day')
      .toDate(),
    to: moment
      .utc(date)
      .endOf('day')
      .toDate()
  })
};

export enum OperationType {
  Addition = 'Addition',
  Removal = 'Removal',
  Modification = 'Modification'
}

interface IOperation {
  type: OperationType;
  repeatMode: RepeatMode;
}

export interface Addition extends IOperation {
  type: OperationType.Addition;

  key: string;
  begin: Milliseconds;
  duration: Milliseconds;
  proofreader: Proofreader;
}

export interface Removal extends IOperation {
  type: OperationType.Removal;

  applyMode: ApplyMode;
  shift: Shift;
}

export interface Modification extends IOperation {
  type: OperationType.Modification;

  applyMode: ApplyMode;
  shift: Shift;

  offset: Milliseconds;
  durationDiff: Milliseconds;
}

export type Operation = Addition | Removal | Modification;

export type ChangeOps = { [key: string]: Operation };

export interface Store {
  operations: ChangeOps;
  selected?: string;
}

export const initStore = (): Store => ({
  operations: {}
});

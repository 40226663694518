import { capitalize } from 'lodash';

const permissionLabels: any = {
  ADVANCED_GEC: 'Advanced GEC',
  PURE_GEC: 'Pure GEC',
  PURE_GEC_PARALLEL: 'Pure GEC (P)',
  GEC_VOTING: 'GEC Voting',
  PURE_GEC_VOTING: 'Pure GEC Voting',

  SENTENCE_CLARITY: 'S-CL',
  SENTENCE_CLARITY_VOTING: 'S-CL Voting',

  PARA_CLARITY: 'P-CL',
  PARA_CLARITY_VOTING: 'P-CL Voting',
  CLARITY_PLUS: 'CL+',
  CLARITY_PLUS_PARALLEL: 'CL+ (P)',
  CLARITY_PLUS_VOTING: 'CL+ Voting',

  CORPUS_REALTIME_GEC: 'Corpus RT',
  USER_REALTIME_GEC: 'User RT',
  REALTIME_GEC_VOTING: 'RT Voting',

  ACADEMIC_TONE: 'AT',
  ACADEMIC_TONE_VOTING: 'AT Voting'
};

export const getLabel = (permission: string): string =>
  permissionLabels[permission] || capitalize(permission.replaceAll('_', ' '));

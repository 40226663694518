import { Reducer } from 'redux';

import { Actions, ActionType } from './actions';
import { initStore, Store } from './store';

export const reducer: Reducer<Store, Actions> = (state = initStore(), action) => {
  switch (action.type) {
    case ActionType.StartLoading:
      return { ...state, ready: true, loading: true, ...action.payload };

    case ActionType.LoadingFailure:
    case ActionType.StopLoading:
      return { ...state, loading: false };

    case ActionType.LoadingSuccess:
      return { ...state, loading: false, voterResults: action.payload.voterResults };

    default:
      return state;
  }
};

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';

import { ProofreaderRow, SelectedColumn } from '../view/store';
import { formatNumber } from '../../../utils/format';
import { nameColumnWidth, timeBoxWidth } from '../styles/constants';
import { ITimeInterval, Milliseconds } from '../../../utils/types';
import { aggregate } from '../utils';
import { display } from '../ShiftView';
import { ProofreaderStatus } from '../../../apis/proofreaders.api';

import ProofreaderFilter from './ProofreaderFilter';

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
      position: 'relative'
    },

    column: {
      '&:last-child': {
        width: 120
      },

      '&.name': {
        fontFamily: 'sans-serif',
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: nameColumnWidth
      },

      '&.filterCell': {
        paddingTop: 0,
        paddingBottom: 0
      },

      borderColor: grey[400],
      borderWidth: 1,
      borderStyle: 'none',
      borderRightStyle: 'dashed',
      borderBottomStyle: 'dashed',

      boxSizing: 'border-box',
      padding: '.65em 0'
    },

    header: {
      '&:hover': {
        textDecoration: 'underline'
      },
      '&.selected': {
        textDecoration: 'underline',
        fontWeight: 'bold'
      },
      cursor: 'pointer'
    },

    stat: {
      fontStyle: 'italic',
      backgroundColor: fade(grey[300], 0.3)
    },

    overtimeShift: {
      backgroundColor: fade(green[300], 0.1),
      fontWeight: 'bold'
    }
  })
);

interface HeaderRowProps {
  columns: string[];
  selectedColumn?: SelectedColumn;
  onClick: (index: number) => void;
}

export const HeaderRow = (props: HeaderRowProps) => {
  const classes = useStyles();

  const cellClasses = (index: number) =>
    classnames(classes.column, classes.header, {
      selected: props.selectedColumn && props.selectedColumn.column === index
    });

  const fixedWidth = { width: timeBoxWidth / props.columns.length };
  return (
    <div className={classes.row}>
      <div className={classnames(classes.column, classes.header, 'name', 'filterCell')}>
        <ProofreaderFilter />
      </div>

      {props.columns.map((title, i) => (
        <div key={i} className={cellClasses(i)} style={fixedWidth} onClick={() => props.onClick(i)}>
          {title}
        </div>
      ))}

      <div className={classnames(classes.column, classes.stat)}>Total (h)</div>
    </div>
  );
};

interface AggregationRowProps {
  proofreaders: ProofreaderRow[];
  intervals: ITimeInterval[];
}

export const AggregationRow = (props: AggregationRowProps) => {
  const classes = useStyles();

  const values = aggregate(props.proofreaders, props.intervals);
  const total = props.proofreaders.reduce((acc, el) => el.totalHours + acc, 0);
  const fixedWidth = { width: timeBoxWidth / props.intervals.length };

  return (
    <div className={classnames(classes.row)}>
      <div className={classnames(classes.column, classes.stat, 'name')}>Total human-hours</div>

      {values.map((value, i) => (
        <div key={i} className={classnames(classes.column, classes.stat)} style={fixedWidth}>
          {formatNumber(value)}
        </div>
      ))}

      <div className={classnames(classes.column, classes.stat)}>{total}</div>
    </div>
  );
};

const isOvertimeShift = (p: ProofreaderRow): boolean => {
  const currentTime = Date.now();
  return (
    p.status === ProofreaderStatus.Onshift &&
    !p.shifts.map(display).find(s => s.begin < currentTime && s.end > currentTime)
  );
};

interface DataRowProps {
  data: ProofreaderRow;
  columnsCount: number;
  className?: string;
  onClick: (offset: Milliseconds) => void;
}

export const DataRow = (props: PropsWithChildren<DataRowProps>) => {
  const classes = useStyles();

  const fillerArray = new Array(props.columnsCount).fill('');
  const fixedWidth = { width: timeBoxWidth / props.columnsCount };

  return (
    <div className={classnames(classes.row, { [`${classes.overtimeShift}`]: isOvertimeShift(props.data) })}>
      {props.children}

      <div className={classnames(classes.column, 'name')}>{props.data.name}</div>

      {fillerArray.map((el, i) => (
        <div
          key={i}
          className={classnames(classes.column)}
          style={fixedWidth}
          onClick={e => {
            const targetEl: any = e.target;
            const baseX = targetEl.parentElement.getBoundingClientRect().left + nameColumnWidth;
            const clickX = e.clientX;

            props.onClick(clickX - baseX);
          }}
        />
      ))}

      <div className={classnames(classes.column, classes.stat)}>{props.data.totalHours}</div>
    </div>
  );
};

import React from 'react';

import FatalError from './FatalError';

export class ErrorBoundary extends React.Component<{ children: JSX.Element }> {
  state = {
    hasError: false
  };

  componentDidCatch(error: Error, info: any) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <FatalError />;
    }
    return this.props.children;
  }
}

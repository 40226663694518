import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import React, { MouseEventHandler } from 'react';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import classnames from 'classnames';

import { TimeInterval } from '../../../utils/types';
import { ProofreaderRow, Scale, Shift } from '../view/store';
import { ProofreaderStatus } from '../../../apis/proofreaders.api';

const useStyles = makeStyles(() => {
  const backgroundColor = blue[300];
  const notOnShiftColor = red[300];
  const onShiftColor = green[300];
  const wrapBorderColor = grey[800];

  return createStyles({
    shiftBlock: {
      position: 'absolute',
      top: 2,
      bottom: 2,
      backgroundColor: fade(backgroundColor, 0.7),
      borderRadius: 5,
      cursor: 'pointer',
      boxSizing: 'border-box',
      boxShadow: `${grey[500]} 3px 3px 3px`,
      transition: 'left ease-out 0.15s, width ease-out 0.15s'
    },
    wrapLeft: {
      borderRadius: '0 5px 5px 0',
      borderLeft: `3px dashed ${wrapBorderColor}`
    },
    wrapRight: {
      borderRadius: '5px 0 0 5px',
      borderRight: `3px dashed ${wrapBorderColor}`
    },
    past: {
      backgroundColor: fade(backgroundColor, 0.3),
      boxShadow: 'none'
    },
    onShift: {
      backgroundColor: fade(onShiftColor, 0.7)
    },
    notOnShift: {
      backgroundColor: fade(notOnShiftColor, 0.7)
    },
    selected: {
      boxShadow: `inset ${grey[500]} 1px 1px 3px`
    },
    modified: {
      backgroundImage:
        'repeating-linear-gradient(-45deg, rgba(0, 0, 0, .05) 56px, rgba(0,0,0, .05) 68px, transparent 68px, transparent 80px)'
    }
  });
});

const onDragStart = (e: React.DragEvent, shift: Shift) => {
  e.dataTransfer.setData('shift', shift.shiftKey);
  e.dataTransfer.setData('baseDragX', e.clientX.toString());
};

export default (props: Props) => {
  const classes = useStyles();

  const currentTime = Date.now();
  const shiftInterval = new TimeInterval(props.shift.begin, props.shift.end);
  const displayedInterval = new TimeInterval(props.from.getTime(), props.to.getTime());
  const frame = shiftInterval.intersection(displayedInterval);

  if (!frame.valid()) {
    return null;
  }

  const relativeFrom = frame.begin - displayedInterval.begin;
  const widthTs = frame.end - frame.begin;

  const { millisPerPixel, baseOffset } = props.scale.scalingProps;
  const left = relativeFrom / millisPerPixel + baseOffset;
  const widthPx = widthTs / millisPerPixel;

  const activeShift = shiftInterval.contains(currentTime);

  const blockModifiers = {
    [classes.onShift]: activeShift && props.proofreader.status == ProofreaderStatus.Onshift,
    [classes.notOnShift]: activeShift && props.proofreader.status !== ProofreaderStatus.Onshift,
    [classes.past]: shiftInterval.end < currentTime,
    [classes.selected]: props.selected,
    [classes.modified]: props.modified,
    [classes.wrapRight]: props.shift.end > displayedInterval.end,
    [classes.wrapLeft]: props.shift.begin < displayedInterval.begin
  };

  return (
    <div
      draggable
      onDragStart={e => onDragStart(e, props.shift)}
      className={classnames(classes.shiftBlock, blockModifiers)}
      style={{ left: left, width: widthPx }}
      onClick={props.onClick}
    />
  );
};

export interface Props {
  from: Date;
  to: Date;
  scale: Scale;
  shift: Shift;
  proofreader: ProofreaderRow;
  selected: boolean;
  modified: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

import React from 'react';
import { Dispatch } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, createStyles, Grid, makeStyles, Popper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Close, Delete, Undo } from '@material-ui/icons';

import { Shift } from '../view/store';
import { AppsActions } from '../../../redux/actions/actions';
import { actions } from '../edit/actions';
import { duration, ITimeInterval, Millis, Timestamp } from '../../../utils/types';
import { ApplyMode, RepeatMode, RepeatType } from '../edit/store';
import { millisToHours } from '../../../utils/date';
import { display, ShiftView } from '../ShiftView';

import TimeSelector from './TimeSelector';
import ChangeCard from './ChangeCard';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1
    },
    paper: {
      borderRadius: 5,
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${grey[500]} 1px 1px 10px`,
      textAlign: 'center'
    },
    timeSelector: {
      width: '6em',
      margin: theme.spacing(1)
    },
    duration: {
      width: '3em'
    },
    closeButton: {
      color: grey[500],
      position: 'absolute',
      top: 0,
      right: 0
    }
  })
);

const stringifyRepeat = (repeat: RepeatMode): string => {
  const repeatMsgs = {
    [RepeatType.Once]: 'Never repeats',
    [RepeatType.Everyday]: 'Repeats everyday',
    [RepeatType.Weekday]: 'Repeats Monday to Friday',
    [RepeatType.DayOfWeek]: `Repeats on ${moment.utc(repeat.from).format('dddd')}`
  };

  const untilMsg =
    repeat.type === RepeatType.Once || !repeat.to ? '' : `until ${moment.utc(repeat.to).format('YYYY-MM-DD')}`;

  return `${repeatMsgs[repeat.type]} ${untilMsg}`;
};

const EditPopper = (props: Props & Dispatches) => {
  const classes = useStyles();

  const view = display(props.shift);

  const updateShift = (shift: Shift, field: 'begin' | 'end', valueString: string) => {
    if (!valueString) {
      return;
    }

    const [hours, minutes] = valueString.split(':');
    const valueTs: Timestamp = moment
      .utc(shift.begin)
      .startOf('day')
      .hours(+hours)
      .minutes(+minutes)
      .valueOf();

    const newBegin = field === 'begin' ? valueTs : shift.begin;
    const newEnd = field === 'end' ? valueTs : shift.end;
    const correctedEnd = newEnd <= newBegin ? newEnd + Millis.day : newEnd;

    props.changeShift(props.shift, newBegin, correctedEnd);
  };

  const escapeListener = (e: React.KeyboardEvent<HTMLDivElement>) => {
    /*
     * In Internet Explorer (tested on release 9 and 11) and Firefox 36 and earlier,
     * the Esc key returns "Esc" instead of "Escape"
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#Special_values
     */
    if (e.key === 'Escape' || e.key === 'Esc') {
      props.resetSelection();
    }
  };

  return (
    <Popper anchorEl={props.anchor} open={true} className={classes.root} onKeyDown={escapeListener}>
      <div className={classes.paper}>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.timeSelector}>
            <TimeSelector
              label="From"
              value={moment.utc(view.begin).format('HH:mm')}
              autoFocus={true}
              onChange={v => view && updateShift(view, 'begin', v)}
            />
          </Grid>
          <Grid item className={classes.timeSelector}>
            <TimeSelector
              label="To"
              value={moment.utc(view.end).format('HH:mm')}
              autoFocus={false}
              onChange={v => view && updateShift(view, 'end', v)}
            />
          </Grid>
          <Grid item className={classes.duration}>
            {millisToHours(duration(view))}h
          </Grid>
        </Grid>
        {!props.shift.modified && <p>{stringifyRepeat(view.repeat)}</p>}
        {!!props.shift.modified && (
          <div>
            <ChangeCard
              op={props.shift.modified.op}
              showSummary={false}
              undo={() => props.resetChange(view.seriesKey)}
              changeApplyMode={applyMode => props.changeModificationMode(view.seriesKey, applyMode)}
              changeRepeatMode={repeatMode => props.changeRepeat(view.seriesKey, repeatMode)}
            />
          </div>
        )}

        {/*
         * This button is put here intentionally. While tabbing through elements this button
         * should be selected before "undo" and "remove" actions
         */}
        <Button size="small" className={classes.closeButton} onClick={props.resetSelection}>
          Close [<Close />]
        </Button>

        <p>
          {!!props.shift.modified && (
            <Button title="Undo change" onClick={() => view && props.resetChange(view.seriesKey)}>
              <Undo />
              Undo change
            </Button>
          )}
          <Button title="Remove shift" onClick={() => view && props.removeShift(view)}>
            <Delete />
            Remove shift
          </Button>
        </p>
      </div>
    </Popper>
  );
};

interface Props {
  anchor: any;
  displayInterval: ITimeInterval;
  shift: ShiftView;
}

interface Dispatches {
  resetSelection: () => void;
  changeShift: (shift: ShiftView, begin: number, end: number) => void;
  removeShift: (shift: Shift) => void;
  resetChange: (shiftKey: string) => void;
  changeModificationMode: (opKey: string, mode: ApplyMode) => void;
  changeRepeat: (opKey: string, repeat: Partial<RepeatMode>) => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    resetSelection: () => dispatch(actions.resetSelection()),
    changeShift: (shift, begin, end) => dispatch(actions.changeShift(shift, begin, end)),
    removeShift: (shift: Shift) => dispatch(actions.removeShift(shift)),
    resetChange: shiftKey => dispatch(actions.resetChange(shiftKey)),
    changeModificationMode: (cKey, mode) => dispatch(actions.changeModificationMode(cKey, mode)),
    changeRepeat: (opKey, repeat) => dispatch(actions.changeRepeat(opKey, repeat))
  })
)(EditPopper);

export interface AppStoreInterface {
  initDate: Date | null;
  terminateDate: Date | null;
  loading: boolean;
  loadings: number;
}

export const INIT_APP_STORE: AppStoreInterface = {
  initDate: null,
  terminateDate: null,
  loading: false,
  loadings: 0
};

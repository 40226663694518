import React, { ChangeEvent, useEffect, useState } from 'react';
import { createStyles, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingLeft: '.5em',
      fontSize: '0.875rem',
      appearance: 'textfield'
    },
    textField: {
      padding: 0
    }
  })
);

interface Props {
  label?: string;
  value: number;
  disabled: boolean;
  onChange: (v: number) => void;
}

export default (props: Props) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = +event.target.value;
    if (number >= 0) {
      props.onChange(number);
    }
  };

  return (
    <TextField
      disabled={props.disabled}
      value={props.value}
      onChange={handleChange}
      type="number"
      margin="none"
      className={classes.textField}
      InputProps={{
        inputProps: { min: 0, className: classes.input },
        startAdornment: <span>$</span>
      }}
    />
  );
};

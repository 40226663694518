import { Shift } from './view/store';
import { Operation } from './edit/store';

export interface ShiftView {
  original?: Shift;
  modified?: {
    op: Operation;
    view: Shift;
  };
}

export const display = (shift: ShiftView): Shift => {
  if (shift.modified) {
    return shift.modified.view;
  } else if (shift.original) {
    return shift.original;
  } else {
    throw new Error('Invalid ShiftView');
  }
};

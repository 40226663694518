import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuid } from 'uuid';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    TableWithHeader: {
      overflow: 'auto'
    },
    noFilter: {
      paddingTop: 30 /* Calculated manually, couldn't find correlation with theme attributes */,
      fontSize: '1rem',
      fontWeight: 'normal'
    }
  })
);

export interface Column<T> {
  id?: keyof T;
  label: string;
  showTitle?: boolean;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: T, values: T[]) => string | JSX.Element | null;
  editableFormat?: (value: T, values: T[]) => string | JSX.Element | null;
  createFormat?: () => string | JSX.Element | null;
  filter?: () => string | JSX.Element | null;
}

export interface Props<T> {
  columns: Column<T>[];
  data: T[];
  selected?: string;
  createMode?: boolean;
}

const columnKey = (column: Column<any>) => (column.id ? column.id.toString() : column.label);

export function TableWithHeader<T>(props: Props<T>) {
  const classes = useStyles();
  return (
    <div className={classes.TableWithHeader}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {props.columns.map(column => (
              <TableCell
                className={column.filter ? '' : classes.noFilter}
                key={columnKey(column)}
                align={column.align}
                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
              >
                {column.filter ? column.filter() : column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.createMode ? (
            <TableRow hover role="checkbox" tabIndex={-1}>
              {props.columns.map(column => (
                <TableCell key={columnKey(column)}>{column.createFormat ? column.createFormat() : null}</TableCell>
              ))}
            </TableRow>
          ) : null}
          {props.data.map((data: T) => {
            const id: string = data && (data as any)['id'] ? (data as any)['id'] : uuid();
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                {props.columns.map(column => {
                  const staticValue = column.format
                    ? column.format(data, props.data)
                    : column.id
                    ? data[column.id]
                    : null;
                  const editableValue = column.editableFormat ? column.editableFormat(data, props.data) : staticValue;
                  let title: string | undefined = column.id ? `${column.label}: ${data[column.id]}` : column.label;
                  title = column.showTitle == undefined || column.showTitle ? title : undefined;
                  return (
                    <TableCell key={columnKey(column)} align={column.align} title={title}>
                      {props.selected == id ? editableValue : staticValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TableWithHeader;

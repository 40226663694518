export type Weekday = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export enum SettingType {
  GEC,
  CLARITY
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface Turnaround {
  enabled: boolean;
  extended: boolean;
  available: boolean;
  canBeExtended: boolean;
  usedPercentage: number;
  usedSnippetsCount: number;
  snippetsLimit: number;
  lastPeriodSnippetsCount: number;
}

export interface Settings {
  lateGecSnippets: number;
  lateClaritySnippets: number;
  maxCapacity: number;

  gecOptions: Dictionary<Turnaround>;
  clarityOptions: Dictionary<Turnaround>;

  basicPrices: Dictionary<Dictionary<number>>;
  basicClarityPrices: Dictionary<Dictionary<number>>;

  maxPrices: Dictionary<number>;
}

export interface GeneralSettingsDto {
  maxCapacity: number;
}

export interface AvailabilitiesUpdateDto {
  availabilities: Dictionary<boolean>;
}

import { put, takeEvery } from '@redux-saga/core/effects';

import { ActionType, ApplyFilter, resultsActions } from '../results.actions';

function* reload(action: ApplyFilter) {
  yield put(resultsActions.stopLoading());
  yield put(resultsActions.startLoading(action.payload.from, action.payload.to));
}

export default function*() {
  yield takeEvery(ActionType.ApplyFilter, reload);
}

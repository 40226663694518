import React from 'react';
import { NavLink } from 'react-router-dom';
import GroupIcon from '@material-ui/icons/Group';
import Typography from '@material-ui/core/Typography';

import { AppRoute } from '../../routes';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { SecurityGroup } from '../../modules/auth/okta';

import ProofreadersList from './components/ProofreadersList';

const proofreadersListRoutes: AppRoute = {
  /* Use this path when creating links elsewhere */
  path: '/proofreaders',
  component: ProofreadersList,
  parent: indexRoute,
  pathMatch: /\/proofreaders/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-scheduleRoute">
      <NavLink to="/proofreaders">
        <GroupIcon className="AppBreadcrumbsIcon" />
        Proofreaders
      </NavLink>
    </Typography>
  ),
  private: true,
  securityGroups: [SecurityGroup.ProofitOps],
  exact: true
};

export default [proofreadersListRoutes];

import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import { Corpus, CorpusModificationOperation, CorpusStatus, QueuesStats } from '../corpora.interface';
import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { localDateTimeUTCTooltip } from '../../../utils/date';
import { corpusActions } from '../corpora.actions';
import { corpusIsFinished } from '../_utils';
import { CorpusType } from '../../../model/corpus/corpusType';

import { CorpusActionsGroup } from './CorpusActionsGroup';
import { CorpusProgress } from './CorpusProgress';
import EstimatedTimeRemaining from './EstimatedTimeRemaining';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContentWrapper: {
      overflowX: 'scroll',
      overflowY: 'hidden',
      width: '100%'
    },
    oneElementWrapper: {
      margin: theme.spacing(1)
    }
  })
);

const useTableStyles = makeStyles(() =>
  createStyles({
    header: {
      verticalAlign: 'bottom'
    },
    noWrap: {
      whiteSpace: 'nowrap'
    },
    name: {
      minWidth: 300
    },
    progress: {
      minWidth: 250
    },
    estimate: {
      minWidth: 100
    }
  })
);

const CorporaTable: FunctionComponent<CorporaTableProps> = (props: CorporaTableProps) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  const unfinishedCorpora = props.corpora.filter(c => !corpusIsFinished(c));
  const inProgressCorpora = unfinishedCorpora.filter(c => c.status !== CorpusStatus.PAUSED);
  const displayedCorpora = props.finishedCorporaHidden ? unfinishedCorpora : props.corpora;

  return (
    <div className={classes.mainContentWrapper}>
      {props.loading ? (
        <Box display="flex" justifyContent="center" className={classes.oneElementWrapper}>
          <CircularProgress />
        </Box>
      ) : !displayedCorpora || displayedCorpora.length === 0 ? (
        <Box display="flex" justifyContent="center" className={classes.oneElementWrapper}>
          <Typography>Nothing to display</Typography>
        </Box>
      ) : (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap, tableClasses.name)}>
                Corpus
              </TableCell>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap)}>Created</TableCell>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap)}>Redundancy</TableCell>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap)}>
                Estimated end-date (Days remaining)
              </TableCell>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap, tableClasses.progress)}>
                Jobs Completed
              </TableCell>
              <TableCell className={classNames(tableClasses.header, tableClasses.noWrap)}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedCorpora.map((corpus: Corpus, index: number) => (
              <TableRow key={corpus.id} hover>
                <TableCell className={tableClasses.name}>{corpus.originalName}</TableCell>
                {corpus.status === CorpusStatus.INITIALIZING ? (
                  <TableCell colSpan={3}>Waiting for corpus to initialize...</TableCell>
                ) : corpus.status === CorpusStatus.FAILED_TO_INIT ? (
                  <TableCell colSpan={3}>Corpus have failed to unpack</TableCell>
                ) : (
                  <>
                    <TableCell className={tableClasses.noWrap}>{localDateTimeUTCTooltip(corpus.created)}</TableCell>
                    <TableCell className={tableClasses.noWrap}>{corpus.redundancy}</TableCell>
                    <TableCell className={classNames(tableClasses.noWrap, tableClasses.estimate)}>
                      <Tooltip
                        key="estimate-tooltip"
                        title="Based on corpus size and queue bandwidth for the last week"
                        placement="bottom"
                      >
                        <span>
                          <EstimatedTimeRemaining corpus={corpus} loading={props.loadingStats} />
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classNames(tableClasses.noWrap, tableClasses.progress)}>
                      <CorpusProgress
                        modifying={props.modifying}
                        corpus={corpus}
                        lastOfType={inProgressCorpora.length === 1 && inProgressCorpora[0] === corpus}
                        pauseHandler={props.pause}
                        resumeHandler={props.resume}
                      />
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <CorpusActionsGroup
                    corpus={corpus}
                    higherRankCorpus={index === 0 ? null : displayedCorpora[index - 1]}
                    lowerRankCorpus={index === displayedCorpora.length - 1 ? null : displayedCorpora[index + 1]}
                    rankingDisabled={displayedCorpora === props.corpora}
                    modifying={props.modifying}
                    removeHandler={props.remove}
                    editLabelHandler={props.editLabel}
                    swapCorporaRanks={props.swapRanks}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

interface OwnProps {
  corpusType: CorpusType;
  finishedCorporaHidden: boolean;
}

interface Props extends OwnProps {
  loading: boolean;
  loadingStats: boolean;
  corpora: Corpus[];
  stats: QueuesStats;
  modifying: boolean;
}

interface Dispatches {
  pause: (corpus: Corpus) => void;
  resume: (corpus: Corpus) => void;
  remove: (corpus: Corpus) => void;
  editLabel: (corpus: Corpus) => void;
  swapRanks: (first: Corpus, second: Corpus) => void;
}

type CorporaTableProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface, ownProps: OwnProps): Props => ({
  corpusType: ownProps.corpusType,
  finishedCorporaHidden: ownProps.finishedCorporaHidden,

  corpora: state.corpora.corpora.filter(c => c.type === ownProps.corpusType),
  stats: state.corpora.corpusQueuesStats,
  loading: state.corpora.loadingCorpora,
  loadingStats: state.corpora.loadingStats,
  modifying: state.corpora.modifyingCorpus
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  pause: (corpus: Corpus) => dispatch(corpusActions.modify(corpus, CorpusModificationOperation.PAUSE)),
  resume: (corpus: Corpus) => dispatch(corpusActions.modify(corpus, CorpusModificationOperation.RESUME)),
  remove: (corpus: Corpus) => dispatch(corpusActions.modify(corpus, CorpusModificationOperation.REMOVE)),
  editLabel: (corpus: Corpus) => dispatch(corpusActions.modify(corpus, CorpusModificationOperation.EDIT_LABEL)),
  swapRanks: (first: Corpus, second: Corpus) => dispatch(corpusActions.swapRanks(first, second))
});

export default connect(mapStateToProps, mapDispatchToProps)(CorporaTable);

import React, { useCallback, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { blue, blueGrey } from '@material-ui/core/colors';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import CheckIcon from '@material-ui/icons/Check';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { isEqual, uniqWith } from 'lodash';

import { Row } from '../results.store';
import { resultsActions } from '../results.actions';
import { StoreInterface } from '../../../../redux/store/store';
import { AppsActions } from '../../../../redux/actions/actions';
import { assertNever } from '../../../../utils/errors';
import { Dimension } from '../dynamicTableDimensions';
import { ProofreaderDetails } from '../../../../apis/proofreaders.api';
import { cohortFilter } from '../../../../modules/permissions/CohortSwitcher';

import GradeProofreaderDialog from './GradeProofreaderDialog';

const nameComparator = (r1: Row, r2: Row) => (r1.proofreader.name > r2.proofreader.name ? 1 : -1);

const formatValue = (dim: Dimension, row: Row) => {
  const value = Dimension.project(dim, row);
  switch (dim.value) {
    case 'sentences':
      return value || '';
    case 'validPercent':
      return value ? `${value}%` : '';
    default:
      assertNever(dim.value);
  }
};

const preBuiltComparators = {
  name: nameComparator,
  inGrade: (r1: Row, r2: Row) => +r2.proofreader.inGrade - +r1.proofreader.inGrade || nameComparator(r1, r2)
};

const dimensionComparator = (dim: Dimension) => (r1: Row, r2: Row) => {
  const numericValue1 = Dimension.project(dim, r1) || 0;
  const numericValue2 = Dimension.project(dim, r2) || 0;

  return numericValue2 - numericValue1 || nameComparator(r1, r2);
};

const styles = makeStyles(() =>
  createStyles({
    addGradeJobs: {
      '&:hover': {
        color: blueGrey[400]
      },
      '&.clarity': {
        color: blue[600],
        '&:hover': {
          color: blue[200]
        }
      },
      color: blueGrey[900],
      cursor: 'pointer',
      right: '.2em',
      top: 0,
      height: '1em'
    },
    inGrade: {
      backgroundColor: blueGrey[50]
    },
    orderColumn: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      verticalAlign: 'bottom'
    }
  })
);

const VoteeResultsTable = (props: Props & ReduxProps & Dispatches) => {
  const [ordering, changeOrdering] = useState<Dimension | keyof typeof preBuiltComparators>('name');

  const dimensions = useMemo(() => Dimension.buildDimensions(props.rows), [props.rows]);

  const sortingComparator = useMemo(
    () => (typeof ordering === 'string' ? preBuiltComparators[ordering] : dimensionComparator(ordering)),
    [ordering]
  );

  const rowFilter = useCallback(cohortFilter(props.selectedCohort), [props.selectedCohort]);

  const classes = styles();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.orderColumn} rowSpan={2} colSpan={2} onClick={() => changeOrdering('name')}>
            Proofreader
            <TableSortLabel active={ordering === 'name'} />
          </TableCell>
          <TableCell className={classes.orderColumn} rowSpan={2} onClick={() => changeOrdering('inGrade')}>
            In&nbsp;Voting
            <TableSortLabel active={ordering === 'inGrade'} />
          </TableCell>

          {uniqWith(dimensions, (d1, d2) => d1.voteJobType === d2.voteJobType && d1.voteType === d2.voteType).map(
            dim => (
              <TableCell key={Dimension.id(dim)} colSpan={2}>
                {Dimension.topLevelHeader(dim)}
              </TableCell>
            )
          )}
        </TableRow>

        <TableRow>
          {dimensions.map(dim => (
            <React.Fragment key={Dimension.id(dim)}>
              <TableCell className={classes.orderColumn} onClick={() => changeOrdering(dim)}>
                {Dimension.detailedHeader(dim)}
                <TableSortLabel active={isEqual(ordering, dim)} />
              </TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {props.rows
          .filter(row => rowFilter(row.proofreader))
          .sort(sortingComparator)
          .map(row => (
            <TableRow key={row.proofreader.id} className={classnames({ [classes.inGrade]: row.proofreader.inGrade })}>
              <TableCell>{row.proofreader.name}</TableCell>
              <TableCell>
                <ControlPointIcon
                  onClick={() => props.setProofreaderToGrade(row.proofreader)}
                  className={classnames(classes.addGradeJobs)}
                />
              </TableCell>
              <TableCell>{row.proofreader.inGrade && <CheckIcon fontSize="inherit" />}</TableCell>

              {dimensions.map(dim => (
                <TableCell key={Dimension.id(dim)}>{formatValue(dim, row)}</TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
      <GradeProofreaderDialog />
    </Table>
  );
};

interface Props {
  selectedCohort?: string;
}

interface ReduxProps {
  rows: Row[];
}

interface Dispatches {
  setProofreaderToGrade: (proofreader: ProofreaderDetails) => void;
}

export default connect(
  (state: StoreInterface): ReduxProps => ({
    rows: state.voting.results.rows
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    setProofreaderToGrade: proofreader => dispatch(resultsActions.setProofreaderToGrade(proofreader))
  })
)(VoteeResultsTable);

import moment from 'moment';

import { timestampToDate } from '../../utils/date';
import { str2enumStrict } from '../../utils/enums/str2enum';
import { CorpusType } from '../../model/corpus/corpusType';

import { Corpus, CorpusStatus, QueuesStats } from './corpora.interface';
import { CorpusDto } from './corpora.api.interface';

export const toCorpus = (dto: CorpusDto): Corpus => ({
  id: dto.id,
  type: str2enumStrict(CorpusType, dto.type, 'corpus type'),
  status: str2enumStrict(CorpusStatus, dto.status, 'corpus status'),
  originalName: dto.originalName,
  created: timestampToDate(dto.created),
  redundancy: dto.redundancy,
  rawCount: dto.rawCount,
  processedCount: dto.processedCount,
  label: dto.label,
  rank: dto.rank,
  wordsTotal: dto.wordsTotal,
  wordsProcessed: dto.wordsProcessed,
  jobsCompleted: dto.jobsCompleted,
  jobsCancelled: dto.jobsCancelled,
  jobsTotal: dto.jobsTotal,
  estimatedEndDate: null,
  daysRemaining: null
});

export const corpusIsFinished = (corpus: Corpus) =>
  corpus.status === CorpusStatus.REMOVED ||
  corpus.status === CorpusStatus.COMPLETED ||
  corpus.status === CorpusStatus.FAILED_TO_INIT;

export const corporaComparator = (a: Corpus, b: Corpus): number => {
  const aFinished = corpusIsFinished(a);
  const bFinished = corpusIsFinished(b);
  if (aFinished !== bFinished) {
    return aFinished ? -1 : 1;
  } else {
    return a.rank - b.rank;
  }
};

export const clarityReportsComparator = (a: string, b: string): number => Date.parse(a) - Date.parse(b);

export const daysToFinish = (corpus: Corpus, wordsProcessedLastWeek: number): number | null => {
  const wordsInCorpus = corpus.wordsTotal - corpus.wordsProcessed;
  if (wordsProcessedLastWeek === 0 || wordsInCorpus <= 0) return null;

  const wordsPerDay = wordsProcessedLastWeek / 7;
  return Math.floor(wordsInCorpus / wordsPerDay) + 1;
};

const finishDate = (daysLeft: number, startDate?: Date): Date =>
  moment
    .utc(startDate)
    .add(daysLeft, 'day')
    .toDate();

type CorpusTypeDate = {
  [key in CorpusType]?: Date;
};

export const recalculateCorporaEndDates = (corpora: Corpus[], stats: QueuesStats): Corpus[] => {
  let previousEndDate: CorpusTypeDate = {};

  return corpora.map(c => {
    if (!corpusIsFinished(c)) {
      const daysLeftForCorpus = daysToFinish(c, stats.wordsProcessedLastWeek[c.type]);
      if (daysLeftForCorpus != null) {
        const endDate = finishDate(daysLeftForCorpus, previousEndDate[c.type]);
        c.estimatedEndDate = endDate;
        c.daysRemaining = daysLeftForCorpus;
        previousEndDate[c.type] = endDate;
      }
    }
    return c;
  });
};

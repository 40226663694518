import React, { PropsWithChildren, useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import SettingsControls from '../SettingsControls';

import { FormStateContextValue, FormStateContext } from './FormStateContext';
import { FormModifiedContext } from './FormModifiedContext';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginBottom: theme.spacing(4)
    },
    fixAlign: {
      display: 'flex',
      alignItems: 'center'
    },
    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderBottom: '1px solid gray'
    }
  })
);

const SettingsGroup = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  const classes = useStyles();

  const [modified, setModified] = useState(false);

  const [formGroupContext] = useState(() => new FormStateContextValue());

  useEffect(() => () => formGroupContext.clean(), []);

  return (
    <FormModifiedContext.Provider value={{ modified, setModified }}>
      <FormStateContext.Provider value={formGroupContext}>
        <div className={classes.container}>
          <Grid container alignItems="center" justify="space-between" className={classes.header}>
            <Grid item className={classes.fixAlign}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid>
              <SettingsControls />
            </Grid>
          </Grid>

          {children}
        </div>
      </FormStateContext.Provider>
    </FormModifiedContext.Provider>
  );
};

export default SettingsGroup;

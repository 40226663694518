import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AllCounts, Counts, initCounts, Progress, VotingProgressTab } from '../progress.store';
import { VotingStatus } from '../progress.interface.api';
import { actions } from '../progress.actions';
import { StoreInterface } from '../../../../redux/store/store';
import { AppsActions } from '../../../../redux/actions/actions';
import PaddedBox from '../../../../components/layout/PaddedBox';
import { VoteJobType } from '../../../../model/voting/voteJobType';

import VotingProgressTable from './VotingProgressTable';
import VotingStatusSwitcher from './VotingStatusSwitcher';
import VoteTypeSwitcher from './VoteTypeSwitcher';

const combineCounts = (counts: AllCounts, filter?: VoteJobType): Counts => {
  if (!filter) {
    return Object.values(counts).reduce((acc, typeCounts) => {
      const keys = Object.keys(acc) as VotingProgressTab[];

      keys.forEach(key => {
        acc[key] = (acc[key] || 0) + typeCounts[key];
      });

      return acc;
    }, initCounts());
  }

  return counts[filter];
};

const compareFn = (r1: VotingStatus, r2: VotingStatus) =>
  (r2.proofreadingCompleted || 0) - (r1.proofreadingCompleted || 0);

const combineRows = (rows: Progress, tab: VotingProgressTab, filter?: VoteJobType) => {
  const maxRecords = 300;

  const allRows = !filter
    ? Object.values(rows).reduce((acc, typeRows) => acc.concat(typeRows[tab]), [] as VotingStatus[])
    : rows[filter][tab] || [];

  return allRows.sort(compareFn).slice(0, maxRecords);
};

const VotingProgressPage = (props: Props & Dispatches) => {
  const [ready, setReady] = useState<boolean>(false);
  const [rows, setRows] = useState<VotingStatus[]>([]);

  useEffect(() => {
    if (!ready) {
      props.load();
      setReady(true);
    }
  });

  useEffect(() => {
    setRows(combineRows(props.combinedProgress, props.selectedTab, props.voteTypeFilter));
  }, [props.selectedTab, props.voteTypeFilter, props.combinedProgress]);

  if (!ready || props.loading) {
    return null;
  }

  const counts = combineCounts(props.combinedCounts, props.voteTypeFilter);
  return (
    <PaddedBox style={{ position: 'relative' }}>
      {!props.loading && (
        <PaddedBox>
          <Grid container justify="space-between">
            <Grid item component={Paper}>
              <VotingStatusSwitcher counts={counts} />
            </Grid>
            <Grid item>
              <VoteTypeSwitcher onTabChange={props.changeVoteTypeFilter} />
            </Grid>
          </Grid>
        </PaddedBox>
      )}

      {!props.loading && (
        <PaddedBox centered>
          <VotingProgressTable rows={rows} totalCount={counts[props.selectedTab]} />
        </PaddedBox>
      )}
    </PaddedBox>
  );
};

interface Props {
  voteTypeFilter?: VoteJobType;
  selectedTab: VotingProgressTab;
  combinedCounts: AllCounts;
  combinedProgress: Progress;
  loading: boolean;
}

interface Dispatches {
  load: () => void;
  changeVoteTypeFilter: (tab?: VoteJobType) => void;
}

export default connect(
  ({ voting: { progress } }: StoreInterface): Props => ({
    voteTypeFilter: progress.voteTypeFilter,
    selectedTab: progress.selectedTab,
    combinedCounts: progress.counts,
    combinedProgress: progress.progress,
    loading: progress.loading
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    load: () => dispatch(actions.startLoading()),
    changeVoteTypeFilter: filter => dispatch(actions.changeSnippetTypeFilter(filter))
  })
)(VotingProgressPage);

import { Action } from 'redux';

export enum AppActionType {
  Initialize = 'app/initialize',
  Terminate = 'app/terminate',
  ShowSpinner = 'app/show-spinner',
  HideSpinner = 'app/hide-spinner'
}

/**
 * Initialize
 */
export interface InitializeAction extends Action {
  type: AppActionType.Initialize;
  payload: {
    initDate: Date;
  };
}
export const initialize = (initDate: Date): InitializeAction => ({
  type: AppActionType.Initialize,
  payload: {
    initDate
  }
});

/**
 * Terminate
 */
export interface TerminateAction extends Action {
  type: AppActionType.Terminate;
  payload: {
    terminateDate: Date;
  };
}
export const terminate = (terminateDate: Date): TerminateAction => ({
  type: AppActionType.Terminate,
  payload: {
    terminateDate
  }
});

/**
 * ShowSpinner
 */
export interface ShowSpinnerAction extends Action {
  type: AppActionType.ShowSpinner;
  payload: {
    initiator: string;
  };
}
export const showSpinner = (initiator: string): ShowSpinnerAction => ({
  type: AppActionType.ShowSpinner,
  payload: { initiator }
});

/**
 * HideSpinner
 */
export interface HideSpinnerAction extends Action {
  type: AppActionType.HideSpinner;
  payload: {
    initiator: string;
  };
}
export const hideSpinner = (initiator: string): HideSpinnerAction => ({
  type: AppActionType.HideSpinner,
  payload: { initiator }
});

/**
 * All App Actions
 */
export type AppActions = InitializeAction | TerminateAction | ShowSpinnerAction | HideSpinnerAction;

import { call, put, takeEvery } from 'redux-saga/effects';
import { UserClaims } from '@okta/okta-auth-js';

import { AuthProfile, SecurityGroup, string2Group } from './okta';
import { actions, AuthActionType, GetUserInfo } from './actions';

function* getUserInfo(action: GetUserInfo) {
  const userInfo: UserClaims | undefined = yield call(action.payload.getUser);

  const profile: AuthProfile | undefined = userInfo && {
    name: userInfo.name || '',
    email: userInfo.preferred_username || '',
    groups: userInfo.groups ? userInfo.groups.map(string2Group).filter((group?: SecurityGroup) => group) : []
  };

  yield put(actions.storeUser(profile));
}

export default function*() {
  yield takeEvery(AuthActionType.GetUserInfo, getUserInfo);
}

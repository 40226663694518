import { ID } from '../../utils/types';
import { timestampToDate } from '../../utils/date';
import { Proofreader } from '../../apis/proofreaders.api';

import { ProofitLog as ApiLog } from './proofit-log.api.interface';
import { ProofitLog as StoreLog } from './proofit-log.store';

const findProofreaderName = (id: ID, proofreaders: Proofreader[]): string | undefined => {
  const foundProofreader = proofreaders.find(p => p.id === id);
  return foundProofreader && foundProofreader.name;
};

export default (api: ApiLog, proofreaders: Proofreader[]): StoreLog => ({
  id: api.id,

  jobId: api.jobId,
  taskType: api.taskType,
  taskId: api.taskId,

  assigneeId: api.assigneeId,
  assigneeEmail: api.assigneeEmail,
  assigneeName: findProofreaderName(api.assigneeId, proofreaders),

  when: timestampToDate(api.when),
  category: api.category,
  message: api.message
});

import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { createStyles, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Dispatch } from 'redux';

import { AppsActions } from '../../../redux/actions/actions';
import { StoreInterface } from '../../../redux/store/store';
import { scheduleActions } from '../view/actions';
import { DAILY_SCALE, Scale, WEEKLY_SCALE } from '../view/store';
import CohortSwitcher from '../../../modules/permissions/CohortSwitcher';

const styles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      position: 'relative'
    },
    item: {
      '& > *': {
        margin: '0 .3em'
      }
    },
    filterSelector: {
      minWidth: 150,
      maxWidth: 150,
      overflow: 'ellipsis'
    }
  })
);

const ChangeScaleButton = (props: { text: string; selected: boolean; handler: () => void }) => {
  const buttonProps: any = props.selected ? { color: 'primary', variant: 'outlined' } : {};

  return (
    <Button onClick={props.handler} {...buttonProps}>
      {props.text}
    </Button>
  );
};

type ControlButtonsProps = Props & Dispatches;

const ControlButtons: React.FunctionComponent<ControlButtonsProps> = (props: ControlButtonsProps) => {
  const classes = styles();

  return (
    <Grid container alignItems="center" spacing={5} className={classes.root}>
      <Grid item className={classes.item}>
        <CohortSwitcher onChange={props.setCohortFilter} />
      </Grid>

      <Grid item className={classes.item}>
        <Button onClick={props.previous}>
          <ChevronLeftIcon />
        </Button>

        <span>{props.scale === WEEKLY_SCALE ? `${format(props.from)} - ${format(props.to)}` : format(props.from)}</span>

        <Button onClick={props.next}>
          <ChevronRightIcon />
        </Button>
      </Grid>

      <Grid item className={classes.item}>
        <ChangeScaleButton text="daily" selected={props.scale === DAILY_SCALE} handler={props.toDaily} />
        <ChangeScaleButton text="weekly" selected={props.scale === WEEKLY_SCALE} handler={props.toWeekly} />
      </Grid>
    </Grid>
  );
};

function format(date: Date): string {
  return moment.utc(date).format('ddd, MMM DD');
}

interface Props {
  from: Date;
  to: Date;
  asOf: Date;
  scale: Scale;

  cohortFilter: string;
}

interface Dispatches {
  toDaily: () => void;
  toWeekly: () => void;
  next: () => void;
  previous: () => void;
  setCohortFilter: (value: string) => void;
}

export default connect(
  ({ schedule: { view } }: StoreInterface): Props => ({
    from: view.from,
    to: view.to,
    asOf: view.asOfDate,
    scale: view.scale,
    cohortFilter: view.cohortFilter
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    toDaily: () => dispatch(scheduleActions.toDaily()),
    toWeekly: () => dispatch(scheduleActions.toWeekly()),
    next: () => dispatch(scheduleActions.next()),
    previous: () => dispatch(scheduleActions.previous()),
    setCohortFilter: value => dispatch(scheduleActions.setPermissionFilter(value))
  })
)(ControlButtons);

import { str2enumStrict } from '../utils/enums/str2enum';
import { VoteJobType } from '../model/voting/voteJobType';

export enum ProofreaderStatus {
  Onshift = 'Onshift',
  Away = 'Away'
}

export enum OnboardingStatus {
  Testing = 'Testing',
  Approved = 'Approved',
  TestFailed = 'TestFailed',
  AssignedJobs = 'AssignedJobs'
}

export interface ProofreaderRaw {
  id: number;
  name: string;
  email: string;
  timeZone: string;
  status: string;
  onboardingStatus: string;
  blocked: boolean;
  inTestGroup: boolean;
  inGrade: boolean;

  permissions: string[];
  cohorts: string[];
  suitableForVoting: string[];

  welcomeEmailSent: boolean;
  lastSuccessfulLogin?: number;
}

export type Proofreader = { id: number; name: string };

export type ProofreaderDetails = Omit<
  ProofreaderRaw,
  'status' | 'onboardingStatus' | 'lastSuccessfulLogin' | 'suitableForVoting'
> & {
  status: ProofreaderStatus;
  onboardingStatus: OnboardingStatus;
  lastSuccessfulLogin: Date | null;
  suitableForVoting: VoteJobType[];
};

export interface ProofreaderUpdate {
  name: string;
  email: string;
  onboardingStatus: OnboardingStatus;
  blocked: boolean;
  inTestGroup: boolean;
  permissions: string[];
}

export const parse = (raw: ProofreaderRaw): ProofreaderDetails => {
  const { status, onboardingStatus, lastSuccessfulLogin, ...rest } = raw;

  return {
    ...rest,
    status: str2enumStrict(ProofreaderStatus, raw.status, 'proofreader status'),
    onboardingStatus: str2enumStrict(OnboardingStatus, raw.onboardingStatus, 'onboarding status'),
    lastSuccessfulLogin: raw.lastSuccessfulLogin ? new Date(raw.lastSuccessfulLogin) : null,
    suitableForVoting: raw.suitableForVoting.map(voteJobType =>
      str2enumStrict(VoteJobType, voteJobType, 'vote job type')
    )
  };
};

export const dummyProofreader = (dummy: Partial<ProofreaderDetails>): ProofreaderDetails => ({
  id: 0,
  name: '',
  email: 'string',
  timeZone: 'UTC',
  status: ProofreaderStatus.Away,
  onboardingStatus: OnboardingStatus.Approved,
  blocked: false,
  inTestGroup: false,
  inGrade: false,

  permissions: [],
  cohorts: [],
  suitableForVoting: [],

  welcomeEmailSent: true,
  lastSuccessfulLogin: new Date(),

  ...dummy
});

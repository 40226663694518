import { call, cancel, fork, put, take } from 'redux-saga/effects';

import fetch from '../../../../utils/fetch';
import appConfig from '../../../../config';
import { hideSpinner, showSpinner } from '../../../App/app.actions';
import { NewJobForm } from '../../job.interface';
import { actions, JobsListActionType, JobsListCreateJob } from '../jobs-list.actions';
import { Job as ApiJob } from '../../job.api.interface';
import convertJob from '../../jobs.api.converter';
import { getOwnership } from '../../../../model/job/jobType';

function* request({ jobType, userId, file, proofreaderId, turnaround }: NewJobForm) {
  try {
    const params = new URLSearchParams();

    if (getOwnership(jobType) === 'user') {
      if (!userId) throw new Error('Wrong userId');
    }

    if (!file) {
      throw new Error('No file specified');
    }

    params.append('jobType', jobType.toString());
    params.append('turnaround', turnaround);

    if (proofreaderId) {
      params.append('proofreaderId', proofreaderId);
    }
    if (userId) {
      params.append('userId', userId);
    }

    const formData = new FormData();
    formData.append('file', file);

    const config: RequestInit = {
      method: 'POST',
      body: formData
    };

    let response: ApiJob = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.createJob}?${params.toString()}`,
      config
    );
    yield put(actions.createJobSuccess(convertJob(response)));
    yield put(actions.performJobActionSuccess(convertJob(response)));
  } catch (error) {
    yield put(actions.createJobFailure(error.message));
  }
}

export default function*(action: JobsListCreateJob) {
  try {
    yield put(showSpinner('create-job'));

    const task = yield fork(request, action.payload);

    const stopTaskAction = yield take([JobsListActionType.CreateJobSuccess, JobsListActionType.CreateJobFailed]);

    if (stopTaskAction.type === JobsListActionType.CreateJobFailed) {
      yield cancel(task);
    }
    yield put(actions.hideCreateJobPopup());
  } finally {
    yield put(hideSpinner('create-job'));
  }
}

export const str2enum = <E, K extends string>(enumDef: { [key in K]: E }, str: string | undefined): E | undefined => {
  if (str && str in enumDef) {
    return enumDef[str as K] as E;
  }
  return undefined;
};

export const str2enumStrict = <E, K extends string>(
  enumDef: { [key in K]: E },
  str: string | undefined,
  enumName: string
): E => {
  const result = str2enum(enumDef, str);

  if (!result) throw new Error(`Unknown ${enumName}: ${str}`);

  return result;
};

import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects';

import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { hideSpinner, showSpinner } from '../App/app.actions';
import { parse, ProofreaderDetails, ProofreaderRaw, ProofreaderUpdate } from '../../apis/proofreaders.api';
import { getProofreaders } from '../../redux/saga/get-proofreaders.saga';
import { JobsListActionType } from '../Jobs';

import {
  proofreaderListActions,
  CreateProofreader,
  UpdateProofreader,
  WelcomeEmail,
  ProofreadersListActionType
} from './proofreader-list.actions';

export function* welcomeEmailRequest(id: number) {
  try {
    yield call(fetch.request.bind(fetch), `${appConfig.api.proofreaders.welcomeEmail}`.replace('{id}', id.toString()));
    yield put(proofreaderListActions.welcomeEmailSuccess(id));
  } catch (error) {
    yield put(proofreaderListActions.welcomeEmailFailure(error.message));
  }
}

export function* updateProofreaderRequest(id: number, proofreader: ProofreaderUpdate) {
  try {
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(proofreader)
    };

    const response: ProofreaderRaw = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.proofreaders.root}/${id}`,
      config,
      'application/json'
    );

    const updatedProofreader = parse(response);

    yield put(proofreaderListActions.updateProofreaderSuccess(updatedProofreader));
  } catch (error) {
    yield put(proofreaderListActions.proofreaderUpdateFailure(error.message));
  }
}

export function* createProofreaderRequest(proofreader: ProofreaderUpdate) {
  try {
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(proofreader)
    };

    const response: ProofreaderRaw = yield call(
      fetch.request.bind(fetch),
      appConfig.api.proofreaders.root,
      config,
      'application/json'
    );

    const createdProofreader = parse(response);

    yield put(proofreaderListActions.createProofreaderSuccess(createdProofreader));
  } catch (error) {
    yield put(proofreaderListActions.proofreaderCreateFailure(error.message));
  }
}

export function* proofreadersListStartAsync() {
  try {
    yield put(showSpinner('load-proofreaders-list'));

    const proofreaders: ProofreaderDetails[] = yield getProofreaders({}, e =>
      proofreaderListActions.loadingFailure(e.message)
    );

    yield put(proofreaderListActions.loadingSuccess(proofreaders));
  } finally {
    yield put(hideSpinner('load-proofreaders-list'));
  }
}

export function* sendWelcomeEmailAsync(action: WelcomeEmail) {
  try {
    yield put(showSpinner('send-welcome-email'));
    const task = yield fork(welcomeEmailRequest, action.payload);
    const actionTask = yield take([
      ProofreadersListActionType.WelcomeEmailSuccess,
      ProofreadersListActionType.WelcomeEmailFailure
    ]);
    if (actionTask.type === ProofreadersListActionType.WelcomeEmailFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('send-welcome-email'));
  }
}

export function* proofreaderUpdateAsync(action: UpdateProofreader) {
  try {
    yield put(showSpinner('proofreader-update'));
    const task = yield fork(updateProofreaderRequest, action.payload.id, action.payload.proofreader);
    const actionTask = yield take([
      ProofreadersListActionType.UpdateProofreaderSuccess,
      ProofreadersListActionType.UpdateProofreaderFailure
    ]);
    if (actionTask.type === ProofreadersListActionType.UpdateProofreaderFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('proofreader-update'));
  }
}

export function* proofreaderCreateAsync(action: CreateProofreader) {
  try {
    yield put(showSpinner('proofreader-create'));
    const task = yield fork(createProofreaderRequest, action.payload);
    const actionTask = yield take([
      ProofreadersListActionType.CreateProofreaderSuccess,
      ProofreadersListActionType.CreateProofreaderFailure
    ]);
    if (actionTask.type === ProofreadersListActionType.CreateProofreaderFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('proofreader-create'));
  }
}

export function* watchProofreadersListSaga() {
  yield takeLatest(
    [ProofreadersListActionType.StartLoading, JobsListActionType.StartLoading],
    proofreadersListStartAsync
  );
  yield takeLatest(ProofreadersListActionType.UpdateProofreader, proofreaderUpdateAsync);
  yield takeLatest(ProofreadersListActionType.CreateProofreader, proofreaderCreateAsync);
  yield takeLatest(ProofreadersListActionType.WelcomeEmail, sendWelcomeEmailAsync);
}

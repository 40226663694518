import React, { ChangeEvent, FunctionComponent } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { Dispatch } from 'redux';
import Tooltip from '@material-ui/core/Tooltip';

import { StoreInterface } from '../../../redux/store/store';
import { fileNameToLetterAvatar } from '../_utils';
import { AppsActions } from '../../../redux/actions/actions';
import { practiceDocumentsActions } from '../practice.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadWrapperEmpty: {
      overflow: 'hidden'
    },
    uploadWrapperSelected: {
      display: 'flex',
      alignItems: 'stretch',
      background: theme.palette.background.default
    },
    uploadInput: {
      display: 'none'
    },
    selectedFile: {
      width: '100%',
      flexDirection: 'column',
      alignSelf: 'center',
      paddingTop: 0,
      paddingBottom: 0
    },
    selectedFileButtons: {
      right: theme.spacing(1)
    },
    fab: {
      float: 'right',
      margin: theme.spacing(1)
    }
  })
);

const PracticeDocsUploader: FunctionComponent<PracticeDocsUploaderProps> = (props: PracticeDocsUploaderProps) => {
  const [practiceFile, setPracticeFile] = React.useState<File | null>(null);

  const file = practiceFile || props.uploadFile || null;
  const classes = useStyles();
  const uploadWrapperClass = clsx({
    [classes.uploadWrapperEmpty]: !file,
    [classes.uploadWrapperSelected]: file
  });

  const fileSelectedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const files = event.target.files;
    if (files && files.length == 1 && files[0]) {
      const file: File = files[0];
      setPracticeFile(file);
    }
  };

  const selectedFileCancelClickedHandler = () => {
    setPracticeFile(null);
  };

  const selectedFileUploadClickedHandler = () => {
    if (practiceFile) {
      props.upload(practiceFile);
      setPracticeFile(null);
    }
  };

  const selectedFile = file && (
    <List className={classes.selectedFile}>
      <ListItem key={0} dense>
        <ListItemAvatar>
          <Avatar>{fileNameToLetterAvatar(file.name)}</Avatar>
        </ListItemAvatar>
        <ListItemText id="new-file-0" primary={file.name} />
        <ListItemSecondaryAction className={classes.selectedFileButtons}>
          <Tooltip title="Discard selection">
            <IconButton disabled={props.uploading} onClick={selectedFileCancelClickedHandler} size="small">
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  const materialButton = !file ? (
    <div>
      <input
        accept=".txt,.doc,.docx"
        id="PracticeDocButton"
        type="file"
        onChange={fileSelectedHandler}
        className={classes.uploadInput}
      />
      <label htmlFor="PracticeDocButton">
        <Tooltip title="Select new document">
          <Fab color="primary" component="span" className={classes.fab} size="small">
            <AddIcon />
          </Fab>
        </Tooltip>
      </label>
    </div>
  ) : (
    <div>
      <Tooltip title="Upload document">
        <Fab
          color="primary"
          className={classes.fab}
          disabled={props.uploading}
          onClick={selectedFileUploadClickedHandler}
          size="small"
        >
          <CloudUploadIcon />
        </Fab>
      </Tooltip>
    </div>
  );

  return (
    <div className={uploadWrapperClass}>
      {selectedFile}
      {materialButton}
    </div>
  );
};

interface Props {
  uploading: boolean;
  uploadFile: File | null;
}

interface Dispatches {
  upload: (file: File) => void;
}

type PracticeDocsUploaderProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface): Props => ({
  uploading: state.practice.uploadingDocument,
  uploadFile: state.practice.uploadDocument
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  upload: (file: File) => dispatch(practiceDocumentsActions.upload(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeDocsUploader);

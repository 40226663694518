import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';

import { resultsActions } from '../results.actions';
import { AppsActions } from '../../../../redux/actions/actions';
import { StoreInterface } from '../../../../redux/store/store';
import { PopupStore } from '../results.store';
import { ProofreaderDetails } from '../../../../apis/proofreaders.api';
import { VoteJobType, prettyPrint } from '../../../../model/voting/voteJobType';

const styles = makeStyles(() =>
  createStyles({
    container: {
      width: 450
    }
  })
);

type DialogProps = Props & Dispatches;

const GradeProofreaderDialog: React.FunctionComponent<DialogProps> = (props: DialogProps) => {
  const classes = styles();

  const [gradingJobs, changeGradingJobs] = useState<number>(60);
  const [snippetType, changeSnippetType] = useState<string>('GEC');

  if (!props.popup) {
    return null;
  }

  const onSubmit = () => {
    props.popup && props.gradeProofreader(props.popup.proofreader, gradingJobs, snippetType);
  };

  return (
    <Dialog open={true} onClose={props.onClose} classes={{ paper: classes.container }}>
      <DialogTitle>{props.popup.proofreader.name}</DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          type="number"
          value={gradingJobs}
          label="Initiate the following number of voting jobs"
          onChange={e => changeGradingJobs(+e.target.value)}
        />
      </DialogContent>

      <DialogContent>
        <Typography variant="body1">Type of snippets to vote</Typography>
        <RadioGroup value={snippetType} onChange={e => changeSnippetType(e.target.value)}>
          <FormControlLabel control={<Radio />} label="GEC" value="GEC" />
          {Object.values(VoteJobType)
            .filter(type => type !== VoteJobType.GEC)
            .filter(type => props.popup?.proofreader.suitableForVoting.includes(type))
            .map(type => (
              <FormControlLabel key={type} control={<Radio />} label={prettyPrint(type)} value={type} />
            ))}
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        {props.popup.creatingGrades && <CircularProgress />}
        <Button color="secondary" onClick={props.onClose} disabled={props.popup.creatingGrades}>
          Don&apos;t Initiate
        </Button>
        <Button autoFocus color="primary" onClick={onSubmit} disabled={props.popup.creatingGrades}>
          Initiate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  popup?: PopupStore;
}

interface Dispatches {
  gradeProofreader: (proofreader: ProofreaderDetails, voteJobs: number, snippetType: string) => void;
  onClose: () => void;
}

export default connect(
  (store: StoreInterface): Props => ({
    popup: store.voting.results.popup
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    gradeProofreader: (proofreader, voteJobs, snippetType) => {
      dispatch(resultsActions.gradeProofreader(proofreader, voteJobs, snippetType));
    },
    onClose: () => dispatch(resultsActions.unsetProofreaderToGrade())
  })
)(GradeProofreaderDialog);

import { APP_CONFIG, AppConfig } from './app.config';
import { merge } from './utils';

const LOCAL_CONFIG: AppConfig = merge(APP_CONFIG, {
  mode: 'development',
  env: 'local',
  domain: {
    host: 'localhost:3000',
    localServer: 'http://localhost:3000/'
  },
  api: {
    server: 'http://localhost:8090/'
  },
  okta: {
    baseUrl: 'https://dev-149533.okta.com/',
    clientId: '0oa7o63sdlIaHK2AO357',
    issuer: 'https://dev-149533.okta.com/oauth2/ausaoubyvby6gxqWB357'
  },
  denali: {
    server: 'https://app.qagr.io/'
  },
  grammarlyUserId: '2326656'
});

export default LOCAL_CONFIG;

import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';

import { Corpus, CorpusCreationInfo, CorpusModificationOperation, QueuesStats } from './corpora.interface';

export enum CorporaActionType {
  StartLoading = 'corpora/start-loading',
  StopLoading = 'corpora/stop-loading',
  CorporaLoadingSuccess = 'corpora/corpora-loading-success',
  CorporaLoadingFailure = 'corpora/corpora-loading-failure',
  ModifyCorpus = 'corpora/modify-corpus',
  CorpusModificationSuccess = 'corpora/corpus-modification-success',
  CorpusModificationFailure = 'corpora/corpus-modification-failure',
  CreateCorpus = 'corpora/create-corpus',
  CorpusCreationSuccess = 'corpora/corpus-creation-success',
  CorpusCreationFailure = 'corpora/corpus-creation-failure',
  SwapRanks = 'corpora/swap-ranks',
  SwapRanksSuccess = 'corpora/swap-ranks-success',
  SwapRanksFailure = 'corpora/swap-ranks-failure',

  CorpusArchivesLoadingSuccess = 'corpora/corpus-archives-loading-success',
  CorpusArchivesLoadingFailure = 'corpora/corpus-archives-loading-failure',
  UploadCorpusArchive = 'corpora/upload-corpus-archive',
  CorpusArchiveUploadingSuccess = 'corpora/corpus-archive-uploading-success',
  CorpusArchiveUploadingFailure = 'corpora/corpus-archive-uploading-failure',

  ClarityReportsLoading = 'corpora/clarity-reports-start-loading',
  ClarityReportsLoadingSuccess = 'corpora/clarity-reports-start-loading-success',
  ClarityReportsLoadingFailure = 'corpora/clarity-reports-start-loading-failure',
  ClarityReportDownload = 'corpora/clarity-report-download',

  CorpusQueuesStatsLoading = 'corpora/corpus-queues-stats-start-loading',
  CorpusQueuesStatsLoadingSuccess = 'corpora/corpus-queues-stats-start-loading-success',
  CorpusQueuesStatsLoadingFailure = 'corpora/corpus-queues-stats-start-loading-failure'
}

export interface CorporaStartLoadingAction extends Action {
  type: CorporaActionType.StartLoading;
}

export interface CorporaStopLoadingAction extends Action {
  type: CorporaActionType.StopLoading;
}

export interface CorporaLoadingSuccessAction extends Action {
  type: CorporaActionType.CorporaLoadingSuccess;
  payload: {
    corpora: Corpus[];
  };
}

export interface CorporaLoadingFailureAction extends ErrorAction {
  type: CorporaActionType.CorporaLoadingFailure;
}

export interface ModifyCorpusAction extends Action {
  type: CorporaActionType.ModifyCorpus;
  payload: {
    corpus: Corpus;
    operation: CorpusModificationOperation;
  };
}

export interface CorpusModificationSuccessAction extends Action {
  type: CorporaActionType.CorpusModificationSuccess;
  payload: {
    corpus: Corpus;
    operation: CorpusModificationOperation;
  };
}

export interface CorpusModificationFailureAction extends ErrorAction {
  type: CorporaActionType.CorpusModificationFailure;
}

export interface CreateCorpusAction extends Action {
  type: CorporaActionType.CreateCorpus;
  payload: {
    corpus: CorpusCreationInfo;
  };
}

export interface CorpusCreationSuccessAction extends Action {
  type: CorporaActionType.CorpusCreationSuccess;
  payload: {
    corpus: Corpus;
  };
}

export interface CorpusCreationFailureAction extends ErrorAction {
  type: CorporaActionType.CorpusCreationFailure;
}

export interface CorporaSwapRanksAction extends Action {
  type: CorporaActionType.SwapRanks;
  payload: {
    first: Corpus;
    second: Corpus;
  };
}

export interface CorporaSwapRanksSuccessAction extends Action {
  type: CorporaActionType.SwapRanksSuccess;
  payload: {
    first: Corpus;
    second: Corpus;
  };
}

export interface CorporaSwapRanksFailureAction extends ErrorAction {
  type: CorporaActionType.SwapRanksFailure;
}

export interface ClarityReportLoadingAction extends Action {
  type: CorporaActionType.ClarityReportsLoading;
}

export interface ClarityReportLoadingSuccessAction extends Action {
  type: CorporaActionType.ClarityReportsLoadingSuccess;
  payload: {
    clarityReports: string[];
  };
}

export interface ClarityReportLoadingFailureAction extends ErrorAction {
  type: CorporaActionType.ClarityReportsLoadingFailure;
}

export interface ClarityReportDownloadAction extends Action {
  type: CorporaActionType.ClarityReportDownload;
  payload: {
    reportDate: string;
  };
}

export interface CorpusQueuesStatsLoadingAction extends Action {
  type: CorporaActionType.CorpusQueuesStatsLoading;
}

export interface CorpusQueuesStatsLoadingSuccessAction extends Action {
  type: CorporaActionType.CorpusQueuesStatsLoadingSuccess;
  payload: {
    stats: QueuesStats;
  };
}

export interface CorpusQueuesStatsLoadingFailureAction extends ErrorAction {
  type: CorporaActionType.CorpusQueuesStatsLoadingFailure;
}

export const corporaGeneralActions = {
  startLoading: (): CorporaStartLoadingAction => ({
    type: CorporaActionType.StartLoading
  }),
  stopLoading: (): CorporaStopLoadingAction => ({
    type: CorporaActionType.StopLoading
  })
};

export const corpusActions = {
  loadingSuccess: (corpora: Corpus[]): CorporaLoadingSuccessAction => ({
    type: CorporaActionType.CorporaLoadingSuccess,
    payload: {
      corpora: corpora
    }
  }),
  loadingFailure: (reason: string): CorporaLoadingFailureAction => ({
    type: CorporaActionType.CorporaLoadingFailure,
    payload: new Error(`Failed to load all corpora: ${reason}`),
    error: true
  }),
  modify: (corpus: Corpus, operation: CorpusModificationOperation): ModifyCorpusAction => ({
    type: CorporaActionType.ModifyCorpus,
    payload: {
      corpus: corpus,
      operation: operation
    }
  }),
  modificationSuccess: (corpus: Corpus, operation: CorpusModificationOperation): CorpusModificationSuccessAction => ({
    type: CorporaActionType.CorpusModificationSuccess,
    payload: {
      corpus: corpus,
      operation: operation
    }
  }),
  modificationFailure: (reason: string): CorpusModificationFailureAction => ({
    type: CorporaActionType.CorpusModificationFailure,
    payload: new Error(`Failed to modify the corpus: ${reason}`),
    error: true
  }),
  create: (corpus: CorpusCreationInfo): CreateCorpusAction => ({
    type: CorporaActionType.CreateCorpus,
    payload: {
      corpus: corpus
    }
  }),
  creationSuccess: (corpus: Corpus): CorpusCreationSuccessAction => ({
    type: CorporaActionType.CorpusCreationSuccess,
    payload: {
      corpus: corpus
    }
  }),
  creationFailure: (reason: string): CorpusCreationFailureAction => ({
    type: CorporaActionType.CorpusCreationFailure,
    payload: new Error(`Failed to create the corpus: ${reason}`),
    error: true
  }),
  swapRanks: (first: Corpus, second: Corpus): CorporaSwapRanksAction => ({
    type: CorporaActionType.SwapRanks,
    payload: {
      first: first,
      second: second
    }
  }),
  swapRanksSuccess: (first: Corpus, second: Corpus): CorporaSwapRanksSuccessAction => ({
    type: CorporaActionType.SwapRanksSuccess,
    payload: {
      first: first,
      second: second
    }
  }),
  swapRanksFailure: (reason: string): CorporaSwapRanksFailureAction => ({
    type: CorporaActionType.SwapRanksFailure,
    payload: new Error(`Failed to change corpus rank: ${reason}`),
    error: true
  })
};

export const clarityReportActions = {
  clarityReportsLoad: (): ClarityReportLoadingAction => ({
    type: CorporaActionType.ClarityReportsLoading
  }),
  clarityReportsLoadSuccess: (clarityReports: string[]): ClarityReportLoadingSuccessAction => ({
    type: CorporaActionType.ClarityReportsLoadingSuccess,
    payload: {
      clarityReports: clarityReports
    }
  }),
  clarityReportsLoadFailure: (reason: string): ClarityReportLoadingFailureAction => ({
    type: CorporaActionType.ClarityReportsLoadingFailure,
    payload: new Error(`Failed to load clarity reports list: ${reason}`),
    error: true
  }),
  clarityReportDownload: (reportDate: string): ClarityReportDownloadAction => ({
    type: CorporaActionType.ClarityReportDownload,
    payload: {
      reportDate: reportDate
    }
  })
};

export const corpusQueuesStatsActions = {
  corpusQueuesStatsLoad: (): CorpusQueuesStatsLoadingAction => ({
    type: CorporaActionType.CorpusQueuesStatsLoading
  }),
  corpusQueuesStatsLoadSuccess: (stats: QueuesStats): CorpusQueuesStatsLoadingSuccessAction => ({
    type: CorporaActionType.CorpusQueuesStatsLoadingSuccess,
    payload: {
      stats: stats
    }
  }),
  corpusQueuesStatsLoadFailure: (reason: string): CorpusQueuesStatsLoadingFailureAction => ({
    type: CorporaActionType.CorpusQueuesStatsLoadingFailure,
    payload: new Error(`Failed to load corpus queues stats: ${reason}`),
    error: true
  })
};

/**
 * All Corpora Actions
 */
export type CorporaActions =
  | CorporaStartLoadingAction
  | CorporaStopLoadingAction
  | CorporaLoadingSuccessAction
  | CorporaLoadingFailureAction
  | ModifyCorpusAction
  | CorpusModificationSuccessAction
  | CorpusModificationFailureAction
  | CreateCorpusAction
  | CorpusCreationSuccessAction
  | CorpusCreationFailureAction
  | CorporaSwapRanksAction
  | CorporaSwapRanksSuccessAction
  | CorporaSwapRanksFailureAction
  | ClarityReportLoadingAction
  | ClarityReportLoadingSuccessAction
  | ClarityReportLoadingFailureAction
  | ClarityReportDownloadAction
  | CorpusQueuesStatsLoadingAction
  | CorpusQueuesStatsLoadingSuccessAction
  | CorpusQueuesStatsLoadingFailureAction;

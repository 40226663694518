import React from 'react';
import { Checkbox, createStyles, makeStyles } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { TurnaroundOption } from '../../../../model/job/turnaroundOption';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      width: 15,
      height: 15,
      padding: 0
    },
    icon: {
      fontSize: 20
    }
  })
);

interface Props {
  label?: string;
  value: boolean;
  option: TurnaroundOption;
  disabled: boolean;
  onChange: (option: TurnaroundOption, value: boolean) => void;
}

export default (props: Props) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(props.option, event.target.checked);
  };

  return (
    <Checkbox
      className={classes.checkbox}
      icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
      checkedIcon={<CheckBoxIcon className={classes.icon} />}
      color="primary"
      checked={props.value}
      disabled={props.disabled}
      onChange={handleChange}
    />
  );
};

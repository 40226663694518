import React from 'react';
import { NavLink } from 'react-router-dom';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';

import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { AppRoute } from '../../routes';

import JobsList from './JobsList/components/JobsList/JobsList';

const jobsListRoute: AppRoute = {
  path: '/jobs',
  component: JobsList,
  parent: indexRoute,
  pathMatch: /\/jobs/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-jobsListRoute">
      <NavLink to="/jobs">
        <Inbox className="AppBreadcrumbsIcon" />
        Jobs
      </NavLink>
    </Typography>
  ),
  private: true,
  exact: true
};

export default [jobsListRoute];

import React from 'react';
import { Container, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ID } from '../../../utils/types';
import { history, StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { actions } from '../proofit-log.actions';
import { ProofitLog, Filtering } from '../proofit-log.store';
import { isValidId } from '../../../utils/validation';

import ProofitLogTable from './ProofitLogTable';

const ensureValidId = (id: string | null): ID | undefined => (id && isValidId(id) ? +id : undefined);

const getUrlParams = (search: string): Filtering => {
  const params = new URLSearchParams(search);

  const jobId = ensureValidId(params.get('jobId'));
  const assigneeId = ensureValidId(params.get('assigneeId'));
  const taskId = ensureValidId(params.get('taskId'));

  return { jobId, assigneeId, taskId };
};

class ProofitLogPage extends React.Component<Props & Dispatches> {
  componentDidMount() {
    this.props.load(getUrlParams(history.location.search));
  }

  componentWillUnmount(): void {
    this.props.reset();
  }

  render() {
    if (!this.props.filteringReady) {
      return null;
    }

    const changeFiltering = (key: keyof Filtering, value?: string): void => {
      const newFiltering = { ...this.props.filtering, [key]: value || undefined };
      this.props.load(newFiltering);
    };

    return (
      <Container maxWidth="lg">
        <Paper>
          <ProofitLogTable logs={this.props.logs} filtering={this.props.filtering} onFilterChange={changeFiltering} />
        </Paper>
      </Container>
    );
  }
}

interface Props {
  loading: boolean;
  logs: ProofitLog[];
  filtering: Filtering;
  filteringReady: boolean;
}

interface Dispatches {
  load: (params: Filtering) => void;
  reset: () => void;
}

export default connect(
  (store: StoreInterface) => ({
    loading: store.proofitLog.loading,
    logs: store.proofitLog.logs,
    filtering: store.proofitLog.filtering || {},
    filteringReady: !!store.proofitLog.filtering
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    load: (filtering: Filtering) => dispatch(actions.loadLog(filtering)),
    reset: () => dispatch(actions.reset())
  })
)(ProofitLogPage);

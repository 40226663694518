import { Settings } from './settings.interface';

export interface SettingsStore {
  settings: Settings;
  loading: boolean;
  generalUpdating: boolean;
  priceUpdating: boolean;
  maxPriceUpdating: boolean;
  optionsUpdating: boolean;
}

export const INIT_SETTINGS_STORE: SettingsStore = {
  loading: false,
  generalUpdating: false,
  priceUpdating: false,
  maxPriceUpdating: false,
  optionsUpdating: false,
  settings: {
    lateGecSnippets: 0,
    lateClaritySnippets: 0,
    maxCapacity: 0,
    gecOptions: {},
    clarityOptions: {},
    basicPrices: {},
    basicClarityPrices: {},
    maxPrices: {}
  }
};

import React from 'react';

import { isValidUserId, isValidId } from '../../../../../utils/validation';
import { FilteringText } from '../../../../../components/FilteringText/FilteringText';
import { FilteringSelect } from '../../../../../components/FilteringSelect/FilteringSelect';
import { JobStatus, UserInputsFilter } from '../../../job.interface';
import { JobsFiltering } from '../../jobs-list.store';
import { idfy } from '../../../../../utils/types';
import { JobType, prettyPrint } from '../../../../../model/job/jobType';
import { JobSource, prettyPrint as prettyPrintJobSource } from '../../../../../model/job/source';
import {
  TurnaroundOption,
  prettyPrintShort as prettyPrintTurnaroundOption
} from '../../../../../model/job/turnaroundOption';

interface FilterProps {
  initialValue?: string;
  filter: (key: keyof JobsFiltering, value: string) => void;
}

export default {
  Id: (props: FilterProps) => (
    <FilteringText
      label="ID"
      initial={props.initialValue}
      submit={value => props.filter('id', idfy(value))}
      isValid={value => value === '' || isValidId(value)}
    />
  ),

  UserId: (props: FilterProps) => (
    <FilteringText
      label="UID"
      initial={props.initialValue}
      submit={value => props.filter('uid', idfy(value))}
      isValid={value => value === '' || isValidUserId(value)}
    />
  ),

  Type: React.memo((props: FilterProps) => (
    <FilteringSelect
      label="Job type"
      initial={props.initialValue}
      submit={value => props.filter('jobType', value)}
      data={[
        { name: 'All', value: '' },
        ...Object.values(JobType).map(jobType => ({ name: prettyPrint(jobType), value: jobType }))
      ]}
    />
  )),

  Inputs: React.memo((props: FilterProps) => (
    <FilteringSelect
      label="Inputs"
      data={[
        { name: 'All', value: UserInputsFilter.ALL },
        { name: 'No inputs', value: UserInputsFilter.NO_INPUTS },
        { name: 'Has inputs', value: UserInputsFilter.HAS_INPUTS },
        { name: 'Has note to editor', value: UserInputsFilter.HAS_NOTE }
      ]}
      submit={value => props.filter('inputs', value)}
    />
  )),

  Status: (props: FilterProps) => (
    <FilteringSelect
      label="Status"
      initial={props.initialValue}
      submit={value => props.filter('status', value)}
      data={[{ name: 'All', value: '' }, ...Object.values(JobStatus).map(status => ({ name: status, value: status }))]}
    />
  ),

  Options: (props: FilterProps) => (
    <FilteringSelect
      label="Option"
      initial={props.initialValue}
      submit={value => props.filter('option', value)}
      data={[
        { name: 'All', value: '' },
        ...Object.values(TurnaroundOption).map(option => ({ name: prettyPrintTurnaroundOption(option), value: option }))
      ]}
    />
  ),

  Source: (props: FilterProps) => (
    <FilteringSelect
      label="Source"
      initial={props.initialValue}
      submit={value => props.filter('source', value)}
      data={[
        { name: 'All', value: '' },
        ...Object.values(JobSource).map(source => ({ name: prettyPrintJobSource(source), value: source }))
      ]}
    />
  )
};

const numberFormatOption = { type: 'number', maximumFractionDigits: 1, useGrouping: true };

export function formatUsd(val: number = 0): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
}

export function formatNumber(val?: number, integer: boolean = false): string {
  if (val === undefined) {
    return '';
  }

  return integer ? '' + Math.round(val) : val.toLocaleString(undefined, numberFormatOption);
}

import React, { FunctionComponent, useState } from 'react';
import { Grid, IconButton, Theme } from '@material-ui/core';
import { AddComment, DeleteForever, InsertComment, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

import { Corpus, CorpusStatus } from '../corpora.interface';
import { corpusIsFinished } from '../_utils';
import { OpsOnly } from '../../../components/security/SecurityGroupFilter';
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';

import { EditCorpusLabelDialog } from './EditCorpusLabelDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden'
    },
    noLabel: {
      color: theme.palette.primary.main
    },
    withLabel: {
      color: theme.palette.success.main
    },
    rankButton: {
      color: 'black'
    }
  })
);

export const CorpusActionsGroup: FunctionComponent<CorpusActionsGroupProps> = (props: CorpusActionsGroupProps) => {
  const classes = useStyles();
  const editViewOnly = corpusIsFinished(props.corpus);
  const editClass = classNames({
    [classes.noLabel]: !editViewOnly && !props.corpus.label,
    [classes.withLabel]: !editViewOnly && !!props.corpus.label
  });
  const editTooltip = editViewOnly
    ? 'View corpus label'
    : props.corpus.label
    ? 'Edit corpus label'
    : 'Add corpus label';

  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);

  const editDisabled = props.modifying || (corpusIsFinished(props.corpus) && !props.corpus.label);
  const removeDisabled = props.modifying || props.corpus.status !== CorpusStatus.PAUSED;
  const rankDownDisabled =
    props.rankingDisabled || props.modifying || !props.lowerRankCorpus || corpusIsFinished(props.corpus);
  const rankUpDisabled =
    props.rankingDisabled || props.modifying || !props.higherRankCorpus || corpusIsFinished(props.corpus);

  const editDialogOpenHandler = () => {
    setEditDialogOpen(true);
  };

  const editDialogCloseHandler = () => {
    setEditDialogOpen(false);
  };

  const removeDialogOpenHandler = () => {
    setRemoveDialogOpen(true);
  };

  const removeDialogCloseHandler = () => {
    setRemoveDialogOpen(false);
  };

  const rankDownHandler = () => {
    if (!corpusIsFinished(props.corpus) && !!props.lowerRankCorpus && !corpusIsFinished(props.lowerRankCorpus)) {
      props.swapCorporaRanks(props.lowerRankCorpus, props.corpus);
    }
  };

  const rankUpHandler = () => {
    if (!corpusIsFinished(props.corpus) && !!props.higherRankCorpus && !corpusIsFinished(props.higherRankCorpus)) {
      props.swapCorporaRanks(props.corpus, props.higherRankCorpus);
    }
  };

  const removeHandler = () => {
    props.removeHandler(props.corpus);
  };

  const removalConfirmQuestion = (
    <React.Fragment>
      <Typography variant="h6">
        <span>
          Sure to remove the <b>{props.corpus.originalName}</b> corpus?
        </span>
      </Typography>
      <Typography variant="body1">
        <span>The action cannot be reverted, all existing jobs will be cancelled.</span>
      </Typography>
    </React.Fragment>
  );

  return (
    <Grid container justify="flex-start" wrap="nowrap">
      <OpsOnly>
        <Tooltip title={editTooltip} placement="bottom">
          <Grid item>
            <IconButton size="small" className={editClass} disabled={editDisabled} onClick={editDialogOpenHandler}>
              {!props.corpus.label ? <AddComment fontSize="small" /> : <InsertComment fontSize="small" />}
            </IconButton>
          </Grid>
        </Tooltip>

        <EditCorpusLabelDialog
          open={editDialogOpen}
          corpus={props.corpus}
          saving={props.modifying}
          viewOnly={editViewOnly}
          closeHandler={editDialogCloseHandler}
          saveEditedLabelHandler={props.editLabelHandler}
        />
      </OpsOnly>

      <Tooltip title="Remove corpus" placement="bottom">
        <Grid item>
          <IconButton size="small" color="secondary" disabled={removeDisabled} onClick={removeDialogOpenHandler}>
            <DeleteForever fontSize="small" />
          </IconButton>

          <ConfirmDialog
            open={removeDialogOpen}
            questionElement={removalConfirmQuestion}
            confirmLabel={'Remove'}
            confirmActionHandler={removeHandler}
            closeHandler={removeDialogCloseHandler}
          />
        </Grid>
      </Tooltip>
      <Tooltip title="Rank up" placement="bottom">
        <Grid item>
          <IconButton size="small" className={classes.rankButton} disabled={rankUpDisabled} onClick={rankUpHandler}>
            <KeyboardArrowUp fontSize="small" />
          </IconButton>
        </Grid>
      </Tooltip>
      <Tooltip title="Rank down" placement="bottom">
        <Grid item>
          <IconButton size="small" className={classes.rankButton} disabled={rankDownDisabled} onClick={rankDownHandler}>
            <KeyboardArrowDown fontSize="small" />
          </IconButton>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

interface Props {
  corpus: Corpus;
  higherRankCorpus: Corpus | null;
  lowerRankCorpus: Corpus | null;
  rankingDisabled: boolean;
  modifying: boolean;
}

interface Dispatches {
  removeHandler: (corpus: Corpus) => void;
  editLabelHandler: (corpus: Corpus) => void;
  swapCorporaRanks: (first: Corpus, second: Corpus) => void;
}

type CorpusActionsGroupProps = Props & Dispatches;

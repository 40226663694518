import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ProofreaderRow, Scale, SelectedColumn, Shift } from '../view/store';
import { ITimeInterval, Milliseconds, split, TimeInterval, Timestamp } from '../../../utils/types';
import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import mergeChanges from '../utils/mergeChanges';
import { scheduleActions } from '../view/actions';
import { actions } from '../edit/actions';
import { display, ShiftView } from '../ShiftView';
import { ProofreaderStatus } from '../../../apis/proofreaders.api';
import { cohortFilter } from '../../../modules/permissions/CohortSwitcher';

import { AggregationRow, DataRow, HeaderRow } from './TableRows';
import EditPopper from './EditPopper';
import ShiftBlock from './ShiftBlock';
import CurrentTimeIndicator from './CurrentTimeIndicator';

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      fontSize: 14,
      position: 'relative',
      textAlign: 'center'
    }
  })
);

const CalendarTable = (props: Props & Dispatches) => {
  const classes = useStyles();

  const cohortFilterValue = useSelector((state: StoreInterface) => state.schedule.view.cohortFilter);
  const [popperAnchor, setPopperAnchor] = useState<any>(undefined);

  const findShift = (key: string): Shift | undefined =>
    props.proofreaders
      .flatMap(p => p.shifts)
      .map(display)
      .find(s => s.shiftKey == key);

  const dropHandler = (e: React.DragEvent) => {
    e.preventDefault();

    const shift = findShift(e.dataTransfer.getData('shift'));
    const baseDragX = +e.dataTransfer.getData('baseDragX');
    const finishDragX = e.clientX;

    const dragDeltaPx = finishDragX - baseDragX;

    if (shift) {
      props.moveShift(shift, dragDeltaPx * props.scale.scalingProps.millisPerPixel);
    }
  };

  const addShiftHandler = (offset: Milliseconds, p: ProofreaderRow) => {
    const offsetMillis = props.scale.scalingProps.millisPerPixel * offset;

    const beginMoment = moment.utc(props.from.getTime() + offsetMillis);
    const dayStartMoment = beginMoment.clone().startOf('day');

    const dayStart = dayStartMoment.valueOf();
    const offsetWithinDay = beginMoment.valueOf() - dayStartMoment.valueOf();

    props.addShift(p, dayStart, offsetWithinDay);
  };

  const filters = {
    permission: (p: ProofreaderRow): boolean => cohortFilter(cohortFilterValue)(p),

    name: (p: ProofreaderRow): boolean =>
      !props.nameFilter || p.name.toLowerCase().includes(props.nameFilter.toLowerCase()),

    modified: (p: ProofreaderRow): boolean => !!p.shifts.find(s => !!s.modified),

    selectedColumn: (p: ProofreaderRow): boolean =>
      !props.selectedColumn ||
      !!p.shifts
        .map(display)
        .find(s => props.selectedColumn && new TimeInterval(s.begin, s.end).intersects(props.selectedColumn.interval)),

    noShifts: (p: ProofreaderRow): boolean => props.showNoShifts || p.shifts.length > 0,

    onShift: (p: ProofreaderRow): boolean => p.status === ProofreaderStatus.Onshift
  };

  /* TODO: filter order should be simplified and streamlined. Also replace with function composition */
  const rowsFilter = (p: ProofreaderRow) =>
    filters.name(p) &&
    filters.permission(p) &&
    (filters.onShift(p) || filters.modified(p) || (filters.noShifts(p) && filters.selectedColumn(p)));

  const aggregationFilter = filters.permission;

  const displayInterval: ITimeInterval = { begin: props.from.getTime(), end: props.to.getTime() };

  const splits = split(displayInterval, props.scale.step);

  const columns = splits.map(i => moment.utc(i.begin).format(props.scale.format));

  /*
   * TODO: is it possible to have scaling props somewhere in the rendering scope
   *       rather than have them predefined in our store?
   */

  return (
    <div className={classes.tableWrapper} onDragOver={e => e.preventDefault()} onDrop={dropHandler}>
      <HeaderRow columns={columns} selectedColumn={props.selectedColumn} onClick={props.toggleColumn} />

      <AggregationRow proofreaders={props.proofreaders.filter(aggregationFilter)} intervals={splits} />

      {props.selectedShift && (
        <EditPopper anchor={popperAnchor} displayInterval={displayInterval} shift={props.selectedShift} />
      )}

      {props.proofreaders.filter(rowsFilter).map(p => (
        <DataRow key={p.id} data={p} columnsCount={columns.length} onClick={offset => addShiftHandler(offset, p)}>
          {p.shifts.map(shiftView => {
            const shift = display(shiftView);
            return (
              <ShiftBlock
                key={shift.shiftKey}
                from={props.from}
                to={props.to}
                scale={props.scale}
                shift={shift}
                proofreader={p}
                selected={!!props.selectedShift && display(props.selectedShift).shiftKey === shift.shiftKey}
                modified={!!shiftView.modified}
                onClick={e => {
                  setPopperAnchor(e.currentTarget);
                  props.toggleSelection(shift);
                }}
              />
            );
          })}
        </DataRow>
      ))}

      <CurrentTimeIndicator now={Date.now()} scale={props.scale} />
    </div>
  );
};

interface Props {
  from: Date;
  to: Date;
  scale: Scale;
  proofreaders: ProofreaderRow[];

  selectedShift?: ShiftView;

  selectedColumn?: SelectedColumn;
  showNoShifts: boolean;
  nameFilter: string;
  cohortFilter: string;
}

interface Dispatches {
  addShift: (proofreader: ProofreaderRow, dayStart: Timestamp, offsetWithinDay: Milliseconds) => void;
  toggleSelection: (shift: Shift) => void;
  moveShift: (shift: Shift, offset: number) => void;
  toggleColumn: (index: number) => void;
}

export default connect(
  ({ schedule: { view, edit } }: StoreInterface): Props => {
    const proofreaders = view.proofreaders.map(p =>
      mergeChanges(p, edit.operations, new TimeInterval(view.from.getTime(), view.to.getTime()))
    );

    const selectedShift = proofreaders.flatMap(p => p.shifts).find(s => display(s).shiftKey === edit.selected);

    return {
      from: view.from,
      to: view.to,
      scale: view.scale,

      proofreaders,
      selectedShift,

      selectedColumn: view.selectedColumn,
      showNoShifts: view.showNoShifts,
      nameFilter: view.proofreaderFilter,
      cohortFilter: view.cohortFilter
    };
  },
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    toggleColumn: (index: number) => dispatch(scheduleActions.toggleColumn(index)),
    addShift: (proofreader, dayStart, offsetWithinDay) =>
      dispatch(actions.addShift(proofreader, dayStart, offsetWithinDay)),
    moveShift: (shift, offset) => dispatch(actions.moveShift(shift, offset)),
    toggleSelection: (shift: Shift) => dispatch(actions.toggleSelection(shift))
  })
)(CalendarTable);

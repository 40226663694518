import React, { FunctionComponent, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { CorpusType } from '../../../model/corpus/corpusType';
import { StoreInterface } from '../../../redux/store/store';

import { CorporaActionsGroup } from './CorporaActionsGroup';
import { CorpusExpansionPanel } from './CorpusExpansionPanel';
import { CreateCorpusDialog } from './CreateCorpusDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden'
    },
    header: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    mainContentWrapper: {
      overflowX: 'scroll',
      overflowY: 'hidden'
    },
    modificationProgress: {
      marginBottom: -4
    }
  })
);

export const CorporaPaper: FunctionComponent = () => {
  const classes = useStyles();

  const modifying = useSelector((state: StoreInterface) => state.corpora.modifyingCorpus);

  const [createCorpusDialogOpen, setCreateCorpusDialogOpen] = useState(false);
  const [showInactiveQueues, setShowInactiveQueues] = useState(false);

  const createCorpusButtonClickedHandler = () => {
    setCreateCorpusDialogOpen(true);
  };

  const createCorpusDialogClosedHandler = () => {
    setCreateCorpusDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      {modifying && <LinearProgress className={classes.modificationProgress} />}
      <div className={classes.header}>
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="h5">Corpora</Typography>
          </Grid>
          <Grid item>
            <CorporaActionsGroup uploading={modifying} createCorpusClickedHandler={createCorpusButtonClickedHandler} />
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={showInactiveQueues}
                    checkedIcon={<Visibility />}
                    icon={<VisibilityOff />}
                    onChange={v => setShowInactiveQueues(v.target.checked)}
                  />
                }
                label="Inactive queues"
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div className={classes.mainContentWrapper}>
        {Object.values(CorpusType).map(corpusType => (
          <CorpusExpansionPanel key={corpusType} corpusType={corpusType} showInactiveQueues={showInactiveQueues} />
        ))}
      </div>

      <CreateCorpusDialog
        open={createCorpusDialogOpen}
        uploading={modifying}
        closeHandler={createCorpusDialogClosedHandler}
      />
    </div>
  );
};

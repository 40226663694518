import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import loginRoute from '../../modules/auth/routes';
import scheduleRoutes from '../Schedule/schedule.routes';
import jobsListRoute from '../Jobs/jobs.routes';
import proofitLogRoute from '../ProofitLog/proofit-log.routes';
import settingsRoutes from '../Settings/settings.routes';
import proofreadersListRoutes from '../Proofreader/proofreaders-list.routes';
import onshiftProofreadersRoutes from '../Dashboard/onshift-proofreaders.routes';
import peerVotesRoutes from '../PeerVotes/peer-votes.routes';
import practiceRoute from '../Practice/practice.routes';
import corporaRoute from '../Corpora/corpora.routes';
import { error403route, error404route, fatalityRoute } from '../../routes/errors.routes';
import { AppRoute } from '../../routes';

export const APP_ROUTES: AppRoute[] = [
  indexRoute,
  ...proofreadersListRoutes,
  ...settingsRoutes,
  ...jobsListRoute,
  ...scheduleRoutes,
  ...practiceRoute,
  ...corporaRoute,
  ...onshiftProofreadersRoutes,
  ...peerVotesRoutes,
  proofitLogRoute,
  loginRoute,
  error403route,
  fatalityRoute
];

function getBreadcrumbsTree(currentRoute: AppRoute): AppRoute[] {
  if (!currentRoute.parent) {
    return [currentRoute];
  }
  return getBreadcrumbsTree(currentRoute.parent).concat([currentRoute]);
}

export function getBreadcrumbs(pathname: string): AppRoute[] {
  let currentRoute = APP_ROUTES.find(route => route.pathMatch.test(pathname));
  if (!currentRoute) {
    currentRoute = error404route;
  }
  return getBreadcrumbsTree(currentRoute);
}

import React, { FunctionComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { PracticeJob } from '../practice.interface';
import { AppsActions } from '../../../redux/actions/actions';
import { ID } from '../../../utils/types';
import { practiceJobsActions } from '../practice.actions';

export const PracticeJobAssessmentBlock: FunctionComponent<JobAssessmentProps> = (props: JobAssessmentProps) => {
  const rejectClickedHandler = () => {
    props.reject(props.job.id);
  };

  const acceptClickedHandler = () => {
    props.accept(props.job.id);
  };

  return (
    <div>
      <Tooltip title="Reject" placement="bottom">
        <IconButton onClick={rejectClickedHandler} size="small">
          <ThumbDownIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Accept" placement="bottom">
        <IconButton onClick={acceptClickedHandler} size="small">
          <ThumbUpIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

interface Props {
  job: PracticeJob;
}

interface Dispatches {
  accept: (id: ID) => void;
  reject: (id: ID) => void;
}

type JobAssessmentProps = Props & Dispatches;

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  accept: (id: ID) => dispatch(practiceJobsActions.acceptJob(id)),
  reject: (id: ID) => dispatch(practiceJobsActions.rejectJob(id))
});

export default connect(null, mapDispatchToProps)(PracticeJobAssessmentBlock);

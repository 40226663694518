import React from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { scheduleActions } from '../view/actions';

const ProofreaderFilter = (props: Props & Dispatches) => (
  <Input
    startAdornment={
      <InputAdornment position="start">
        <FilterList />
      </InputAdornment>
    }
    autoFocus={true}
    disableUnderline={true}
    value={props.value}
    onChange={e => props.onChange(e.target.value)}
    placeholder="Proofreader Filter"
  />
);

interface Props {
  value: string;
}

interface Dispatches {
  onChange: (value: string) => void;
}

export default connect(
  ({ schedule: { view } }: StoreInterface): Props => ({
    value: view.proofreaderFilter
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    onChange: value => dispatch(scheduleActions.setProofreaderFilter(value))
  })
)(ProofreaderFilter);

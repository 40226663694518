import { ProofreaderDetails } from '../../apis/proofreaders.api';
import { QueueStatus } from '../../apis/status.interface';
import { JobType } from '../../model/job/jobType';
import { VoteJobType } from '../../model/voting/voteJobType';

export interface OngointTaskStatus {
  name: string;
  clarity: boolean;
  clarityPlus: boolean;
  paraClarity: boolean;
  realtime: boolean;
  snippetId: number | null;
  jobId: number | null;
  voteId: number | null;
  jobType: JobType | null;
  voteJobType: VoteJobType | null;
  snippetNumber: number | null;
  accepted: number | null;
  due: number | null;
}

export interface OnshiftProofreadersStore {
  onshiftProofreaders: ProofreaderDetails[];
  scheduledProofreaders: ProofreaderDetails[];
  queuesStatus: QueueStatus[];
  tasksStatus: OngointTaskStatus[];
  loading: boolean;
}

export const INIT_ONSHIFT_PROOFREADERS_STORE: OnshiftProofreadersStore = {
  loading: false,
  onshiftProofreaders: [],
  scheduledProofreaders: [],
  queuesStatus: [],
  tasksStatus: []
};

import { MiddlewareType } from '../../redux/middleware/middleware';

import { AppActionType, AppActions, hideSpinner, showSpinner } from './app.actions';

let timer: any;

// Initialize
export const appInitialize: MiddlewareType<AppActions> = ({ dispatch }) => next => action => {
  next(action);
  if (action.type !== AppActionType.Initialize) {
    return;
  }

  dispatch(showSpinner('app init'));
  timer = setTimeout(() => {
    dispatch(hideSpinner('app init'));
  }, 1000);
};

// Terminate
export const appTerminate: MiddlewareType<AppActions> = ({ dispatch }) => next => action => {
  next(action);
  if (action.type !== AppActionType.Terminate) {
    return;
  }

  if (timer) {
    clearTimeout(timer);
    timer = null;
  }

  dispatch(hideSpinner('app terminate'));
};

export const appMiddleware = [appInitialize, appTerminate];

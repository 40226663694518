import { AllVotingProgressCounts as Api, VotingProgressCounts } from '../progress.interface.api';
import { AllCounts, Counts, initAllCounts, initCounts } from '../progress.store';
import { str2enumStrict } from '../../../../utils/enums/str2enum';
import { VoteJobType } from '../../../../model/voting/voteJobType';

const convertCountsByStatus = (api: VotingProgressCounts): Counts =>
  Object.keys(api).reduce((acc, key) => {
    // Skipping the unknown properties
    if (Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key as keyof typeof acc] = api[key] || 0;
    }
    return acc;
  }, initCounts());

export default (api: Api): AllCounts =>
  Object.keys(api.counts).reduce((acc, key) => {
    const voteJobType = str2enumStrict(VoteJobType, key, 'vote job type');

    acc[voteJobType] = convertCountsByStatus(api.counts[key]);

    return acc;
  }, initAllCounts());

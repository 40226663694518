import { call, put } from '@redux-saga/core/effects';

import fetch from '../../../../utils/fetch';
import { ErrorAction } from '../../../../redux/actions/actions';

// TODO: Consider moving this saga to some shared space

export function* post(url: string, params: any, onFailureAction: (msg: string) => ErrorAction) {
  try {
    const paramsString = Object.keys(params)
      .map(key => `${encodeURI(key)}=${encodeURI(params[key])}`)
      .join('&');

    const config: RequestInit = { method: 'POST', body: paramsString };

    return yield call(fetch.request.bind(fetch), url, config, 'application/x-www-form-urlencoded');
  } catch (error) {
    yield put(onFailureAction(error.message));
  }
}

export function* get(url: string, onFailureAction: (msg: string) => ErrorAction) {
  try {
    return yield call(fetch.request.bind(fetch), url);
  } catch (error) {
    yield put(onFailureAction(error.message));
  }
}

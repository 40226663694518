import { ID } from '../../utils/types';

export interface ProofitLog {
  id: ID;

  jobId: ID;
  taskType: string;
  taskId: ID;

  assigneeId: ID;
  assigneeEmail: string;
  assigneeName?: string;

  when: Date | null;
  category: string;
  message: string;
}

export interface Filtering {
  jobId?: ID;
  assigneeId?: ID;
  taskId?: ID;
}

export interface ProofitLogStore {
  logs: ProofitLog[];
  loading: boolean;
  filtering?: Filtering;
}

export const INIT_PROOFIT_LOG_STORE = () => ({
  logs: [],
  loading: false
});

import React, { FunctionComponent, useState } from 'react';
import { Box, Grid, IconButton, lighten, Theme } from '@material-ui/core';
import { Clear, Done, Pause, PlayArrow } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';

import { Corpus, CorpusStatus } from '../corpora.interface';
import { roundPercentToBase } from '../../../utils/math';
import { StaticBufferedLinearProgress } from '../../../components/StaticBufferedLinearProgress/StaticBufferedLinearProgress';
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      cursor: 'pointer'
    },
    doneIcon: {
      color: theme.palette.success.main
    },
    removedIcon: {
      color: lighten(theme.palette.error.light, 0.1)
    }
  })
);

export const CorpusProgress: FunctionComponent<CorpusProgressProps> = (props: CorpusProgressProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const [pauseDialogOpen, setPauseDialogOpen] = useState<boolean>(false);
  const [pauseTooltipOpen, setPauseTooltipOpen] = useState<boolean>(false);

  const pauseButtonClickedHandler = () => {
    setPauseTooltipOpen(false);
    setPauseDialogOpen(true);
  };

  const resumeHandler = () => props.resumeHandler(props.corpus);
  const pauseHandler = () => props.pauseHandler(props.corpus);

  const totalFiles = props.corpus.rawCount + props.corpus.processedCount;
  const completedPercent = totalFiles == 0 ? 0 : props.corpus.jobsCompleted / (totalFiles * props.corpus.redundancy);
  const cancelledPercent = totalFiles == 0 ? 0 : props.corpus.jobsCancelled / (totalFiles * props.corpus.redundancy);
  const uploadedAsFilesPercent = totalFiles == 0 ? 0 : props.corpus.processedCount / totalFiles;
  const roundCompletedPercent = roundPercentToBase(completedPercent, 10);
  const roundCancelledPercent = roundPercentToBase(cancelledPercent, 10);
  const roundUploadedAsFilesPercent = roundPercentToBase(uploadedAsFilesPercent, 10);
  const progressBarTooltip =
    props.corpus.status === CorpusStatus.COMPLETED
      ? 'Files: uploaded, jobs: finished'
      : props.corpus.status === CorpusStatus.REMOVED
      ? 'Cancelled'
      : props.corpus.status === CorpusStatus.UPLOAD_DONE
      ? 'Files: uploaded, jobs: in progress'
      : props.corpus.status === CorpusStatus.PAUSED
      ? 'Files: on hold, jobs: on hold'
      : props.corpus.status === CorpusStatus.UPLOAD_GOING
      ? 'Files: in progress, jobs: in progress'
      : 'Completed / uploaded progress';
  const progressBaseColor =
    props.corpus.status === CorpusStatus.COMPLETED
      ? theme.palette.success.main
      : props.corpus.status === CorpusStatus.REMOVED
      ? lighten(theme.palette.error.light, 0.1)
      : props.corpus.status === CorpusStatus.PAUSED
      ? theme.palette.grey[800]
      : theme.palette.primary.main;
  const incompleteJobsNumber =
    totalFiles * props.corpus.redundancy - (props.corpus.jobsCompleted + props.corpus.jobsCancelled);

  const pauseConfirmQuestion = (
    <React.Fragment>
      <Typography variant="h6">
        <span>
          Sure to pause the <b>{props.corpus.originalName}</b> corpus?
        </span>
      </Typography>
      <Typography variant="body1">
        {props.lastOfType && (
          <span>This is the last corpus of the type, the {props.corpus.type} queue will be empty.</span>
        )}
        <br />
        <span>It has {incompleteJobsNumber} incomplete jobs left, they will not be proofread until resumed.</span>
      </Typography>
    </React.Fragment>
  );

  const stateIndicator =
    props.corpus.status === CorpusStatus.COMPLETED ? (
      <Tooltip key="done-tooltip" title="Corpus is completed" placement="bottom">
        <Grid item>
          <IconButton size="small" disabled>
            <Done className={classes.doneIcon} />
          </IconButton>
        </Grid>
      </Tooltip>
    ) : props.corpus.status === CorpusStatus.REMOVED ? (
      <Tooltip key="removed-tooltip" title="Corpus is removed" placement="bottom">
        <Grid item>
          <IconButton size="small" disabled>
            <Clear className={classes.removedIcon} />
          </IconButton>
        </Grid>
      </Tooltip>
    ) : props.corpus.status === CorpusStatus.PAUSED ? (
      <Tooltip key="resume-tooltip" title="Resume work on corpus" placement="bottom">
        <Grid item>
          <IconButton size="small" disabled={props.modifying} onClick={resumeHandler}>
            <PlayArrow />
          </IconButton>
        </Grid>
      </Tooltip>
    ) : (
      <Tooltip key="pause-tooltip" title="Pause work on corpus" placement="bottom" open={pauseTooltipOpen}>
        <Grid item>
          <IconButton
            size="small"
            color="primary"
            disabled={props.modifying}
            onMouseEnter={() => setPauseTooltipOpen(true)}
            onMouseLeave={() => setPauseTooltipOpen(false)}
            onClick={pauseButtonClickedHandler}
          >
            <Pause />
          </IconButton>

          <ConfirmDialog
            open={pauseDialogOpen}
            questionElement={pauseConfirmQuestion}
            confirmLabel={'Pause it'}
            confirmActionHandler={pauseHandler}
            closeHandler={() => setPauseDialogOpen(false)}
          />
        </Grid>
      </Tooltip>
    );

  const percentageTooltip = (
    <React.Fragment>
      <span>{`Jobs completed: ${roundCompletedPercent}%`}</span>
      <br />
      <span>{`Jobs cancelled: ${roundCancelledPercent}%`}</span>
      <br />
      <span>{`Files uploaded: ${roundUploadedAsFilesPercent}%`}</span>
    </React.Fragment>
  );

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" justify="flex-end" spacing={1}>
      {stateIndicator}
      <Grid item container direction="column" wrap="nowrap" className={classes.grid}>
        <Grid item>
          <Tooltip title={progressBarTooltip} placement="bottom">
            <StaticBufferedLinearProgress
              baseColor={progressBaseColor}
              value={roundCompletedPercent}
              valueBuffer={roundUploadedAsFilesPercent}
            />
          </Tooltip>
        </Grid>
        <Grid item container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid item>
              <Tooltip title={percentageTooltip}>
                <Box fontWeight="bold" minWidth="40px" color={progressBaseColor}>{`${roundPercentToBase(
                  completedPercent,
                  1
                )}%`}</Box>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title="Completed / Total (Cancelled)" placement="bottom">
              <span>
                <span>{`${props.corpus.jobsCompleted} / ${totalFiles * props.corpus.redundancy} `}</span>
                <Box component="span" color="secondary.main">{`(${props.corpus.jobsCancelled})`}</Box>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface Props {
  modifying: boolean;
  corpus: Corpus;
  lastOfType: boolean;
}

interface Dispatches {
  pauseHandler: (corpus: Corpus) => void;
  resumeHandler: (corpus: Corpus) => void;
}

type CorpusProgressProps = Props & Dispatches;

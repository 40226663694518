import { Reducer } from 'redux';

import { ProofreaderDetails } from '../../apis/proofreaders.api';

import { INIT_PROOFREADER_LIST_STORE, ProofreadersListStore } from './proofreader-list.store';
import { ProofreaderListActions, ProofreadersListActionType } from './proofreader-list.actions';

function comparator() {
  return (a: ProofreaderDetails, b: ProofreaderDetails) => {
    if (a.lastSuccessfulLogin === null && b.lastSuccessfulLogin === null) {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    }

    if (a.lastSuccessfulLogin === null) return 1;
    if (b.lastSuccessfulLogin === null) return -1;

    return a.lastSuccessfulLogin > b.lastSuccessfulLogin ? -1 : a.lastSuccessfulLogin < b.lastSuccessfulLogin ? 1 : 0;
  };
}

export const proofreaderListReducer: Reducer<ProofreadersListStore, ProofreaderListActions> = (
  state = INIT_PROOFREADER_LIST_STORE,
  action
) => {
  switch (action.type) {
    case ProofreadersListActionType.StartLoading:
      return { ...state, proofreaders: [], loading: true };

    case ProofreadersListActionType.LoadingSuccess:
      return { ...state, proofreaders: action.payload.sort(comparator()), loading: false };

    case ProofreadersListActionType.LoadingFailure:
      return { ...state, loading: false };

    case ProofreadersListActionType.UpdateProofreader:
      return { ...state, loading: true };

    case ProofreadersListActionType.CreateProofreader:
      return { ...state, loading: true };

    case ProofreadersListActionType.UpdateProofreaderFailure:
      return { ...state, loading: false };

    case ProofreadersListActionType.CreateProofreaderFailure:
      return { ...state, loading: false };

    case ProofreadersListActionType.WelcomeEmail: {
      return { ...state, loading: true };
    }

    case ProofreadersListActionType.WelcomeEmailSuccess: {
      let newProofreaders = state.proofreaders.map(item => {
        if (item.id === action.payload) {
          return { ...item, welcomeEmailSent: true };
        }
        return item;
      });
      return { ...state, proofreaders: newProofreaders, loading: false };
    }

    case ProofreadersListActionType.WelcomeEmailFailure:
      return { ...state, loading: false };

    case ProofreadersListActionType.UpdateProofreaderSuccess: {
      let newProofreaders = state.proofreaders
        .map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        })
        .sort(comparator());
      return { ...state, proofreaders: newProofreaders, loading: false };
    }

    case ProofreadersListActionType.CreateProofreaderSuccess:
      return { ...state, proofreaders: [...state.proofreaders, action.payload].sort(comparator()), loading: false };

    default:
      return state;
  }
};

import { call, put } from 'redux-saga/effects';

import { parse, ProofreaderRaw } from '../../apis/proofreaders.api';
import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { AppsActions } from '../actions/actions';

const defaultOnErrorAction = () => null;

export function* getProofreaders(
  params: Record<string, string> = {},
  onErrorAction: (e: Error) => AppsActions | null = defaultOnErrorAction
) {
  try {
    const queryString = new URLSearchParams(params).toString();

    const response: ProofreaderRaw[] = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.proofreaders.root}?${queryString}`
    );

    return response.map(parse);
  } catch (error) {
    const action = onErrorAction(error);

    if (action) {
      yield put(action);
    }
  }
}

import { Action } from 'redux';

import { ErrorAction, SuccessAction } from '../../../redux/actions/actions';
import { ID } from '../../../utils/types';
import { ProofreaderDetails } from '../../../apis/proofreaders.api';

import { Row } from './results.store';

export enum ActionType {
  /* Loading the page */
  StartLoading = 'peer-votes/results/start-loading',
  StopLoading = 'peer-votes/results/stop-loading',
  LoadingSuccess = 'peer-votes/results/loading-success',
  LoadingFailure = 'peer-votes/results/loading-failure',

  /* Change time frame */
  ApplyFilter = 'peer-votes/results/apply-filter',

  /* Grade proofreader */
  SetProofreaderToGrade = 'peer-votes/results/grade-proofreader/set',
  UnsetProofreaderToGrade = 'peer-votes/results/grade-proofreader/unset',
  GradeProofreaderStart = 'peer-votes/results/grade-proofreader/start',
  GradeProofreaderSuccess = 'peer-votes/results/grade-proofreader/success',
  GradeProofreaderFailure = 'peer-votes/results/grade-proofreader/failure'
}

export interface StartLoading extends Action {
  type: ActionType.StartLoading;
  payload: {
    from: Date;
    to: Date;
  };
}

interface StopLoading extends Action {
  type: ActionType.StopLoading;
}

export interface LoadingSuccess extends Action {
  type: ActionType.LoadingSuccess;
  payload: {
    rows: Row[];
  };
}

interface LoadingFailure extends ErrorAction {
  type: ActionType.LoadingFailure;
}

export interface ApplyFilter extends Action {
  type: ActionType.ApplyFilter;
  payload: {
    from: Date;
    to: Date;
  };
}

interface SetProofreaderToGrade extends Action {
  type: ActionType.SetProofreaderToGrade;
  payload: {
    proofreader: ProofreaderDetails;
  };
}

interface UnsetProofreaderToGrade extends Action {
  type: ActionType.UnsetProofreaderToGrade;
}

export interface GradeProofreaderStart extends Action {
  type: ActionType.GradeProofreaderStart;
  payload: {
    proofreader: ProofreaderDetails;
    voteJobs: number;
    snippetType: string;
  };
}

interface GradeProofreaderFailure extends ErrorAction {
  type: ActionType.GradeProofreaderFailure;
}

export interface GradeProofreaderSuccess extends SuccessAction {
  type: ActionType.GradeProofreaderSuccess;
  payload: {
    proofreaderId: ID;
    voteJobs: number;
  };
}

export const resultsActions = {
  startLoading: (from: Date, to: Date): StartLoading => ({
    type: ActionType.StartLoading,
    payload: { from, to }
  }),

  stopLoading: (): StopLoading => ({
    type: ActionType.StopLoading
  }),

  loadingSuccess: (rows: Row[]): LoadingSuccess => ({
    type: ActionType.LoadingSuccess,
    payload: { rows }
  }),

  loadingFailure: (reason: String): LoadingFailure => ({
    type: ActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading grade stats: ${reason}`)
  }),

  applyFilter: (from: Date, to: Date): ApplyFilter => ({
    type: ActionType.ApplyFilter,
    payload: { from, to }
  }),

  setProofreaderToGrade: (proofreader: ProofreaderDetails): SetProofreaderToGrade => ({
    type: ActionType.SetProofreaderToGrade,
    payload: { proofreader }
  }),

  unsetProofreaderToGrade: (): UnsetProofreaderToGrade => ({
    type: ActionType.UnsetProofreaderToGrade
  }),

  gradeProofreader: (
    proofreader: ProofreaderDetails,
    voteJobs: number,
    snippetType: string
  ): GradeProofreaderStart => ({
    type: ActionType.GradeProofreaderStart,
    payload: { proofreader, voteJobs, snippetType }
  }),

  gradeProofreaderSuccess: (proofreader: ProofreaderDetails, voteJobs: number): GradeProofreaderSuccess => ({
    type: ActionType.GradeProofreaderSuccess,
    payload: { proofreaderId: proofreader.id, voteJobs },
    success: true,
    message: `Successfully created ${voteJobs} vote jobs for ${proofreader.name}`
  }),

  gradeProofreaderFailure: (reason: String): GradeProofreaderFailure => ({
    type: ActionType.GradeProofreaderFailure,
    error: true,
    payload: new Error(`Failed to create grading jobs: ${reason}`)
  })
};

export type Actions =
  | StartLoading
  | StopLoading
  | LoadingSuccess
  | LoadingFailure
  | ApplyFilter
  | SetProofreaderToGrade
  | UnsetProofreaderToGrade
  | GradeProofreaderStart
  | GradeProofreaderSuccess
  | GradeProofreaderFailure;

import React from 'react';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import InboxIcon from '@material-ui/icons/Inbox';
import MailIcon from '@material-ui/icons/Mail';
import SubjectIcon from '@material-ui/icons/Subject';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { ProofreadersFiltering } from '../proofreader-list.interface';
import { Column } from '../../../components/TableWithHeader/TableWithHeader';
import { FilteringText } from '../../../components/FilteringText/FilteringText';
import { FilteringSelect, FilteringSelectValue } from '../../../components/FilteringSelect/FilteringSelect';
import { SelectInput } from '../../../components/SelectInput/SelectInput';
import TextInput from '../../../components/TextInput/TextInput';
import BooleanInput from '../../../components/BooleanInput/BooleanInput';
import { idfy } from '../../../utils/types';
import { Permission } from '../../../apis/permissions.interface';
import { hasPermission } from '../utils';
import { getLabel } from '../../../modules/permissions/model';
import { OnboardingStatus, ProofreaderDetails, ProofreaderStatus } from '../../../apis/proofreaders.api';

export const getProofreadersColumns: (
  self: {
    filtering: (key: keyof ProofreadersFiltering, value: string | boolean) => void;
    permissionFiltering: (key: string, value?: boolean) => void;
    selectRow: (proofreader: ProofreaderDetails) => void;
    selected: () => string;
    unselectRow: () => void;
    updateProofreader: (key: string, value: any) => void;
    updatePermission: (key: string, value: boolean) => void;
    update: () => void;
    create: () => void;
    createMode: (mode: boolean) => void;
    inCreateMode: () => boolean;
    sendWelcomeEmail: (id: number) => void;
  },
  loading: boolean,
  permissions: Permission[]
) => Column<ProofreaderDetails>[] = (self, loading, permissions) => {
  const permissionsColumns = permissions.map(
    (p): Column<ProofreaderDetails> => ({
      label: getLabel(p.type),
      align: 'center',
      minWidth: 130,
      format: (proofreader: ProofreaderDetails) => (
        <span>{hasPermission(proofreader, p.type) && <CheckIcon fontSize="inherit" />}</span>
      ),
      editableFormat: (proofreader: ProofreaderDetails) => (
        <BooleanInput
          initial={hasPermission(proofreader, p.type)}
          onChange={value => self.updatePermission(p.type, value)}
        />
      ),
      createFormat: () => <BooleanInput initial={false} onChange={value => self.updatePermission(p.type, value)} />,
      filter: () => {
        const data: FilteringSelectValue[] = [
          { name: 'All', value: 'all' },
          { name: 'Yes', value: 'true' },
          { name: 'No', value: 'false' }
        ];

        return (
          <FilteringSelect
            label={getLabel(p.type)}
            submit={value => self.permissionFiltering(p.type, value === 'all' ? undefined : value === 'true')}
            data={data}
          />
        );
      }
    })
  );

  return [
    {
      id: 'id',
      label: 'ID',
      align: 'left',
      maxWidth: 70,
      format: (proofreader: ProofreaderDetails) => (
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <Tooltip title="Jobs in which proofiteer have participated" arrow>
              <IconButton component={Link} size="small" to={`/jobs?assigneeId=${proofreader.id}`}>
                <InboxIcon display="block" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Proofreader activity log" arrow>
              <IconButton component={Link} size="small" to={`/logs?assigneeId=${proofreader.id}`}>
                <SubjectIcon display="block" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>{proofreader.id}</Grid>
        </Grid>
      ),
      filter: () => <FilteringText label="ID" submit={value => self.filtering('id', idfy(value))} />
    },
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 100,
      format: (proofreader: ProofreaderDetails) => <div>{proofreader.name}</div>,
      editableFormat: (proofreader: ProofreaderDetails) => (
        <TextInput
          label="Name"
          initial={proofreader.name}
          onChange={value => self.updateProofreader('name', value)}
          error={value => value === ''}
        />
      ),
      createFormat: () => (
        <TextInput
          label="Name"
          required={true}
          onChange={value => self.updateProofreader('name', value)}
          error={value => value === ''}
        />
      ),
      filter: () => <FilteringText label="Name" submit={value => self.filtering('name', value)} />
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left',
      minWidth: 100,
      format: (proofreader: ProofreaderDetails) => <div>{proofreader.email}</div>,
      editableFormat: (proofreader: ProofreaderDetails) => (
        <TextInput
          label="Email"
          initial={proofreader.email}
          onChange={value => self.updateProofreader('email', value)}
          error={value => value === ''}
        />
      ),
      createFormat: () => (
        <TextInput
          label="Email"
          required={true}
          onChange={value => self.updateProofreader('email', value)}
          error={value => value === ''}
        />
      ),
      filter: () => <FilteringText label="Email" submit={value => self.filtering('email', value)} />
    },
    {
      id: 'onboardingStatus',
      label: 'Onboarding Status',
      align: 'left',
      minWidth: 130,
      format: (proofreader: ProofreaderDetails) => <div>{proofreader.onboardingStatus}</div>,
      editableFormat: (proofreader: ProofreaderDetails) => {
        const data: FilteringSelectValue[] = [
          { name: 'Testing', value: OnboardingStatus.Testing },
          { name: 'Approved', value: OnboardingStatus.Approved },
          { name: 'AssignedJobs', value: OnboardingStatus.AssignedJobs },
          { name: 'TestFailed', value: OnboardingStatus.TestFailed }
        ];
        return (
          <SelectInput
            data={data}
            label="Status"
            initial={proofreader.onboardingStatus}
            onChange={value => self.updateProofreader('onboardingStatus', value)}
          />
        );
      },
      createFormat: () => {
        const data: FilteringSelectValue[] = [
          { name: 'Testing', value: OnboardingStatus.Testing },
          { name: 'Approved', value: OnboardingStatus.Approved },
          { name: 'AssignedJobs', value: OnboardingStatus.AssignedJobs },
          { name: 'TestFailed', value: OnboardingStatus.TestFailed }
        ];
        return (
          <SelectInput
            data={data}
            label="Status"
            initial="Testing"
            onChange={value => self.updateProofreader('onboardingStatus', value)}
          />
        );
      },
      filter: () => {
        const data: FilteringSelectValue[] = [
          { name: 'All', value: '' },
          { name: 'Testing', value: OnboardingStatus.Testing },
          { name: 'Approved', value: OnboardingStatus.Approved },
          { name: 'AssignedJobs', value: OnboardingStatus.AssignedJobs },
          { name: 'TestFailed', value: OnboardingStatus.TestFailed }
        ];
        return (
          <FilteringSelect label="Status" submit={value => self.filtering('onboardingStatus', value)} data={data} />
        );
      }
    },
    {
      id: 'blocked',
      label: 'Blocked',
      align: 'center',
      minWidth: 80,
      format: (proofreader: ProofreaderDetails) => (
        <span>{proofreader.blocked && <CheckIcon fontSize="inherit" />}</span>
      ),
      editableFormat: (proofreader: ProofreaderDetails) => (
        <BooleanInput initial={proofreader.blocked} onChange={value => self.updateProofreader('blocked', value)} />
      ),
      createFormat: () => <BooleanInput initial={false} onChange={value => self.updateProofreader('blocked', value)} />,
      filter: () => {
        const data: FilteringSelectValue[] = [
          { name: 'All', value: 'all' },
          { name: 'Yes', value: 'true' },
          { name: 'No', value: 'false' }
        ];
        return (
          <FilteringSelect
            label="Blocked"
            submit={value => self.filtering('blocked', value)}
            data={data}
            initial="false"
          />
        );
      }
    },
    {
      id: 'inTestGroup',
      label: 'In test group',
      align: 'center',
      minWidth: 100,
      format: (proofreader: ProofreaderDetails) => (
        <span>{proofreader.inTestGroup && <CheckIcon fontSize="inherit" />}</span>
      ),
      editableFormat: (proofreader: ProofreaderDetails) => (
        <BooleanInput
          initial={proofreader.inTestGroup}
          onChange={value => self.updateProofreader('inTestGroup', value)}
        />
      ),
      createFormat: () => (
        <BooleanInput initial={false} onChange={value => self.updateProofreader('inTestGroup', value)} />
      ),
      filter: () => {
        const data: FilteringSelectValue[] = [
          { name: 'All', value: 'all' },
          { name: 'Yes', value: 'true' },
          { name: 'No', value: 'false' }
        ];
        return (
          <FilteringSelect label="Test group" submit={value => self.filtering('inTestGroup', value)} data={data} />
        );
      }
    },
    ...permissionsColumns,
    {
      id: 'lastSuccessfulLogin',
      label: 'Last Login',
      align: 'left',
      minWidth: 120,
      format: (proofreader: ProofreaderDetails) =>
        proofreader.lastSuccessfulLogin ? moment(proofreader.lastSuccessfulLogin).format('MM/DD/YYYY HH:mm') : '-'
    },
    {
      id: 'status',
      label: 'Sign-in Status',
      align: 'center',
      minWidth: 70,
      format: (proofreader: ProofreaderDetails) => <div>{proofreader.status}</div>,
      filter: () => {
        const data: FilteringSelectValue[] = [
          { name: 'All', value: '' },
          { name: 'Onshift', value: ProofreaderStatus.Onshift },
          { name: 'Away', value: ProofreaderStatus.Away }
        ];
        return <FilteringSelect label="Sign-in" submit={value => self.filtering('status', value)} data={data} />;
      }
    },
    {
      label: '',
      align: 'left',
      minWidth: 65,
      format: (proofreader: ProofreaderDetails) => {
        function disabled() {
          return self.inCreateMode() || self.selected() != '' || loading;
        }

        if (!proofreader.welcomeEmailSent)
          return (
            <div>
              <Tooltip title="Update proofreader">
                <IconButton
                  color="primary"
                  size="small"
                  disabled={disabled()}
                  onClick={() => self.selectRow(proofreader)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send welcome email">
                <IconButton
                  color="primary"
                  size="small"
                  disabled={disabled()}
                  onClick={() => self.sendWelcomeEmail(proofreader.id)}
                >
                  <MailIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        else
          return (
            <div>
              <Tooltip title="Update proofreader">
                <IconButton
                  color="primary"
                  size="small"
                  disabled={disabled()}
                  onClick={() => self.selectRow(proofreader)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
      },
      editableFormat: () => (
        <div>
          <IconButton color="primary" size="small" disabled={loading} onClick={() => self.update()}>
            <CheckIcon />
          </IconButton>
          <IconButton color="primary" size="small" disabled={loading} onClick={() => self.unselectRow()}>
            <CancelIcon />
          </IconButton>
        </div>
      ),
      createFormat: () => (
        <div>
          <IconButton color="primary" size="small" disabled={loading} onClick={() => self.create()}>
            <CheckIcon />
          </IconButton>
          <IconButton color="primary" size="small" disabled={loading} onClick={() => self.createMode(false)}>
            <CancelIcon />
          </IconButton>
        </div>
      )
    }
  ];
};

import { all, call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects';

import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { hideSpinner, showSpinner } from '../App/app.actions';
import { parse, ProofreaderRaw } from '../../apis/proofreaders.api';
import { parseQueueStatus, QueueStatus } from '../../apis/status.interface';
import { Permission } from '../../apis/permissions.interface';

import {
  onshiftProofreadersActions,
  OnshiftProofreadersActionType,
  OnshiftProofreadersStartLoadingAction
} from './onshift-proofreaders.actions';
import { OngointTaskStatus } from './onshift-proofreaders.store';

export function* initialLoad() {
  try {
    const [scheduledProofreadersRaw, onshiftProofreadersRaw, permissions, queuesRaw, tasks]: [
      ProofreaderRaw[],
      ProofreaderRaw[],
      Permission[],
      QueueStatus[],
      OngointTaskStatus[]
    ] = yield all([
      call(fetch.request.bind(fetch), appConfig.api.proofreaders.scheduled),
      call(fetch.request.bind(fetch), `${appConfig.api.proofreaders.root}?status=Onshift`),
      call(fetch.request.bind(fetch), appConfig.api.permissions),
      call(fetch.request.bind(fetch), appConfig.api.status.queues),
      call(fetch.request.bind(fetch), appConfig.api.status.tasks)
    ]);

    const scheduledProofreaders = scheduledProofreadersRaw.map(parse);
    const onshiftProofreaders = onshiftProofreadersRaw.map(parse);
    const queues = queuesRaw.map(parseQueueStatus);

    yield put(
      onshiftProofreadersActions.loadingSuccess(scheduledProofreaders, onshiftProofreaders, permissions, queues, tasks)
    );
  } catch (error) {
    yield put(onshiftProofreadersActions.loadingFailure(error.message));
  }
}

export function* initialLoadAsync(action: OnshiftProofreadersStartLoadingAction) {
  try {
    yield put(showSpinner('onshift-proofreaders'));
    const task = yield fork(initialLoad);
    const actionTask = yield take([
      OnshiftProofreadersActionType.LoadingFailure,
      OnshiftProofreadersActionType.LoadingSuccess
    ]);
    if (actionTask.type === OnshiftProofreadersActionType.LoadingFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('onshift-proofreaders'));
  }
}

export function* watchOnshiftProofreadersSaga() {
  yield takeLatest(OnshiftProofreadersActionType.StartLoading, initialLoadAsync);
}

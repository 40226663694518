import React, { useContext, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Dictionary, SettingType, Weekday } from '../../settings.interface';
import PriceInput from '../inputs/PriceInput';
import { settingsActions } from '../../settings.actions';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import useAppSelector from '../../../../hooks/useAppSelector';
import { useFormSubscriber } from '../sections/FormStateContext';
import { FormModifiedContext } from '../sections/FormModifiedContext';
import { prettyPrint, TurnaroundOption } from '../../../../model/job/turnaroundOption';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    table: {
      '& td': {
        // padding: theme.spacing(1)
      }
    }
  })
);

const weekdays: Weekday[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const pricesStore2State = (store: Dictionary<Dictionary<number>>): Dictionary<Dictionary<number>> =>
  Object.keys(store).reduce((acc, obj) => {
    acc[obj] = store[obj];
    return acc;
  }, {} as Dictionary<Dictionary<number>>);

interface TurnaroundRowProps {
  option: TurnaroundOption;
  prices: Dictionary<number>;
  onChange: (option: TurnaroundOption, weekday: Weekday) => (value: number) => void;
}

const TurnaroundRow = ({ option, prices, onChange }: TurnaroundRowProps) => {
  const updating = useAppSelector(state => state.settings.price.priceUpdating);

  if (!prices) {
    return null;
  }

  return (
    <TableRow>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{prettyPrint(option)}</TableCell>
      {weekdays.map(weekday => (
        <TableCell key={weekday}>
          <PriceInput value={prices[weekday]} disabled={updating} onChange={onChange(option, weekday)} />
        </TableCell>
      ))}
    </TableRow>
  );
};

const PricesTable = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [pricesState, setPricesState] = useState(() => pricesStore2State(props.prices));

  const { setModified } = useContext(FormModifiedContext);

  const updatePriceState = (option: TurnaroundOption, weekday: Weekday) => (value: number) => {
    setPricesState({ ...pricesState, [option]: { ...pricesState[option], [weekday]: value } });
    setModified(true);
  };

  useFormSubscriber('cancel', () => setPricesState(pricesStore2State(props.prices)), [props.prices, setPricesState]);

  useFormSubscriber('submit', () => dispatch(settingsActions.pricesUpdate(pricesState, props.type)), [
    props.type,
    pricesState,
    dispatch
  ]);

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          {weekdays.map(value => (
            <TableCell key={value}>{value}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.options.map(option => (
          <TurnaroundRow key={option} option={option} prices={pricesState[option]} onChange={updatePriceState} />
        ))}
      </TableBody>
    </Table>
  );
};

interface Props {
  prices: Dictionary<Dictionary<number>>;
  options: TurnaroundOption[];
  type: SettingType;
}

export default PricesTable;

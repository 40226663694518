import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { StoreInterface } from '../../../redux/store/store';
import { PracticeJob } from '../practice.interface';

import PracticeJobsTable from './PracticeJobsTable';
import CreatePracticeJobDialog from './CreatePracticeJobDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden'
    },
    header: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    progress: {
      margin: theme.spacing(1)
    },
    tableWrapper: {
      width: '100%'
    }
  })
);

export const PracticeJobs: FunctionComponent<PracticeJobsProps> = (props: PracticeJobsProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Practice Jobs
      </Typography>
      <Divider />
      <Grid container justify="center" spacing={1}>
        {props.loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <div className={classes.tableWrapper}>
            <PracticeJobsTable />
          </div>
        )}
      </Grid>
      <CreatePracticeJobDialog />
    </Paper>
  );
};

interface Props {
  loading: boolean;
  jobs: PracticeJob[];
}

type PracticeJobsProps = Props;

const mapStateToProps = (state: StoreInterface): Props => ({
  loading: state.practice.loadingJobs,
  jobs: state.practice.jobs
});

export default connect(mapStateToProps)(PracticeJobs);

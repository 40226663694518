import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { InfoPopup } from '../../jobs-list.store';
import { AppsActions } from '../../../../../redux/actions/actions';
import { actions } from '../../jobs-list.actions';
import { isRealtimeJob } from '../../../_utils';
import { StepType } from '../../../job.interface';

import JobFlagsTable from './JobFlagsTable';
import StepSnippetsDetails from './StepSnippetsDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    title: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    paper: {
      padding: theme.spacing(2)
    }
  })
);

const JobInfoModal: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => {
  const classes = useStyles();

  const job = props.popup.job;
  const steps = job.steps.filter(step => step.type === StepType.EDIT);

  return (
    <Modal open={true} onClose={props.onClose} className={classes.modal}>
      <Paper className={classes.paper} style={{ width: 450, maxWidth: '90%', maxHeight: '90%', overflowY: 'scroll' }}>
        <Typography variant="h6">Additional job properties</Typography>
        <JobFlagsTable job={job} />
        {steps.map(step => (
          <StepSnippetsDetails key={step.serialNumber} step={step} realtime={isRealtimeJob(job)} />
        ))}
      </Paper>
    </Modal>
  );
};

interface Props {
  popup: InfoPopup;
}

interface Dispatches {
  onClose: () => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    onClose: () => dispatch(actions.hideInfoPopup())
  })
)(JobInfoModal);

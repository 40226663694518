import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';
import { ProofreaderDetails } from '../../apis/proofreaders.api';
import { QueueStatus } from '../../apis/status.interface';
import { Permission } from '../../apis/permissions.interface';

import { OngointTaskStatus } from './onshift-proofreaders.store';

export enum OnshiftProofreadersActionType {
  StartLoading = 'onshift-proofreaders/start-loading',
  LoadingSuccess = 'onshift-proofreaders/loading-success',
  LoadingFailure = 'onshift-proofreaders/loading-failure'
}

export interface OnshiftProofreadersStartLoadingAction extends Action {
  type: OnshiftProofreadersActionType.StartLoading;
}

export interface OnshiftProofreadersLoadingSuccessAction extends Action {
  type: OnshiftProofreadersActionType.LoadingSuccess;
  payload: {
    scheduledProofreaders: ProofreaderDetails[];
    onshiftProofreaders: ProofreaderDetails[];
    permissions: Permission[];
    queuesStatus: QueueStatus[];
    tasksStatus: OngointTaskStatus[];
  };
}

interface OnshiftProofreadersLoadingFailureAction extends ErrorAction {
  type: OnshiftProofreadersActionType.LoadingFailure;
}

export const onshiftProofreadersActions = {
  loadingSuccess: (
    scheduledProofreaders: ProofreaderDetails[],
    onshiftProofreaders: ProofreaderDetails[],
    permissions: Permission[],
    queuesStatus: QueueStatus[],
    tasksStatus: OngointTaskStatus[]
  ): OnshiftProofreadersLoadingSuccessAction => ({
    type: OnshiftProofreadersActionType.LoadingSuccess,
    payload: { scheduledProofreaders, onshiftProofreaders, permissions, queuesStatus, tasksStatus }
  }),

  loadingFailure: (reason: string): OnshiftProofreadersLoadingFailureAction => ({
    type: OnshiftProofreadersActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading dashboard: ${reason}`)
  }),

  startLoading: (): OnshiftProofreadersStartLoadingAction => ({
    type: OnshiftProofreadersActionType.StartLoading
  })
};

export type OnshiftProofreadersActions =
  | OnshiftProofreadersStartLoadingAction
  | OnshiftProofreadersLoadingSuccessAction
  | OnshiftProofreadersLoadingFailureAction;

import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid, IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { Pagination } from '@material-ui/lab';

import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { clarityReportActions } from '../corpora.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      height: '100%'
    },
    header: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    contentWrapper: {
      overflowX: 'scroll',
      overflowY: 'hidden',
      padding: theme.spacing(1)
    }
  })
);

const REPORTS_PER_PAGE = 10;

const ClarityReportsTable = () => {
  const dispatch = useDispatch<Dispatch<AppsActions>>();
  const loading = useSelector((state: StoreInterface) => state.corpora.clarityReportsLoading);
  const reports = useSelector((state: StoreInterface) => state.corpora.clarityReports || []);

  const [page, setPage] = useState(1);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (reports.length === 0) {
    return (
      <Grid container justify="center">
        <Typography>Nothing to display</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <Pagination
            color="primary"
            count={Math.ceil(reports.length / REPORTS_PER_PAGE)}
            onChange={(_, newPage) => setPage(newPage)}
            page={page}
            boundaryCount={1}
            siblingCount={1}
            hideNextButton={true}
            hidePrevButton={true}
          />
        </Grid>
      </Grid>

      <Table stickyHeader size="small">
        <TableBody>
          {reports.slice((page - 1) * REPORTS_PER_PAGE, page * REPORTS_PER_PAGE).map((report, i) => (
            <TableRow key={i} hover>
              <TableCell>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>{report}</Grid>
                  <Tooltip title="Download report" placement="bottom">
                    <Grid item>
                      <IconButton
                        size="small"
                        color="primary"
                        aria-label="download"
                        onClick={() => dispatch(clarityReportActions.clarityReportDownload(report))}
                      >
                        <CloudDownloadIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between" alignItems="flex-end" className={classes.header}>
        <Typography variant="h5">Clarity Reports</Typography>
      </Grid>

      <Divider />

      <div className={classes.contentWrapper}>
        <ClarityReportsTable />
      </div>
    </Paper>
  );
};

import { createBrowserHistory, History, LocationState } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { appMiddleware } from '../../pages/App/app.middleware';
import getUserInfoSaga from '../../modules/auth/getUserInfo.saga';
import logoutSaga from '../../modules/auth/logout.saga';
import { createRootReducer } from '../reducers/reducer';
import { errorsMiddleware } from '../middleware/errors.middleware';
import { watchJobsListStartSaga } from '../../pages/Jobs/JobsList';
import { telemetryMiddleware } from '../middleware/telemetry.middleware';
import watchScheduleStartSaga from '../../pages/Schedule/schedule.saga';
import watchDownloadFileStartSaga from '../saga/download-file.saga';
import watchProofitLogSaga from '../../pages/ProofitLog/proofit-log.saga';
import submitChange from '../../pages/Schedule/edit/submitChange.saga';
import {
  watchVotingProgressStartSaga,
  watchVoterProgressStartSaga,
  watchVotingResultsStartSaga
} from '../../pages/PeerVotes';
import { watchProofreadersListSaga } from '../../pages/Proofreader';
import { watchSettingsSaga } from '../../pages/Settings/settings.saga';
import { watchOnshiftProofreadersSaga } from '../../pages/Dashboard/onshift-proofreaders.saga';
import watchPracticeRootSaga from '../../pages/Practice/practice.saga';
import { successMiddleware } from '../middleware/success.middleware';
import watchUploadFileStartSaga from '../saga/upload-file.saga';
import watchCorporaRootSaga from '../../pages/Corpora/corpora.saga';
import loadPermissionsList from '../../modules/permissions/loadPermissionsList.saga';
import { watchVotingSettingsSaga } from '../../pages/Settings/VotingSettings/saga';

export const history: History<LocationState> = createBrowserHistory();
const rootReducer = createRootReducer(history);
export type StoreInterface = ReturnType<typeof rootReducer>;

export function configureStore() {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        ...appMiddleware,
        ...errorsMiddleware,
        ...successMiddleware,
        ...telemetryMiddleware
      )
    )
  );

  /* TODO: combine sagas in some master saga, not in store definition */
  sagaMiddleware.run(getUserInfoSaga);
  sagaMiddleware.run(logoutSaga);
  sagaMiddleware.run(watchVotingResultsStartSaga);
  sagaMiddleware.run(watchVoterProgressStartSaga);
  sagaMiddleware.run(watchVotingProgressStartSaga);
  sagaMiddleware.run(watchJobsListStartSaga);
  sagaMiddleware.run(watchScheduleStartSaga);
  sagaMiddleware.run(watchPracticeRootSaga);
  sagaMiddleware.run(watchCorporaRootSaga);
  sagaMiddleware.run(watchDownloadFileStartSaga);
  sagaMiddleware.run(watchUploadFileStartSaga);
  sagaMiddleware.run(watchProofreadersListSaga);
  sagaMiddleware.run(watchProofitLogSaga);
  sagaMiddleware.run(watchSettingsSaga);
  sagaMiddleware.run(watchOnshiftProofreadersSaga);
  sagaMiddleware.run(submitChange);
  sagaMiddleware.run(loadPermissionsList);
  sagaMiddleware.run(watchVotingSettingsSaga);
  return store;
}

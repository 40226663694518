import { APP_CONFIG, AppConfig } from './app.config';
import { merge } from './utils';

const PP_CONFIG = merge(APP_CONFIG, {
  mode: 'production',
  env: 'preprod',
  domain: {
    host: 'admin.preprod-proofit.grammarlyaws.com',
    localServer: 'https://admin.preprod-proofit.grammarlyaws.com'
  },
  api: {
    server: 'https://preprod-proofit.grammarlyaws.com/'
  },
  okta: {
    baseUrl: 'https://dev-53032780.okta.com/',
    clientId: '0oa5ulk8szM5CQuVI5d6',
    issuer: 'https://dev-53032780.okta.com/oauth2/aus18sezp7i74agEN5d7'
  },
  denali: {
    server: 'https://app.ppgr.io/'
  },
  grammarlyUserId: '2326656'
});

export default PP_CONFIG;

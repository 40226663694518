import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import { blue, green, grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';

import { JobDownloadInfo, JobFileType } from '../../../job.interface';
import { AppsActions } from '../../../../../redux/actions/actions';
import { downloadFileStartLoading } from '../../../../../redux/saga/download-file.actions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'block'
    },
    original: {
      color: blue[600]
    },
    annotated: {
      color: green[600]
    },
    result: {
      color: grey[900]
    }
  })
);

const titles = {
  original: 'Download original file',
  annotated: 'Download annotated file',
  result: 'Download result file'
};

const JobFileDownloadIcon: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => {
  const classes = useStyles();

  const fileExist = {
    original: props.job.hasOriginal,
    annotated: false,
    result: props.job.hasResult
  }[props.type];

  if (!fileExist) {
    return null;
  }

  return (
    <IconButton size="small" aria-label="download" onClick={() => props.onClick(props.job, props.type)}>
      <CloudDownloadIcon titleAccess={titles[props.type]} className={classNames(classes.root, classes[props.type])} />
    </IconButton>
  );
};

interface Props {
  job: JobDownloadInfo;
  type: JobFileType;
}

interface Dispatches {
  onClick: (job: JobDownloadInfo, type: JobFileType) => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    onClick: (job: JobDownloadInfo, type: JobFileType) => dispatch(downloadFileStartLoading(job.id, type))
  })
)(JobFileDownloadIcon);

import React, { useCallback, useState } from 'react';
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import { prettyPrint, VoteJobType } from '../../../../model/voting/voteJobType';
import { str2enumStrict } from '../../../../utils/enums/str2enum';

const useStyles = makeStyles(() =>
  createStyles({
    filterSelector: {
      minWidth: 180,
      maxWidth: 180,
      overflow: 'ellipsis'
    }
  })
);

const tabs = [
  { value: 'all', label: 'All' },
  ...Object.values(VoteJobType).map(voteJobType => ({
    value: voteJobType,
    label: prettyPrint(voteJobType)
  }))
];

interface Props {
  onTabChange?: (tabKey?: VoteJobType) => void;
}

export default ({ onTabChange }: Props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState<VoteJobType | undefined>(undefined);

  const handleTabChange = useCallback((e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const value = e.target.value as string;

    const voteJobType = value === 'all' ? undefined : str2enumStrict(VoteJobType, value, 'vote job type');

    setActiveTab(voteJobType);

    onTabChange && onTabChange(voteJobType);
  }, []);

  return (
    <FormControl>
      <InputLabel>Filter vote jobs</InputLabel>
      <Select defaultValue="all" onChange={handleTabChange} className={classes.filterSelector}>
        {tabs.map(tab => (
          <MenuItem key={tab.value} value={tab.value} selected={activeTab === tab.value}>
            <span>{tab.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

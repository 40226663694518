import { Action } from 'redux';

import { ErrorAction, SuccessAction } from '../../../redux/actions/actions';
import { Job, JobAction, NewJobForm } from '../job.interface';
import { ID } from '../../../utils/types';
import { JobType } from '../../../model/job/jobType';
import { TurnaroundOption } from '../../../model/job/turnaroundOption';

import { JobsFiltering } from './jobs-list.store';

export enum JobsListActionType {
  StartLoading = 'jobs-list/start-loading',
  StopLoading = 'jobs-list/stop-loading',
  LoadingSuccess = 'jobs-list/loading-success',
  LoadingFailed = 'jobs-list/loading-failed',
  Clear = 'jobs-list/clear',

  InitFiltering = 'jobs-list/init-filtering',
  Filtering = 'jobs-list/filtering',

  ShowChangeJobAlert = 'jobs-list/show-change-job-alert',
  HideChangeJobAlert = 'jobs-list/hide-change-job-alert',

  ShowInfoPopup = 'jobs-list/show-job-info-popup',
  HideInfoPopup = 'jobs-list/hide-job-info-popup',

  ToggleStepsInfo = 'jobs-list/toggle-steps-info',

  PerformJobAction = 'jobs-list/perform-job-action',
  PerformJobActionSuccess = 'jobs-list/perform-job-action-success',
  PerformJobActionFailure = 'jobs-list/perform-job-action-failure',

  ShowCreateJobPopup = 'jobs-list/show-create-job-popup',
  HideCreateJobPopup = 'jobs-list/hide-create-job-popup',
  CreateJob = 'jobs-list/create-job',
  CreateJobSuccess = 'jobs-list/create-job-success',
  CreateJobFailed = 'jobs-list/create-job-failed',

  UploadRevisedResult = 'jobs-list/upload-revised-result',
  ShowRevisedResultPopup = 'jobs-list/show-revised-result-popup',
  HideRevisedResultPopup = 'jobs-list/hide-revised-result-popup'
}

/**
 * JobsLoadingStart
 */
export interface JobsListStartLoadingAction extends Action {
  type: JobsListActionType.StartLoading;
  payload: {
    page: number;
    filtering: JobsFiltering;
  };
}

/**
 * JobsLoadingStop
 */
export interface JobsListStopLoadingAction extends Action {
  type: JobsListActionType.StopLoading;
}

/**
 * JobsLoadingSuccess
 */
export interface JobsListLoadingSuccessAction extends Action {
  type: JobsListActionType.LoadingSuccess;
  payload: {
    list: Job[];
  };
}

/**
 * JobsLoadingFailed
 */
export interface JobsListLoadingFailedAction extends ErrorAction {
  type: JobsListActionType.LoadingFailed;
}

/**
 * JobsLoadingStop
 */
export interface JobsListClearAction extends Action {
  type: JobsListActionType.Clear;
}

export interface JobsListInitFilteringAction extends Action {
  type: JobsListActionType.InitFiltering;
  payload: JobsFiltering;
}

/**
 * Toggle Filtering
 */
export interface JobsListFilteringAction extends Action {
  type: JobsListActionType.Filtering;
  payload: {
    key: keyof JobsFiltering;
    value: any;
  };
}

interface JobsListToggleStepsInfoAction extends Action {
  type: JobsListActionType.ToggleStepsInfo;
  payload: { job: Job };
}

interface JobsListShowChangeJobAlert extends Action {
  type: JobsListActionType.ShowChangeJobAlert;
  payload: {
    job: Job;
    action: JobAction;
  };
}

interface JobsListHideChangeJobAlert extends Action {
  type: JobsListActionType.HideChangeJobAlert;
}

interface JobsListShowInfoPopup extends Action {
  type: JobsListActionType.ShowInfoPopup;
  payload: {
    job: Job;
  };
}

interface JobsListHideInfoPopup extends Action {
  type: JobsListActionType.HideInfoPopup;
}

interface JobsListShowCreateJobPopup extends Action {
  type: JobsListActionType.ShowCreateJobPopup;
}

interface JobsListHideCreateJobPopup extends Action {
  type: JobsListActionType.HideCreateJobPopup;
}

export interface JobsListCreateJob extends Action {
  type: JobsListActionType.CreateJob;
  payload: NewJobForm;
}

export interface JobsListCreateJobSuccess extends SuccessAction {
  type: JobsListActionType.CreateJobSuccess;
}

export interface JobsListCreateJobFailed extends ErrorAction {
  type: JobsListActionType.CreateJobFailed;
}

export interface JobsListPerformJobAction extends Action {
  type: JobsListActionType.PerformJobAction;
  payload: {
    jobId: ID;
    actionType: JobAction;
  };
}

export interface JobsListPerformJobActionSuccess extends Action {
  type: JobsListActionType.PerformJobActionSuccess;
  payload: {
    job: Job;
  };
}

interface JobsListPerformJobActionFailure extends ErrorAction {
  type: JobsListActionType.PerformJobActionFailure;
}

interface JobsListUploadRevisedResult extends Action {
  type: JobsListActionType.UploadRevisedResult;
  payload: {
    job: Job;
    file: File;
  };
}

interface JobsListShowRevisedResultPopup extends Action {
  type: JobsListActionType.ShowRevisedResultPopup;
  payload: {
    jobId: number;
    message: string;
    error: boolean;
  };
}

interface JobsListHideRevisedResultPopup extends Action {
  type: JobsListActionType.HideRevisedResultPopup;
}

export const actions = {
  stopLoading: (): JobsListStopLoadingAction => ({
    type: JobsListActionType.StopLoading
  }),
  startLoading: (page: number, filtering: JobsFiltering): JobsListStartLoadingAction => ({
    type: JobsListActionType.StartLoading,
    payload: { page, filtering }
  }),
  loadingFailed: (reason: string): JobsListLoadingFailedAction => ({
    type: JobsListActionType.LoadingFailed,
    payload: new Error(`Failed loading Jobs: ${reason}`),
    error: true
  }),
  loadingSuccess: (jobs: Job[]): JobsListLoadingSuccessAction => ({
    type: JobsListActionType.LoadingSuccess,
    payload: { list: jobs }
  }),
  clear: (): JobsListClearAction => ({
    type: JobsListActionType.Clear
  }),
  filtering: (key: keyof JobsFiltering, value: any): JobsListFilteringAction => ({
    type: JobsListActionType.Filtering,
    payload: { key, value }
  }),
  initFiltering: (filtering: JobsFiltering): JobsListInitFilteringAction => ({
    type: JobsListActionType.InitFiltering,
    payload: filtering
  }),
  toggleStepsInfo: (job: Job): JobsListToggleStepsInfoAction => ({
    type: JobsListActionType.ToggleStepsInfo,
    payload: { job }
  }),
  showChangeJobAlert: (job: Job, action: JobAction): JobsListShowChangeJobAlert => ({
    type: JobsListActionType.ShowChangeJobAlert,
    payload: { job, action }
  }),
  hideChangeJobAlert: (): JobsListHideChangeJobAlert => ({
    type: JobsListActionType.HideChangeJobAlert
  }),
  showInfoPopup: (job: Job): JobsListShowInfoPopup => ({
    type: JobsListActionType.ShowInfoPopup,
    payload: { job }
  }),
  hideInfoPopup: (): JobsListHideInfoPopup => ({
    type: JobsListActionType.HideInfoPopup
  }),
  performJobAction: (jobId: ID, actionType: JobAction): JobsListPerformJobAction => ({
    type: JobsListActionType.PerformJobAction,
    payload: { jobId, actionType }
  }),
  performJobActionSuccess: (job: Job): JobsListPerformJobActionSuccess => ({
    type: JobsListActionType.PerformJobActionSuccess,
    payload: { job }
  }),
  performJobActionFailure: (reason: string): JobsListPerformJobActionFailure => ({
    type: JobsListActionType.PerformJobActionFailure,
    payload: new Error(`Failed loading Jobs: ${reason}`),
    error: true
  }),
  showCreateJobPopup: (): JobsListShowCreateJobPopup => ({
    type: JobsListActionType.ShowCreateJobPopup
  }),
  hideCreateJobPopup: (): JobsListHideCreateJobPopup => ({
    type: JobsListActionType.HideCreateJobPopup
  }),
  createJob: (
    jobType: JobType,
    turnaround: TurnaroundOption,
    userId: string,
    proofreaderId: string | null,
    file: File | null
  ): JobsListCreateJob => ({
    type: JobsListActionType.CreateJob,
    payload: {
      jobType,
      turnaround,
      userId,
      proofreaderId,
      file
    }
  }),
  createJobSuccess: (job: Job): JobsListCreateJobSuccess => ({
    type: JobsListActionType.CreateJobSuccess,
    message: `Job ${job.id} successfully created`,
    success: true
  }),
  createJobFailure: (reason: string): JobsListCreateJobFailed => ({
    type: JobsListActionType.CreateJobFailed,
    payload: new Error(`Failed to create job: ${reason}`),
    error: true
  }),
  showRevisedResultPopup: (jobId: number, message: string, error: boolean): JobsListShowRevisedResultPopup => ({
    type: JobsListActionType.ShowRevisedResultPopup,
    payload: { jobId, message, error }
  }),
  hideRevisedResultPopup: (): JobsListHideRevisedResultPopup => ({
    type: JobsListActionType.HideRevisedResultPopup
  })
};

/**
 * All App Actions
 */
export type JobsListActions =
  | JobsListStartLoadingAction
  | JobsListStopLoadingAction
  | JobsListLoadingSuccessAction
  | JobsListLoadingFailedAction
  | JobsListClearAction
  | JobsListFilteringAction
  | JobsListShowChangeJobAlert
  | JobsListHideChangeJobAlert
  | JobsListShowInfoPopup
  | JobsListHideInfoPopup
  | JobsListToggleStepsInfoAction
  | JobsListInitFilteringAction
  | JobsListPerformJobAction
  | JobsListPerformJobActionSuccess
  | JobsListPerformJobActionFailure
  | JobsListShowCreateJobPopup
  | JobsListHideCreateJobPopup
  | JobsListCreateJob
  | JobsListCreateJobSuccess
  | JobsListCreateJobFailed
  | JobsListUploadRevisedResult
  | JobsListShowRevisedResultPopup
  | JobsListHideRevisedResultPopup;

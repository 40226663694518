import React, { useCallback, useContext, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';

import { Dictionary, SettingType, Turnaround } from '../../settings.interface';
import { settingsActions } from '../../settings.actions';
import AvailabilityInput from '../inputs/AvailabilityInput';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import useAppSelector from '../../../../hooks/useAppSelector';
import { useFormSubscriber } from '../sections/FormStateContext';
import { FormModifiedContext } from '../sections/FormModifiedContext';
import { prettyPrint, TurnaroundOption } from '../../../../model/job/turnaroundOption';

interface TurnaroundRowProps {
  value: boolean;
  option: TurnaroundOption;
  turnaround: Turnaround;
  onChange: (option: TurnaroundOption, val: boolean) => void;
}

const TurnaroundRow = ({ value, option, turnaround, onChange }: TurnaroundRowProps) => {
  const updating = useAppSelector(state => state.settings.price.optionsUpdating);

  if (!turnaround) {
    return null;
  }

  const title = prettyPrint(option) + (turnaround.extended ? ' (extended)' : '');

  return (
    <TableRow>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{title}</TableCell>
      <TableCell align="center">
        <AvailabilityInput value={value} option={option} onChange={onChange} disabled={updating} />
      </TableCell>
      <TableCell align="center">{!turnaround.available && <CheckIcon fontSize="inherit" />}</TableCell>
      <TableCell align="center">{turnaround.canBeExtended && <CheckIcon fontSize="inherit" />}</TableCell>
      <TableCell align="center">
        {turnaround.usedPercentage}% ({turnaround.usedSnippetsCount}/{turnaround.snippetsLimit})
      </TableCell>
      <TableCell align="center">{turnaround.lastPeriodSnippetsCount}</TableCell>
    </TableRow>
  );
};

const toPriceOptionState = (from: Dictionary<Turnaround>) =>
  Object.keys(from).reduce((acc, obj) => {
    acc[obj] = from[obj].enabled;
    return acc;
  }, {} as Dictionary<boolean>);

const useStyles = makeStyles(theme =>
  createStyles({
    table: {
      '& td': {
        padding: theme.spacing(1)
      }
    }
  })
);

const AvailableOptionsTable = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [priceOptionsState, setPriceOptionsState] = useState(() => toPriceOptionState(props.priceOptions));

  const { setModified } = useContext(FormModifiedContext);

  const updateAvailability = useCallback(
    (option: TurnaroundOption, value: boolean) => {
      setPriceOptionsState(prevState => ({ ...prevState, [option]: value }));
      setModified(true);
    },
    [setPriceOptionsState]
  );

  useFormSubscriber('cancel', () => setPriceOptionsState(toPriceOptionState(props.priceOptions)), [
    props.priceOptions,
    setPriceOptionsState
  ]);

  useFormSubscriber('submit', () => dispatch(settingsActions.availabilityUpdate(priceOptionsState, props.type)), [
    props.type,
    priceOptionsState,
    dispatch
  ]);

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align="center">Enabled</TableCell>
          <TableCell align="center">Unavailable</TableCell>
          <TableCell align="center">Extendable</TableCell>
          <TableCell align="center">Usage</TableCell>
          <TableCell align="center">Snippets in last period</TableCell>
        </TableRow>
      </TableHead>
      {props.options.length && (
        <TableBody>
          {props.options.map((option: TurnaroundOption) => (
            <TurnaroundRow
              key={option}
              option={option}
              value={priceOptionsState[option]}
              turnaround={props.priceOptions[option]}
              onChange={updateAvailability}
            />
          ))}
        </TableBody>
      )}
    </Table>
  );
};

interface Props {
  priceOptions: Dictionary<Turnaround>;
  type: SettingType;
  options: TurnaroundOption[];
}

export default AvailableOptionsTable;

import { Reducer } from 'redux';

import { INIT_SETTINGS_STORE, SettingsStore } from './settings.store';
import { SettingsActions, SettingsActionType } from './settings.actions';
import { SettingType } from './settings.interface';

export const settingsReducer: Reducer<SettingsStore, SettingsActions> = (state = INIT_SETTINGS_STORE, action) => {
  switch (action.type) {
    case SettingsActionType.StartLoading:
      return { ...state, loading: true };

    case SettingsActionType.LoadingSuccess:
      return { ...state, settings: action.payload, loading: false };

    case SettingsActionType.LoadingFailure:
      return { ...state, loading: false };

    case SettingsActionType.UpdateFailure:
      return { ...state, updating: false };

    case SettingsActionType.GeneralSettingsUpdate:
      return { ...state, generalUpdating: true };

    case SettingsActionType.GeneralSettingsUpdateSuccess:
      return { ...state, settings: { ...state.settings, maxCapacity: action.payload }, generalUpdating: false };

    case SettingsActionType.AvailabilityUpdate:
      return { ...state, optionsUpdating: true };

    case SettingsActionType.AvailabilityUpdateSuccess: {
      if (action.payload.type === SettingType.GEC) {
        return {
          ...state,
          settings: { ...state.settings, gecOptions: action.payload.options },
          optionsUpdating: false
        };
      } else {
        return {
          ...state,
          settings: { ...state.settings, clarityOptions: action.payload.options },
          optionsUpdating: false
        };
      }
    }

    case SettingsActionType.PricesUpdate:
      return { ...state, priceUpdating: true };

    case SettingsActionType.PricesUpdateSuccess: {
      if (action.payload.type === SettingType.GEC) {
        return { ...state, settings: { ...state.settings, basicPrices: action.payload.prices }, priceUpdating: false };
      } else {
        return {
          ...state,
          settings: { ...state.settings, basicClarityPrices: action.payload.prices },
          priceUpdating: false
        };
      }
    }

    case SettingsActionType.MaxPricesUpdate:
      return { ...state, maxPriceUpdating: true };

    case SettingsActionType.MaxPricesUpdateSuccess:
      return { ...state, settings: { ...state.settings, maxPrices: action.payload }, maxPriceUpdating: false };

    default:
      return state;
  }
};

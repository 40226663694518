import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

import { AppsActions } from '../../../redux/actions/actions';
import { StoreInterface } from '../../../redux/store/store';
import PaddedBox from '../../../components/layout/PaddedBox';
import { onshiftProofreadersActions } from '../onshift-proofreaders.actions';

import OnshiftProofreadersTable from './OnshiftProofreadersTable';
import SnippetsQueuesTable from './SnippetsQueuesTable';
import ScheduledProofreadersTable from './ScheduledProofreadersTable';

class OnshiftProofreadersPage extends React.Component<Props & Dispatches> {
  componentDidMount() {
    this.props.load();
  }

  render() {
    return (
      <PaddedBox>
        {this.props.loading ? (
          <PaddedBox centered>
            <CircularProgress className="Progress" />
          </PaddedBox>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <ScheduledProofreadersTable />
            </Grid>
            <Grid item xs={7}>
              <SnippetsQueuesTable />
            </Grid>
            <Grid item xs={12}>
              <OnshiftProofreadersTable />
            </Grid>
          </Grid>
        )}
      </PaddedBox>
    );
  }
}

interface Props {
  loading: boolean;
}

interface Dispatches {
  load: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  load: () => {
    dispatch(onshiftProofreadersActions.startLoading());
  }
});

const mapStateToProps = (state: StoreInterface): Props => ({
  loading: state.onshiftProofreaders.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(OnshiftProofreadersPage);

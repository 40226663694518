import { call, fork, join, put, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import moment from 'moment';

import appConfig from '../../../config';
import fetch from '../../../utils/fetch';
import { hideSpinner, showSpinner } from '../../App/app.actions';

import { actions, ActionType, StartLoading } from './actions';

function* request(dateParams: string) {
  const url = appConfig.api.report.voterStats + '?' + dateParams;
  return yield call(fetch.request.bind(fetch), url);
}

function* loadVoterResults(action: StartLoading) {
  yield put(showSpinner('load-voters'));

  try {
    const fromParam = moment.utc(action.payload.from).format('YYYY-MM-DD');
    const toParam = moment.utc(action.payload.to).format('YYYY-MM-DD');
    const dateParams = `from=${fromParam}&to=${toParam}`;

    yield put(push(`?${dateParams}`));

    const task = yield fork(request, dateParams);
    const voterResults = yield join(task);

    yield put(actions.loadingSuccess(voterResults));
  } catch (error) {
    yield put(actions.loadingFailure(error.message));
  } finally {
    yield put(hideSpinner('load-voters'));
  }
}

export default function*() {
  yield takeLatest(ActionType.StartLoading, loadVoterResults);
}

import React, { useCallback, useContext, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Dictionary } from '../../settings.interface';
import { settingsActions } from '../../settings.actions';
import PriceInput from '../inputs/PriceInput';
import useAppSelector from '../../../../hooks/useAppSelector';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useFormSubscriber } from '../sections/FormStateContext';
import { FormModifiedContext } from '../sections/FormModifiedContext';
import { prettyPrint, TurnaroundOption } from '../../../../model/job/turnaroundOption';

const refreshMaxPriceState = (initial: Dictionary<number>) =>
  Object.keys(initial).reduce((acc, obj) => {
    acc[obj] = initial[obj];
    return acc;
  }, {} as Dictionary<number>);

interface EditTurnaroundRowProps {
  option: TurnaroundOption;
  maxPrice?: number;
  onChange: (value: number) => void;
}

const TurnaroundRow = ({ option, maxPrice, onChange }: EditTurnaroundRowProps) => {
  const updating = useAppSelector(state => state.settings.price.maxPriceUpdating);

  if (!maxPrice) {
    return null;
  }

  return (
    <TableRow>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{prettyPrint(option)}</TableCell>
      <TableCell>
        <PriceInput disabled={updating} value={maxPrice} onChange={onChange} />
      </TableCell>
    </TableRow>
  );
};

const PricesTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const [maxPriceState, setMaxPriceState] = useState(() => refreshMaxPriceState(props.maxPrices));

  const { setModified } = useContext(FormModifiedContext);

  const updateMaxPriceState = useCallback(
    (option: TurnaroundOption) => (value: number) => {
      setMaxPriceState(oldValue => ({ ...oldValue, [option]: value }));
      setModified(true);
    },
    [setMaxPriceState]
  );

  useFormSubscriber('cancel', () => setMaxPriceState(refreshMaxPriceState(props.maxPrices)), [
    props.maxPrices,
    setMaxPriceState
  ]);

  useFormSubscriber('submit', () => dispatch(settingsActions.maxPricesUpdate(maxPriceState)), [
    maxPriceState,
    dispatch
  ]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Surge price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.options.map(option => (
          <TurnaroundRow
            key={option}
            option={option}
            maxPrice={maxPriceState[option]}
            onChange={updateMaxPriceState(option)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

interface Props {
  maxPrices: Dictionary<number>;
  options: TurnaroundOption[];
}

export default PricesTable;

import { ProofreaderDetails } from '../../apis/proofreaders.api';

export interface ProofreadersListStore {
  proofreaders: ProofreaderDetails[];
  loading: boolean;
}

export const INIT_PROOFREADER_LIST_STORE: ProofreadersListStore = {
  proofreaders: [],
  loading: false
};

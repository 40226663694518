import React, { SyntheticEvent } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  initial?: string | number;
  label: string;
  submit: (value: string) => void;
  isValid?: (value: string) => boolean;
}

export const FilteringText: React.FunctionComponent<Props> = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.initial === undefined ? '' : `${props.initial}`);
  const [submittedValue, setSubmittedValue] = React.useState<string>(value);
  const [error, setError] = React.useState<boolean>(false);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setError(props.isValid ? !props.isValid(newValue) : false);
    setValue(newValue);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.persist();
    if (!error && value !== submittedValue) {
      setSubmittedValue(value);
      props.submit(value);
    }
  };

  const handleBlur = () => {
    if (!error && value !== submittedValue) {
      setSubmittedValue(value);
      props.submit(value);
    } else if (error) {
      setError(false);
      setValue('');
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        error={error}
        value={value}
        onChange={handleChange()}
        onBlur={handleBlur}
        label={props.label}
        type="search"
        margin="none"
        fullWidth
      />
    </form>
  );
};

import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import { blue, green, grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';

import { JobFileType, StepInfo, StepStatus } from '../../../job.interface';
import { AppsActions } from '../../../../../redux/actions/actions';
import { downloadStepFileStartLoading } from '../../../../../redux/saga/download-file.actions';
import { ID } from '../../../../../utils/types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'block',
      '&:hover': {
        opacity: 0.8
      }
    },
    original: {
      color: blue[600]
    },
    annotated: {
      color: green[600]
    },
    result: {
      color: grey[900]
    }
  })
);

const titles = {
  original: 'Download original file',
  annotated: 'Download annotated file',
  result: 'Download result file'
};

const StepFileDownloadIcon: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => {
  const classes = useStyles();

  const fileExist = {
    original: props.step.hasOriginal,
    annotated: props.step.hasAnnotated,
    result: props.step.hasResult || props.step.status == StepStatus.Running
  }[props.fileType];

  if (!fileExist) {
    return null;
  }

  return (
    <IconButton
      size="small"
      aria-label="download"
      onClick={() => props.onClick(props.step.jobId, props.step.stepId, props.fileType, props.step.additionalPass)}
    >
      <CloudDownloadIcon
        titleAccess={titles[props.fileType]}
        className={classNames(classes.root, classes[props.fileType])}
      />
    </IconButton>
  );
};

interface Props {
  step: StepInfo;
  fileType: JobFileType;
}

interface Dispatches {
  onClick: (jobId: ID, stepId: string, fileType: JobFileType, additionalPass: boolean) => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    onClick: (jobId: ID, stepId: string, fileType: JobFileType, additionalPass: boolean) =>
      dispatch(downloadStepFileStartLoading(jobId, stepId, fileType, additionalPass))
  })
)(StepFileDownloadIcon);

import { ID } from '../../utils/types';
import { Proofreader } from '../../apis/proofreaders.api';

import { PracticeDocument, PracticeJob, PracticeJobFiltering } from './practice.interface';

export interface PracticeStoreInterface {
  proofreaders: Proofreader[];
  loadingDocuments: boolean;
  documents: PracticeDocument[];
  uploadingDocument: boolean;
  uploadDocument: File | null;
  deleteDocumentId: ID | null;

  createJobPopup: CreateJobPopupInterface | null;
  loadingJobs: boolean;
  jobs: PracticeJob[];
  jobsFiltering: PracticeJobFiltering;
}

export interface CreateJobPopupInterface {
  document: PracticeDocument;
  creatingJob: boolean;
}

export const DEFAULT_PRACTICE_JOB_FILTERING: PracticeJobFiltering = {
  jobIds: [],
  jobStatuses: [],
  proofreaderIds: [],
  testDocumentIds: []
};

export const INIT_PRACTICE_STORE: PracticeStoreInterface = {
  proofreaders: [],
  loadingDocuments: false,
  documents: [],
  uploadingDocument: false,
  uploadDocument: null,
  deleteDocumentId: null,
  createJobPopup: null,
  loadingJobs: false,
  jobs: [],
  jobsFiltering: DEFAULT_PRACTICE_JOB_FILTERING
};

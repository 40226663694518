import { Job, JobAction, JobStatus, UserInputsFilter } from '../job.interface';
import { LoadingStatusEnum } from '../../../utils/enums';
import { ID } from '../../../utils/types';
import { TurnaroundOption } from '../../../model/job/turnaroundOption';
import { JobSource } from '../../../model/job/source';

export interface JobsFiltering {
  id?: string;
  uid?: string;
  jobType?: string;
  inputs?: UserInputsFilter;
  status?: JobStatus;
  option?: TurnaroundOption;
  source?: JobSource;
  assigneeId?: string;
}

export interface InfoPopup {
  type: 'info';
  job: Job;
}

export interface JobCreatePopup {
  type: 'create';
}

export interface JobActionAlertPopup {
  type: 'alert';
  job: Job;
  action: JobAction;
}

export interface JobRevisedResultPopup {
  type: 'revised';
  jobId: number;
  message: string;
  error: boolean;
}

export type JobsListPopupWindow = InfoPopup | JobActionAlertPopup | JobCreatePopup | JobRevisedResultPopup;

export interface JobsListStoreInterface {
  list: Job[];
  popupWindow?: JobsListPopupWindow;
  openStepInfo?: ID;
  lastLoadingStatus: LoadingStatusEnum;
  filtering: JobsFiltering;
}

export const INIT_JOBS_LIST_STORE: JobsListStoreInterface = {
  list: [],
  lastLoadingStatus: LoadingStatusEnum.NoStarted,
  filtering: {
    id: '',
    uid: ''
  }
};

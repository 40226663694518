import React, { useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import { Button, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';

import { DatePicker } from './DatePicker';

let DateSelectors: React.FunctionComponent<Props>;

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '10em'
    },
    button: {
      whiteSpace: 'nowrap'
    }
  })
);

export default DateSelectors = (props: Props) => {
  const classes = useStyles();
  const [state, changeState] = useState({ from: props.from, to: props.to });

  const setFrom = (date: Date) => changeState({ ...state, from: date });
  const setTo = (date: Date) => changeState({ ...state, to: date });
  const onApply = () => props.onApply(state.from, state.to);

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <Grid container spacing={2} alignItems="flex-end" wrap="nowrap">
        <Grid item>
          <DatePicker
            label="from"
            className={classes.input}
            placeholder="From"
            maxDate={state.to}
            value={state.from}
            onChange={setFrom}
          />
        </Grid>
        <Grid item>
          <DatePicker
            label="to"
            className={classes.input}
            placeholder="To"
            minDate={state.from}
            value={state.to}
            onChange={setTo}
          />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={onApply}
            disabled={props.loading}
          >
            {props.buttonText}
          </Button>
        </Grid>
        <Grid item>{props.loading && <CircularProgress />}</Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

interface Props {
  from: Date;
  to: Date;
  loading: boolean;
  onApply: (from: Date, to: Date) => void;
  buttonText: string;
}

import { call, fork, join, put, takeEvery } from '@redux-saga/core/effects';

import fetch from '../../../utils/fetch';
import appConfig from '../../../config';
import { hideSpinner, showSpinner } from '../../App/app.actions';

import { actions, VotingProgressActionType } from './progress.actions';
import { AllVotingProgressCounts, CombinedVotingProgressResponse } from './progress.interface.api';
import api2counts from './utils/api2counts';
import api2progress from './utils/api2progress';

function* request(url: string) {
  try {
    return yield call(fetch.request.bind(fetch), url);
  } catch (error) {
    yield put(actions.loadingFailure(error.message));
  }
}

function* gradeStatsStartAsync() {
  yield put(showSpinner('grade-stats'));

  try {
    const tasks = [yield fork(request, appConfig.api.voting.counts), yield fork(request, appConfig.api.voting.root)];

    const [votingCounts, groupedVotingStatuses]: [AllVotingProgressCounts, CombinedVotingProgressResponse] = yield join(
      tasks
    );

    const counts = api2counts(votingCounts);
    const progress = api2progress(groupedVotingStatuses);
    yield put(actions.loadingSuccess(counts, progress));
  } finally {
    yield put(hideSpinner('grade-stats'));
  }
}

export default function*() {
  yield takeEvery(VotingProgressActionType.StartLoading, gradeStatsStartAsync);
}

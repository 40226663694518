import React, { useState } from 'react';
import { createStyles, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { VotingStatus } from '../progress.interface.api';

import VotingProgressRow from './VotingProgressRow';

const useStyles = makeStyles(() =>
  createStyles({
    andMore: {
      '& > td': {
        border: 'none'
      },
      fontStyle: 'italic'
    }
  })
);

export default (props: Props) => {
  if (props.rows.length === 0) {
    return <h3>There is no data to display</h3>;
  }

  const classes = useStyles();

  const [selectedRow, selectRow] = useState(-1);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Voting job #</TableCell>
          <TableCell>Snippet edited on</TableCell>
          <TableCell>Proofreader</TableCell>
          <TableCell>Voting progress</TableCell>
          <TableCell>Completed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, index) => (
          <VotingProgressRow
            key={row.snippetId}
            open={selectedRow === index}
            row={row}
            onOpen={() => selectRow(index)}
            onClose={() => selectRow(-1)}
          />
        ))}
      </TableBody>
      {props.totalCount > props.rows.length && (
        <TableBody>
          <TableRow className={classes.andMore}>
            <TableCell colSpan={6}>And more...</TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  );
};

interface Props {
  totalCount: number;
  rows: VotingStatus[];
}

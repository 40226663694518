import { makeStyles } from '@material-ui/core/styles';
import { lighten, LinearProgressProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

interface AdditionalProps {
  baseColor: string;
}

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: (props: AdditionalProps) => lighten(props.baseColor, 0.6)
  },
  barColorPrimary: {
    backgroundColor: (props: AdditionalProps) => props.baseColor
  },
  dashed: {
    animation: 'none'
  },
  dashedColorPrimary: {
    backgroundColor: (props: AdditionalProps) => lighten(props.baseColor, 0.8),
    backgroundImage: 'none'
  },
  root: {
    width: '100%'
  }
});

export const StaticBufferedLinearProgress: FunctionComponent<LinearProgressProps & AdditionalProps> = React.forwardRef(
  (props: LinearProgressProps & AdditionalProps, ref) => {
    const { baseColor, ...linearProgressProps } = props;
    const classes = useStyles({ baseColor: baseColor });

    return <LinearProgress variant="buffer" {...linearProgressProps} classes={classes} ref={ref} />;
  }
);

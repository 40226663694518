import { Monitoring, LoggingImpl, Logging } from '@grammarly/telemetry.ts';
import { AsyncLogAppender } from '@grammarly/telemetry.ts/lib/_logging_impl/async_log_appender';

import { AppsActions, isErrorAction } from '../actions/actions';
import { browserInfo } from '../../utils/os/browser-info';
import { AuthProfile } from '../../modules/auth/okta';
import config from '../../config';
import { AppActionType } from '../../pages/App/app.actions';
import { NotificationActionType } from '../../modules/notifications/notifications.actions';

import { MiddlewareType } from './middleware';

const APP_NAME = 'balto';

const createFelogClient = (url: string, wrapExtra = true): AsyncLogAppender =>
  new LoggingImpl.PostFelogClient(url, APP_NAME, 'v.1.0.0', `${config.mode}.${config.env}`, fetch, wrapExtra);

const getRateLimitingFelogWrapper = (endPoint: string, wrapExtra: boolean) =>
  new LoggingImpl.RateLimitingFelogWrapper(
    new LoggingImpl.CountingFelogWrapper(createFelogClient(endPoint, wrapExtra))
  );

const appender: LoggingImpl.AsyncLogAppender = getRateLimitingFelogWrapper(
  'https://f-log-balto.grammarly.io/log',
  false
);
const crashAppender: LoggingImpl.AsyncLogAppender = getRateLimitingFelogWrapper(
  'https://f-log-balto.grammarly.io/crash',
  false
);

LoggingImpl.LoggingConfig.configure(
  LoggingImpl.DefaultLogAppender.createRootLogger({
    name: APP_NAME,
    appendLevel: Logging.LogLevel.INFO,
    appender: appender,
    crash: {
      sendLevel: Logging.LogLevel.ERROR,
      appendLevel: Logging.LogLevel.DEBUG,
      appender: crashAppender
    },
    copyToConsole: false
  })
);

const logger = Monitoring.Logging.root.getLogger(APP_NAME);

// In-house session id to connect all events of a single app instance
const userAgent = {
  ...browserInfo(),
  raw: navigator.userAgent
};
Monitoring.Logging.root.context.add({
  userAgent
});

let profile: AuthProfile | undefined = undefined;

// Monitoring.TimeSeries.root.getCounter('sessions').increment();

const ignoreReduxEvents = [
  AppActionType.ShowSpinner,
  AppActionType.HideSpinner,
  ...Object.values(NotificationActionType)
].reduce((acc, actionType) => {
  acc[actionType] = true;
  return acc;
}, {} as { [key: string]: boolean });

// init
export const log: MiddlewareType<AppsActions> = ({ getState }) => next => action => {
  next(action);

  if (ignoreReduxEvents[action.type]) return;

  const state = getState();
  if (state.auth.profile !== profile) {
    profile = state.auth.profile;
    Monitoring.Logging.root.context.add({
      profile
    });
  }

  const method = isErrorAction(action) ? 'error' : 'info';
  logger[method](`ACTION: ${action.type}`, action);
};

export const telemetryMiddleware = [log];

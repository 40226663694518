export const sec = 1000;
export const min = 60 * sec;
export const hr = 60 * min;

export function merge<T extends U, U>(config: T, update: U) {
  return internalMerge(internalMerge({} as T, config), update);
}

function internalMerge<T, U>(source: T, target: U) {
  for (const k in target) {
    const v = (source as any)[k] || {};
    const updV = (target as any)[k];
    if (updV != null && v != null && updV.constructor === Object && v.constructor === Object) {
      (source as any)[k] = internalMerge(v, updV);
    } else {
      (source as any)[k] = updV;
    }
  }
  return source;
}

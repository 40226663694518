import moment from 'moment';

import { DAILY_SCALE, Scale, WEEKLY_SCALE } from '../view/store';

export default (asOf: Date, scale: Scale): { from: Date; to: Date } => {
  if (scale !== DAILY_SCALE && scale !== WEEKLY_SCALE) {
    throw new Error(`Trying to calculate time frame for an unsupported scale: ${scale}`);
  }

  return {
    from: moment
      .utc(asOf)
      .startOf(scale.unitOfTime)
      .toDate(),
    to: moment
      .utc(asOf)
      .add(1, scale.unitOfTime)
      .startOf(scale.unitOfTime)
      .toDate()
  };
};

import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DatePicker: React.FunctionComponent<Props> = (props: Props) => (
  <KeyboardDatePicker
    disableToolbar
    variant="inline"
    disableFuture={true}
    format="YYYY-MM-DD"
    label={props.label}
    maxDate={props.maxDate}
    minDate={props.minDate}
    placeholder={props.placeholder}
    value={props.value}
    onChange={props.onChange || noop}
    className={props.className}
  />
);

function noop() {}

interface Props {
  label: string;
  maxDate?: Date;
  minDate?: Date;
  placeholder: string;
  value: Date;
  className?: string;
  onChange?: (date: any) => void;
}

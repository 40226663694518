import { call, fork, join, put, take, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import appConfig from '../../../config';
import { ID } from '../../../utils/types';
import fetch from '../../../utils/fetch';
import { scheduleActions, ScheduleViewActionType } from '../view/actions';
import { hideSpinner, showSpinner } from '../../App/app.actions';

import { actions, ScheduleChangesActionType, Submit } from './actions';
import { Addition, ApplyMode, Modification, Operation, OperationType, Removal, RepeatType } from './store';

interface Op {
  type: OperationType;
  applyType: ApplyMode;

  scheduledId: ID | null;
  proofreaderId: ID;

  begin: string | null;
  end: string | null;

  startOn: string;
  until: string | null;

  period: RepeatType;
}

const additionToOp = (op: Addition): Op => ({
  type: op.type,
  applyType: ApplyMode.AllShifts,

  scheduledId: null,
  proofreaderId: op.proofreader.id,

  begin: moment.utc(op.begin).format('HH:mm'),
  end: moment.utc(op.begin + op.duration).format('HH:mm'),

  startOn: moment.utc(op.repeatMode.from).format('YYYY-MM-DD'),
  until: op.repeatMode.to ? moment.utc(op.repeatMode.to).format('YYYY-MM-DD') : null,
  period: op.repeatMode.type
});

const removalToOp = (op: Removal): Op => ({
  type: op.type,
  applyType: op.applyMode,

  scheduledId: +op.shift.seriesKey || null,
  proofreaderId: op.shift.proofreader.id,

  begin: null,
  end: null,

  startOn: moment.utc(op.repeatMode.from).format('YYYY-MM-DD'),
  until: op.repeatMode.to ? moment.utc(op.repeatMode.from).format('YYYY-MM-DD') : null,
  period: op.repeatMode.type
});

const modificationToOp = (op: Modification): Op => ({
  type: op.type,
  applyType: op.applyMode,

  scheduledId: +op.shift.seriesKey || null,
  proofreaderId: op.shift.proofreader.id,

  begin: moment.utc(op.shift.begin + op.offset).format('HH:mm'),
  end: moment.utc(op.shift.end + op.offset + op.durationDiff).format('HH:mm'),

  startOn: moment.utc(op.repeatMode.from).format('YYYY-MM-DD'),
  until: op.repeatMode.to ? moment.utc(op.repeatMode.to).format('YYYY-MM-DD') : null,
  period: op.repeatMode.type
});

const toOp = (op: Operation): Op => {
  switch (op.type) {
    case OperationType.Addition:
      return additionToOp(op);
    case OperationType.Modification:
      return modificationToOp(op);
    case OperationType.Removal:
      return removalToOp(op);
    default:
      throw new Error('Unknown op.type for op ' + JSON.stringify(op));
  }
};

function* request(operations: Op[]) {
  try {
    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(operations)
    };

    yield call(fetch.request.bind(fetch), appConfig.api.schedule.root, config, 'application/json');
  } catch (error) {
    yield put(actions.submitError(error.message));
  }
}

function* submitChanges(action: Submit) {
  try {
    yield put(showSpinner('update-schedule'));

    const ops = action.payload.ops;

    const operationsToSubmit = Object.keys(ops).map(key => toOp(ops[key]));

    const task = yield fork(request, operationsToSubmit);

    yield join(task);

    yield put(scheduleActions.softLoad());

    yield take([
      ScheduleViewActionType.LoadingSuccess,
      ScheduleViewActionType.LoadingFailure,
      ScheduleViewActionType.StopLoading
    ]);

    yield put(actions.submitSuccessful());
  } finally {
    yield put(hideSpinner('update-schedule'));
  }
}

export default function*() {
  yield takeEvery(ScheduleChangesActionType.Submit, submitChanges);
}

import { put, call, takeEvery } from 'redux-saga/effects';

import { hideSpinner, showSpinner } from '../../pages/App/app.actions';

import { AuthActionType, Logout } from './actions';

function* logout(action: Logout) {
  try {
    yield put(showSpinner('logout'));
  } finally {
    yield call(action.payload.authService.signOut.bind(action.payload.authService));
    yield put(hideSpinner('logout'));
  }
}

export default function*() {
  yield takeEvery(AuthActionType.Logout, logout);
}

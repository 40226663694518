import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { BoxProps } from '@material-ui/core/Box';
import classNames from 'classnames';

let PaddedBox: React.FunctionComponent<BoxProps>;

const styles = makeStyles(() =>
  createStyles({
    padded: {
      padding: '1.5em 2.5em 0 2.5em'
    },
    centered: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

export default PaddedBox = (props: Props) => {
  const classes = styles();

  const { padded, centered, ...boxProps } = props;
  const cls = classNames({
    [classes.padded]: padded === undefined ? true : padded,
    [classes.centered]: centered === undefined ? false : centered
  });

  return (
    <Box className={cls} {...boxProps}>
      {props.children}
    </Box>
  );
};

interface Props extends BoxProps {
  padded?: boolean;
  centered?: boolean;
}

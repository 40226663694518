import { VoteJobType } from '../../../model/voting/voteJobType';

import { VotingSettings } from './store';

export enum ActionType {
  Load = 'settings/voting/load',
  LoadingSuccess = 'settings/voting/loading-success',
  LoadingFailure = 'settings/voting/loading-failure',

  SetVotingRate = 'settings/voting/set-voting-rate',
  SetRequiredVotes = 'settings/voting/set-required-votes',

  CommenceUpdate = 'settings/voting/update/commence',
  CommitUpdate = 'settings/voting/update/commit',
  ResetUpdate = 'settings/voting/update/reset',
  UpdateSuccess = 'settings/voting/update/success',
  UpdateFailure = 'settings/voting/update/failure'
}

export type Load = { type: ActionType.Load };
export type LoadingSuccess = { type: ActionType.LoadingSuccess; payload: { settings: VotingSettings[] } };
export type LoadingFailure = { type: ActionType.LoadingFailure };

export type SetVotingRate = { type: ActionType.SetVotingRate; payload: { value: number; type: VoteJobType } };
export type SetRequiredVotes = { type: ActionType.SetRequiredVotes; payload: { value: number; type: VoteJobType } };

export type CommenceUpdate = { type: ActionType.CommenceUpdate };
export type CommitUpdate = { type: ActionType.CommitUpdate };
export type ResetUpdate = { type: ActionType.ResetUpdate };
export type UpdateSuccess = { type: ActionType.UpdateSuccess; payload: { settings: VotingSettings[] } };
export type UpdateFailure = { type: ActionType.UpdateFailure };

export const actions = {
  load: (): Load => ({ type: ActionType.Load }),
  loadingSuccess: (settings: VotingSettings[]): LoadingSuccess => ({
    type: ActionType.LoadingSuccess,
    payload: { settings }
  }),
  loadingFailure: (): LoadingFailure => ({
    type: ActionType.LoadingFailure
  }),

  setVotingRate: (type: VoteJobType, value: number): SetVotingRate => ({
    type: ActionType.SetVotingRate,
    payload: { type, value }
  }),
  setRequiredVotes: (type: VoteJobType, value: number): SetRequiredVotes => ({
    type: ActionType.SetRequiredVotes,
    payload: { type, value }
  }),

  commenceUpdate: (): CommenceUpdate => ({ type: ActionType.CommenceUpdate }),
  commitUpdate: (): CommitUpdate => ({ type: ActionType.CommitUpdate }),
  resetUpdate: (): ResetUpdate => ({ type: ActionType.ResetUpdate }),
  updateSuccess: (settings: VotingSettings[]): UpdateSuccess => ({
    type: ActionType.UpdateSuccess,
    payload: { settings }
  }),
  updateFailure: (): UpdateFailure => ({ type: ActionType.UpdateFailure })
};

export type Action =
  | Load
  | LoadingSuccess
  | LoadingFailure
  | SetVotingRate
  | SetRequiredVotes
  | CommenceUpdate
  | CommitUpdate
  | ResetUpdate
  | UpdateSuccess
  | UpdateFailure;

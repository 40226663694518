import React, { useCallback, useContext } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { settingsActions } from '../../settings.actions';
import useAppSelector from '../../../../hooks/useAppSelector';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useFormSubscriber } from '../sections/FormStateContext';
import { FormModifiedContext } from '../sections/FormModifiedContext';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      appearance: 'textfield',
      fontSize: '0.875rem',
      paddingRight: '.5em',
      textAlign: 'right',
      width: 30
    }
  })
);

const GeneralSettingsTable = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const maxCapacityStore = useAppSelector(state => state.settings.price.settings.maxCapacity);
  const lateGecSnippets = useAppSelector(state => state.settings.price.settings.lateGecSnippets);
  const lateClaritySnippets = useAppSelector(state => state.settings.price.settings.lateClaritySnippets);

  const [maxCapacity, setMaxCapacity] = React.useState<number>(maxCapacityStore);

  const { setModified } = useContext(FormModifiedContext);

  useFormSubscriber('cancel', () => setMaxCapacity(maxCapacityStore), [maxCapacityStore, setMaxCapacity]);

  useFormSubscriber('submit', () => dispatch(settingsActions.generalSettingUpdate(maxCapacity)), [
    maxCapacity,
    dispatch
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMaxCapacity(+event.target.value);
      setModified(true);
    },
    [setMaxCapacity]
  );

  return (
    <>
      <Typography variant="body1">Late GEC snippets: {lateGecSnippets}</Typography>
      <Typography variant="body1">Late Clarity snippets: {lateClaritySnippets}</Typography>
      <Typography component={Grid} container variant="body1" alignItems="center">
        <Grid item>Maximum capacity:</Grid>
        <Grid item>
          <TextField
            value={maxCapacity}
            onChange={handleChange}
            type="number"
            margin="none"
            InputProps={{
              inputProps: { min: 0, max: 100, className: classes.input },
              endAdornment: <span>%</span>
            }}
          />
        </Grid>
      </Typography>
    </>
  );
};

export default GeneralSettingsTable;

import { assertNever } from '../../utils/errors';

export enum TurnaroundOption {
  Realtime = 'Realtime',
  Instant = 'Instant',
  ThreeHours = 'ThreeHours',
  SixHours = 'SixHours',
  TwentyFourHours = 'TwentyFourHours',
  TwoDays = 'TwoDays',
  ThreeDays = 'ThreeDays'
}

export const prettyPrint = (option: TurnaroundOption) => {
  switch (option) {
    case TurnaroundOption.Realtime:
      return 'Realtime';
    case TurnaroundOption.Instant:
      return 'Instant';
    case TurnaroundOption.ThreeHours:
      return '3 hours';
    case TurnaroundOption.SixHours:
      return '6 hours';
    case TurnaroundOption.TwentyFourHours:
      return '24 hours';
    case TurnaroundOption.TwoDays:
      return '2 days';
    case TurnaroundOption.ThreeDays:
      return '3 days';
    default:
      assertNever(option);
  }
};

export const prettyPrintShort = (option: TurnaroundOption) => {
  switch (option) {
    case TurnaroundOption.Realtime:
      return '1m';
    case TurnaroundOption.Instant:
      return '30m';
    case TurnaroundOption.ThreeHours:
      return '3h';
    case TurnaroundOption.SixHours:
      return '6h';
    case TurnaroundOption.TwentyFourHours:
      return '24h';
    case TurnaroundOption.TwoDays:
      return '2d';
    case TurnaroundOption.ThreeDays:
      return '3d';
    default:
      assertNever(option);
  }
};

import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import React from 'react';

import { AppRoute } from '../../routes';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { SecurityGroup } from '../../modules/auth/okta';

import CorporaPage from './components/CorporaPage';

const corporaRoute: AppRoute = {
  path: '/corpora',
  component: CorporaPage,
  parent: indexRoute,
  pathMatch: /\/corpora/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-corporaRoute">
      <NavLink to="/corpora">
        <LibraryBooksIcon className="AppBreadcrumbsIcon" />
        Corpora
      </NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.AL],
  private: true,
  exact: true
};

export default [corporaRoute];

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

interface Props {
  value: string;
  label: string;
  autoFocus: boolean;
  onChange: (value: string) => void;
}

const leadingZero = (n: number) => ('0' + n).substr(-2);
const timeValues = new Array(24 * 4).fill('').map((el, index) => {
  const minutes = (index % 4) * 15;
  const hours = Math.floor(index / 4);

  return leadingZero(hours) + ':' + leadingZero(minutes);
});

export default ({ value, label, autoFocus, onChange }: Props) => (
  <Autocomplete
    value={value}
    multiple={false}
    options={timeValues}
    onChange={(event: React.ChangeEvent<{}>, value: string | null) => onChange(value || '')}
    renderInput={params => <TextField {...params} autoFocus={autoFocus} size="small" label={label} />}
  />
);

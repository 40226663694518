import Person from '@material-ui/icons/Person';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import indexRoute from '../rootRedirect/dashboard.routes';
import { AppRoute } from '../../routes';

import Unauthorized from './components/Login/Unauthorized';

const route: AppRoute = {
  path: '/login',
  component: Unauthorized,
  parent: indexRoute,
  pathMatch: /\/login/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-loginRoute">
      <Person className="AppBreadcrumbsIcon" />
      Login
    </Typography>
  ),
  exact: true
};

export default route;

import './assets/index.css';
import 'typeface-roboto';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Security } from '@okta/okta-react';
import { SnackbarProvider } from 'notistack';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router';

import App from './pages/App/components/App/App';
import { configureStore, history } from './redux/store/store';
import appConfig from './config';

console.info('%c~~~> App Config ~~~>', 'color: aqua;', appConfig);

if (!('process' in window)) {
  // @ts-ignore
  window.process = {};
}

const TheApp = () => {
  const history = useHistory();
  const onAuthRequired = () => history.push('/login');
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    issuer: appConfig.okta.issuer,
    clientId: appConfig.okta.clientId,
    scopes: ['openid', 'email', 'profile', 'groups'],
    redirectUri: window.location.origin + '/login/callback',
    pkce: true
  });

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
};

ReactDOM.render(
  <Provider store={configureStore()}>
    <SnackbarProvider maxSnack={3}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <TheApp />
      </ConnectedRouter>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);

import { ErrorAction } from '../../../redux/actions/actions';
import { VoteJobType } from '../../../model/voting/voteJobType';

import { AllCounts, Progress, VotingProgressTab } from './progress.store';

export enum VotingProgressActionType {
  Init = 'peer-votes/progress/init',

  StartLoading = 'peer-votes/progress/start-loading',
  LoadingSuccess = 'peer-votes/progress/loading-success',
  LoadingFailure = 'peer-votes/progress/loading-failure',

  ChangeTab = 'peer-votes/progress/change-tab',
  ChangeSnippetTypeFilter = 'peer-votes/progress/change-snippet-type-filter'
}

type Init = { type: VotingProgressActionType.Init };

export type StartLoading = { type: VotingProgressActionType.StartLoading };

interface LoadingSuccess {
  type: VotingProgressActionType.LoadingSuccess;
  payload: {
    counts: AllCounts;
    progress: Progress;
  };
}

interface LoadingFailed extends ErrorAction {
  type: VotingProgressActionType.LoadingFailure;
}

interface ChangeTab {
  type: VotingProgressActionType.ChangeTab;
  payload: { tab: VotingProgressTab };
}

interface ChangeVoteTypeFilter {
  type: VotingProgressActionType.ChangeSnippetTypeFilter;
  payload: { filter?: VoteJobType };
}

export const actions = {
  init: (): Init => ({
    type: VotingProgressActionType.Init
  }),

  startLoading: (): StartLoading => ({
    type: VotingProgressActionType.StartLoading
  }),

  loadingSuccess: (counts: AllCounts, progress: Progress): LoadingSuccess => ({
    type: VotingProgressActionType.LoadingSuccess,
    payload: { counts, progress }
  }),

  loadingFailure: (reason: string): LoadingFailed => ({
    type: VotingProgressActionType.LoadingFailure,
    error: true,
    payload: new Error(`Failed loading voting progress: ${reason}`)
  }),

  changeTab: (tab: VotingProgressTab): ChangeTab => ({
    type: VotingProgressActionType.ChangeTab,
    payload: { tab }
  }),

  changeSnippetTypeFilter: (filter?: VoteJobType): ChangeVoteTypeFilter => ({
    type: VotingProgressActionType.ChangeSnippetTypeFilter,
    payload: { filter }
  })
};

export type VotingProgressActions =
  | Init
  | StartLoading
  | LoadingSuccess
  | LoadingFailed
  | ChangeTab
  | ChangeVoteTypeFilter;

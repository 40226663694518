import { APP_CONFIG, AppConfig } from './app.config';
import { merge } from './utils';

const QA_CONFIG: AppConfig = merge(APP_CONFIG, {
  mode: 'production',
  env: 'qa',
  domain: {
    host: 'admin.qa-proofit.grammarlyaws.com',
    localServer: 'https://admin.qa-proofit.grammarlyaws.com'
  },
  api: {
    server: 'https://qa-proofit.grammarlyaws.com/'
  },
  okta: {
    baseUrl: 'https://dev-149533.okta.com/',
    clientId: '0oa7o6mw20ngjntgG357',
    issuer: 'https://dev-149533.okta.com/oauth2/ausaoubyvby6gxqWB357'
  },
  denali: {
    server: 'https://app.qagr.io/'
  },
  grammarlyUserId: '2326656'
});

export default QA_CONFIG;

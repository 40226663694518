import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import EmailIcon from '@material-ui/icons/Email';
import { Add } from '@material-ui/icons';

import { VotingStatus } from '../progress.interface.api';
import { clarityBlue, Diamond, Paragraph } from '../icons';

import DetailsRows from './DetailsRows';

let VotingProgressRow: React.FunctionComponent<Props>;

const styles = makeStyles(() =>
  createStyles({
    iconAnchor: {
      position: 'relative'
    },
    iconColumn: { width: 20, textAlign: 'center' },
    icon: {
      position: 'absolute',
      top: 0,
      height: '100%'
    },
    openIndicator: {
      '&.opened': {
        transform: 'rotate(90deg)'
      },
      right: 0,
      cursor: 'pointer',
      transition: 'transform .1s'
    }
  })
);

export default VotingProgressRow = (props: Props) => {
  const classes = styles();

  const openIndicator = (
    <KeyboardArrowRightIcon
      onClick={props.open ? props.onClose : props.onOpen}
      className={classnames(classes.icon, classes.openIndicator, { opened: props.open })}
    />
  );

  const requiredVotes = props.row.voteJobs.length;
  const doneVotes = props.row.voteJobs.filter(vj => !!vj.voterId).length;

  const proofreadingCompleted = props.row.proofreadingCompleted
    ? moment.utc(props.row.proofreadingCompleted).format('YYYY-MM-DD')
    : '-';

  const votingCompleted = props.row.votingCompleted ? moment.utc(props.row.votingCompleted).format('YYYY-MM-DD') : '-';

  const mainRow = (
    <TableRow hover selected={props.open} key={props.row.snippetId}>
      <TableCell className={classes.iconColumn}>
        {props.row.voteJobType === 'CLARITY' && <Diamond />}
        {props.row.voteJobType === 'CLARITY_PLUS' && <Add style={{ color: clarityBlue }} />}
        {props.row.voteJobType === 'PARA_CLARITY' && <Paragraph />}
        {props.row.voteJobType === 'REALTIME_GEC' && <EmailIcon fontSize="small" />}
      </TableCell>
      <TableCell>{props.row.snippetId}</TableCell>
      <TableCell>{proofreadingCompleted}</TableCell>
      <TableCell>{props.row.proofreaderName}</TableCell>
      <TableCell>
        {doneVotes} / {requiredVotes}
      </TableCell>
      <TableCell className={classes.iconAnchor}>
        {props.row.status === 'completed' ? votingCompleted : '-'}
        {requiredVotes > 0 && openIndicator}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {mainRow}
      {props.open && <DetailsRows voteJobs={props.row.voteJobs} />}
    </>
  );
};

export interface Props {
  open: boolean;
  row: VotingStatus;
  onOpen: () => void;
  onClose: () => void;
}

import './AppBreadcrumbs.css';
import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@material-ui/core';

import { StoreInterface } from '../../../../redux/store/store';
import { AppRoute } from '../../../../routes';
import { getBreadcrumbs } from '../../app.routes';

interface Props {
  pathname: string;
  store: StoreInterface;
}

class AppBreadcrumbs extends React.Component<Props> {
  private breadcrumbs: AppRoute[] = [];

  renderBreadcrumbs() {
    return this.breadcrumbs.map(route => route.breadcrumb(this.props.store, this.breadcrumbs));
  }

  render() {
    this.breadcrumbs = getBreadcrumbs(this.props.pathname);
    return (
      <div className="AppBreadcrumbsRoot">
        {this.breadcrumbs.length ? <Breadcrumbs aria-label="breadcrumb">{this.renderBreadcrumbs()}</Breadcrumbs> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreInterface): Props => ({
  pathname: state.router.location.pathname,
  store: state
});

export default connect(mapStateToProps)(AppBreadcrumbs);

import React from 'react';
import { capitalize, uniq } from 'lodash';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';

import { StoreInterface } from '../../../redux/store/store';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '1.5em',
      overflow: 'scroll',
      marginBottom: 20,
      minHeight: 150
    }
  })
);

const ScheduledProofreadersTable = () => {
  const classes = useStyles();

  const onShiftProofreaders = useSelector((store: StoreInterface) => store.onshiftProofreaders.onshiftProofreaders);
  const scheduledProofreaders = useSelector((store: StoreInterface) => store.onshiftProofreaders.scheduledProofreaders);
  const cohorts = useSelector((store: StoreInterface) => store.permissions.cohorts);

  return (
    <Paper className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">On-shift / Scheduled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow selected>
            <TableCell>Total</TableCell>
            <TableCell align="center">
              {onShiftProofreaders.length}/{scheduledProofreaders.length}
            </TableCell>
          </TableRow>
          {cohorts.map(cohort => (
            <TableRow key={cohort}>
              <TableCell>{capitalize(cohort).replace('_', ' ')}</TableCell>
              <TableCell align="center">
                {onShiftProofreaders.filter(p => p.cohorts.includes(cohort)).length}/
                {scheduledProofreaders.filter(p => p.cohorts.includes(cohort)).length}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ScheduledProofreadersTable;

import React from 'react';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Typography from '@material-ui/core/Typography';

import { AppRoute } from '../../routes';
import { SecurityGroup } from '../../modules/auth/okta';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';

import OnshiftProofreadersPage from './components/OnshiftProofreadersPage';

const onshiftProofreadersRoutes: AppRoute = {
  path: '/onshift-proofreaders',
  component: OnshiftProofreadersPage,
  parent: indexRoute,
  pathMatch: /\/onshift-proofreaders/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-scheduleRoute">
      <NavLink to="/onshift-proofreaders">
        <DashboardIcon className="AppBreadcrumbsIcon" />
        Dashboard
      </NavLink>
    </Typography>
  ),
  private: true,
  securityGroups: [SecurityGroup.ProofitOps],
  exact: true
};

export default [onshiftProofreadersRoutes];

import { Reducer } from 'redux';

import { INIT_NOTIFICATIONS_STORE, NotificationsStoreInterface } from './notifications.store';
import { NotificationActionType, NotificationsActions } from './notifications.actions';

export type NotificationsReducer = Reducer<NotificationsStoreInterface, NotificationsActions>;
export const notificationReducer: NotificationsReducer = (state = INIT_NOTIFICATIONS_STORE, action) => {
  switch (action.type) {
    case NotificationActionType.Add:
      return { notifications: [...state.notifications, action.payload.notification] };

    case NotificationActionType.Close:
      return {
        notifications: state.notifications.filter(notification => notification.key !== action.payload.notification.key)
      };

    case NotificationActionType.CloseAll:
      return { notifications: [] };

    default:
      return state;
  }
};

import { JobStatus, UserInputsFilter } from '../job.interface';
import { str2enum } from '../../../utils/enums/str2enum';
import { JobSource } from '../../../model/job/source';
import { TurnaroundOption } from '../../../model/job/turnaroundOption';

import { JobsFiltering } from './jobs-list.store';

export const parseUrlParams = (query: string): JobsFiltering => {
  const params = new URLSearchParams(query);

  const jobType = params.get('jobType') || undefined;
  const inputsParam = params.get('inputs') as UserInputsFilter;

  const inputs =
    inputsParam && Object.values(UserInputsFilter).includes(inputsParam) ? inputsParam : UserInputsFilter.ALL;

  return {
    id: params.get('id') || undefined,
    uid: params.get('userId') || undefined,
    jobType: jobType && jobType.toUpperCase(),
    inputs,
    status: str2enum(JobStatus, params.get('status') || ''),
    option: str2enum(TurnaroundOption, params.get('option') || ''),
    source: str2enum(JobSource, params.get('source') || ''),
    assigneeId: params.get('assigneeId') || undefined
  };
};

export const stringifyFilteringParams = (filtering: JobsFiltering): string => {
  const stringifiedValues = {
    id: filtering.id,
    userId: filtering.uid,
    jobType: filtering.jobType,
    inputs: filtering.inputs,
    status: filtering.status,
    option: filtering.option,
    source: filtering.source ? filtering.source : '',
    assigneeId: filtering.assigneeId
  };

  const params = Object.entries(stringifiedValues)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`);

  return params.join('&');
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { StoreInterface } from '../../../../redux/store/store';
import { AuthProfile, SecurityGroup } from '../../../auth/okta';
import { UrlRelative } from '../../../../utils/types';

const redirectDestination = (authProfile: AuthProfile): UrlRelative => {
  if (authProfile.groups.includes(SecurityGroup.Scheduler)) {
    return '/schedule';
  } else if (authProfile.groups.includes(SecurityGroup.AL)) {
    return '/corpora';
  } else {
    return '/jobs';
  }
};

const Dashboard = () => {
  const authProfile = useSelector((state: StoreInterface) => state.auth.profile);

  return authProfile ? (
    <Redirect to={redirectDestination(authProfile)} />
  ) : (
    <div className="App">{/* This is a dummy block to wait until we receive user's auth info */}</div>
  );
};

export default Dashboard;

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Container from '@material-ui/core/Container';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Paper from '@material-ui/core/Paper';
import { IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { StoreInterface } from '../../../redux/store/store';
import { ProofreadersFiltering } from '../proofreader-list.interface';
import { AppsActions } from '../../../redux/actions/actions';
import { proofreaderListActions } from '../proofreader-list.actions';
import TableWithHeader from '../../../components/TableWithHeader/TableWithHeader';
import { downloadReport } from '../../../redux/saga/download-file.actions';
import appConfig from '../../../config';
import { hasPermission } from '../utils';
import { Permission } from '../../../apis/permissions.interface';
import { OnboardingStatus, ProofreaderDetails, ProofreaderStatus } from '../../../apis/proofreaders.api';

import { getProofreadersColumns } from './ProofreadersListColumns';

// FIXME: rewrite as a function component
class ProofreadersList extends React.Component<Props & Dispatches, State> {
  constructor(init: Props & Dispatches) {
    super(init);
    this.state = {
      filtering: {
        id: null,
        name: '',
        email: '',
        onboardingStatus: null,
        status: null,
        blocked: 'false',
        inTestGroup: 'all',
        permissions: {}
      },
      selectedProofreader: null,
      createMode: false
    };
  }

  componentDidMount() {
    this.props.load();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props & Dispatches>, nextContext: any): void {
    if (!nextProps.loading) {
      this.setState({ selectedProofreader: null, createMode: false });
    }
  }

  render() {
    return (
      <Container maxWidth="xl" className="ProofreadersListWrapper">
        <Tooltip title="Create new proofreader">
          <IconButton color="primary" onClick={() => this.createMode(true)}>
            <PersonAddIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download in CSV">
          <Button color="primary" onClick={() => this.downloadCsvReport()}>
            Download in CSV
          </Button>
        </Tooltip>

        <Paper className="ProofreadersList">
          <TableWithHeader
            columns={getProofreadersColumns(this, this.props.loading, this.props.permissions)}
            data={this.filterProofreaders(this.props.proofreaders, this.state.filtering)}
            selected={this.selected()}
            createMode={this.state.createMode}
          />
        </Paper>
      </Container>
    );
  }

  filtering(key: keyof ProofreadersFiltering, value: string | boolean) {
    this.setState({
      createMode: false,
      selectedProofreader: null,
      filtering: { ...this.state.filtering, [key]: value }
    });
  }

  permissionFiltering(key: string, value?: boolean) {
    const permissions = { ...this.state.filtering.permissions };

    if (value === undefined) {
      delete permissions[key];
    } else {
      permissions[key] = value;
    }

    this.setState({ ...this.state, filtering: { ...this.state.filtering, permissions } });
  }

  selectRow(proofreader: ProofreaderDetails) {
    this.setState({ selectedProofreader: proofreader });
  }

  unselectRow() {
    this.setState({ selectedProofreader: null });
  }

  selected() {
    return this.state.selectedProofreader != null ? this.state.selectedProofreader.id.toString() : '';
  }

  createMode(mode: boolean) {
    this.setState({ createMode: mode });
    mode
      ? this.setState({
          selectedProofreader: {
            id: -1,
            name: '',
            email: '',
            timeZone: 'UTC',
            status: ProofreaderStatus.Away,
            onboardingStatus: OnboardingStatus.Testing,
            blocked: false,
            inTestGroup: false,
            inGrade: false,

            permissions: [],
            cohorts: [],
            suitableForVoting: [],

            welcomeEmailSent: false,
            lastSuccessfulLogin: null
          }
        })
      : this.setState({ selectedProofreader: null });
  }

  inCreateMode(): boolean {
    return this.state.createMode;
  }

  updateProofreader(key: string, value: any) {
    if (this.state.selectedProofreader) {
      this.setState({ selectedProofreader: { ...this.state.selectedProofreader, [key]: value } });
    }
  }

  updatePermission(key: string, value: boolean) {
    if (this.state.selectedProofreader) {
      const otherPermissions = this.state.selectedProofreader.permissions.filter(p => p !== key);

      const permissions = value ? [...otherPermissions, key] : otherPermissions;

      this.setState({ selectedProofreader: { ...this.state.selectedProofreader, permissions } });
    }
  }

  create() {
    if (this.state.selectedProofreader) {
      this.props.createProofreader(this.state.selectedProofreader);
    }
  }

  update() {
    if (this.state.selectedProofreader) {
      this.props.updateProofreader(this.state.selectedProofreader);
    }
  }

  sendWelcomeEmail(id: number) {
    this.props.sendWelcomeEmail(id);
  }

  downloadCsvReport() {
    this.props.downloadCsvReport(this.state.filtering);
  }

  filterProofreaders(list: ProofreaderDetails[], filter: ProofreadersFiltering): ProofreaderDetails[] {
    return list
      .filter(proofreader => (!filter.id ? true : proofreader.id == filter.id))
      .filter(proofreader => (!filter.name ? true : proofreader.name.toLowerCase().includes(filter.name.toLowerCase())))
      .filter(proofreader =>
        !filter.email ? true : proofreader.email.toLowerCase().includes(filter.email.toLowerCase())
      )
      .filter(proofreader =>
        !filter.onboardingStatus ? true : proofreader.onboardingStatus === filter.onboardingStatus
      )
      .filter(proofreader => (!filter.status ? true : proofreader.status === filter.status))
      .filter(proofreader =>
        filter.blocked === 'all' ? true : filter.blocked === 'true' ? proofreader.blocked : !proofreader.blocked
      )
      .filter(proofreader =>
        filter.inTestGroup === 'all'
          ? true
          : filter.inTestGroup === 'true'
          ? proofreader.inTestGroup
          : !proofreader.inTestGroup
      )
      .filter(proofreader =>
        Object.keys(filter.permissions).every(perm => hasPermission(proofreader, perm, filter.permissions[perm]))
      );
  }
}
interface State {
  selectedProofreader: ProofreaderDetails | null;
  filtering: ProofreadersFiltering;
  createMode: boolean;
}

interface Props {
  loading: boolean;
  proofreaders: ProofreaderDetails[];
  permissions: Permission[];
}

interface Dispatches {
  load: () => void;
  updateProofreader: (proofreader: ProofreaderDetails) => void;
  createProofreader: (proofreader: ProofreaderDetails) => void;
  sendWelcomeEmail: (id: number) => void;
  downloadCsvReport: (filter: ProofreadersFiltering) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  load: () => {
    dispatch(proofreaderListActions.startLoading());
  },
  updateProofreader: (proofreader: ProofreaderDetails) => {
    dispatch(proofreaderListActions.updateProofreader(proofreader));
  },
  createProofreader: (proofreader: ProofreaderDetails) => {
    dispatch(proofreaderListActions.createProofreader(proofreader));
  },
  sendWelcomeEmail: (id: number) => {
    dispatch(proofreaderListActions.welcomeEmail(id));
  },
  downloadCsvReport: (filter: ProofreadersFiltering) => {
    dispatch(downloadReport(`${appConfig.api.proofreaders.export}?${stringifyFilteringParams(filter)}`, 'report.csv'));
  }
});

export const stringifyFilteringParams = (filtering: ProofreadersFiltering): string => {
  const stringifiedValues = {
    id: filtering.id,
    name: filtering.name,
    email: filtering.email,
    status: filtering.status,
    onboardingStatus: filtering.onboardingStatus,
    blocked: filtering.blocked === 'all' ? '' : filtering.blocked,
    inTestGroup: filtering.inTestGroup === 'all' ? '' : filtering.inTestGroup
  };

  const params = Object.entries(stringifiedValues)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`);

  return params.join('&');
};

const mapStateToProps = (state: StoreInterface): Props => ({
  loading: state.proofreadersList.loading,
  proofreaders: state.proofreadersList.proofreaders,
  permissions: state.permissions.permissions
});

export default connect(mapStateToProps, mapDispatchToProps)(ProofreadersList);

import { ProofreaderRow } from '../view/store';
import { duration, intersection, ITimeInterval } from '../../../utils/types';
import { millisToHours } from '../../../utils/date';
import { display } from '../ShiftView';

const countProofreaderTimePerSlot = (proofreaders: ProofreaderRow[], timeBuckets: ITimeInterval[]): number[] => {
  const base = Array(timeBuckets.length).fill(0);

  const millis = timeBuckets.reduce((acc, bucket, index) => {
    proofreaders.forEach(p => {
      const coveredMillis = p.shifts
        .map(s => intersection(bucket, display(s)))
        .reduce((res, i) => res + duration(i), 0);

      acc[index] += coveredMillis;
    });

    return acc;
  }, base);

  return millis.map(millisToHours);
};

export default (proofreaders: ProofreaderRow[], timeBuckets: ITimeInterval[]): number[] =>
  countProofreaderTimePerSlot(proofreaders, timeBuckets);

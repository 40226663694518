import React from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  label: string;
  initial?: string;
  required?: boolean;
  onChange?: (value: string) => void;
  error?: (value: string) => boolean;
  textarea?: boolean;
}

export default (props: Props) => {
  const [value, setValue] = React.useState<string>(props.initial === undefined ? '' : props.initial);
  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
  };
  return (
    <TextField
      value={value}
      error={props.error ? props.error(value) : false}
      onChange={handleChange()}
      label={props.label}
      required={props.required}
      multiline={props.textarea}
      type="text"
      margin="none"
      fullWidth
    />
  );
};

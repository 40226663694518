export type ModeType = 'development' | 'production';
export const MODES: ReadonlyArray<ModeType> = ['development', 'production'];

export type EnvType = 'local' | 'qa' | 'preprod' | 'prod';
export const ENVS: ReadonlyArray<EnvType> = ['local', 'qa', 'preprod', 'prod'];

export interface WindowConfig {
  mode: ModeType;
  env: EnvType;
}

declare global {
  interface Window {
    balto: WindowConfig;
  }
}

window.balto = window.balto || { mode: 'production', env: 'prod' };

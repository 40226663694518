import React, { FunctionComponent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { Dispatch } from 'redux';
import Tooltip from '@material-ui/core/Tooltip';

import { PracticeDocument } from '../practice.interface';
import { StoreInterface } from '../../../redux/store/store';
import { fileNameToLetterAvatar } from '../_utils';
import { AppsActions } from '../../../redux/actions/actions';
import { practiceDocumentsActions, practiceJobsActions } from '../practice.actions';
import { ID } from '../../../utils/types';
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';

import PracticeDocsUploader from './PracticeDocsUploader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden'
    },
    header: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    mainContentWrapper: {
      display: 'flex',
      justifyContent: 'center'
    },
    progress: {
      marginTop: theme.spacing(1)
    },
    documentsList: {
      width: '100%',
      paddingBottom: 0
    },
    documentActionButtons: {
      right: theme.spacing(1)
    }
  })
);

const PracticeDocs: FunctionComponent<PracticeDocsProps> = (props: PracticeDocsProps) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDocument, setDeleteDocument] = useState<PracticeDocument | null>(null);

  const documentDeleteClickedHandler = (document: PracticeDocument) => {
    setDeleteDocument(document);
    setDeleteDialogOpen(true);
  };

  const deleteDialogClosedHandler = () => {
    setDeleteDocument(null);
    setDeleteDialogOpen(false);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Practice Documents
      </Typography>
      <Divider />

      <div className={classes.mainContentWrapper}>
        {props.loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <List className={classes.documentsList}>
            {props.documents.map((document: PracticeDocument) => {
              const labelId = `checkbox-list-label-${document.id}`;
              return (
                <ListItem key={document.id} dense button>
                  <ListItemAvatar>
                    <Avatar>{fileNameToLetterAvatar(document.fileName)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={document.fileName} />
                  <ListItemSecondaryAction className={classes.documentActionButtons}>
                    <Tooltip title="Assign to proofreader" placement="left">
                      <IconButton
                        size="small"
                        disabled={props.deleteDocumentId !== null}
                        onClick={() => props.createJob(document)}
                      >
                        <PersonPinIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete document" placement="right">
                      <IconButton
                        size="small"
                        disabled={props.deleteDocumentId !== null}
                        onClick={() => documentDeleteClickedHandler(document)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
            <ConfirmDialog
              open={deleteDialogOpen}
              question={`Sure to delete the ${deleteDocument && deleteDocument.fileName} document?`}
              confirmActionHandler={() => deleteDocument && props.delete(deleteDocument.id)}
              closeHandler={deleteDialogClosedHandler}
            />
          </List>
        )}
      </div>

      <PracticeDocsUploader />
    </Paper>
  );
};

interface Props {
  loading: boolean;
  documents: PracticeDocument[];
  deleteDocumentId: ID | null;
}

interface Dispatches {
  delete: (id: ID) => void;
  createJob: (document: PracticeDocument) => void;
}

type PracticeDocsProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface): Props => ({
  loading: state.practice.loadingDocuments,
  documents: state.practice.documents,
  deleteDocumentId: state.practice.deleteDocumentId
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  delete: (id: ID) => dispatch(practiceDocumentsActions.delete(id)),
  createJob: (document: PracticeDocument) => dispatch(practiceJobsActions.openCreateJobPopup(document))
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeDocs);

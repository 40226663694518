import React from 'react';
import { NavLink } from 'react-router-dom';
import GroupIcon from '@material-ui/icons/Group';
import Typography from '@material-ui/core/Typography';

import { AppRoute } from '../../routes';
import { SecurityGroup } from '../../modules/auth/okta';
import indexRoute from '../../modules/rootRedirect/dashboard.routes';

import SettingsPage from './components/SettingsPage';

const settingsRoutes: AppRoute = {
  path: '/settings',
  component: SettingsPage,
  parent: indexRoute,
  pathMatch: /\/settings/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-scheduleRoute">
      <NavLink to="/settings">
        <GroupIcon className="AppBreadcrumbsIcon" />
        Settings
      </NavLink>
    </Typography>
  ),
  private: true,
  securityGroups: [SecurityGroup.ProofitOps, SecurityGroup.AdvancedSupport, SecurityGroup.Support],
  exact: true
};

export default [settingsRoutes];

import React, { useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { CorpusType, prettyPrint } from '../../../model/corpus/corpusType';
import useAppSelector from '../../../hooks/useAppSelector';
import { corpusIsFinished } from '../_utils';

import CorporaTable from './CorporaTable';

const useExpansionPanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      },
      marginBottom: 0
    },
    expanded: {}
  })
);

const useExpansionSummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      marginBottom: -1,
      minHeight: 52,
      backgroundColor: theme.palette.grey['200'],
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      '&$expanded': {
        minHeight: 52,
        maxHeight: 52
      }
    },
    content: {
      '&$expanded': {
        margin: 0
      }
    },
    expanded: {}
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldTitle: {
      fontWeight: 'bold'
    },
    expansionPanelDetails: {
      padding: 0
    },
    summaryWrapper: {
      marginLeft: theme.spacing(2)
    }
  })
);

export const CorpusExpansionPanel = ({ corpusType, showInactiveQueues }: Props) => {
  const classes = useStyles();
  const expansionPanelStyles = useExpansionPanelStyles();
  const expansionSummaryStyles = useExpansionSummaryStyles();

  const isInactiveQueue = useAppSelector(
    state => !state.corpora?.corpora?.find(c => c.type === corpusType && !corpusIsFinished(c))
  );

  const [expanded, setExpanded] = useState(true);

  const panelExpandedChangedHandler = useCallback((event: React.ChangeEvent<{}>, expanded: boolean) => {
    setExpanded(expanded);
  }, []);

  if (!showInactiveQueues && isInactiveQueue) return null;

  return (
    <Accordion expanded={expanded} onChange={panelExpandedChangedHandler} classes={expansionPanelStyles}>
      <AccordionSummary expandIcon={<ExpandMore />} classes={expansionSummaryStyles}>
        <Grid container className={classes.summaryWrapper} wrap="nowrap" alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="subtitle1" className={classes.boldTitle}>
              {prettyPrint(corpusType)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.expansionPanelDetails}>
        <CorporaTable corpusType={corpusType} finishedCorporaHidden={!showInactiveQueues} />
      </AccordionDetails>
    </Accordion>
  );
};

interface Props {
  corpusType: CorpusType;
  showInactiveQueues: boolean;
}

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Grid } from '@material-ui/core';

import { actions } from '../actions';
import { AppsActions } from '../../../../redux/actions/actions';
import { StoreInterface } from '../../../../redux/store/store';
import PaddedBox from '../../../../components/layout/PaddedBox';
import TimeFrameSelector from '../../../../components/dates/TimeFrameSelector';
import { readParams } from '../utils';
import { VoterResult } from '../interface.api';

import VoterResultsTable from './VoterResultsTable';

const VoterResultsPage = (props: Props & Dispatches) => {
  const location = useLocation();
  const { from, to } = readParams(location.search);

  useEffect(() => {
    if (!props.ready) {
      props.load(from, to);
    }
  });

  const shouldDisplay = props.ready && !props.loading;

  return (
    <PaddedBox>
      <PaddedBox>
        {props.ready && (
          <TimeFrameSelector
            from={from}
            to={to}
            loading={props.loading}
            onApply={props.load}
            buttonText="Show results"
          />
        )}
      </PaddedBox>
      {shouldDisplay && props.rows.length > 0 && <VoterResultsTable rows={props.rows} />}
      {shouldDisplay && props.rows.length === 0 && (
        <Grid container justify="center">
          <h3>There is no data to display. Try selecting other dates.</h3>
        </Grid>
      )}
    </PaddedBox>
  );
};

interface Props {
  ready: boolean;
  loading: boolean;
  rows: VoterResult[];
  from: Date;
  to: Date;
}

interface Dispatches {
  load: (from: Date, to: Date) => void;
}

export default connect(
  ({ voting: { voterResults } }: StoreInterface): Props => ({
    ready: voterResults.ready,
    loading: voterResults.loading,
    rows: voterResults.voterResults,
    from: voterResults.from,
    to: voterResults.to
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    load: (from, to) => dispatch(actions.startLoading(from, to))
  })
)(VoterResultsPage);

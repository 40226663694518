import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { SnippetRow, StepInfo } from '../../../job.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    },
    title: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      textAlign: 'center',
      textDecoration: 'underline',
      position: 'relative',

      '&:hover': {
        backgroundColor: grey[100],
        color: theme.palette.text.primary,
        textDecoration: 'none'
      }
    },
    titleIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%'
    },
    details: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  })
);

const getProofreaders = (step: StepInfo): { proofreader: string; snippets: number[] }[] => {
  if (!step.snippets || !step.snippets.length) {
    return [];
  }

  const grouped: { [key: string]: number[] } = step.snippets.reduce((acc: any, item: SnippetRow, index: number) => {
    const key = item.proofreaderName || 'N/A';
    acc[key] = acc[key] || [];
    acc[key].push(index + 1);
    return acc;
  }, {});

  return Object.entries(grouped)
    .map(([proofreader, snippets]) => ({ proofreader, snippets }))
    .sort((v1, v2) => (v1.proofreader < v2.proofreader ? -1 : 1));
};

interface Props {
  realtime: boolean;
  step: StepInfo;
}

export default ({ step, realtime }: Props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title} onClick={() => setOpen(!open)}>
        <Typography variant="subtitle1">{step.stepIdPretty}</Typography>
        {open ? <ExpandLess className={classes.titleIcon} /> : <ExpandMore className={classes.titleIcon} />}
      </Box>

      {open && (
        <Box className={classes.details}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Proofreader</TableCell>
                <TableCell>{realtime ? 'Slices' : 'Snippets'}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {getProofreaders(step).map(({ proofreader, snippets }) => (
                <TableRow key={proofreader}>
                  <TableCell>{proofreader}</TableCell>
                  <TableCell>{snippets.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableHead>
              <TableRow>
                <TableCell>{realtime ? 'Slice' : 'Snippet'}</TableCell>
                <TableCell>Sentence range</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {step.snippets.map((snippet, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {snippet.firstSentence} - {snippet.lastSentence}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

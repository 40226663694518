import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import { AppsActions } from '../../../redux/actions/actions';
import { settingsActions } from '../settings.actions';
import { StoreInterface } from '../../../redux/store/store';
import { Settings, SettingType } from '../settings.interface';
import PaddedBox from '../../../components/layout/PaddedBox';
import VotingSettings from '../VotingSettings/components/VotingSettings';
import { TurnaroundOption } from '../../../model/job/turnaroundOption';

import AvailableOptionsTable from './tables/AvailabilityTable';
import PricesTable from './tables/PricesTable';
import MaxPricesTable from './tables/MaxPricesTable';
import GeneralSettingsTable from './tables/GeneralSettingsTable';
import SettingsGroup from './sections/SettingsGroup';
import SettingsSubGroup from './sections/SettingsSubGroup';

const gecOptions = [
  TurnaroundOption.Instant,
  TurnaroundOption.ThreeHours,
  TurnaroundOption.SixHours,
  TurnaroundOption.TwentyFourHours
];
const clarityOptions = [TurnaroundOption.ThreeHours, TurnaroundOption.SixHours, TurnaroundOption.TwentyFourHours];

class SettingsPage extends React.Component<Props & Dispatches> {
  componentDidMount() {
    this.props.load();
  }

  render() {
    if (this.props.loading) {
      return (
        <PaddedBox centered>
          <CircularProgress className="Progress" />
        </PaddedBox>
      );
    }
    return (
      <Container maxWidth="xl" className="PracticeWrapper">
        <Grid container spacing={2} justify="center">
          <Grid item xs={8}>
            <SettingsGroup title="General settings">
              <GeneralSettingsTable />
            </SettingsGroup>

            <SettingsGroup title='"Correctness only" turnarounds'>
              <SettingsSubGroup title="Availability">
                <AvailableOptionsTable
                  priceOptions={this.props.settings.gecOptions}
                  options={gecOptions}
                  type={SettingType.GEC}
                />
              </SettingsSubGroup>
              <SettingsSubGroup title="Daily prices">
                <PricesTable prices={this.props.settings.basicPrices} options={gecOptions} type={SettingType.GEC} />
              </SettingsSubGroup>
              <SettingsSubGroup title="Surge prices">
                <MaxPricesTable options={gecOptions} maxPrices={this.props.settings.maxPrices} />
              </SettingsSubGroup>
            </SettingsGroup>

            <SettingsGroup title='"Correctness and Clarity" turnarounds'>
              <SettingsSubGroup title="Availability">
                <AvailableOptionsTable
                  priceOptions={this.props.settings.clarityOptions}
                  options={clarityOptions}
                  type={SettingType.CLARITY}
                />
              </SettingsSubGroup>
              <SettingsSubGroup title="Daily prices">
                <PricesTable
                  prices={this.props.settings.basicClarityPrices}
                  options={clarityOptions}
                  type={SettingType.CLARITY}
                />
              </SettingsSubGroup>
            </SettingsGroup>

            <SettingsGroup title="Auto Voting Settings">
              <VotingSettings />
            </SettingsGroup>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

interface Props {
  loading: boolean;
  settings: Settings;
}

interface Dispatches {
  load: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  load: () => {
    dispatch(settingsActions.startLoading());
  }
});

const mapStateToProps = (state: StoreInterface): Props => ({
  loading: state.settings.price.loading,
  settings: state.settings.price.settings
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

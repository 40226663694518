import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import { VoterResult } from '../interface.api';
import { prettyPrint, VoteJobType, votingUnit } from '../../../../model/voting/voteJobType';

const useStyles = makeStyles(() =>
  createStyles({
    orderColumn: {
      '&:hover': {
        textDecoration: 'underline'
      },
      cursor: 'pointer',
      verticalAlign: 'bottom'
    },
    noSpaceSpan: {
      whiteSpace: 'nowrap'
    }
  })
);

interface Props {
  rows: VoterResult[];
}

const nameComparator = (r1: VoterResult, r2: VoterResult) => (r1.proofreaderName > r2.proofreaderName ? 1 : -1);

const votedSentencesComparator = (type: VoteJobType) => (r1: VoterResult, r2: VoterResult) => {
  const v1 = r1.votedSentences[type] ? r1.votedSentences[type] : -1;
  const v2 = r2.votedSentences[type] ? r2.votedSentences[type] : -1;

  return v2 - v1 || nameComparator(r1, r2);
};

const correctSentencesComparator = (type: VoteJobType) => (r1: VoterResult, r2: VoterResult) => {
  const v1 = percent(r1.votedSentences[type], r1.correctSentences[type]);
  const v2 = percent(r2.votedSentences[type], r2.correctSentences[type]);

  return v2 - v1 || nameComparator(r1, r2);
};

type Comparator = (r1: VoterResult, r2: VoterResult) => number;

const orderingTotal = (voteJobType: VoteJobType) => `${voteJobType}.total`;
const orderingCorrect = (voteJobType: VoteJobType) => `${voteJobType}.correct`;

const comparators = Object.values(VoteJobType).reduce(
  (acc, voteJobType) => {
    acc[orderingTotal(voteJobType)] = votedSentencesComparator(voteJobType);
    acc[orderingCorrect(voteJobType)] = correctSentencesComparator(voteJobType);

    return acc;
  },
  { proofreaderName: nameComparator } as Record<string, Comparator>
);

type Ordering = keyof typeof comparators;

const percent = (total: number, correct: number): number =>
  total && correct ? Math.round((correct * 100) / total) : 0;

const formatPercent = (total: number, current: number): string => {
  const v = percent(total, current);
  return v > 0 ? v + '%' : '-';
};

const VoterResultsTable = ({ rows }: Props) => {
  const classes = useStyles();

  const [ordering, setOrdering] = useState<Ordering>('proofreaderName');

  const typeColumns = useMemo(
    () =>
      Object.values(VoteJobType).map(voteJobType => ({
        type: voteJobType,
        pretty: prettyPrint(voteJobType),
        votingUnit: capitalize(votingUnit(voteJobType).plural),
        orderingTotal: orderingTotal(voteJobType),
        orderingCorrect: orderingCorrect(voteJobType)
      })),
    []
  );

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.orderColumn} rowSpan={2} onClick={() => setOrdering('proofreaderName')}>
            Proofreader&nbsp;name
            <TableSortLabel active={ordering === 'proofreaderName'} />
          </TableCell>
          {typeColumns.map(({ type, pretty }) => (
            <TableCell colSpan={2} key={type}>
              {pretty}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {typeColumns.map(({ type, votingUnit, orderingTotal, orderingCorrect }) => (
            <React.Fragment key={type}>
              <TableCell className={classes.orderColumn} onClick={() => setOrdering(orderingTotal)}>
                {votingUnit}
                <TableSortLabel active={ordering === orderingTotal} />
              </TableCell>

              <TableCell className={classes.orderColumn} onClick={() => setOrdering(orderingCorrect)}>
                Acceptable
                <TableSortLabel active={ordering === orderingCorrect} />
              </TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.sort(comparators[ordering]).map(row => (
          <TableRow key={row.proofreaderId}>
            <TableCell>{row.proofreaderName}</TableCell>

            {Object.values(VoteJobType).map(voteJobType => (
              <React.Fragment key={voteJobType}>
                <TableCell>{row.votedSentences[voteJobType] || '-'}</TableCell>
                <TableCell>
                  {formatPercent(row.votedSentences[voteJobType], row.correctSentences[voteJobType])}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default VoterResultsTable;

import { AuthStore, initAuthStore } from './store';
import { AuthActions, AuthActionType } from './actions';

export default (state = initAuthStore(), action: AuthActions): AuthStore => {
  switch (action.type) {
    case AuthActionType.StoreUser:
      return { ...state, profile: action.payload.profile };

    case AuthActionType.ResetUser:
      return { ...state, profile: undefined };

    default:
      return state;
  }
};

import moment from 'moment';

import { ProofreaderDetails } from '../../../apis/proofreaders.api';
import { VoteJobType } from '../../../model/voting/voteJobType';

import { GradeStats, VotingResults } from './results.interface.api';
import { OverallGradingResults, Row, Store } from './results.store';

/*
 * This function duplicate the one from Report and Schedule pages.
 * TODO: generalize this function
 */
export const parseParams = (queryString: string): { from: Date; to: Date } => {
  const params = new URLSearchParams(queryString);

  const to = parseDateOrDefault(params.get('to') || '', Store.defaultTo());
  const from = parseDateOrDefault(params.get('from') || '', Store.defaultFrom(to));

  return { from, to };
};

const convertGradeStats = (votingResults?: VotingResults) => {
  const total = votingResults ? votingResults.total : 0;

  const valid = votingResults ? votingResults.valid : 0;
  const validPercent = total ? Math.round((valid * 100) / total) : 0;

  return { sentences: total, validPercent };
};

const normalizeGradeStats = (grade?: GradeStats) => {
  if (!grade) return undefined;

  const accuracy = convertGradeStats(grade.accuracy);
  const completeness = convertGradeStats(grade.completeness);

  return completeness ? { accuracy, completeness } : { accuracy };
};

export const joinToRows = (proofreaders: ProofreaderDetails[], gradeStats: Record<string, GradeStats[]>): Row[] =>
  proofreaders.map(proofreader => {
    const results = Object.values(VoteJobType).reduce((acc, voteJobType) => {
      const grade = gradeStats[voteJobType]?.find(g => g.proofreaderId === proofreader.id);
      const normalized = normalizeGradeStats(grade);

      if (normalized) {
        acc[voteJobType] = normalized;
      }

      return acc;
    }, {} as OverallGradingResults);

    return {
      proofreader: proofreader,
      results
    };
  });

function parseDateOrDefault(stringDate: string, defaultValue: Date): Date {
  const parsedDate = moment.utc(stringDate, 'YYYY-MM-DD');
  return parsedDate.isValid() ? parsedDate.toDate() : defaultValue;
}

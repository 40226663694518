import { Permission } from '../../apis/permissions.interface';

export enum ActionType {
  StartLoading = 'permissions/start-loading',
  LoadingSuccess = 'permissions/loading-success'
}

type StartLoading = { type: ActionType.StartLoading };

interface LoadingSuccess {
  type: ActionType.LoadingSuccess;
  payload: {
    permissions: Permission[];
  };
}

export type Action = StartLoading | LoadingSuccess;

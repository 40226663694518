import { takeLatest, takeEvery } from 'redux-saga/effects';

import { JobsListActionType } from '../jobs-list.actions';

import applyFilter from './apply-filter.saga';
import jobAction from './job-action.saga';
import loadJobs from './load-jobs.saga';
import createJob from './create-job.saga';

export default function*() {
  yield takeLatest(JobsListActionType.StartLoading, loadJobs);
  yield takeEvery(JobsListActionType.Filtering, applyFilter);
  yield takeEvery(JobsListActionType.PerformJobAction, jobAction);
  yield takeEvery(JobsListActionType.CreateJob, createJob);
}

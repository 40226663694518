import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router';

import { AppsActions } from '../../../../redux/actions/actions';
import { resultsActions } from '../results.actions';
import TimeFrameSelector from '../../../../components/dates/TimeFrameSelector';
import { StoreInterface } from '../../../../redux/store/store';
import PaddedBox from '../../../../components/layout/PaddedBox';
import { parseParams } from '../_utils';
import CohortSwitcher from '../../../../modules/permissions/CohortSwitcher';

import VotingResultsTable from './VoteeResultsTable';

const VoteeResultsPage = (props: Props & Dispatches) => {
  const location = useLocation();

  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    if (!props.ready) {
      props.load(location.search);
    }
  });

  if (!props.ready) {
    return null;
  }

  return (
    <PaddedBox>
      <PaddedBox>
        <Grid container justify="space-between" wrap="nowrap">
          <Grid item>
            {props.from && props.to && (
              <TimeFrameSelector
                from={props.from}
                to={props.to}
                loading={props.loading}
                onApply={props.applyFilter}
                buttonText="Build report"
              />
            )}
          </Grid>
          <Grid item>
            <CohortSwitcher onChange={setFilter} />
          </Grid>
        </Grid>
      </PaddedBox>
      {(!props.loading || props.hasData) && <VotingResultsTable selectedCohort={filter} />}
    </PaddedBox>
  );
};

interface Props {
  from?: Date;
  to?: Date;
  loading: boolean;
  ready: boolean;
  hasData: boolean;
}

interface Dispatches {
  load: (query: string) => void;
  applyFilter: (from: Date, to: Date) => void;
}

export default connect(
  (store: StoreInterface): Props => ({
    from: store.voting.results.from,
    to: store.voting.results.to,
    loading: store.voting.results.loading,
    ready: store.voting.results.ready,
    hasData: store.voting.results.rows.length > 0
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    load: (query: string) => {
      const { from, to } = parseParams(query);
      dispatch(resultsActions.startLoading(from, to));
    },
    applyFilter: (from: Date, to: Date) => dispatch(resultsActions.applyFilter(from, to))
  })
)(VoteeResultsPage);

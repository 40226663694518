import { combineReducers } from 'redux';

import { ScheduleViewActions, ScheduleViewActionType } from './view/actions';
import { ScheduleChangesActions, ScheduleChangesActionType } from './edit/actions';
import viewReducer from './view/reducer';
import editReducer from './edit/reducer';
import { Store as ViewStore } from './view/store';
import { Store as EditStore } from './edit/store';

export type ScheduleActions = ScheduleViewActions | ScheduleChangesActions;
export type ScheduleActionTypes = ScheduleViewActionType | ScheduleChangesActionType;
export type ScheduleErrorTypes = ScheduleViewActionType.LoadingFailure | ScheduleChangesActionType.SubmitError;
export type Store = { view: ViewStore; edit: EditStore };

export const scheduleReducer = combineReducers({
  view: viewReducer,
  edit: editReducer
});

import React, { CSSProperties, FunctionComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { MenuProps } from '@material-ui/core/Menu';

import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { PracticeDocument, PracticeJob, PracticeJobFiltering } from '../practice.interface';
import TableWithHeader, { Column } from '../../../components/TableWithHeader/TableWithHeader';
import { FilteringText } from '../../../components/FilteringText/FilteringText';
import { isValidId } from '../../../utils/validation';
import { practiceJobsActions } from '../practice.actions';
import { FilteringSelect, FilteringSelectValue } from '../../../components/FilteringSelect/FilteringSelect';
import { JobFileType, JobStatus } from '../../Jobs';
import JobFileDownloadIcon from '../../Jobs/JobsList/components/JobsList/JobFileDownloadIcon';
import { toJobDownloadInfo } from '../_utils';
import { OnboardingStatus, Proofreader } from '../../../apis/proofreaders.api';

import PracticeJobAssessmentBlock from './PracticeJobAssessmentBlock';

const isValidIdForFiltering = (value: string): boolean => !value || isValidId(value);

const selectValues = (base: FilteringSelectValue[]) => {
  const val: FilteringSelectValue = { name: 'All', value: '' };
  return [val].concat(base);
};

const assessmentNeeded = (job: PracticeJob): boolean =>
  job.jobStatus === JobStatus.Completed && job.proofreader.onboardingStatus === OnboardingStatus.Testing;

const cellWithButtonsStyle: CSSProperties = {
  minHeight: 36
};

const dynamicSelectProps: Partial<MenuProps> = {
  style: {
    maxHeight: 465
  }
};

export const PracticeJobsTable: FunctionComponent<PracticeJobsTableProps> = (props: PracticeJobsTableProps) => {
  const proofreaderId =
    props.filtering.proofreaderIds.length === 0 ? undefined : `${props.filtering.proofreaderIds[0]}`;
  const practiceDocId =
    props.filtering.testDocumentIds.length === 0 ? undefined : `${props.filtering.testDocumentIds[0]}`;
  const jobId = props.filtering.jobIds.length === 0 ? undefined : `${props.filtering.jobIds[0]}`;
  const jobStatus = props.filtering.jobStatuses.length === 0 ? undefined : `${props.filtering.jobStatuses[0]}`;
  const proofreadersToSelect = selectValues(props.proofreaders.map(p => ({ name: p.name, value: '' + p.id })));
  const documentsToSelect = selectValues(props.documents.map(d => ({ name: d.fileName, value: '' + d.id })));

  const filterFieldHandler = (key: keyof PracticeJobFiltering, val: string) => {
    const newVal = val === null || val === '' ? [] : [val];
    props.filter(key, newVal);
  };

  const columns: Column<PracticeJob>[] = [
    {
      id: 'proofreader',
      label: 'Proofreader',
      showTitle: false,
      format: (job: PracticeJob) => (
        <Box component="span" whiteSpace="nowrap">
          <span>{job.proofreader.name} </span>
          <Box component="span" color="grey.A200">
            ({job.proofreader.shiftStatus}, {job.proofreader.onboardingStatus})
          </Box>
        </Box>
      ),
      filter: () => (
        <FilteringSelect
          initial={proofreaderId}
          label="Proofreader"
          data={proofreadersToSelect}
          submit={value => filterFieldHandler('proofreaderIds', value)}
          menuProps={dynamicSelectProps}
        />
      )
    },
    {
      id: 'testDocumentName',
      label: 'Document',
      format: (job: PracticeJob) => <Box whiteSpace="nowrap">{job.testDocumentName}</Box>,
      filter: () => (
        <FilteringSelect
          initial={practiceDocId}
          label="Document"
          data={documentsToSelect}
          submit={value => filterFieldHandler('testDocumentIds', value)}
          menuProps={dynamicSelectProps}
        />
      )
    },
    {
      id: 'jobId',
      label: 'Job Id',
      format: (job: PracticeJob) => (
        <Grid container spacing={1} alignItems="center" style={cellWithButtonsStyle}>
          <Grid item>
            <span>{job.jobId}</span>
          </Grid>
          <Grid item>
            <JobFileDownloadIcon job={toJobDownloadInfo(job)} type={JobFileType.result} />
          </Grid>
        </Grid>
      ),
      filter: () => (
        <FilteringText
          label="Job Id"
          initial={jobId}
          isValid={isValidIdForFiltering}
          submit={value => filterFieldHandler('jobIds', value)}
        />
      )
    },
    {
      id: 'jobStatus',
      label: 'Job Status',
      showTitle: false,
      format: (job: PracticeJob) => (
        <Grid container spacing={1} alignItems="center" style={cellWithButtonsStyle}>
          <Grid item>
            <span>{job.jobStatus}</span>
          </Grid>
          <Grid item>{assessmentNeeded(job) && <PracticeJobAssessmentBlock job={job} />}</Grid>
        </Grid>
      ),
      filter: () => (
        <FilteringSelect
          initial={jobStatus}
          label="Job Status"
          data={[
            { name: 'All', value: '' },
            { name: 'Building', value: JobStatus.Building },
            { name: 'Cancelled', value: JobStatus.Cancelled },
            { name: 'Completed', value: JobStatus.Completed },
            { name: 'New', value: JobStatus.New }
          ]}
          submit={value => filterFieldHandler('jobStatuses', value)}
        />
      )
    }
  ];

  return <TableWithHeader columns={columns} data={props.jobs} />;
};

interface Props {
  jobs: PracticeJob[];
  documents: PracticeDocument[];
  filtering: PracticeJobFiltering;
  proofreaders: Proofreader[];
}

interface Dispatches {
  filter: (key: keyof PracticeJobFiltering, value: string[]) => void;
}

type PracticeJobsTableProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface): Props => ({
  jobs: state.practice.jobs,
  documents: state.practice.documents,
  filtering: state.practice.jobsFiltering,
  proofreaders: state.practice.proofreaders
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  filter: (key: keyof PracticeJobFiltering, value: string[]) => dispatch(practiceJobsActions.filterJobs(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeJobsTable);

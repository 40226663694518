import React, { DependencyList, useContext, useEffect } from 'react';

type Subscriber = () => void;

export class FormStateContextValue {
  private cancelSubscribers = [] as Subscriber[];
  private submitSubscribers = [] as Subscriber[];

  public triggerSubscribers(type: 'submit' | 'cancel') {
    const subs = type === 'submit' ? this.submitSubscribers : this.cancelSubscribers;
    subs.forEach(sub => sub());
  }

  public addSubscriber(type: 'submit' | 'cancel', subscriber: Subscriber) {
    const subs = type === 'submit' ? this.submitSubscribers : this.cancelSubscribers;

    if (!subs.includes(subscriber)) {
      subs.push(subscriber);
    }

    return subscriber;
  }

  public removeSubscriber(type: 'submit' | 'cancel', subscriber: Subscriber) {
    const subs = type === 'submit' ? this.submitSubscribers : this.cancelSubscribers;
    const index = subs.indexOf(subscriber);
    if (index > -1) {
      subs.splice(index, 1);
    }
  }

  public clean() {
    this.cancelSubscribers.length = 0;
    this.submitSubscribers.length = 0;
  }
}

export const FormStateContext = React.createContext(new FormStateContextValue());

export const useFormSubscriber = (type: 'submit' | 'cancel', subscriber: Subscriber, deps: DependencyList) => {
  const formGroupContext = useContext(FormStateContext);

  useEffect(() => {
    const sub = formGroupContext.addSubscriber(type, subscriber);
    return () => formGroupContext.removeSubscriber(type, sub);
  }, deps);
};

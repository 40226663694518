import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { hideSpinner, showSpinner } from '../App/app.actions';
import { Proofreader } from '../../apis/proofreaders.api';
import { getProofreaders } from '../../redux/saga/get-proofreaders.saga';

import { Filtering, ProofitLog } from './proofit-log.store';
import { ProofitLog as ApiProofitLog } from './proofit-log.api.interface';
import convert from './proofit-log.api.converter';
import { actions, ProofitLogActionType, ProofitLogLoadAction } from './proofit-log.actions';

const filtering2query = ({ jobId, assigneeId, taskId }: Filtering): string => {
  const params = [];
  if (jobId) {
    params.push(['jobId', jobId]);
  }
  if (assigneeId) {
    params.push(['assigneeId', assigneeId]);
  }
  if (taskId) {
    params.push(['taskId', taskId]);
  }

  return '?' + params.map(keyValue => keyValue.join('=')).join('&');
};

function* request(queryString: string) {
  try {
    const proofreaders: Proofreader[] = yield getProofreaders({ blocked: 'false' }, e =>
      actions.loadingFailed(e.message)
    );
    const logs: ApiProofitLog[] = yield call(fetch.request.bind(fetch), appConfig.api.logs + queryString);
    const proofitLogs: ProofitLog[] = logs.map(log => convert(log, proofreaders));

    yield put(actions.loadingSuccess(proofitLogs));
  } catch (error) {
    yield put(actions.loadingFailed(error.message));
  }
}

function* loadLog(action: ProofitLogLoadAction) {
  try {
    yield put(showSpinner('load-log'));

    const queryString = filtering2query(action.payload);

    yield put(push(queryString));

    yield fork(request, queryString);

    yield take([ProofitLogActionType.LoadingSuccess, ProofitLogActionType.LoadingFailed]);
  } finally {
    yield put(hideSpinner('load-log'));
  }
}

export default function*() {
  yield takeLatest(ProofitLogActionType.LoadLog, loadLog);
}

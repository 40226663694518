import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { History } from 'history';

import { appReducer } from '../../pages/App/app.reducer';
import { default as authReducer } from '../../modules/auth/reducer';
import { jobsListReducer } from '../../pages/Jobs';
import { proofitLogReducer } from '../../pages/ProofitLog/proofit-log.reducer';
import { notificationReducer } from '../../modules/notifications/notifications.reducer';
import { scheduleReducer } from '../../pages/Schedule';
import { downloadFileReducer } from '../saga/download-file.reducer';
import { proofreaderListReducer } from '../../pages/Proofreader';
import { votingReducer } from '../../pages/PeerVotes';
import { settingsReducer } from '../../pages/Settings';
import { onshiftProofreadersReducer } from '../../pages/Dashboard/onshift-proofreaders.reducer';
import { practiceReducer } from '../../pages/Practice';
import { uploadFileReducer } from '../saga/upload-file.reducer';
import { corporaReducer } from '../../pages/Corpora';
import permissionsReducer from '../../modules/permissions/reducer';

export const createRootReducer = (history: History) => {
  const rootReducer = {
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
    notifications: notificationReducer,
    voting: votingReducer,
    jobsList: jobsListReducer,
    proofitLog: proofitLogReducer,
    permissions: permissionsReducer,
    schedule: scheduleReducer,
    practice: practiceReducer,
    corpora: corporaReducer,
    downloadFile: downloadFileReducer,
    uploadFile: uploadFileReducer,
    proofreadersList: proofreaderListReducer,
    settings: settingsReducer,
    onshiftProofreaders: onshiftProofreadersReducer
  };

  return combineReducers(rootReducer);
};

import { Action } from 'redux';

import { AppActionType, AppActions } from '../../pages/App/app.actions';
import { AuthActionType, AuthActions } from '../../modules/auth/actions';
import { JobsListActionType, JobsListActions } from '../../pages/Jobs';
import { NotificationActionType, NotificationsActions } from '../../modules/notifications/notifications.actions';
import { DownloadFileActions, DownloadFileActionType } from '../saga/download-file.actions';
import { VotingActions, VotingActionTypes } from '../../pages/PeerVotes';
import { ProofreaderListActions, ProofreadersListActionType } from '../../pages/Proofreader';
import { ScheduleActionTypes, ScheduleActions } from '../../pages/Schedule';
import { ProofitLogActions, ProofitLogActionType } from '../../pages/ProofitLog/proofit-log.actions';
import { Action as SettingsActions, ActionType as SettingsActionType } from '../../pages/Settings';
import {
  OnshiftProofreadersActions,
  OnshiftProofreadersActionType
} from '../../pages/Dashboard/onshift-proofreaders.actions';
import { PracticeActions, PracticeActionType } from '../../pages/Practice';
import { UploadFileActions, UploadFileAction } from '../saga/upload-file.actions';
import { CorporaActions, CorporaActionType } from '../../pages/Corpora';

import { ReduxActions, ReduxActionType } from './redux.actions';

export type AppsActionsTypes =
  | ReduxActionType
  | AuthActionType
  | AppActionType
  | JobsListActionType
  | ProofitLogActionType
  | PracticeActionType
  | CorporaActionType
  | ScheduleActionTypes
  | NotificationActionType
  | DownloadFileActionType
  | UploadFileAction
  | VotingActionTypes
  | SettingsActionType
  | OnshiftProofreadersActionType
  | ProofreadersListActionType;

export type AppsActions =
  | ReduxActions
  | AuthActions
  | AppActions
  | JobsListActions
  | ProofitLogActions
  | NotificationsActions
  | ScheduleActions
  | PracticeActions
  | CorporaActions
  | DownloadFileActions
  | UploadFileActions
  | VotingActions
  | SettingsActions
  | OnshiftProofreadersActions
  | ProofreaderListActions;

/**
 * A human-friendly standard for Flux action objects.
 * https://github.com/redux-utilities/flux-standard-action
 */
export interface FluxActionInterface extends Action {
  type: AppsActionsTypes;
  payload?: any;
  meta?: any;
  error?: boolean;
}

export interface ErrorAction extends FluxActionInterface {
  payload: Error;
  error: true;
}

export const isErrorAction = (action: AppsActions): boolean => {
  const untypedAction = action as any;
  return untypedAction.error === true;
};

export interface SuccessAction extends Action {
  message: string;
  success: true;
}

export const isSuccessAction = (action: AppsActions): boolean => {
  const untypedAction = action as any;
  return untypedAction.success === true;
};

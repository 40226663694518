import { Reducer } from 'redux';

import { LoadingStatusEnum } from '../../utils/enums';

import { INIT_DOWNLOAD_FILE_STORE, DownloadFileStoreInterface } from './download-file.store';
import { DownloadFileActions, DownloadFileActionType } from './download-file.actions';

export type DownloadFileReducer = Reducer<DownloadFileStoreInterface, DownloadFileActions>;
export const downloadFileReducer: DownloadFileReducer = (state = INIT_DOWNLOAD_FILE_STORE, action) => {
  switch (action.type) {
    case DownloadFileActionType.StartLoading:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Loading };

    case DownloadFileActionType.LoadingSuccess:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Success };

    case DownloadFileActionType.LoadingFailed:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Failed };

    case DownloadFileActionType.StopLoading:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Stoped };

    default:
      return state;
  }
};

import moment from 'moment';

type Result = { from: Date; to: Date };

// TODO: this looks like a generic function. Consider moving this upper in the scope.
export default (queryString?: string): Result => {
  queryString = queryString || '';

  const params = new URLSearchParams(queryString);

  const fromParam = params.get('from');
  const toParam = params.get('to');

  const resultTo = toParam ? moment.utc(toParam, 'YYYY-MM-DD') : moment.utc(Date.now()).startOf('day');

  const defaultFrom = resultTo.clone().subtract(2, 'weeks');

  let resultFrom = !!fromParam ? moment.utc(fromParam, 'YYYY-MM-DD') : defaultFrom;

  if (resultFrom.isAfter(resultTo)) {
    resultFrom = defaultFrom;
  }

  return { from: resultFrom.toDate(), to: resultTo.toDate() };
};

import React from 'react';
import { Checkbox } from '@material-ui/core';

interface Props {
  label?: string;
  initial: boolean;
  onChange?: (value: boolean) => void;
}

export default (props: Props) => {
  const [value, setValue] = React.useState<boolean>(props.initial);
  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
    if (props.onChange) props.onChange(event.target.checked);
  };
  return <Checkbox color="primary" checked={value} onChange={handleChange()} />;
};

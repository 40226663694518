import {
  Adb,
  Build,
  Done,
  FastForward,
  FiberNew,
  NotInterested,
  Payment,
  PermIdentity,
  Person,
  Publish,
  QueryBuilder,
  School
} from '@material-ui/icons';
import React from 'react';

import { MiddlePlane, QuickPlane, SlowPlane } from '../icons';
import { JobStatus, JobSubmitter, StepStatus } from '../../../job.interface';
import { JobSource } from '../../../../../model/job/source';
import { TurnaroundOption } from '../../../../../model/job/turnaroundOption';

const ImgIcon = ({ src, alt }: { src: string; alt: string }) => (
  <img src={src} width={24} height={24} alt={alt} style={{ display: 'block' }} />
);

export default {
  jobStatus: {
    [JobStatus.Pending]: <QueryBuilder style={{ display: 'block' }} />,
    [JobStatus.PaymentFailed]: <Payment style={{ display: 'block' }} />,
    [JobStatus.Building]: <QueryBuilder style={{ display: 'block' }} />,
    [JobStatus.BuildFailed]: <Build style={{ display: 'block' }} />,
    [JobStatus.New]: <FiberNew style={{ display: 'block' }} />,
    [JobStatus.Accepted]: <PermIdentity style={{ display: 'block' }} />,
    [JobStatus.Completed]: <Done style={{ display: 'block' }} />,
    [JobStatus.Cancelled]: <NotInterested style={{ display: 'block' }} />
  },
  jobSource: {
    [JobSource.Denali_editor]: <ImgIcon src="/images/peak-48.png" alt={JobSource.Denali_editor} />,
    [JobSource.OfficeAddIn]: <ImgIcon src="/images/microsoft.png" alt={JobSource.OfficeAddIn} />,
    [JobSource.Admin]: <ImgIcon src="/images/dog-48.png" alt={JobSource.Admin} />,
    [JobSource.Gmail]: <ImgIcon src="/images/gmail-48.png" alt={JobSource.Gmail} />,
    [JobSource.CorpusUploader]: <Publish style={{ display: 'block' }} />
  },
  internalUser: '/images/icon-grammarly.svg',
  jobSubmitter: {
    [JobSubmitter.Corpus]: <Adb style={{ display: 'block' }} />,
    [JobSubmitter.TestRun]: <School style={{ display: 'block' }} />,
    [JobSubmitter.User]: <Person style={{ display: 'block' }} />
  },
  turnaround: {
    [TurnaroundOption.Realtime]: QuickPlane(),
    [TurnaroundOption.Instant]: QuickPlane(),
    [TurnaroundOption.ThreeHours]: QuickPlane(),
    [TurnaroundOption.SixHours]: MiddlePlane(),
    [TurnaroundOption.TwentyFourHours]: SlowPlane(),
    [TurnaroundOption.TwoDays]: SlowPlane(),
    [TurnaroundOption.ThreeDays]: SlowPlane()
  },
  stepStatus: {
    [StepStatus.Queued]: <FiberNew style={{ display: 'block' }} />,
    [StepStatus.Running]: <PermIdentity style={{ display: 'block' }} />,
    [StepStatus.Success]: <Done style={{ display: 'block' }} />,
    [StepStatus.Shutdown]: <NotInterested style={{ display: 'block' }} />,
    [StepStatus.Failed]: <NotInterested style={{ display: 'block' }} />,
    [StepStatus.UpstreamFailed]: <NotInterested style={{ display: 'block' }} />,
    [StepStatus.Skipped]: <FastForward style={{ display: 'block' }} />
  }
};

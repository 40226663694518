import { call, cancel, fork, put, take, takeLatest } from '@redux-saga/core/effects';

import appConfig from '../../config';
import fetch from '../../utils/fetch';
import { downloadReport } from '../../redux/saga/download-file.actions';

import {
  clarityReportActions,
  ClarityReportDownloadAction,
  CorporaActionType,
  CorporaSwapRanksAction,
  corpusActions,
  corpusQueuesStatsActions,
  CreateCorpusAction,
  ModifyCorpusAction
} from './corpora.actions';
import { CorpusDto } from './corpora.api.interface';
import { toCorpus } from './_utils';
import { Corpus, CorpusCreationInfo, CorpusModificationOperation, QueuesStats } from './corpora.interface';

function* requestAllCorpora() {
  try {
    const url = `${appConfig.api.corpora.basic}`;
    const response: CorpusDto[] = yield call(fetch.request.bind(fetch), url);
    yield put(corpusActions.loadingSuccess(response.map(toCorpus)));
  } catch (error) {
    yield put(corpusActions.loadingFailure(error.message));
  }
}

function* requestAllClarityReports() {
  try {
    const url = `${appConfig.api.clarityReports.list}`;
    const response: string[] = yield call(fetch.request.bind(fetch), url);
    yield put(clarityReportActions.clarityReportsLoadSuccess(response));
  } catch (error) {
    yield put(clarityReportActions.clarityReportsLoadFailure(error.message));
  }
}

function* requestAllClarityReportsAsync() {
  const req = yield fork(requestAllClarityReports);
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.ClarityReportsLoadingSuccess,
    CorporaActionType.ClarityReportsLoadingFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(req);
  }
}

function* corporaStartLoadingAsync() {
  const corporaGetAllTask = yield fork(requestAllCorpora);
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.CorporaLoadingSuccess,
    CorporaActionType.CorporaLoadingFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(corporaGetAllTask);
  }
}

function* requestCreateCorpus(corpus: CorpusCreationInfo) {
  try {
    const url = `${appConfig.api.corpora.basic}`;

    const config: RequestInit = {
      method: 'POST',
      body: JSON.stringify(corpus)
    };

    const response: CorpusDto = yield call(fetch.request.bind(fetch), url, config, 'application/json');
    yield put(corpusActions.creationSuccess(toCorpus(response)));
  } catch (error) {
    yield put(corpusActions.creationFailure(error.message));
  }
}

function* corporaStartCreationAsync(action: CreateCorpusAction) {
  const createCorpusTask = yield fork(requestCreateCorpus, action.payload.corpus);
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.CorpusCreationSuccess,
    CorporaActionType.CorpusCreationFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(createCorpusTask);
  }
}

function* requestPauseCorpus(corpus: Corpus) {
  try {
    const url = `${appConfig.api.corpora.basic}/${corpus.id}/pause`;
    const config: RequestInit = {
      method: 'POST'
    };
    const response: CorpusDto = yield call(fetch.request.bind(fetch), url, config);
    yield put(corpusActions.modificationSuccess(toCorpus(response), CorpusModificationOperation.PAUSE));
  } catch (error) {
    yield put(corpusActions.modificationFailure(error.message));
  }
}

function* requestResumeCorpus(corpus: Corpus) {
  try {
    const url = `${appConfig.api.corpora.basic}/${corpus.id}/resume`;
    const config: RequestInit = {
      method: 'POST'
    };
    const response: CorpusDto = yield call(fetch.request.bind(fetch), url, config);
    yield put(corpusActions.modificationSuccess(toCorpus(response), CorpusModificationOperation.RESUME));
  } catch (error) {
    yield put(corpusActions.modificationFailure(error.message));
  }
}

function* requestRemoveCorpus(corpus: Corpus) {
  try {
    const url = `${appConfig.api.corpora.basic}/${corpus.id}`;
    const config: RequestInit = {
      method: 'DELETE'
    };
    const response: CorpusDto = yield call(fetch.request.bind(fetch), url, config);
    yield put(corpusActions.modificationSuccess(toCorpus(response), CorpusModificationOperation.REMOVE));
  } catch (error) {
    yield put(corpusActions.modificationFailure(error.message));
  }
}

function* requestEditCorpusLabel(corpus: Corpus) {
  try {
    const url =
      !corpus.label || corpus.label === ''
        ? `${appConfig.api.corpora.basic}/${corpus.id}/label`
        : `${appConfig.api.corpora.basic}/${corpus.id}/label?value=${encodeURIComponent(corpus.label)}`;
    const config: RequestInit = {
      method: 'POST'
    };
    const response: CorpusDto = yield call(fetch.request.bind(fetch), url, config);
    yield put(corpusActions.modificationSuccess(toCorpus(response), CorpusModificationOperation.EDIT_LABEL));
  } catch (error) {
    yield put(corpusActions.modificationFailure(error.message));
  }
}

function* corporaStartModificationAsync(action: ModifyCorpusAction) {
  let modificationTask;
  switch (action.payload.operation) {
    case CorpusModificationOperation.PAUSE:
      modificationTask = yield fork(requestPauseCorpus, action.payload.corpus);
      break;
    case CorpusModificationOperation.RESUME:
      modificationTask = yield fork(requestResumeCorpus, action.payload.corpus);
      break;
    case CorpusModificationOperation.REMOVE:
      modificationTask = yield fork(requestRemoveCorpus, action.payload.corpus);
      break;
    case CorpusModificationOperation.EDIT_LABEL:
      modificationTask = yield fork(requestEditCorpusLabel, action.payload.corpus);
      break;
  }
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.CorpusModificationSuccess,
    CorporaActionType.CorpusModificationFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(modificationTask);
  }
}

function* requestCorporaSwapRanks(first: Corpus, second: Corpus) {
  try {
    const url =
      `${appConfig.api.corpora.basic}/swap-ranks` +
      `?firstId=${encodeURIComponent(first.id)}&secondId=${encodeURIComponent(second.id)}`;
    const config: RequestInit = {
      method: 'POST'
    };
    const response: CorpusDto[] = yield call(fetch.request.bind(fetch), url, config);
    if (response.length !== 2) {
      yield put(corpusActions.swapRanksFailure('Response format is wrong'));
    } else {
      yield put(corpusActions.swapRanksSuccess(toCorpus(response[0]), toCorpus(response[1])));
    }
  } catch (error) {
    yield put(corpusActions.swapRanksFailure(error.message));
  }
}

function* corporaStartSwappingRanksAsync(action: CorporaSwapRanksAction) {
  const swappingTask = yield fork(requestCorporaSwapRanks, action.payload.first, action.payload.second);
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.SwapRanksSuccess,
    CorporaActionType.SwapRanksFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(swappingTask);
  }
}

function* requestCorpusQueuesStats() {
  try {
    const url = `${appConfig.api.corpora.stats}`;
    const response: QueuesStats = yield call(fetch.request.bind(fetch), url);
    yield put(corpusQueuesStatsActions.corpusQueuesStatsLoadSuccess(response));
  } catch (error) {
    yield put(corpusQueuesStatsActions.corpusQueuesStatsLoadFailure(error.message));
  }
}

function* corpusQueuesStatsLoadingAsync() {
  const task = yield fork(requestCorpusQueuesStats);
  const actionTask = yield take([
    CorporaActionType.StopLoading,
    CorporaActionType.CorpusQueuesStatsLoadingSuccess,
    CorporaActionType.CorpusQueuesStatsLoadingFailure
  ]);
  if (actionTask.type === CorporaActionType.StopLoading) {
    yield cancel(task);
  }
}

function* clarityReportDownload(action: ClarityReportDownloadAction) {
  yield put(
    downloadReport(
      appConfig.api.clarityReports.download.replace('{reportDate}', action.payload.reportDate),
      `clarity-data-${action.payload.reportDate}.csv`
    )
  );
}

export default function* watchCorporaRootSaga() {
  yield takeLatest(CorporaActionType.StartLoading, corporaStartLoadingAsync);
  yield takeLatest(CorporaActionType.StartLoading, corpusQueuesStatsLoadingAsync);
  yield takeLatest(CorporaActionType.ModifyCorpus, corporaStartModificationAsync);
  yield takeLatest(CorporaActionType.CreateCorpus, corporaStartCreationAsync);
  yield takeLatest(CorporaActionType.SwapRanks, corporaStartSwappingRanksAsync);
  yield takeLatest(CorporaActionType.ClarityReportsLoading, requestAllClarityReportsAsync);
  yield takeLatest(CorporaActionType.ClarityReportDownload, clarityReportDownload);
}

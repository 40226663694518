export enum SecurityGroup {
  ProofitOps = 'ACL:Proofit:GEN2:ProofitOps',
  AdvancedSupport = 'ACL:Proofit:GEN2:AdvancedSupportMember',
  Support = 'ACL:Proofit:GEN2:SupportMember',
  Scheduler = 'ACL:Proofit:GEN2:Scheduler',
  AL = 'ACL:Proofit:GEN2:AL',
  Proofreader = 'ACL:Proofit:GEN2:Proofreader'
}

export interface AuthProfile {
  name: string;
  email: string;
  groups: SecurityGroup[];
}

export const string2Group = (s: string): SecurityGroup | undefined => {
  switch (s) {
    case SecurityGroup.ProofitOps:
      return SecurityGroup.ProofitOps;
    case SecurityGroup.AdvancedSupport:
      return SecurityGroup.AdvancedSupport;
    case SecurityGroup.Support:
      return SecurityGroup.Support;
    case SecurityGroup.Scheduler:
      return SecurityGroup.Scheduler;
    case SecurityGroup.AL:
      return SecurityGroup.AL;
    case SecurityGroup.Proofreader:
      return SecurityGroup.Proofreader;
    default:
      return undefined;
  }
};

type Token = string;
interface Profile1 {
  firstName: string;
  lastName: string;
  locale: string;
  login: string;
  timeZone: string;
}

export interface AuthenticationResponse {
  session: {
    setCookieAndRedirect: () => void; // ?
    token: Token;
  };
  status: 'SUCCESS' | 'FORGOT_PASSWORD_EMAIL_SENT' | 'UNLOCK_ACCOUNT_EMAIL_SENT' | 'SESSION_STEP_UP';
  type: 'SESSION_SSO';
  user: {
    id: string;
    passwordChanged: string;
    profile: Profile1;
  };
}

import { Reducer } from 'redux';

import { LoadingStatusEnum } from '../../../utils/enums';

import { INIT_JOBS_LIST_STORE, JobsListStoreInterface } from './jobs-list.store';
import { JobsListActions, JobsListActionType } from './jobs-list.actions';

export type JobsListReducer = Reducer<JobsListStoreInterface, JobsListActions>;
export const jobsListReducer: JobsListReducer = (state = INIT_JOBS_LIST_STORE, action) => {
  switch (action.type) {
    case JobsListActionType.StartLoading:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Loading };

    case JobsListActionType.LoadingSuccess:
      // Remove duplicates by ID
      const list = state.list.concat(action.payload.list.filter(job => !state.list.find(j => j.id === job.id)));
      return { ...state, list, lastLoadingStatus: LoadingStatusEnum.Success };

    case JobsListActionType.LoadingFailed:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Failed };

    case JobsListActionType.StopLoading:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Stoped };

    case JobsListActionType.Clear:
      return { ...state, list: [], openStepInfo: undefined };

    case JobsListActionType.InitFiltering:
      return { ...state, filtering: action.payload };

    case JobsListActionType.Filtering:
      return { ...state, filtering: { ...state.filtering, [action.payload.key]: action.payload.value } };

    case JobsListActionType.ToggleStepsInfo:
      const shouldClose = action.payload.job.id === state.openStepInfo;
      return { ...state, openStepInfo: shouldClose ? undefined : action.payload.job.id };

    case JobsListActionType.ShowChangeJobAlert:
      return { ...state, popupWindow: { type: 'alert', job: action.payload.job, action: action.payload.action } };

    case JobsListActionType.HideChangeJobAlert:
      return { ...state, popupWindow: undefined };

    case JobsListActionType.ShowInfoPopup:
      return { ...state, popupWindow: { type: 'info', job: action.payload.job } };

    case JobsListActionType.HideInfoPopup:
      return { ...state, popupWindow: undefined };

    case JobsListActionType.ShowCreateJobPopup:
      return { ...state, popupWindow: { type: 'create' } };

    case JobsListActionType.HideCreateJobPopup:
      return { ...state, popupWindow: undefined };

    case JobsListActionType.CreateJob:
      return { ...state, popupWindow: { type: 'create' }, lastLoadingStatus: LoadingStatusEnum.Loading };

    case JobsListActionType.CreateJobSuccess:
      return { ...state, popupWindow: undefined, lastLoadingStatus: LoadingStatusEnum.Success };

    case JobsListActionType.CreateJobFailed:
      return { ...state, popupWindow: undefined, lastLoadingStatus: LoadingStatusEnum.Failed };

    case JobsListActionType.ShowRevisedResultPopup:
      return {
        ...state,
        popupWindow: {
          type: 'revised',
          jobId: action.payload.jobId,
          message: action.payload.message,
          error: action.payload.error
        }
      };

    case JobsListActionType.HideRevisedResultPopup:
      return { ...state, popupWindow: undefined };

    case JobsListActionType.PerformJobActionSuccess: {
      let index = state.list.findIndex(j => j.id === action.payload.job.id);
      if (index === -1) {
        return { ...state, list: [action.payload.job, ...state.list] };
      } else {
        const updatedJobs = state.list.map(job => (job.id === action.payload.job.id ? action.payload.job : job));
        return { ...state, list: updatedJobs };
      }
    }

    default:
      return state;
  }
};

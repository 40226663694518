import React, { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';

import { actions } from '../actions';
import { StoreInterface } from '../../../../redux/store/store';
import { prettyPrint } from '../../../../model/voting/voteJobType';
import { VotingSettings } from '../store';
import { useFormSubscriber } from '../../components/sections/FormStateContext';
import { FormModifiedContext } from '../../components/sections/FormModifiedContext';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      appearance: 'textfield',
      textAlign: 'right',
      width: 60
    },
    percentInput: {
      paddingRight: '.5em'
    },
    textField: {
      padding: 0
    }
  })
);

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.load());
  }, []);

  const settings = useSelector((store: StoreInterface) => store.settings.voting.settings);

  const { setModified } = useContext(FormModifiedContext);

  const onChangeVotingRate = useCallback(
    (setting: VotingSettings) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value;
      const normalizedValue = Math.min(Math.max(value, 0), 100);

      dispatch(actions.setVotingRate(setting.type, normalizedValue));
      setModified(true);
    },
    [dispatch]
  );

  const onChangeRequiredVotes = useCallback(
    (setting: VotingSettings) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value;
      const normalizedValue = Math.max(value, 0);

      dispatch(actions.setRequiredVotes(setting.type, normalizedValue));
      setModified(true);
    },
    [dispatch]
  );

  useFormSubscriber('cancel', () => dispatch(actions.resetUpdate()), [dispatch]);
  useFormSubscriber('submit', () => dispatch(actions.commitUpdate()), [dispatch]);

  if (!settings || settings.length === 0) {
    return null;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align="center">Voting rate</TableCell>
          <TableCell align="center">Required votes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {settings.map(setting => (
          <TableRow key={setting.type}>
            <TableCell>{prettyPrint(setting.type)}</TableCell>
            <TableCell align="center">
              <TextField
                type="number"
                onChange={onChangeVotingRate(setting)}
                value={setting.votingRatePercent.updatedValue}
                InputProps={{
                  inputProps: { className: clsx([classes.input, classes.percentInput]), min: 0, max: 100 },
                  endAdornment: <span>%</span>
                }}
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                type="number"
                onChange={onChangeRequiredVotes(setting)}
                value={setting.requiredVotes.updatedValue}
                InputProps={{ inputProps: { className: classes.input, min: 0 } }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

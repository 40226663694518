import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';

import { ProofitLog, Filtering } from './proofit-log.store';

export enum ProofitLogActionType {
  LoadLog = 'proofit-log/load-log',
  LoadingSuccess = 'proofit-log/loading-success',
  LoadingFailed = 'proofit-log/loading-failed',
  Reset = 'proofit-log/reset'
}

export interface ProofitLogLoadAction extends Action {
  type: ProofitLogActionType.LoadLog;
  payload: Filtering;
}

export interface ProofitLogLoadingSuccessAction extends Action {
  type: ProofitLogActionType.LoadingSuccess;
  payload: {
    logs: ProofitLog[];
  };
}

export interface ProofitLogLoadingFailedAction extends ErrorAction {
  type: ProofitLogActionType.LoadingFailed;
}

export interface ProofitLogResetAction extends Action {
  type: ProofitLogActionType.Reset;
}

export const actions = {
  loadLog: (params: Filtering): ProofitLogLoadAction => ({
    type: ProofitLogActionType.LoadLog,
    payload: params
  }),

  loadingSuccess: (logs: ProofitLog[]): ProofitLogLoadingSuccessAction => ({
    type: ProofitLogActionType.LoadingSuccess,
    payload: { logs }
  }),

  loadingFailed: (message: string): ProofitLogLoadingFailedAction => ({
    type: ProofitLogActionType.LoadingFailed,
    payload: new Error(`Failed loading proofit log: ${message}`),
    error: true
  }),
  reset: (): ProofitLogResetAction => ({
    type: ProofitLogActionType.Reset
  })
};

export type ProofitLogActions =
  | ProofitLogLoadAction
  | ProofitLogLoadingSuccessAction
  | ProofitLogLoadingFailedAction
  | ProofitLogResetAction;

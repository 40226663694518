import { ID } from '../../utils/types';
import { Guideline, JobType } from '../../model/job/jobType';
import { TurnaroundOption } from '../../model/job/turnaroundOption';
import { JobSource } from '../../model/job/source';

export enum JobStatus {
  Pending = 'Pending', // Not created completely
  PaymentFailed = 'PaymentFailed', // Payment failed
  Building = 'Building', // Creating snippets
  BuildFailed = 'BuildFailed', // Unable to create snippets
  New = 'New',
  Accepted = 'Accepted',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export enum JobAction {
  resend = 'resend',
  complete = 'complete',
  cancel = 'cancel'
}

export enum JobFileType {
  original = 'original',
  annotated = 'annotated',
  result = 'result'
}

export enum StepLabel {
  GEC = 'GEC',
  GEC2 = 'GEC 2',
  Clarity = 'Clarity',
  Other = 'Other'
}

export enum StepType {
  SCRIPT = 'SCRIPT',
  EDIT = 'EDIT',
  VOTE = 'VOTE',
  ANNOTATE = 'ANNOTATE',
  TEST = 'TEST'
}

export enum StepStatus {
  Queued = 'Queued',
  Running = 'Running',
  Success = 'Success',
  Shutdown = 'Shutdown',
  Failed = 'Failed',
  UpstreamFailed = 'UpstreamFailed',
  Skipped = 'Skipped'
}

// Re-use JobType data structure from WrEx. The one that
// is split into guideline/urgency/ownership parts.
export enum JobSubmitter {
  Corpus = 'Corpus',
  TestRun = 'Test run',
  User = 'User'
}

export enum UserInputsFilter {
  ALL = 'all',
  NO_INPUTS = 'no-inputs',
  HAS_INPUTS = 'has-inputs',
  HAS_NOTE = 'has-note'
}

export interface StepInfo {
  /* This field is unnecessary but useful when, for example, downloading step files */
  jobId: ID;

  serialNumber: number;
  label: StepLabel;
  type: StepType;
  actionType: string;
  stepIdPretty: string;
  stepId: string;
  additionalPass: boolean;
  status: StepStatus;
  due: Date | null;
  completed: Date | null;

  hasOriginal: boolean;
  hasAnnotated: boolean;
  hasResult: boolean;

  snippets: SnippetRow[];
}

export interface SnippetRow {
  firstSentence: number;
  lastSentence: number;
  proofreaderName: string;
}

export interface UserInputs {
  nativeLanguage?: string;
  dialect?: string;
  quotationType?: string;
  spaceType?: string;

  noteToEditor?: string;
}

export interface DocumentContext {
  domain?: string;
  audience?: string;
  formality?: string;
  documentDialect?: string;
  academicTextType?: string;
  academicCitationFormat?: string;
}

export interface JobDownloadInfo {
  id: ID;
  hasOriginal: boolean;
  hasResult: boolean;
}

export interface Job extends JobDownloadInfo {
  id: ID;
  uid: ID;
  docId: ID;
  source: JobSource;
  status: JobStatus;
  turnaroundOption: TurnaroundOption;
  submitted: Date | null;
  due: Date | null;
  completed: Date | null;
  price: number;
  pricePerWord: string | null;
  creditsPayment: string;
  paymentVerified: boolean;
  cancelReason: string | null;
  cancellationDetails: string | null;
  downloaded: Date | null;
  snippets: SnippetRow[];
  steps: StepInfo[];
  hasOriginal: boolean;
  hasResult: boolean;
  fromGrammarlyUser: boolean;
  userInputs: (UserInputs & DocumentContext) | null;

  jobSubmitter: JobSubmitter;
  jobType: Guideline;

  corpus: boolean;
}

export interface NewJobForm {
  jobType: JobType;
  turnaround: TurnaroundOption;
  userId: string;
  proofreaderId: string | null;
  file: File | null;
}

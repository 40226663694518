import React, { FunctionComponent } from 'react';
import {
  Box,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import { Add, Edit, Remove, Undo } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import momentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  Addition,
  ApplyMode,
  Modification,
  Operation,
  OperationType,
  Removal,
  RepeatMode,
  RepeatType
} from '../edit/store';
import { ITimeInterval } from '../../../utils/types';
import { noop } from '../../../utils/functions/noop';

const useInputStyles = makeStyles(theme =>
  createStyles({
    input: {
      width: 150
    }
  })
);

interface StartProps {
  value?: Date;
}

const StartDate = ({ value }: StartProps) => {
  const classes = useInputStyles();

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <KeyboardDatePicker
        className={classes.input}
        disableToolbar
        variant="inline"
        format="YYYY-MM-DD"
        label="From"
        disabled={true}
        value={moment.utc(value)}
        onChange={noop}
      />
    </MuiPickersUtilsProvider>
  );
};

interface UntilProps {
  disabled?: boolean;
  value?: Date;
  minValue?: Date;
  onChange?: (update: Partial<RepeatMode>) => void;
}

const UntilDate = ({ disabled, value, minValue, onChange }: UntilProps) => {
  const classes = useInputStyles();

  const changeHandler = onChange ? (v: any) => onChange({ to: v ? (v as Moment).toDate() : undefined }) : noop;

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <KeyboardDatePicker
        className={classes.input}
        disableToolbar
        variant="inline"
        format="YYYY-MM-DD"
        label="Until"
        disabled={!!disabled}
        emptyLabel="Forever"
        value={value ? moment.utc(value) : null}
        minDate={minValue}
        onChange={changeHandler}
      />
    </MuiPickersUtilsProvider>
  );
};

interface RepeatModeSelectProps {
  value: RepeatMode;
  onChange: (change: Partial<RepeatMode>) => void;
}

const RepeatModeSelect = ({ value, onChange }: RepeatModeSelectProps) => {
  const classes = useInputStyles();

  return (
    <FormControl className={classes.input}>
      <InputLabel>Repeat</InputLabel>
      <Select value={value.type} onChange={e => onChange({ type: e.target.value as any })}>
        <MenuItem value={RepeatType.Once}>Don&apos;t repeat</MenuItem>
        <MenuItem value={RepeatType.Everyday}>Everyday</MenuItem>
        <MenuItem value={RepeatType.Weekday}>On workdays</MenuItem>
        <MenuItem value={RepeatType.DayOfWeek}>Each {moment.utc(value.from).format('dddd')}</MenuItem>
      </Select>
    </FormControl>
  );
};

interface ApplyModeSelectProps {
  value: ApplyMode;
  disabled?: boolean;
  onChange: (value: ApplyMode) => void;
}

const ApplyModeSelect = ({ value, disabled, onChange }: ApplyModeSelectProps) => {
  const classes = useInputStyles();
  return (
    <FormControl className={classes.input}>
      <InputLabel>Apply to</InputLabel>
      <Select value={value} disabled={!!disabled} onChange={e => onChange(e.target.value as any)}>
        <MenuItem value={ApplyMode.SingleShift}>Single shift</MenuItem>
        <MenuItem value={ApplyMode.FutureShifts}>Future shifts</MenuItem>
        <MenuItem value={ApplyMode.AllShifts}>All shifts</MenuItem>
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    item: {
      margin: `${theme.spacing(0.5)}px`
    },
    summary: {
      fontStyle: 'italic'
    }
  })
);

const useLayoutStyles = makeStyles(theme =>
  createStyles({
    item: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    }
  })
);

interface LayoutProps<T extends Operation> {
  op: T;
  changeRepeatMode?: (updates: Partial<RepeatMode>) => void;
  changeApplyMode?: (applyTo: ApplyMode) => void;
}

const AdditionLayout = ({ op, changeRepeatMode }: LayoutProps<Addition>) => {
  changeRepeatMode = changeRepeatMode || noop;

  const classes = useLayoutStyles();

  return (
    <Grid container className={classes.item}>
      <Grid item className={classes.item}>
        <RepeatModeSelect value={op.repeatMode} onChange={changeRepeatMode} />
      </Grid>
      {op.repeatMode.type !== RepeatType.Once && (
        <Grid item className={classes.item}>
          <UntilDate onChange={changeRepeatMode} value={op.repeatMode.to} minValue={op.repeatMode.from} />
        </Grid>
      )}
    </Grid>
  );
};

const RemovalLayout = ({ op, changeApplyMode }: LayoutProps<Removal>) => {
  changeApplyMode = changeApplyMode || noop;

  const classes = useLayoutStyles();

  return (
    <Box>
      <Grid container className={classes.item}>
        <Grid item className={classes.item}>
          <ApplyModeSelect value={op.applyMode} onChange={changeApplyMode} />
        </Grid>
      </Grid>
      <Grid container className={classes.item}>
        <Grid item className={classes.item}>
          <StartDate value={op.repeatMode.from} />
        </Grid>
        <Grid item className={classes.item}>
          <UntilDate value={op.repeatMode.to} disabled={true} />
        </Grid>
      </Grid>
    </Box>
  );
};

const ModificationLayout = ({ op, changeApplyMode, changeRepeatMode }: LayoutProps<Modification>) => {
  changeApplyMode = changeApplyMode || noop;
  changeRepeatMode = changeRepeatMode || noop;

  const classes = useLayoutStyles();

  const isApplyModeDisabled = op.shift.repeat.type === RepeatType.Once;

  return (
    <Box>
      <Grid container className={classes.item}>
        <Grid item className={classes.item}>
          <RepeatModeSelect value={op.repeatMode} onChange={changeRepeatMode} />
        </Grid>
        <Grid item className={classes.item}>
          <ApplyModeSelect value={op.applyMode} disabled={isApplyModeDisabled} onChange={changeApplyMode} />
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={momentUtils}>
        <Grid container className={classes.item}>
          <Grid item className={classes.item}>
            <StartDate value={op.repeatMode.from} />
          </Grid>
          {op.applyMode !== ApplyMode.SingleShift && (
            <Grid item className={classes.item}>
              <UntilDate value={op.repeatMode.to} minValue={op.repeatMode.from} onChange={changeRepeatMode} />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

const formatInterval = ({ begin, end }: ITimeInterval): string =>
  [begin, end].map(d => moment.utc(d).format('HH:mm')).join(' - ');

const formatChange = (op: Operation): string => {
  switch (op.type) {
    case OperationType.Addition:
      return formatInterval({ begin: op.begin, end: op.begin + op.duration });
    case OperationType.Modification:
      const before = { begin: op.shift.begin, end: op.shift.end };
      const after = { begin: op.shift.begin + op.offset, end: op.shift.end + op.offset + op.durationDiff };
      return `${formatInterval(before)} => ${formatInterval(after)}`;
    case OperationType.Removal:
      return formatInterval({ begin: op.shift.begin, end: op.shift.end });
  }
};

const icons = {
  [OperationType.Addition]: <Add />,
  [OperationType.Removal]: <Remove />,
  [OperationType.Modification]: <Edit />
};

// We lose type safety on this line but it shouldn't be a problem
// since all usages of form layouts are in this file
const FormLayouts: { [key: string]: FunctionComponent<LayoutProps<any>> } = {
  [OperationType.Addition]: AdditionLayout,
  [OperationType.Removal]: RemovalLayout,
  [OperationType.Modification]: ModificationLayout
};

export default ({ op, undo, changeRepeatMode, changeApplyMode, showSummary }: Props) => {
  const classes = useStyles();

  const FormLayout = FormLayouts[op.type];

  return (
    <div>
      {showSummary && (
        <Grid container justify="space-between">
          <Grid item className={classes.item}>
            {icons[op.type]}
          </Grid>
          <Grid item className={classes.item}>
            <Grid container justify="center" className={classes.item}>
              <Grid item className={classes.summary}>
                {formatChange(op)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.item}>
            <IconButton size="small" title="Undo change" onClick={undo}>
              <Undo />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <FormLayout op={op} changeApplyMode={changeApplyMode} changeRepeatMode={changeRepeatMode} />
    </div>
  );
};

interface Props {
  showSummary: boolean;
  op: Operation;
  undo: () => void;
  changeApplyMode: (applyTo: ApplyMode) => void;
  changeRepeatMode: (updates: Partial<RepeatMode>) => void;
}

import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects';
import contentDisposition, { ContentDisposition } from 'content-disposition';

import { hideSpinner, showSpinner } from '../../pages/App/app.actions';
import fetch from '../../utils/fetch';

import {
  DownloadFileActionType,
  downloadFileLoadingFailed,
  downloadFileLoadingSuccess,
  DownloadFileStartLoadingAction
} from './download-file.actions';

function getFilenameFromResponse(response: Response) {
  const dispositionHeader: string | null = response.headers.get('content-disposition');

  if (dispositionHeader != null) {
    const parsedDisposition: ContentDisposition = contentDisposition.parse(dispositionHeader);
    const filenameFromDisposition: string = parsedDisposition.parameters['filename'];
    if (filenameFromDisposition != null) {
      return filenameFromDisposition;
    }
  }

  throw new Error('Could not read filename from content-disposition: ' + dispositionHeader);
}

export function* request(url: string, filename: string | null) {
  try {
    const response: Response = yield call(fetch.rawRequest.bind(fetch), url);

    yield put(downloadFileLoadingSuccess());

    if (response != null) {
      if (response.ok) {
        const blob: Blob = yield response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        if (filename == null) {
          a.download = getFilenameFromResponse(response);
        } else {
          a.download = filename;
        }

        a.click();
      }
    }
  } catch (error) {
    yield put(downloadFileLoadingFailed(error.message));
  }
}

export function* downloadFileStartAsync(action: DownloadFileStartLoadingAction) {
  try {
    yield put(showSpinner('download-file'));
    const task = yield fork(request, action.payload.url, action.payload.filename);
    const actionTask = yield take([
      DownloadFileActionType.StopLoading,
      DownloadFileActionType.LoadingFailed,
      DownloadFileActionType.LoadingSuccess
    ]);

    if (actionTask.type === DownloadFileActionType.StopLoading) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('download-file'));
  }
}

export default function* watchDownloadFileStartSaga() {
  yield takeLatest(DownloadFileActionType.StartLoading, downloadFileStartAsync);
}

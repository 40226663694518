import { Reducer } from 'redux';

import { AppActionType, AppActions } from './app.actions';
import { AppStoreInterface, INIT_APP_STORE } from './app.store';

export type AppReducer = Reducer<AppStoreInterface, AppActions>;
export const appReducer: AppReducer = (state = INIT_APP_STORE, action) => {
  switch (action.type) {
    case AppActionType.Initialize:
      return { ...state, initDate: action.payload.initDate };

    case AppActionType.ShowSpinner:
      return { ...state, loadings: state.loadings + 1, loading: true };

    case AppActionType.HideSpinner:
      const loadings = state.loadings - 1;
      return { ...state, loadings, loading: !!loadings };

    case AppActionType.Terminate:
      return { ...state, terminateDate: action.payload.terminateDate, loadings: 0, loading: false };

    default:
      return state;
  }
};

import { Reducer } from 'redux';

import { LoadingStatusEnum } from '../../utils/enums';

import { INIT_UPLOAD_FILE_STORE, UploadFileStoreInterface } from './upload-file.store';
import { UploadFileActions, UploadFileAction } from './upload-file.actions';

export type UploadFileReducer = Reducer<UploadFileStoreInterface, UploadFileActions>;
export const uploadFileReducer: UploadFileReducer = (state = INIT_UPLOAD_FILE_STORE, action) => {
  switch (action.type) {
    case UploadFileAction.Start:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Loading };

    case UploadFileAction.Stop:
      return { ...state, lastLoadingStatus: LoadingStatusEnum.Stoped };

    default:
      return state;
  }
};

import React from 'react';
import { Redirect } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center'
    }
  })
);

const UnauthorizedContent = () => {
  const { authState, oktaAuth } = useOktaAuth();

  return authState && authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <>
      <h2>You are not logged in</h2>
      <Button variant="contained" color="primary" onClick={() => oktaAuth.signInWithRedirect()}>
        Login via Okta
      </Button>
    </>
  );
};

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UnauthorizedContent />
    </div>
  );
};

import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Route, Switch } from 'react-router-dom';
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import AppNavbar from '../AppNavbar/AppNavbar';
import AppBreadcrumbs from '../AppBreadcrumbs/AppBreadcrumbs';
import { error404route } from '../../../../routes/errors.routes';
import { APP_ROUTES } from '../../app.routes';
import AccessDeniedError from '../../../../components/Errors/AccessDeniedError';
import Unauthorized from '../../../../modules/auth/components/Login/Unauthorized';
import { AuthStore } from '../../../../modules/auth/store';
import { StoreInterface } from '../../../../redux/store/store';

const renderRoutes = (isAuthenticated: boolean, { profile }: AuthStore) =>
  APP_ROUTES.map(route => {
    const requireAuthentication = route.private && !isAuthenticated;
    const hasPermissions =
      !route.securityGroups || route.securityGroups.filter(sg => !!profile && profile.groups.includes(sg)).length;

    const component = requireAuthentication || !hasPermissions ? AccessDeniedError : route.component;

    return route.private ? (
      <SecureRoute exact={route.exact} path={route.path} component={component} key={route.path} />
    ) : (
      <Route exact={route.exact} path={route.path} component={component} key={route.path} />
    );
  });

export const AppLayout = () => {
  const loading = useSelector((store: StoreInterface) => store.app.loading);
  const { authState } = useOktaAuth();
  const authStore = useSelector((store: StoreInterface) => store.auth);

  return (
    <div>
      <AppNavbar />
      <div className="loading">{loading && <LinearProgress color="secondary" />}</div>

      <AppBreadcrumbs />

      <Switch>
        {renderRoutes(!!authState?.isAuthenticated, authStore)}
        <Route exact path="/login" component={Unauthorized} />
        <Route exact path="/login/callback" component={LoginCallback} />
        <Route exact={error404route.exact} path={error404route.path} component={error404route.component} />
      </Switch>
    </div>
  );
};

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import { Corpus } from '../corpora.interface';
import { corpusIsFinished } from '../_utils';

const formatEstimatedTime = (corpus: Corpus) => {
  if (corpusIsFinished(corpus)) {
    return '-';
  }

  if (!corpus.estimatedEndDate) {
    return 'Not enough data';
  }

  return `${moment.utc(corpus.estimatedEndDate).format('MM/DD/YY')} (${corpus.daysRemaining} days)`;
};

const EstimatedTimeRemaining = ({ corpus, loading }: { corpus: Corpus; loading: boolean }) => {
  if (loading) {
    return <CircularProgress />;
  }

  return <>{formatEstimatedTime(corpus)}</>;
};

export default EstimatedTimeRemaining;

import { capitalize } from 'lodash';

import { assertNever } from '../../utils/errors';

export enum JobType {
  USER_GEC = 'USER_GEC',
  USER_CLARITY = 'USER_CLARITY',
  USER_REALTIME = 'USER_REALTIME',

  CORPUS_GEC = 'CORPUS_GEC',
  CORPUS_ADVANCED_GEC = 'CORPUS_ADVANCED_GEC',
  CORPUS_PURE_GEC = 'CORPUS_PURE_GEC',
  CORPUS_CLARITY = 'CORPUS_CLARITY',
  CORPUS_PARA_CLARITY = 'CORPUS_PARA_CLARITY',
  CORPUS_CLARITY_PLUS = 'CORPUS_CLARITY_PLUS',
  CORPUS_REALTIME = 'CORPUS_REALTIME',
  CORPUS_ACADEMIC_TONE = 'CORPUS_ACADEMIC_TONE',

  TEST_RUN = 'TEST_RUN'
}

export enum Guideline {
  Gec = 'GEC',
  AdvancedGec = 'Advance GEC',
  PureGec = 'Pure GEC',
  GenericClarity = 'Clarity',
  SentenceClarity = 'Sentence Clarity',
  ParaClarity = 'Para Clarity',
  ClarityPlus = 'Clarity Plus',
  Realtime = 'Realtime',
  AcademicTone = 'Academic Tone',
  TestRun = 'Test Run'
}

export const getGuideline = (jobType: JobType): Guideline => {
  switch (jobType) {
    case JobType.USER_GEC:
    case JobType.CORPUS_GEC:
      return Guideline.Gec;

    case JobType.CORPUS_ADVANCED_GEC:
      return Guideline.AdvancedGec;

    case JobType.CORPUS_PURE_GEC:
      return Guideline.PureGec;

    case JobType.USER_CLARITY:
      return Guideline.GenericClarity;

    case JobType.CORPUS_CLARITY:
      return Guideline.SentenceClarity;

    case JobType.CORPUS_PARA_CLARITY:
      return Guideline.ParaClarity;

    case JobType.CORPUS_CLARITY_PLUS:
      return Guideline.ClarityPlus;

    case JobType.CORPUS_REALTIME:
    case JobType.USER_REALTIME:
      return Guideline.Realtime;

    case JobType.CORPUS_ACADEMIC_TONE:
      return Guideline.AcademicTone;

    case JobType.TEST_RUN:
      return Guideline.TestRun;

    default:
      return assertNever(jobType);
  }
};

export type Ownership = 'test run' | 'user' | 'corpus';

export const getOwnership = (jobType: JobType): Ownership => {
  if (jobType === JobType.TEST_RUN) return 'test run';
  if (jobType.startsWith('USER_')) return 'user';
  if (jobType.startsWith('CORPUS_')) return 'corpus';

  throw new Error(`Job type of unknown ownership: ${jobType}`);
};

export const prettyPrint = (jobType: JobType) =>
  jobType === JobType.TEST_RUN
    ? getGuideline(jobType)
    : `${capitalize(getOwnership(jobType))} ${getGuideline(jobType)}`;

import { TurnaroundOption } from '../../model/job/turnaroundOption';

import { Job, JobStatus } from './job.interface';
import { JobsFiltering } from './JobsList';

export const isJobStuck = (job: Job): boolean => {
  if (
    !job.due ||
    (job.status != JobStatus.New && job.status != JobStatus.Accepted && job.status != JobStatus.Completed) ||
    job.corpus
  ) {
    return false;
  }
  return (job.completed || new Date()) > job.due;
};

export const isRealtimeJob = (job: Job): boolean => job.turnaroundOption === TurnaroundOption.Realtime;

const filtering2paramKeyMapping: Record<keyof JobsFiltering, string> = {
  id: 'jobId',
  uid: 'userId',
  jobType: 'jobType',
  inputs: 'inputs',
  option: 'turnaround',
  source: 'source',
  status: 'status',
  assigneeId: 'assigneeId'
};

export const filtering2params = (filtering: JobsFiltering): string[] =>
  Object.keys(filtering2paramKeyMapping).flatMap(key => {
    const filteringKey = key as keyof JobsFiltering;
    const paramKey = filtering2paramKeyMapping[filteringKey];
    const paramValue = filtering[filteringKey];

    return paramValue ? [`${paramKey}=${encodeURIComponent(paramValue)}`] : [];
  });

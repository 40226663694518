import { Action } from 'redux';

import appConfig from '../../config';
import { AppsActions } from '../actions/actions';
import { actions } from '../../pages/Jobs/JobsList';
import { RevisedResult } from '../../pages/Jobs/job.api.interface';

/**
 * Action enum
 */
export enum UploadFileAction {
  Start = 'upload-file/start',
  Stop = 'upload-file/stop'
}

/**
 * Action interfaces & creators
 */
export interface UploadFileStart extends Action {
  type: UploadFileAction.Start;
  payload: {
    url: string;
    file: File;
    onFailAction: (reason: string) => AppsActions;
    onSuccessAction: (response: any) => AppsActions;
  };
}

export const uploadFileStartRevisedResult = (jobId: number, file: File): UploadFileStart => {
  const url = `${appConfig.api.jobsList}/${jobId}/uploadRevisedResult`;
  return {
    type: UploadFileAction.Start,
    payload: {
      url,
      file,
      onFailAction: (reason: string) => actions.showRevisedResultPopup(jobId, reason, true),
      onSuccessAction: (response: RevisedResult) =>
        actions.showRevisedResultPopup(
          jobId,
          `${appConfig.denali.server}?downloadProofread=${response.documentId}`,
          false
        )
    }
  };
};

export interface UploadFileStop extends Action {
  type: UploadFileAction.Stop;
}

/**
 * Union type
 */
export type UploadFileActions = UploadFileStart | UploadFileStop;

import React, { PropsWithChildren } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  })
);

const SettingsSubGroup = ({ title, children }: PropsWithChildren<{ title?: string }>) => {
  const classes = useStyles();

  return (
    <>
      {title && (
        <Typography variant="h6" className={classes.header}>
          {title}
        </Typography>
      )}
      {children}
    </>
  );
};

export default SettingsSubGroup;

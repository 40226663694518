import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { capitalize, words } from 'lodash';

import { ProofreaderDetails } from '../../apis/proofreaders.api';
import { StoreInterface } from '../../redux/store/store';

import { actions } from './reducer';

interface Props {
  onChange: (value: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    filterSelector: {
      minWidth: 150,
      maxWidth: 150,
      overflow: 'ellipsis'
    }
  })
);

export const cohortFilter = (query?: string) => (proofreader: ProofreaderDetails): boolean => {
  if (!query || query === '*') return true;
  if (query === '-') return proofreader.cohorts.length === 0;

  return proofreader.cohorts.includes(query);
};

const initialFilterValue = '*';

const CohortSwitcher = ({ onChange }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const cohorts = useSelector((state: StoreInterface) => state.permissions.cohorts);
  const [activeValue, setActiveValue] = useState(initialFilterValue);

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const value = e.target.value as string;
      setActiveValue(value);
      onChange(value);
    },
    [onChange]
  );

  const options = useMemo(
    () => [
      { value: '*', label: 'All', name: 'All' },
      { value: '-', label: 'GEC only', name: 'GEC only' },
      ...cohorts.map(cohort => ({
        value: cohort,
        label: words(cohort.replaceAll('_', ' '))
          .map(capitalize)
          .join(' '),
        name: cohort
      }))
    ],
    [cohorts]
  );

  useEffect(() => {
    dispatch(actions.startLoading());
  }, []);

  return (
    <FormControl>
      <InputLabel>Filter Proofreaders</InputLabel>
      <Select onChange={handleChange} className={classes.filterSelector} defaultValue={initialFilterValue}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} selected={activeValue === option.value} title={option.name}>
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CohortSwitcher;

import moment from 'moment';

import { Scale, SelectedColumn, WEEKLY_SCALE } from '../view/store';
import { TimeInterval, Timestamp } from '../../../utils/types';

export default (scale: Scale, from: Timestamp, to: Timestamp): SelectedColumn | undefined => {
  const currentTime = Date.now();

  if (currentTime > from && currentTime < to) {
    const columnIndex = scale === WEEKLY_SCALE ? moment.utc(currentTime).day() : moment.utc(currentTime).hour();
    return new SelectedColumn(columnIndex, scale, new TimeInterval(from, to));
  }
};

import { JobType } from '../model/job/jobType';
import { str2enumStrict } from '../utils/enums/str2enum';

export interface QueueStatusRaw {
  jobType: string;
  taskCount: number;
}

export interface QueueStatus {
  jobType: JobType;
  taskCount: number;
}

export const parseQueueStatus = (raw: QueueStatusRaw): QueueStatus => ({
  jobType: str2enumStrict(JobType, raw.jobType, 'job type'),
  taskCount: raw.taskCount
});

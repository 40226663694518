import { VoteJobType } from '../../../model/voting/voteJobType';
import { str2enumStrict } from '../../../utils/enums/str2enum';

import { VotingSettings as ApiVotingSettings } from './api.interface';

interface TransactionalValue {
  value: number;
  updatedValue: number;
}

export interface VotingSettings {
  type: VoteJobType;

  votingRatePercent: TransactionalValue;

  requiredVotes: TransactionalValue;
}

export const initStore = (): VotingSettings[] => [];

export const api2Store = (api: ApiVotingSettings): VotingSettings => ({
  type: str2enumStrict(VoteJobType, api.type, 'vote job type'),

  votingRatePercent: { value: api.votingRatePercent, updatedValue: api.votingRatePercent },

  requiredVotes: { value: api.requiredVotes, updatedValue: api.requiredVotes }
});

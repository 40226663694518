import * as React from 'react';

/* FIXME: icons defined in this file don't align well with material UI's icons due to hardcoded sizes and uncommon viewbox sizes */

export const SlowPlane = () => (
  <svg width="24" height="24" viewBox="0 0 48 46" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.99 37.27l31.972 8.01a3 3 0 0 0 3.63-2.144l8.463-32.043a3 3 0 0 0-4.433-3.345L2.187 31.78a3 3 0 0 0 .803 5.49z"
        fill="#C5F7EB"
      />
      <path
        d="M3.314 26.08l9.196 2.235a1 1 0 0 1 .634.479l4.621 8.153a1 1 0 0 0 1.83-.21l1.56-5.308a1 1 0 0 1 1.21-.686l11.6 3.007a1 1 0 0 0 1.218-.712l8.12-30.675a1 1 0 0 0-1.477-1.116l-38.786 23a1 1 0 0 0 .274 1.833z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#FFF"
        strokeLinejoin="round"
      />
      <path
        d="M12.929 28.5L42.25 1.803a.5.5 0 0 1 .733.675L21.429 30.5l-1.838 6.247a1 1 0 0 1-1.827.214L12.929 28.5z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#F0F2FC"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const MiddlePlane = () => (
  <svg width="24" height="24" viewBox="0 0 59 34" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.865 15.93a13.498 13.498 0 0 1-.19-2.153c0-7.49 6.153-13.563 13.74-13.563 5.966 0 11.028 3.762 12.928 9.005 1.41-.773 3.01-1.255 4.74-1.255 4.822 0 8.813 3.444 9.638 7.97 3.473.802 6.065 3.86 6.065 7.53 0 4.28-3.516 7.75-7.852 7.75H8.638c-4.337 0-7.852-3.47-7.852-7.75 0-3.674 2.599-6.737 6.079-7.534z"
        fill="#CDDBFF"
        fillRule="nonzero"
      />
      <path
        d="M45.341 21.214l1.34 8.994a1 1 0 0 1-.518 1.03l-3.053 1.626a1 1 0 0 1-1.181-.18l-8.143-8.239"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#F0F2FC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.616 10.086A20.928 20.928 0 0 1 52.99 8.548l3.235.584c1.036.187 1.72 1.158 1.528 2.168-.06.314-.2.607-.41.852l-1.49 1.745a18.883 18.883 0 0 1-8.918 5.794l-22.534 6.786a38.953 38.953 0 0 1-9.334 1.607l-2.64.129c-.686.033-1.271-.483-1.306-1.153a1.19 1.19 0 0 1 .13-.604l.707-1.378a.91.91 0 0 0-.06-.932l-3.952-4.6a.916.916 0 0 1 .265-1.29l1.607-1.045a.975.975 0 0 1 .956-.058l7.632 2.79c.26.128-2.172 1.516-1.908 1.396l24.118-11.253z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.343 18.964L15.347 10.47a1 1 0 0 1 .077-1.752l2.732-1.342a1 1 0 0 1 .68-.074l22.776 5.595a1 1 0 0 1 .107 1.91l-11.511 4.242a1 1 0 0 1-.865-.084z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#F0F2FC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.786 9.214a17.195 17.195 0 0 1-3.796 1.927l-.204.073"
        stroke="#353847"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const QuickPlane = () => (
  <svg width="24" height="24" viewBox="0 0 42 55" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M21 29c6.684 4.124 8.984 13.275 5.136 20.439a14.624 14.624 0 0 1-4.209 4.88L21 55l-.927-.681c-6.362-4.673-7.986-13.989-3.626-20.808A14.392 14.392 0 0 1 21 29zM35 27c4.785 3.027 6.422 9.726 3.654 14.961a10.64 10.64 0 0 1-2.989 3.538L35 46l-.665-.5c-4.553-3.43-5.704-10.248-2.569-15.23A10.421 10.421 0 0 1 35 27zM7 27c4.785 3.027 6.422 9.726 3.654 14.961A10.64 10.64 0 0 1 7.665 45.5L7 46l-.665-.5C1.782 42.07.631 35.251 3.766 30.27A10.421 10.421 0 0 1 7 27z"
        fill="#FFC2C2"
      />
      <path
        d="M2.337 27.657l16-14.28a4 4 0 0 1 5.326 0l16 14.28A4 4 0 0 1 41 30.641V35H1v-4.359a4 4 0 0 1 1.337-2.984z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#F0F2FC"
        strokeLinejoin="round"
      />
      <path
        d="M15.963 4.877L18 2.5a3.951 3.951 0 0 1 6 0l2.037 2.377A4 4 0 0 1 27 7.48V39H15V7.48a4 4 0 0 1 .963-2.603z"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#FFF"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.414l.249-.366 1.02-1.5c.975-1.435 2.487-1.435 3.462 0l1.027 1.51.242.356"
        stroke="#353847"
        strokeWidth="1.5"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21 21v15" stroke="#353847" strokeWidth="1.5" strokeLinecap="round" />
    </g>
  </svg>
);

import { Action } from 'redux';

import { NotificationInterface } from './notifications.interface';

export enum NotificationActionType {
  Add = 'notifications/add',
  Close = 'notifications/close',
  CloseAll = 'notifications/close-all'
}

/**
 * Add
 */
export interface NotificationsAddAction extends Action {
  type: NotificationActionType.Add;
  payload: {
    notification: NotificationInterface;
  };
}
export const notificationsAdd = (notification: NotificationInterface): NotificationsAddAction => ({
  type: NotificationActionType.Add,
  payload: {
    notification
  }
});

/**
 * Close
 */
export interface NotificationsCloseAction extends Action {
  type: NotificationActionType.Close;
  payload: {
    notification: NotificationInterface;
  };
}
export const notificationsClose = (notification: NotificationInterface): NotificationsCloseAction => ({
  type: NotificationActionType.Close,
  payload: {
    notification
  }
});

/**
 * Close All
 */
export interface NotificationsCloseAllAction extends Action {
  type: NotificationActionType.CloseAll;
}
export const notificationsCloseAll = (): NotificationsCloseAllAction => ({
  type: NotificationActionType.CloseAll
});

export type NotificationsActions = NotificationsAddAction | NotificationsCloseAction | NotificationsCloseAllAction;

import { v4 as uuid } from 'uuid';

import { AppsActions, isSuccessAction, SuccessAction } from '../actions/actions';
import { NotificationInterface } from '../../modules/notifications/notifications.interface';
import { notificationsAdd } from '../../modules/notifications/notifications.actions';

import { MiddlewareType } from './middleware';

// init
export const success: MiddlewareType<AppsActions> = ({ dispatch }) => next => action => {
  next(action);

  if (isSuccessAction(action)) {
    const key = uuid();
    const message = (action as SuccessAction).message;
    const notification: NotificationInterface = {
      key,
      message,
      options: {
        key,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      },
      isClose: true
    };
    dispatch(notificationsAdd(notification));
  }
};

export const successMiddleware = [success];

import { Reducer } from 'redux';

import { Store } from './results.store';
import { Actions, ActionType } from './results.actions';

export const resultsReducer: Reducer<Store, Actions> = (state = new Store(), action) => {
  switch (action.type) {
    case ActionType.StartLoading:
      return { ...state, ready: true, loading: true, ...action.payload };

    case ActionType.LoadingSuccess:
      return { ...state, rows: action.payload.rows, loading: false };

    case ActionType.LoadingFailure:
      return { ...state, loading: false };

    case ActionType.ApplyFilter:
      return { ...state, ...action.payload };

    case ActionType.SetProofreaderToGrade:
      return {
        ...state,
        popup: {
          proofreader: action.payload.proofreader,
          creatingGrades: false
        }
      };

    case ActionType.UnsetProofreaderToGrade:
      return { ...state, popup: undefined };

    case ActionType.GradeProofreaderStart: {
      const newPopup = state.popup && { ...state.popup, creatingGrades: true };
      return { ...state, popup: newPopup };
    }

    case ActionType.GradeProofreaderSuccess:
      const rows = state.rows.map(row => {
        const wasUpdated = row.proofreader.id === action.payload.proofreaderId;
        const inGrade = wasUpdated ? action.payload.voteJobs > 0 : row.proofreader.inGrade;
        return { ...row, proofreader: { ...row.proofreader, inGrade } };
      });
      return { ...state, popup: undefined, rows };

    case ActionType.GradeProofreaderFailure: {
      const newPopup = state.popup && { ...state.popup, creatingGrades: false };
      return { ...state, popup: newPopup };
    }

    default:
      return state;
  }
};

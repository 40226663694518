import { uniq } from 'lodash';

import { Permission } from '../../apis/permissions.interface';
import { ProofreadersListActionType, StartLoading } from '../../pages/Proofreader';
import {
  OnshiftProofreadersActionType,
  OnshiftProofreadersLoadingSuccessAction
} from '../../pages/Dashboard/onshift-proofreaders.actions';

import { Action, ActionType } from './action';

type Store = { permissions: Permission[]; cohorts: string[] };
const initStore = (): Store => ({ permissions: [], cohorts: [] });

export const actions = {
  startLoading: () => ({ type: ActionType.StartLoading }),
  loadingSuccess: (permissions: Permission[]) => ({ type: ActionType.LoadingSuccess, payload: { permissions } })
};

const permissionsReducer = (
  state: Store = initStore(),
  action: Action | StartLoading | OnshiftProofreadersLoadingSuccessAction
): Store => {
  switch (action.type) {
    case ProofreadersListActionType.StartLoading:
    case ActionType.StartLoading:
      return initStore();

    case ActionType.LoadingSuccess:
    case OnshiftProofreadersActionType.LoadingSuccess:
      const permissions = [...action.payload.permissions];
      const cohorts = uniq(permissions.flatMap(p => p.cohorts));
      return { ...state, permissions, cohorts };

    default:
      return state;
  }
};

export default permissionsReducer;

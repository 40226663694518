import Error from '@material-ui/icons/Error';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import FatalError from '../components/Errors/FatalError';
import NotFound from '../components/Errors/NotFound';
import AccessDeniedError from '../components/Errors/AccessDeniedError';
import indexRoute from '../modules/rootRedirect/dashboard.routes';

import { AppRoute } from './index';

export const error403route: AppRoute = {
  path: '/403',
  component: AccessDeniedError,
  parent: indexRoute,
  pathMatch: /\/403/,
  exact: true,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-error403route">
      <Error className="AppBreadcrumbsIcon" />
      Access Denied Error
    </Typography>
  )
};

export const fatalityRoute: AppRoute = {
  path: '/fatality',
  component: FatalError,
  parent: indexRoute,
  pathMatch: /./,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-fatalityRoute">
      <Error className="AppBreadcrumbsIcon" />
      Fatal Error
    </Typography>
  )
};

export const error404route: AppRoute = {
  path: '/',
  component: NotFound,
  parent: indexRoute,
  pathMatch: /./,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-error404route">
      <Error className="AppBreadcrumbsIcon" />
      Not Found Error
    </Typography>
  ),
  order: 999
};

import moment from 'moment';

import { Addition, ChangeOps, Operation, OperationType, RepeatMode, RepeatType } from '../edit/store';
import { ProofreaderRow, Shift } from '../view/store';
import { duration, intersection, ITimeInterval, modifyInterval, split } from '../../../utils/types';
import { display, ShiftView } from '../ShiftView';
import { millisToHours } from '../../../utils/date';

const applyOp = (shiftView: ShiftView, op: Operation): ShiftView | undefined => {
  if (op.type === OperationType.Addition) return shiftView;
  if (!shiftView.original) throw new Error('Trying to remove non-existent shift');

  const original = shiftView.original;

  const afterTargetTo = op.repeatMode.to && original.begin > op.repeatMode.to.getTime();
  const beforeTargetBegin = original.begin < op.repeatMode.from.getTime();

  if (op.type === OperationType.Modification) {
    /* TODO: Shouldn't it only be `viewed` as non-existent? */
    if (afterTargetTo) return undefined;

    const view: Shift = beforeTargetBegin
      ? shiftView.original
      : { ...original, ...modifyInterval(original, op.offset, op.durationDiff) };

    return { original, modified: { op, view } };
  } else if (op.type === OperationType.Removal) {
    /* TODO: Shouldn't it only be `viewed` as non-existent? */
    if (!beforeTargetBegin && !afterTargetTo) return undefined;

    return { original, modified: { op, view: original } };
  }
};

const isDayWithinSchedule = (day: ITimeInterval, schedule: RepeatMode): boolean => {
  switch (schedule.type) {
    case RepeatType.Once: {
      const original = moment.utc(schedule.from).format('yyyyMMdd');
      const thisDay = moment.utc(day.begin).format('yyyyMMdd');
      return original === thisDay;
    }
    case RepeatType.DayOfWeek: {
      const original = moment.utc(schedule.from).weekday();
      const thisDay = moment.utc(day.begin).weekday();
      return original === thisDay;
    }
    case RepeatType.Weekday: {
      const thisDay = moment.utc(day.begin).weekday();
      return thisDay != 0 && thisDay != 6; /* Sunday and Saturday */
    }
    case RepeatType.Everyday:
      return true;
  }
};

const isDayWithinRange = (day: ITimeInterval, schedule: RepeatMode): boolean => {
  const beforeStart = day.begin < schedule.from.getTime();
  const afterEnd = schedule.to && day.begin > schedule.to.getTime();

  return !beforeStart && !afterEnd;
};

const spawnShifts = (row: ProofreaderRow, op: Addition, interval: ITimeInterval): ShiftView[] => {
  const days = split(interval, 'day');

  return days
    .filter(day => isDayWithinRange(day, op.repeatMode) && isDayWithinSchedule(day, op.repeatMode))
    .map(day => {
      const dayKey = moment.utc(day.begin).format('yyyyMMdd');
      return {
        original: undefined,
        modified: {
          op,
          view: {
            proofreader: row,

            begin: day.begin + op.begin,
            end: day.begin + op.begin + op.duration,
            repeat: op.repeatMode,

            shiftKey: op.key + dayKey,
            seriesKey: op.key
          }
        }
      };
    });
};

const mergeAdditions = (row: ProofreaderRow, ops: ChangeOps, displayInterval: ITimeInterval): ProofreaderRow => {
  /* This should've been a `filter` but we need a workaround to pass TS' type-safety ¯\_(ツ)_/¯ */
  const additions: Addition[] = Object.values(ops).reduce<Addition[]>((acc, op) => {
    if (op.type === OperationType.Addition && op.proofreader.id === row.id) {
      acc.push(op);
    }
    return acc;
  }, []);

  const addedShifts = additions.flatMap(op => spawnShifts(row, op, displayInterval));
  return {
    ...row,
    shifts: [...row.shifts, ...addedShifts]
  };
};

const mergeModifications = (row: ProofreaderRow, ops: ChangeOps, displayInterval: ITimeInterval): ProofreaderRow => {
  const shifts = row.shifts.reduce((acc, s) => {
    const op = ops[display(s).seriesKey];

    const mergedShift = op && op.type !== OperationType.Addition ? applyOp(s, op) : s;
    if (mergedShift) {
      acc.push(mergedShift);
    }
    return acc;
  }, [] as ShiftView[]);

  return {
    ...row,
    shifts: shifts,
    totalHours: shifts.reduce((acc, s) => acc + millisToHours(duration(intersection(displayInterval, display(s)))), 0)
  };
};

export default (proofreader: ProofreaderRow, operations: ChangeOps, displayInterval: ITimeInterval): ProofreaderRow =>
  mergeModifications(mergeAdditions(proofreader, operations, displayInterval), operations, displayInterval);

import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { dateToString, timestampToDate } from '../../../utils/date';
import { getGuideline, getOwnership } from '../../../model/job/jobType';
import { prettyPrint } from '../../../model/voting/voteJobType';
import { StoreInterface } from '../../../redux/store/store';
import { OngointTaskStatus } from '../onshift-proofreaders.store';

const styles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '1.5em',
      overflow: 'scroll'
    }
  })
);

const status = (proofreader: OngointTaskStatus) => {
  if (proofreader.voteId != null) {
    return `Voting (vote job #${proofreader.voteId})`;
  }

  if (proofreader.snippetId != null) {
    return `Proofreading ${proofreader.snippetId} (job #${proofreader.jobId}, ${proofreader.snippetNumber})`;
  }

  return 'Idle';
};

const accepted = (proofreader: OngointTaskStatus) => dateToString(timestampToDate(proofreader.accepted));

const timeSpent = (proofreader: OngointTaskStatus) =>
  proofreader.accepted
    ? moment
        .utc(moment.duration(moment.utc(moment.now()).diff(moment.utc(proofreader.accepted))).asMilliseconds())
        .format('mm:ss')
    : '';

const jobDue = (proofreader: OngointTaskStatus) => {
  if (!proofreader.jobType || getOwnership(proofreader.jobType) !== 'user') {
    return '-';
  }

  return dateToString(timestampToDate(proofreader.due));
};

const jobType = (proofreader: OngointTaskStatus) => {
  if (proofreader.jobType != null) {
    return `${getGuideline(proofreader.jobType)} (${getOwnership(proofreader.jobType)})`;
  }

  if (proofreader.voteJobType != null) {
    return `${prettyPrint(proofreader.voteJobType)} (voting)`;
  }

  return '';
};

const OnshiftProofreadersTable = () => {
  const classes = styles();

  const tasks = useSelector((state: StoreInterface) => state.onshiftProofreaders.tasksStatus);

  return (
    <Paper className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Proofreader</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Accepted</TableCell>
            <TableCell>Time spent</TableCell>
            <TableCell>Job due</TableCell>
            <TableCell>Job type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map(task => (
            <TableRow hover key={task.name}>
              <TableCell>{task.name}</TableCell>
              <TableCell>{status(task)}</TableCell>
              <TableCell>{accepted(task)}</TableCell>
              <TableCell>{timeSpent(task)}</TableCell>
              <TableCell>{jobDue(task)}</TableCell>
              <TableCell>{jobType(task)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default OnshiftProofreadersTable;

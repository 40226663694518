import './JobsList.css';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { Dispatch } from 'redux';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Chip } from '@material-ui/core';

import { Job } from '../../../job.interface';
import { JobsFiltering, JobsListPopupWindow } from '../../jobs-list.store';
import { history, StoreInterface } from '../../../../../redux/store/store';
import { AppsActions } from '../../../../../redux/actions/actions';
import { actions } from '../../jobs-list.actions';
import JobInfoModal from '../JobInfoModal/JobInfoModal';
import { OpsOnly } from '../../../../../components/security/SecurityGroupFilter';
import { parseUrlParams } from '../../urlParams';
import JobActionAlert from '../JobActionAlertModal/JobActionAlert';
import CreateJobDialog from '../CreateJobDialog/CreateJobDialog';
import RevisedResultPopup from '../RevisedResultModal/RevisedResultModal';
import { ProofreaderDetails } from '../../../../../apis/proofreaders.api';

import JobsListTable from './JobsListTable';
import DownloadCsvReportButton from './DownloadCsvReportButton';

export type JobsListProps = Props & Dispatches;

export const JobsList = (props: JobsListProps) => {
  const [page, setPage] = useState(0);
  const [initialized, changeInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      props.clear();
      const filtering = parseUrlParams(history.location.search);
      props.initFiltering(filtering);
      changeInitialized(true);
      props.jobsLoading(page, filtering);
    }
  }, [initialized, changeInitialized, props.clear, props.initFiltering, props.jobsLoading]);

  useEffect(() => {
    if (initialized) {
      props.clear();
      setPage(0);
    }
  }, [initialized, props.filtering, props.clear, setPage]);

  const onLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    props.jobsLoading(nextPage, props.filtering);
  };

  const filterByAssigneeName =
    props.filtering.assigneeId &&
    props.proofreaders.find(p => props.filtering.assigneeId && p.id === +props.filtering.assigneeId)?.name;

  return (
    <Container maxWidth="xl" className="JobsListWrapper">
      <OpsOnly>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <DownloadCsvReportButton />
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => props.showCreateJobsDialog()}>
              New job
            </Button>
          </Grid>
        </Grid>
      </OpsOnly>
      <Paper className="JobsList">
        {props.popup && props.popup.type === 'info' && <JobInfoModal popup={props.popup} />}
        {props.popup && props.popup.type === 'alert' && <JobActionAlert popup={props.popup} />}
        {props.popup && props.popup.type === 'create' && <CreateJobDialog />}
        {props.popup && props.popup.type === 'revised' && <RevisedResultPopup popup={props.popup} />}
        {filterByAssigneeName && (
          <div>
            <Chip
              label={`Assignee: ${filterByAssigneeName}`}
              color="secondary"
              onDelete={() => props.filter('assigneeId', '')}
            />
          </div>
        )}
        {initialized && <JobsListTable />}
        <div className="LoadMore">
          {props.loading ? (
            <CircularProgress className="Progress" />
          ) : (
            /* TODO: don't show this button if there are no more records */
            <Button data-name="LoadMoreButton" onClick={() => onLoadMore()} disabled={props.loading}>
              Load More
            </Button>
          )}
        </div>
      </Paper>
    </Container>
  );
};

interface Props {
  jobs: Job[];
  popup?: JobsListPopupWindow;
  loading: boolean;
  filtering: JobsFiltering;
  proofreaders: ProofreaderDetails[];
}

interface Dispatches {
  clear: () => void;
  jobsLoading: (page: number, filtering: JobsFiltering) => void;
  jobsLoadingStop: () => void;
  filter: (key: keyof JobsFiltering, value: any) => void;
  initFiltering: (filtering: JobsFiltering) => void;
  showCreateJobsDialog: () => void;
}

export default connect(
  (state: StoreInterface): Props => ({
    jobs: state.jobsList.list,
    popup: state.jobsList.popupWindow,
    loading: state.app.loading,
    filtering: state.jobsList.filtering,
    proofreaders: state.proofreadersList.proofreaders
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    clear: () => dispatch(actions.clear()),
    jobsLoading: (page: number, filtering: JobsFiltering) => dispatch(actions.startLoading(page, filtering)),
    jobsLoadingStop: () => dispatch(actions.stopLoading()),
    filter: (key: keyof JobsFiltering, value: any) => dispatch(actions.filtering(key, value)),
    initFiltering: (filtering: JobsFiltering) => dispatch(actions.initFiltering(filtering)),
    showCreateJobsDialog: () => dispatch(actions.showCreateJobPopup())
  })
)(JobsList);

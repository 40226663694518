import React, { useCallback, useContext } from 'react';
import { createStyles, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';

import { OpsOnly } from '../../../components/security/SecurityGroupFilter';

import { FormStateContext } from './sections/FormStateContext';
import { FormModifiedContext } from './sections/FormModifiedContext';

const styles = makeStyles(() =>
  createStyles({
    grid: {
      marginTop: 10
    }
  })
);

export default () => {
  const classes = styles();

  const { modified, setModified } = useContext(FormModifiedContext);
  const formStateContext = useContext(FormStateContext);

  const onSubmit = useCallback(() => {
    formStateContext.triggerSubscribers('submit');
    setModified(false);
  }, [formStateContext, setModified]);

  const onCancel = useCallback(() => {
    formStateContext.triggerSubscribers('cancel');
    setModified(false);
  }, [formStateContext, setModified]);

  if (!modified) {
    return null;
  }

  return (
    <OpsOnly>
      <Grid container direction="row" justify="flex-end" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <Tooltip title="Save">
            <IconButton color="primary" onClick={onSubmit}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Cancel">
            <IconButton color="primary" onClick={onCancel}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </OpsOnly>
  );
};

import { CorpusType } from '../../model/corpus/corpusType';

import { Corpus, QueuesStats } from './corpora.interface';

export interface CorporaStoreInterface {
  loadingCorpora: boolean;
  modifyingCorpus: boolean;
  corpora: Corpus[];

  clarityReportsLoading: boolean;
  clarityReports: string[];

  corpusQueuesStats: QueuesStats;
  loadingStats: boolean;
}

export const INIT_CORPORA_STORE: CorporaStoreInterface = {
  loadingCorpora: false,
  modifyingCorpus: false,
  corpora: [],

  clarityReportsLoading: false,
  clarityReports: [],

  corpusQueuesStats: {
    wordsProcessedLastWeek: Object.values(CorpusType).reduce((acc, corpusType) => {
      acc[corpusType as CorpusType] = 0;
      return acc;
    }, {} as Record<CorpusType, number>)
  },
  loadingStats: false
};

import React, { CSSProperties } from 'react';
import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import classNames from 'classnames';
import { CheckCircle, Info, Subject } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import { Job, JobAction, JobFileType, JobStatus, StepStatus, StepType } from '../../../job.interface';
import { ID } from '../../../../../utils/types';
import { JobsFiltering } from '../../jobs-list.store';
import { isJobStuck } from '../../../_utils';
import { localDateTimeUTCTooltip } from '../../../../../utils/date';
import { JobMenu } from '../JobMenu/JobMenu';
import { AppsActions } from '../../../../../redux/actions/actions';
import { actions } from '../../jobs-list.actions';
import { downloadFileStartLoading } from '../../../../../redux/saga/download-file.actions';
import { StoreInterface } from '../../../../../redux/store/store';
import { formatUsd } from '../../../../../utils/format';
import { ExceptOps, OpsAndAdvSupportOnly, OpsOnly } from '../../../../../components/security/SecurityGroupFilter';
import { uploadFileStartRevisedResult } from '../../../../../redux/saga/upload-file.actions';
import { prettyPrint as prettyPrintJobSource } from '../../../../../model/job/source';
import { prettyPrintShort as prettyPrintTurnaroundOption } from '../../../../../model/job/turnaroundOption';

import Filters from './columnFilters';
import icons from './icons';
import JobFileDownloadIcon from './JobFileDownloadIcon';
import StepFileDownloadIcon from './StepFileDownloadIcon';

const useStyles = makeStyles((theme: Theme) => {
  const noFilter: CSSProperties = {
    paddingTop: 20 /* Calculated manually, couldn't find correlation with theme attributes */,
    fontSize: '1rem',
    fontWeight: 'normal'
  };

  return createStyles({
    jobId: {
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    id: {
      textAlign: 'left',
      minWidth: 100
    },
    uid: {
      textAlign: 'left',
      minWidth: 100
    },
    price: {
      textAlign: 'right',
      minWidth: 40,
      '&.header': {
        ...noFilter
      }
    },
    inputs: {
      textAlign: 'center',
      minWidth: 70
    },
    jobType: {
      textAlign: 'left',
      minWidth: 120
    },
    iconPlaceholder: {
      width: 30
    },
    status: {
      textAlign: 'left',
      minWidth: 140
    },
    options: {
      textAlign: 'left',
      minWidth: 70
    },
    snippets: { textAlign: 'center', '&.header': { ...noFilter } },
    submitted: { textAlign: 'left', '&.header': { ...noFilter } },
    due: { textAlign: 'left', '&.header': { ...noFilter } },
    completed: { textAlign: 'left', '&.header': { ...noFilter } },
    source: { textAlign: 'left', minWidth: 80, whiteSpace: 'nowrap' },
    actions: { textAlign: 'right', '&.header': { ...noFilter } },

    stepRow: { fontStyle: 'italic' },
    noBorderCell: { borderBottom: 'none' }
  });
});

const sameUser = (job: Job, other?: Job): boolean => !!other && job.uid === other.uid;

const JobsListTable: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => {
  const classes = useStyles();

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classNames('header', classes.id)}>
            <Filters.Id initialValue={props.filtering.id} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.uid)}>
            <Filters.UserId initialValue={props.filtering.uid} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.price)}>Price</TableCell>

          <TableCell className={classNames('header', classes.jobType)}>
            <Filters.Type initialValue={props.filtering.jobType} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.inputs)}>
            <Filters.Inputs initialValue={props.filtering.inputs} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.status)}>
            <Filters.Status initialValue={props.filtering.status} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.options)}>
            <Filters.Options initialValue={props.filtering.option} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.snippets)}>Snippets/Slices</TableCell>

          <TableCell className={classNames('header', classes.submitted)}>Submitted</TableCell>

          <TableCell className={classNames('header', classes.due)}>Due</TableCell>

          <TableCell className={classNames('header', classes.completed)}>Completed</TableCell>

          <TableCell className={classNames('header', classes.source)}>
            <Filters.Source initialValue={props.filtering.source} filter={props.filter} />
          </TableCell>

          <TableCell className={classNames('header', classes.actions)}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.jobs.map((job, index) => {
          let firstEditStep = job.steps.find(step => step.type === StepType.EDIT);
          return (
            <React.Fragment key={job.id}>
              <TableRow hover className={classNames({ selected: job.id === props.openedJob })}>
                <TableCell className={classes.id}>
                  <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                    <Grid item className={classes.jobId}>
                      <div onClick={() => props.openStepDetails(job)}>{job.id}</div>
                    </Grid>
                    <Grid item>
                      <IconButton size="small" aria-label="info" onClick={() => props.showInfoPopup(job)}>
                        <Info display="block" />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton component={Link} size="small" to={`/logs?jobId=${job.id}`} title="Job log">
                        <Subject display="block" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.uid}>
                  <Grid container alignItems="center" spacing={1} wrap="nowrap" justify="space-between">
                    <Grid item>
                      {sameUser(job, props.jobs[index - 1]) ? <i className="SameId">&uarr;&nbsp;{job.uid}</i> : job.uid}
                    </Grid>
                    <Grid item>
                      {job.fromGrammarlyUser ? (
                        <img
                          src={icons.internalUser}
                          width={20}
                          height={20}
                          title="Internal Grammarly user"
                          style={{ display: 'block' }}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.price}>
                  <Grid
                    container
                    className="JobPrice"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    {job.creditsPayment !== 'Skipped' ? (
                      <>
                        <Grid item></Grid>
                        <Grid item>Free</Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          {job.paymentVerified && (
                            <CheckCircle className="PaymentVerified" titleAccess="Payment Verified" />
                          )}
                        </Grid>
                        <Grid item>{formatUsd(job.price)}</Grid>
                      </>
                    )}
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid container alignItems="center" justify="flex-start" spacing={1} wrap="nowrap">
                    <Grid item title={job.jobSubmitter}>
                      {icons.jobSubmitter[job.jobSubmitter]}
                    </Grid>
                    <Grid item>{job.jobType}</Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.inputs}>
                  {job.userInputs && <CheckIcon fontSize="inherit" display="block" />}
                </TableCell>

                <TableCell className={classes.status}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className={classNames(['JobStatus', job.status])}
                    wrap="nowrap"
                  >
                    <Grid item>{icons.jobStatus[job.status]}</Grid>
                    <Grid item>{job.status}</Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.options}>
                  <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item className="date">
                      {icons.turnaround[job.turnaroundOption]}
                    </Grid>
                    <Grid item>{prettyPrintTurnaroundOption(job.turnaroundOption)}</Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.snippets}>{firstEditStep ? firstEditStep.snippets.length : 0}</TableCell>

                <TableCell className={classes.submitted}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    wrap="nowrap"
                    className={classNames({ stuck: isJobStuck(job) })}
                    justify="space-between"
                  >
                    <Grid item className="date">
                      {localDateTimeUTCTooltip(job.submitted)}
                    </Grid>
                    <OpsAndAdvSupportOnly>
                      <Grid item>
                        <JobFileDownloadIcon job={job} type={JobFileType.original} />
                      </Grid>
                    </OpsAndAdvSupportOnly>
                  </Grid>
                </TableCell>

                <TableCell className={classes.due}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    wrap="nowrap"
                    className={classNames({ stuck: isJobStuck(job) })}
                  >
                    <Grid item className="date">
                      {localDateTimeUTCTooltip(job.due)}
                    </Grid>
                    <OpsOnly>
                      <Grid item>
                        <Box className={classes.iconPlaceholder} />
                      </Grid>
                    </OpsOnly>
                  </Grid>
                </TableCell>

                <TableCell className={classes.completed}>
                  {job.completed && (
                    <Grid
                      container
                      spacing={1}
                      justify="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      className={classNames({ stuck: isJobStuck(job) })}
                    >
                      <Grid item className="date">
                        {localDateTimeUTCTooltip(job.completed)}
                      </Grid>
                      <OpsAndAdvSupportOnly>
                        <Grid item>
                          <JobFileDownloadIcon job={job} type={JobFileType.result} />
                        </Grid>
                      </OpsAndAdvSupportOnly>
                    </Grid>
                  )}
                </TableCell>

                <TableCell className={classes.source}>
                  <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item>{icons.jobSource[job.source]}</Grid>
                    <Grid item>{prettyPrintJobSource(job.source)}</Grid>
                  </Grid>
                </TableCell>

                <TableCell className={classes.actions}>
                  <div className="Buttons">
                    <JobMenu job={job} changeJob={props.changeJob} uploadRevisedResult={props.uploadRevisedResult} />
                  </div>
                </TableCell>
              </TableRow>
              {props.openedJob === job.id &&
                job.steps.map((step, index) => (
                  /*
                   * TODO: extract the declaration of table row so it became clear what
                   *   columns of additional rows match original ones
                   */
                  <TableRow key={step.serialNumber} className={classes.stepRow}>
                    {/* `id`, `uid` and `price` columns are omitted */}
                    {index === 0 && <TableCell colSpan={3} rowSpan={job.steps.length} />}

                    <TableCell className={classes.jobType}>
                      <Grid container alignItems="center" spacing={1} wrap="nowrap">
                        <Grid item>
                          <Box className={classes.iconPlaceholder} />
                        </Grid>
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                          {step.stepIdPretty}
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell />

                    <TableCell>
                      <Grid container className="StepStatus" spacing={1} wrap="nowrap" alignItems="center">
                        <Grid item>{icons.stepStatus[step.status]}</Grid>
                        <Grid item>{step.status}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell />

                    <TableCell className={classes.snippets}>
                      <OpsOnly>
                        {step.snippets.length ? (
                          <Link to={`/snippets?jobId=${job.id}&stepNumber=${step.serialNumber + 1}`}>
                            {step.snippets.length}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </OpsOnly>
                      <ExceptOps>{step.snippets.length || '-'}</ExceptOps>
                    </TableCell>

                    <TableCell>
                      <OpsOnly>
                        <Grid container spacing={1} wrap="nowrap" alignItems="center" justify="flex-end">
                          <Grid item>
                            {step.type == StepType.EDIT && (
                              <StepFileDownloadIcon step={step} fileType={JobFileType.original} />
                            )}
                          </Grid>
                        </Grid>
                      </OpsOnly>
                    </TableCell>

                    <TableCell className={classes.due}>
                      <Grid container spacing={1} className="JobDates" wrap="nowrap" alignItems="center">
                        <Grid item className="date">
                          {localDateTimeUTCTooltip(step.due)}
                        </Grid>
                        <OpsOnly>
                          <Grid item>
                            {step.type == StepType.EDIT && (
                              <StepFileDownloadIcon step={step} fileType={JobFileType.annotated} />
                            )}
                          </Grid>
                        </OpsOnly>
                      </Grid>
                    </TableCell>

                    <TableCell className={classes.completed}>
                      <Grid
                        container
                        spacing={1}
                        className="JobDates"
                        wrap="nowrap"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item className="date">
                          {step.status == StepStatus.Running
                            ? 'In progress...'
                            : localDateTimeUTCTooltip(step.completed)}
                        </Grid>
                        <OpsOnly>
                          <Grid item>
                            {step.type == StepType.EDIT && (
                              <StepFileDownloadIcon step={step} fileType={JobFileType.result} />
                            )}
                          </Grid>
                        </OpsOnly>
                      </Grid>
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      className={classNames({ [classes.noBorderCell]: index != job.steps.length - 1 })}
                    />
                  </TableRow>
                ))}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

interface Props {
  jobs: Job[];
  openedJob?: ID;
  filtering: JobsFiltering;
}

interface Dispatches {
  downloadFile: (job: Job, type: JobFileType) => void;
  filter: (key: keyof JobsFiltering, value: string) => void;
  openStepDetails: (job: Job) => void;
  changeJob: (job: Job, action: JobAction) => void;
  showInfoPopup: (job: Job) => void;
  uploadRevisedResult: (job: Job, file: File) => void;
}

export default connect(
  (state: StoreInterface): Props => ({
    jobs: state.jobsList.list,
    openedJob: state.jobsList.openStepInfo,
    filtering: state.jobsList.filtering
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    downloadFile: (job: Job, type: JobFileType) => dispatch(downloadFileStartLoading(job.id, type)),
    filter: (key: keyof JobsFiltering, value: string) => dispatch(actions.filtering(key, value)),
    openStepDetails: (job: Job) => dispatch(actions.toggleStepsInfo(job)),
    changeJob: (job: Job, action: JobAction) => dispatch(actions.showChangeJobAlert(job, action)),
    showInfoPopup: (job: Job) => dispatch(actions.showInfoPopup(job)),
    uploadRevisedResult: (job: Job, file: File) => dispatch(uploadFileStartRevisedResult(job.id, file))
  })
)(JobsListTable);

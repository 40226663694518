import { upperFirst } from 'lodash';

import { timestampToDate } from '../../utils/date';
import { str2enumStrict } from '../../utils/enums/str2enum';
import { getGuideline, JobType } from '../../model/job/jobType';
import { JobSource } from '../../model/job/source';
import { TurnaroundOption } from '../../model/job/turnaroundOption';

import {
  Job as ModelJob,
  JobStatus,
  JobSubmitter,
  SnippetRow,
  StepInfo,
  StepLabel,
  StepStatus,
  StepType
} from './job.interface';
import { Job as ApiJob, Snippet as ApiSnippet, Step as ApiStep } from './job.api.interface';

export const dialectToString = (dialect?: string) => {
  switch (dialect) {
    case 'American':
      return 'AmE';
    case 'Australian':
      return 'AuE';
    case 'British':
      return 'BrE';
    case 'Canadian':
      return 'CaE';
    default:
      return '';
  }
};

export const quotationTypeToString = (type?: string) => {
  switch (type) {
    case 'Single':
      return 'SQ';
    case 'Double':
      return 'DQ';
    default:
      return '';
  }
};

export const spaceTypeToString = (type?: string) => {
  switch (type) {
    case 'Single':
      return 'SS';
    case 'Double':
      return 'DS';
    default:
      return '';
  }
};

// TODO: remove step label at all.
const toStepLabel = (raw: string): StepLabel => {
  switch (raw.toUpperCase()) {
    case 'GEC':
      return StepLabel.GEC;
    case 'CLARITY':
      return StepLabel.Clarity;
    case 'OTHER':
      return StepLabel.Other;
    default:
      throw new Error('Unknown StepLabel: ' + raw.toUpperCase());
  }
};

const toJobSubmitter = (jobType: string): JobSubmitter => {
  if (jobType === 'TEST_RUN') return JobSubmitter.TestRun;

  if (jobType.startsWith('USER_')) return JobSubmitter.User;

  return JobSubmitter.Corpus;
};

const convertSnippet = (api: ApiSnippet): SnippetRow => ({
  firstSentence: api.firstSentence,
  lastSentence: api.lastSentence,
  proofreaderName: api.proofreaderName
});

const convertStep = (job: ApiJob, api: ApiStep): StepInfo[] => {
  let result: StepInfo[] = [
    {
      jobId: job.jobId,

      serialNumber: api.serialNumber,
      label: toStepLabel(api.label),
      type: str2enumStrict(StepType, api.type, 'step type'),
      actionType: api.actionType,
      stepId: api.stepId,
      stepIdPretty:
        api.stepId === api.actionType
          ? upperFirst(
              api.actionType
                .replaceAll('_', ' ')
                .toLowerCase()
                .replaceAll('gec ', 'GEC ')
            )
          : api.stepId,

      additionalPass: false,
      status: str2enumStrict(StepStatus, api.status, 'step status'),
      due: timestampToDate(api.due),
      completed: timestampToDate(api.completed),

      hasOriginal: api.hasOriginal,
      hasAnnotated: api.hasAnnotated,
      hasResult: api.hasResult,

      snippets: api.snippets.map(convertSnippet)
    }
  ];

  if (api.hasAdditionalGecResult) {
    result.unshift({
      jobId: job.jobId,
      serialNumber: api.serialNumber,
      label: StepLabel.GEC2,
      type: StepType.EDIT,
      actionType: api.actionType,
      stepIdPretty: 'Additional GEC pass',
      stepId: api.stepId,
      additionalPass: true,
      status: StepStatus.Success,
      hasResult: true,

      due: null,
      completed: null,
      hasAnnotated: false,
      hasOriginal: false,
      snippets: api.snippets.map(convertSnippet)
    });
  }

  return result;
};

function toSteps(api: ApiJob) {
  return api.steps.flatMap(s => convertStep(api, s));
}

export default (api: ApiJob): ModelJob => ({
  id: api.jobId,
  uid: +api.userId,
  docId: +api.docId,
  source: str2enumStrict(JobSource, api.source, 'JobSource'),

  // TODO: derive job status from job steps
  status: str2enumStrict(JobStatus, api.status, 'job status'),
  turnaroundOption: str2enumStrict(TurnaroundOption, api.turnaround, 'turnaround option'),

  submitted: timestampToDate(api.submitted),
  due: timestampToDate(api.due),
  completed: timestampToDate(api.completed),
  downloaded: timestampToDate(api.downloaded),

  price: api.price,
  pricePerWord: api.pricePerWord,
  creditsPayment: api.creditsPayment,
  paymentVerified: api.paymentVerified,
  cancelReason: api.cancelReason,
  cancellationDetails: api.cancellationDetails,

  snippets: api.snippets.map(convertSnippet),
  steps: toSteps(api),

  hasOriginal: api.hasOriginal,
  hasResult: api.hasResult,
  fromGrammarlyUser: api.fromGrammarlyUser,

  jobSubmitter: toJobSubmitter(api.jobType),
  jobType: getGuideline(str2enumStrict(JobType, api.jobType, 'job type')),

  userInputs: api.userInputs,

  corpus: api.jobType.toUpperCase().indexOf('CORPUS') > -1
});

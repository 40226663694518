import { call, put, takeLatest } from 'redux-saga/effects';

import { hideSpinner, showSpinner } from '../../pages/App/app.actions';
import fetch from '../../utils/fetch';
import appConfig from '../../config';
import { Permission } from '../../apis/permissions.interface';
import { ProofreadersListActionType } from '../../pages/Proofreader';

import { actions } from './reducer';
import { ActionType } from './action';

function* loadPermissions() {
  try {
    yield put(showSpinner('load-permissions'));

    const permissions: Permission[] = yield call(fetch.request.bind(fetch), appConfig.api.permissions);

    yield put(actions.loadingSuccess(permissions));
  } finally {
    yield put(hideSpinner('load-permissions'));
  }
}

function* loadPermissionsList() {
  yield takeLatest([ProofreadersListActionType.StartLoading, ActionType.StartLoading], loadPermissions);
}

export default loadPermissionsList;

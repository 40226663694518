import React, { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export const ConfirmDialog: FunctionComponent<DialogProps> = (props: DialogProps) => {
  const confirmClickedHandler = () => {
    props.confirmActionHandler();
    props.closeHandler();
  };

  return (
    <Dialog open={props.open} onClose={props.closeHandler}>
      <DialogContent>
        {props.questionElement ? (
          props.questionElement
        ) : props.question ? (
          <Typography variant="body1">{props.question}</Typography>
        ) : (
          <Typography variant="body1">Confirm the action</Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus color="secondary" onClick={props.closeHandler}>
          {props.cancelLabel ? props.cancelLabel : 'Cancel'}
        </Button>
        <Button color="primary" onClick={confirmClickedHandler}>
          {props.confirmLabel ? props.confirmLabel : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  question?: string;
  questionElement?: React.ReactElement;
  confirmLabel?: string;
  cancelLabel?: string;
}

interface Dispatches {
  confirmActionHandler: () => void;
  closeHandler: () => void;
}

type DialogProps = Props & Dispatches;

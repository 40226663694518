import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { MenuProps } from '@material-ui/core/Menu';

import { practiceJobsActions } from '../practice.actions';
import { FilteringSelect } from '../../../components/FilteringSelect/FilteringSelect';
import { CreateJobPopupInterface } from '../practice.store';
import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { Proofreader } from '../../../apis/proofreaders.api';

const proofreaderMenuProps: Partial<MenuProps> = {
  style: {
    maxHeight: 365
  }
};

const CreatePracticeJobDialog: FunctionComponent<DialogProps> = (props: DialogProps) => {
  if (!props.popup) {
    return null;
  }
  const [proofreaderId, setProofreaderId] = useState<string | null>(null);

  const proofreadersToSelect = props.proofreaders.map(p => ({ name: p.name, value: '' + p.id }));

  const submitJobHandler = () => {
    props.popup && proofreaderId && props.createJob(`${props.popup.document.id}`, proofreaderId);
  };

  return (
    <Dialog open={true} onClose={props.closeHandler}>
      {props.popup.creatingJob && <LinearProgress />}
      <DialogTitle>Choose a proofreader to assign</DialogTitle>

      <DialogContent>
        <TextField
          defaultValue={props.popup.document.fileName}
          disabled
          id="filled-disabled"
          label="Document"
          style={{ width: '100%' }}
        />
      </DialogContent>

      <DialogContent>
        <FilteringSelect
          label="Proofreader"
          data={proofreadersToSelect}
          submit={value => setProofreaderId(value)}
          menuProps={proofreaderMenuProps}
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={props.popup.creatingJob} onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={props.popup.creatingJob || !proofreaderId}
          onClick={submitJobHandler}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  proofreaders: Proofreader[];
  popup: CreateJobPopupInterface | null;
}

interface Dispatches {
  closeHandler: () => void;
  createJob: (documentId: string, proofreaderId: string) => void;
}

type DialogProps = Props & Dispatches;

const mapStateToProps = (state: StoreInterface): Props => ({
  proofreaders: state.practice.proofreaders,
  popup: state.practice.createJobPopup
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  closeHandler: () => dispatch(practiceJobsActions.closeCreateJobPopup()),
  createJob: (documentId: string, proofreaderId: string) =>
    dispatch(practiceJobsActions.createJob(documentId, proofreaderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePracticeJobDialog);

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import { MenuProps } from '@material-ui/core/Menu';
import { FormHelperText } from '@material-ui/core';

export interface FilteringSelectValue {
  name?: string;
  value: string;
}

interface Props {
  label: string;
  data: FilteringSelectValue[];
  initial?: string;
  helperText?: string;
  menuProps?: Partial<MenuProps>;

  submit: (value: string) => void;
}

export const FilteringSelect: React.FunctionComponent<Props> = (props: Props) => {
  const [submittedValue, setSubmittedValue] = React.useState<string>(props.initial === undefined ? '' : props.initial);

  const handleChange = () => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = '' + event.target.value;
    setSubmittedValue(value);
    if (value !== submittedValue) {
      props.submit(value);
    }
  };

  const id = `filtering-${props.label}`;

  return (
    <form autoComplete="off" onSubmit={handleChange}>
      <FormControl fullWidth>
        <InputLabel htmlFor={id}>{props.label}</InputLabel>
        <Select
          value={submittedValue}
          onChange={handleChange()}
          inputProps={{
            name: props.label,
            MenuProps: props.menuProps,
            id
          }}
        >
          {props.data.map(row => (
            <MenuItem value={row.value} key={row.name || row.value}>
              {row.name || row.value}
            </MenuItem>
          ))}
        </Select>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      </FormControl>
    </form>
  );
};

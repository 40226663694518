import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import AlertDialog from '../../../../../modules/shared/components/AlertDialog';
import { JobActionAlertPopup } from '../../jobs-list.store';
import { Job, JobAction } from '../../../job.interface';
import { AppsActions } from '../../../../../redux/actions/actions';
import { actions } from '../../jobs-list.actions';

const getAlertDialogContent = (action: JobAction) => {
  if (action == JobAction.resend) {
    return 'You are about to send the email. Are you sure?';
  } else if (action == JobAction.complete) {
    return 'You are about to complete the job. Are you sure?';
  } else if (action == JobAction.cancel) {
    return 'You are about to cancel the job. Are you sure?';
  } else {
    return '';
  }
};

const JobActionAlert: React.FunctionComponent<Props & Dispatches> = (props: Props & Dispatches) => (
  <AlertDialog
    title="Please confirm your selection"
    content={getAlertDialogContent(props.popup.action)}
    onSuccess={() => props.performAction(props.popup.job, props.popup.action)}
    onCancel={props.closePopup}
  />
);

interface Props {
  popup: JobActionAlertPopup;
}

interface Dispatches {
  performAction: (job: Job, action: JobAction) => void;
  closePopup: () => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    performAction: (job: Job, action: JobAction) => dispatch(actions.performJobAction(job.id, action)),
    closePopup: () => dispatch(actions.hideChangeJobAlert())
  })
)(JobActionAlert);

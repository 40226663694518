import { CombinedVotingProgressResponse, VotingProgressResponse } from '../progress.interface.api';
import { initProgress, initProgressItems, Progress, ProgressItems } from '../progress.store';
import { str2enumStrict } from '../../../../utils/enums/str2enum';
import { VoteJobType } from '../../../../model/voting/voteJobType';

const convertProgressItems = (api: VotingProgressResponse): ProgressItems =>
  Object.keys(api).reduce((acc, key) => {
    // Skipping the unknown properties
    if (Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key as keyof typeof acc] = api[key] || [];
    }
    return acc;
  }, initProgressItems());

export default (api: CombinedVotingProgressResponse): Progress =>
  Object.keys(api.progress).reduce((acc, key) => {
    const voteJobType = str2enumStrict(VoteJobType, key, 'vote job type');

    acc[voteJobType] = convertProgressItems(api.progress[key]);

    return acc;
  }, initProgress());

import React from 'react';
import { Redirect, Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { ThumbsUpDown } from '@material-ui/icons';

import indexRoute from '../../modules/rootRedirect/dashboard.routes';
import { AppRoute } from '../../routes';
import { SecurityGroup } from '../../modules/auth/okta';

import VoteeResultsPage from './VoteeResults/components/VoteeResultsPage';
import VotingProgressPage from './VotingProgress/components/VotingProgressPage';
import VoterResultsPage from './VoterResults/components/VoterResultsPage';

const root: AppRoute = {
  path: '/voting',
  component: () => <Route render={() => <Redirect to={{ pathname: '/voting/votee-results' }} />} />,
  parent: indexRoute,
  pathMatch: /\/voting/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-gradesRoute">
      <ThumbsUpDown className="AppBreadcrumbsIcon" />
      <NavLink to="/voting">Peer Votes</NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

const voteeResults: AppRoute = {
  path: '/voting/votee-results',
  component: VoteeResultsPage,
  parent: root,
  pathMatch: /\/voting\/votee-results/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink">
      <NavLink to="/voting/votee-results">Votee Results</NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

const voterResults: AppRoute = {
  path: '/voting/voter-results',
  component: VoterResultsPage,
  parent: root,
  pathMatch: /\/voting\/voter-results/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink">
      <NavLink to="/voting/voter-results">Voter Results</NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

const progress: AppRoute = {
  path: '/voting/progress',
  component: VotingProgressPage,
  parent: root,
  pathMatch: /\/voting\/progress/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink">
      <NavLink to="/voting/progress">Progress</NavLink>
    </Typography>
  ),
  securityGroups: [SecurityGroup.ProofitOps],
  private: true,
  exact: true
};

export default [progress, voteeResults, voterResults, root];

import moment, { Moment } from 'moment';
import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Hours, Milliseconds } from './types';

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12
  }
}));

export function millisToHours(m: Milliseconds): Hours {
  return m / 1000 / 60 / 60;
}

export function timestampToDate(t: number | null): Date | null {
  return t
    ? moment(t)
        .utc()
        .toDate()
    : null;
}

export function stringToDate(s: string | null): Date | null {
  return s ? moment.utc(s, 'YYYY-MM-DD HH:mm:ss').toDate() : null;
}

export function dateToString(d: Date | null): string {
  return d ? moment(d).format('M/DD HH:mm:ss') : '';
}

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

export function localDateTimeUTCTooltip(d: Date | null): JSX.Element {
  return d ? (
    <BootstrapTooltip title={d.toUTCString()} arrow enterDelay={500} interactive>
      <div style={{ display: 'inline' }}>{formatDateString(d)}</div>
    </BootstrapTooltip>
  ) : (
    <></>
  );
}

export function dateToMoment(d: Date | null): Moment | null {
  return d ? moment.utc(d) : null;
}

export function formatDateString(d: Date): string {
  const now = moment(new Date());

  const start = now.clone().startOf('day');
  const end = now.clone().endOf('day');

  if (start.isSameOrBefore(d) && end.isSameOrAfter(d)) return moment(d).format('HH:mm');

  if (now.year() == d.getFullYear()) return moment(d).format('MMM D, HH:mm');

  return moment(d).format('MMM D, YYYY');
}

import { assertNever } from '../../utils/errors';

export enum VoteJobType {
  GEC = 'GEC',
  PURE_GEC = 'PURE_GEC',
  CLARITY = 'CLARITY',
  PARA_CLARITY = 'PARA_CLARITY',
  CLARITY_PLUS = 'CLARITY_PLUS',
  REALTIME_GEC = 'REALTIME_GEC',
  ACADEMIC_TONE = 'ACADEMIC_TONE'
}

export const votingUnit = (type: VoteJobType) => {
  switch (type) {
    case VoteJobType.GEC:
    case VoteJobType.PURE_GEC:
    case VoteJobType.REALTIME_GEC:
    case VoteJobType.CLARITY:
    case VoteJobType.ACADEMIC_TONE:
      return { singular: 'sentence', plural: 'sentences' };

    case VoteJobType.PARA_CLARITY:
      return { singular: 'paragraph', plural: 'paragraphs' };

    case VoteJobType.CLARITY_PLUS:
      return { singular: 'snippet', plural: 'snippets' };

    default:
      assertNever(type);
  }
};

export const prettyPrint = (type: VoteJobType): string => {
  switch (type) {
    case VoteJobType.GEC:
      return 'GEC';
    case VoteJobType.PURE_GEC:
      return 'Pure GEC';
    case VoteJobType.CLARITY:
      return 'Sentence Clarity';
    case VoteJobType.PARA_CLARITY:
      return 'Paragraph Clarity';
    case VoteJobType.CLARITY_PLUS:
      return 'Clarity Plus';
    case VoteJobType.REALTIME_GEC:
      return 'Realtime GEC';
    case VoteJobType.ACADEMIC_TONE:
      return 'Academic Tone';

    default:
      return assertNever(type);
  }
};

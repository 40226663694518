import moment from 'moment';

import { DAILY_SCALE, WEEKLY_SCALE } from '../view/store';

const toScale = (raw: string) => {
  switch (raw.toLowerCase()) {
    case 'weekly':
      return WEEKLY_SCALE;
    default:
      return DAILY_SCALE;
  }
};

export default (queryString: string): any => {
  const defaultAsOf = moment
    .utc(Date.now())
    .startOf('day')
    .toDate();

  const params = new URLSearchParams(queryString);

  const asOfParam = params.get('asOf') || '';
  const parsedAsOf = moment.utc(asOfParam, 'YYYY-MM-DD');

  const asOf = parsedAsOf.isValid() ? parsedAsOf.toDate() : defaultAsOf;
  const scale = toScale(params.get('scale') || '');

  return { asOf: asOf, scale: scale };
};

import { Action } from 'redux';

import { ErrorAction } from '../../redux/actions/actions';
import { ID } from '../../utils/types';
import { Proofreader } from '../../apis/proofreaders.api';

import { PracticeDocument, PracticeJob, PracticeJobFiltering } from './practice.interface';

export enum PracticeActionType {
  StartLoading = 'practice/start-loading',
  StopLoading = 'practice/stop-loading',
  InitialLoadingFailure = 'practice/init-loading-failure',
  ProofreadersLoadingSuccess = 'practice/proofreaders-loading-success',
  DocsLoadingSuccess = 'practice/docs-loading-success',
  DocsLoadingFailure = 'practice/docs-loading-failure',
  UploadDocument = 'practice/doc-upload',
  DocUploadingSuccess = 'practice/doc-uploading-success',
  DocUploadingFailure = 'practice/doc-uploading-failure',
  DeleteDocument = 'practice/doc-delete',
  DocDeletionSuccess = 'practice/doc-deletion-success',
  DocDeletionFailure = 'practice/doc-deletion-failure',
  JobsLoadingSuccess = 'practice/jobs-loading-success',
  JobsLoadingFailure = 'practice/jobs-loading-failure',
  ReloadJobs = 'practice/jobs-reload',
  FilterJobs = 'practice/jobs-filter',
  AcceptJob = 'practice/job-accept',
  RejectJob = 'practice/job-reject',
  JobAssessmentSuccess = 'practice/job-assessment-success',
  JobAssessmentFailure = 'practice/job-assessment-failure',
  CreateJob = 'practice/job-create',
  JobCreationSuccess = 'practice/job-creation-success',
  JobCreationFailure = 'practice/job-creation-failure',
  OpenCreateJobPopup = 'practice/job-open-create-popup',
  CloseCreateJobPopup = 'practice/job-close-create-popup'
}

export interface PracticeStartLoadingAction extends Action {
  type: PracticeActionType.StartLoading;
}

export interface PracticeStopLoadingAction extends Action {
  type: PracticeActionType.StopLoading;
}

export interface PracticeInitialLoadingFailureAction extends ErrorAction {
  type: PracticeActionType.InitialLoadingFailure;
}

export interface PracticeProofreadersLoadingSuccessAction extends Action {
  type: PracticeActionType.ProofreadersLoadingSuccess;
  payload: {
    proofreaders: Proofreader[];
  };
}

export interface PracticeDocsLoadingSuccessAction extends Action {
  type: PracticeActionType.DocsLoadingSuccess;
  payload: {
    docs: PracticeDocument[];
  };
}

export interface PracticeDocsLoadingFailureAction extends ErrorAction {
  type: PracticeActionType.DocsLoadingFailure;
}

export interface PracticeUploadDocumentAction extends Action {
  type: PracticeActionType.UploadDocument;
  payload: {
    file: File;
  };
}

export interface PracticeDocUploadingSuccessAction extends Action {
  type: PracticeActionType.DocUploadingSuccess;
  payload: {
    document: PracticeDocument;
  };
}

export interface PracticeDocUploadingFailureAction extends ErrorAction {
  type: PracticeActionType.DocUploadingFailure;
}

export interface PracticeDeleteDocumentAction extends Action {
  type: PracticeActionType.DeleteDocument;
  payload: {
    id: ID;
  };
}

export interface PracticeDocDeletionSuccessAction extends Action {
  type: PracticeActionType.DocDeletionSuccess;
}

export interface PracticeDocDeletionFailureAction extends ErrorAction {
  type: PracticeActionType.DocDeletionFailure;
}

export interface PracticeJobsLoadingSuccessAction extends Action {
  type: PracticeActionType.JobsLoadingSuccess;
  payload: {
    jobs: PracticeJob[];
  };
}

export interface PracticeJobsLoadingFailureAction extends ErrorAction {
  type: PracticeActionType.JobsLoadingFailure;
}

export interface PracticeReloadJobsAction extends Action {
  type: PracticeActionType.ReloadJobs;
}

export interface PracticeFilterJobsAction extends Action {
  type: PracticeActionType.FilterJobs;
  payload: {
    key: keyof PracticeJobFiltering;
    val: string[];
  };
}

export interface PracticeAcceptJobAction extends Action {
  type: PracticeActionType.AcceptJob;
  payload: {
    id: ID;
  };
}

export interface PracticeRejectJobAction extends Action {
  type: PracticeActionType.RejectJob;
  payload: {
    id: ID;
  };
}

export interface PracticeJobAssessmentSuccessAction extends Action {
  type: PracticeActionType.JobAssessmentSuccess;
  payload: {
    job: PracticeJob;
  };
}

export interface PracticeJobAssessmentFailureAction extends ErrorAction {
  type: PracticeActionType.JobAssessmentFailure;
}

export interface PracticeCreateJobAction extends Action {
  type: PracticeActionType.CreateJob;
  payload: {
    documentId: string;
    proofreaderId: string;
  };
}

export interface PracticeJobCreationSuccessAction extends Action {
  type: PracticeActionType.JobCreationSuccess;
}

export interface PracticeJobCreationFailureAction extends ErrorAction {
  type: PracticeActionType.JobCreationFailure;
}

export interface PracticeOpenCreateJobPopupAction extends Action {
  type: PracticeActionType.OpenCreateJobPopup;
  payload: {
    document: PracticeDocument;
  };
}

export interface PracticeCloseCreateJobPopupAction extends Action {
  type: PracticeActionType.CloseCreateJobPopup;
}

export const practiceGeneralActions = {
  startLoading: (): PracticeStartLoadingAction => ({
    type: PracticeActionType.StartLoading
  }),
  stopLoading: (): PracticeStopLoadingAction => ({
    type: PracticeActionType.StopLoading
  }),
  loadingFailure: (reason: string): PracticeInitialLoadingFailureAction => ({
    type: PracticeActionType.InitialLoadingFailure,
    payload: new Error(`Failed loading practice page: ${reason}`),
    error: true
  }),
  proofreadersLoadingSuccess: (proofreaders: Proofreader[]): PracticeProofreadersLoadingSuccessAction => ({
    type: PracticeActionType.ProofreadersLoadingSuccess,
    payload: {
      proofreaders: proofreaders
    }
  })
};

export const practiceDocumentsActions = {
  loadingSuccess: (docs: PracticeDocument[]): PracticeDocsLoadingSuccessAction => ({
    type: PracticeActionType.DocsLoadingSuccess,
    payload: {
      docs: docs
    }
  }),
  loadingFailure: (reason: string): PracticeDocsLoadingFailureAction => ({
    type: PracticeActionType.DocsLoadingFailure,
    payload: new Error(`Failed loading practice documents: ${reason}`),
    error: true
  }),
  upload: (file: File): PracticeUploadDocumentAction => ({
    type: PracticeActionType.UploadDocument,
    payload: {
      file: file
    }
  }),
  uploadingSuccess: (document: PracticeDocument): PracticeDocUploadingSuccessAction => ({
    type: PracticeActionType.DocUploadingSuccess,
    payload: {
      document: document
    }
  }),
  uploadingFailure: (reason: string): PracticeDocUploadingFailureAction => ({
    type: PracticeActionType.DocUploadingFailure,
    payload: new Error(`Failed uploading practice document: ${reason}`),
    error: true
  }),
  delete: (id: ID): PracticeDeleteDocumentAction => ({
    type: PracticeActionType.DeleteDocument,
    payload: {
      id: id
    }
  }),
  deletionSuccess: (): PracticeDocDeletionSuccessAction => ({
    type: PracticeActionType.DocDeletionSuccess
  }),
  deletionFailure: (reason: string): PracticeDocDeletionFailureAction => ({
    type: PracticeActionType.DocDeletionFailure,
    payload: new Error(`Failed deleting the practice document: ${reason}`),
    error: true
  })
};

export const practiceJobsActions = {
  loadingSuccess: (practiceJobs: PracticeJob[]): PracticeJobsLoadingSuccessAction => ({
    type: PracticeActionType.JobsLoadingSuccess,
    payload: {
      jobs: practiceJobs
    }
  }),
  loadingFailure: (reason: string): PracticeJobsLoadingFailureAction => ({
    type: PracticeActionType.JobsLoadingFailure,
    payload: new Error(`Failed loading practice jobs: ${reason}`),
    error: true
  }),
  reloadJobs: (): PracticeReloadJobsAction => ({
    type: PracticeActionType.ReloadJobs
  }),
  filterJobs: (key: keyof PracticeJobFiltering, val: string[]): PracticeFilterJobsAction => ({
    type: PracticeActionType.FilterJobs,
    payload: {
      key: key,
      val: val
    }
  }),
  acceptJob: (id: ID): PracticeAcceptJobAction => ({
    type: PracticeActionType.AcceptJob,
    payload: {
      id: id
    }
  }),
  rejectJob: (id: ID): PracticeRejectJobAction => ({
    type: PracticeActionType.RejectJob,
    payload: {
      id: id
    }
  }),
  assessmentSuccess: (practiceJob: PracticeJob): PracticeJobAssessmentSuccessAction => ({
    type: PracticeActionType.JobAssessmentSuccess,
    payload: {
      job: practiceJob
    }
  }),
  assessmentFailure: (reason: string): PracticeJobAssessmentFailureAction => ({
    type: PracticeActionType.JobAssessmentFailure,
    payload: new Error(`Failed to assess job: ${reason}`),
    error: true
  }),
  createJob: (documentId: string, proofreaderId: string): PracticeCreateJobAction => ({
    type: PracticeActionType.CreateJob,
    payload: {
      documentId: documentId,
      proofreaderId: proofreaderId
    }
  }),
  creationSuccess: (): PracticeJobCreationSuccessAction => ({
    type: PracticeActionType.JobCreationSuccess
  }),
  creationFailure: (reason: string): PracticeJobCreationFailureAction => ({
    type: PracticeActionType.JobCreationFailure,
    payload: new Error(`Failed to create job: ${reason}`),
    error: true
  }),
  openCreateJobPopup: (document: PracticeDocument): PracticeOpenCreateJobPopupAction => ({
    type: PracticeActionType.OpenCreateJobPopup,
    payload: {
      document: document
    }
  }),
  closeCreateJobPopup: (): PracticeCloseCreateJobPopupAction => ({
    type: PracticeActionType.CloseCreateJobPopup
  })
};

/**
 * All Practice Actions
 */
export type PracticeActions =
  | PracticeStartLoadingAction
  | PracticeStopLoadingAction
  | PracticeInitialLoadingFailureAction
  | PracticeProofreadersLoadingSuccessAction
  | PracticeDocsLoadingSuccessAction
  | PracticeDocsLoadingFailureAction
  | PracticeUploadDocumentAction
  | PracticeDocUploadingSuccessAction
  | PracticeDocUploadingFailureAction
  | PracticeDeleteDocumentAction
  | PracticeDocDeletionSuccessAction
  | PracticeDocDeletionFailureAction
  | PracticeJobsLoadingSuccessAction
  | PracticeJobsLoadingFailureAction
  | PracticeReloadJobsAction
  | PracticeFilterJobsAction
  | PracticeAcceptJobAction
  | PracticeRejectJobAction
  | PracticeJobAssessmentSuccessAction
  | PracticeJobAssessmentFailureAction
  | PracticeCreateJobAction
  | PracticeJobCreationSuccessAction
  | PracticeJobCreationFailureAction
  | PracticeOpenCreateJobPopupAction
  | PracticeCloseCreateJobPopupAction;

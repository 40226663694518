import { call, cancel, fork, put, take } from 'redux-saga/effects';

import { Job } from '../../job.interface';
import { hideSpinner, showSpinner } from '../../../App/app.actions';
import appConfig from '../../../../config';
import fetch from '../../../../utils/fetch';
import { Job as ApiJob } from '../../job.api.interface';
import convertJob from '../../jobs.api.converter';
import { JobsFiltering } from '../jobs-list.store';
import { actions, JobsListActionType, JobsListStartLoadingAction } from '../jobs-list.actions';
import { filtering2params } from '../../_utils';

export function* request(page: number, filtering: JobsFiltering) {
  try {
    const queryParams = filtering2params(filtering);
    queryParams.push(`page=${page}`);

    const response: ApiJob[] = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.jobsList}?${queryParams.join('&')}`
    );
    const jobs: Job[] = response.map(convertJob);

    yield put(actions.loadingSuccess(jobs));
  } catch (error) {
    yield put(actions.loadingFailed(error.message));
  }
}

export default function*(action: JobsListStartLoadingAction) {
  try {
    yield put(showSpinner('load-jobs'));

    const task = yield fork(request, action.payload.page, action.payload.filtering);

    const actionTask = yield take([
      JobsListActionType.StopLoading,
      JobsListActionType.LoadingFailed,
      JobsListActionType.LoadingSuccess
    ]);

    if (actionTask.type === JobsListActionType.StopLoading) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('load-jobs'));
  }
}

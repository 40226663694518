import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppsActions } from '../../../../../redux/actions/actions';
import { downloadReport } from '../../../../../redux/saga/download-file.actions';
import appConfig from '../../../../../config';
import { JobsFiltering } from '../../jobs-list.store';
import { filtering2params } from '../../../_utils';
import useAppSelector from '../../../../../hooks/useAppSelector';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '10px 0',

      '& > *:not(:last-child)': {
        marginRight: 10
      }
    },
    input: {
      width: 100
    }
  })
);

const DownloadCsvReportButton: React.FunctionComponent<Dispatches> = (props: Dispatches) => {
  const classes = useStyles();

  const filtering = useAppSelector(state => state.jobsList.filtering);
  const [count, changeCount] = useState(300);

  return (
    <div className={classes.root}>
      <Button color="primary" onClick={() => props.downloadCsvReport(filtering, count)}>
        Download in CSV
      </Button>
      <TextField
        className={classes.input}
        /*
         * These properties align an input with text label near it.
         * TODO: check if we can modify markup and get rid of these CSS constants
         */
        inputProps={{
          style: {
            height: '35px',
            padding: '0 14px'
          }
        }}
        type="number"
        variant="outlined"
        value={count}
        label="Jobs count"
        onChange={e => changeCount(+e.target.value)}
      />
    </div>
  );
};

interface Dispatches {
  downloadCsvReport: (filtering: JobsFiltering, count: number) => void;
}

export default connect(
  null,
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    downloadCsvReport: (filtering: JobsFiltering, count: number) => {
      const sanitizedCount = count > 0 ? count : 300;

      const queryParams = filtering2params(filtering);
      queryParams.push(`count=${sanitizedCount}`);

      dispatch(downloadReport(`${appConfig.api.jobsList}/export?${queryParams.join('&')}`, 'report.csv'));
    }
  })
)(DownloadCsvReportButton);

import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { green, grey, red } from '@material-ui/core/colors';
import { createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core';

import { VoteJob } from '../progress.interface.api';

const styles = makeStyles(() =>
  createStyles({
    details: {
      '&.unknown': {
        fontStyle: 'italic',
        color: grey[400]
      },
      border: 'none'
    },
    iconAnchor: {
      position: 'relative'
    },
    icon: {
      position: 'absolute',
      top: 0,
      height: '100%'
    },
    completedIcon: {
      color: green[500],
      position: 'absolute',
      top: 0,
      left: '-.5em',
      height: '100%'
    },
    cancelledIcon: {
      color: red[500],
      position: 'absolute',
      top: 0,
      left: '-.5em',
      height: '100%'
    }
  })
);

let DetailsRows: FunctionComponent<Props>;
export default DetailsRows = ({ voteJobs }: Props) => {
  if (!voteJobs || voteJobs.length === 0) {
    return null;
  }

  const classes = styles();

  return (
    <>
      {voteJobs.map((vote, voteIndex) => {
        const completedIcon = <CheckCircleOutlineIcon className={classes.completedIcon} />;
        const cancelledIcon = <ClearIcon className={classes.cancelledIcon} />;
        const cellClasses = classnames(classes.iconAnchor, classes.details, { unknown: !vote.voterId });

        return (
          <TableRow key={voteIndex}>
            <TableCell colSpan={3} className={classes.details} />
            <TableCell colSpan={2} className={cellClasses}>
              {/* TODO: put icons to a hash, simplify this part */}
              {vote.status === 'completed' && completedIcon}
              {vote.status === 'cancelled' && cancelledIcon}
              {voteIndex + 1}. {vote.voterName || 'Unknown'}
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow>
        <TableCell colSpan={6} />
      </TableRow>
    </>
  );
};

interface Props {
  voteJobs: VoteJob[];
}

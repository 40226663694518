import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';

import { StoreInterface } from '../../../redux/store/store';
import { getGuideline, JobType } from '../../../model/job/jobType';

const styles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '1.5em',
      overflow: 'scroll',
      marginBottom: 20,
      minHeight: 150
    }
  })
);

const SnippetsQueuesTable = () => {
  const classes = styles();

  const queuesStatus = useSelector((state: StoreInterface) => state.onshiftProofreaders.queuesStatus);

  const userTypes = Object.values(JobType).filter(type => type.startsWith('USER_'));
  const corpusTypes = Object.values(JobType).filter(type => type.startsWith('CORPUS_'));

  return (
    <Paper className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell align="center">Queue size</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userTypes.map((jobType, i, types) => (
            <TableRow key={jobType}>
              {i === 0 && <TableCell rowSpan={types.length}>User jobs</TableCell>}
              <TableCell>{getGuideline(jobType)}</TableCell>
              <TableCell align="center">{queuesStatus.find(s => s.jobType === jobType)?.taskCount || 0}</TableCell>
            </TableRow>
          ))}
          {corpusTypes.map((jobType, i, types) => (
            <TableRow key={jobType}>
              {i === 0 && <TableCell rowSpan={types.length}>Corpus jobs</TableCell>}
              <TableCell>{getGuideline(jobType)}</TableCell>
              <TableCell align="center">{queuesStatus.find(s => s.jobType === jobType)?.taskCount || 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SnippetsQueuesTable;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { Corpus } from '../corpora.interface';

const MAX_LABEL_LEN = 255;
const INVALID_LABEL_HELPER_TEXT = 'Label is too long';
const DEFAULT_LABEL_HELPER_TEXT =
  'Label that is displayed to the proofreaders on each corpus job from this corpus. Type default is used if left empty.';
const DEFAULT_LABEL = '';

export const EditCorpusLabelDialog: FunctionComponent<DialogProps> = (props: DialogProps) => {
  const [label, setLabel] = useState<string | null>(props.corpus.label);
  const [labelIsValid, setLabelIsValid] = useState<boolean>(true);
  const [labelHelperText, setLabelHelperText] = useState<string>(DEFAULT_LABEL);

  useEffect(() => {
    labelChangedHandler(props.corpus.label);
  }, [props.corpus.label]);

  const labelChangedByTypingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    labelChangedHandler(event.target.value);
  };

  const labelChangedHandler = (newLabel: string | null) => {
    if (!newLabel) {
      newLabel = DEFAULT_LABEL;
    }
    if (newLabel.length > MAX_LABEL_LEN) {
      setLabelIsValid(false);
      setLabelHelperText(INVALID_LABEL_HELPER_TEXT);
    } else {
      setLabelIsValid(true);
      setLabelHelperText(DEFAULT_LABEL_HELPER_TEXT);
    }
    setLabel(newLabel);
  };

  const saveButtonClickedHandler = () => {
    const updatedCorpus: Corpus = { ...props.corpus, label: label };
    props.saveEditedLabelHandler(updatedCorpus);
    dialogCloseHandler();
  };

  const dialogCloseHandler = () => {
    labelChangedHandler(props.corpus.label);
    props.closeHandler();
  };

  return (
    <Dialog open={props.open} onClose={dialogCloseHandler} fullWidth maxWidth="xs">
      <DialogTitle>{props.viewOnly ? 'View' : 'Edit'} the corpus label</DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          label="Corpus label"
          value={label}
          onChange={labelChangedByTypingHandler}
          helperText={labelHelperText}
          error={!labelIsValid}
          InputLabelProps={{ shrink: !!label }}
          InputProps={{
            readOnly: props.viewOnly
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={props.saving} onClick={dialogCloseHandler}>
          {props.viewOnly ? 'Close' : 'Cancel'}
        </Button>
        {!props.viewOnly && (
          <Button autoFocus color="primary" disabled={props.saving || !labelIsValid} onClick={saveButtonClickedHandler}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  corpus: Corpus;
  saving: boolean;
  viewOnly: boolean;
}

interface Dispatches {
  closeHandler: () => void;
  saveEditedLabelHandler: (corpus: Corpus) => void;
}

type DialogProps = Props & Dispatches;

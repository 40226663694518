import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Box, Button, createStyles, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { ApplyMode, ChangeOps, Operation, OperationType, RepeatMode } from '../edit/store';
import { actions } from '../edit/actions';
import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';

import ChangeCard from './ChangeCard';

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      width: 360
    },
    proofreaderGroup: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    card: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 340
    }
  })
);

const ChangesList = (props: Props & Dispatches) => {
  const classes = useStyles();

  const hasChanges = Object.keys(props.operations).length > 0;

  const opProofreader = (op: Operation) => (op.type === OperationType.Addition ? op.proofreader : op.shift.proofreader);

  const groupedOperations = Object.keys(props.operations).reduce((acc, ckey) => {
    const op = props.operations[ckey];
    const name = opProofreader(op).name;
    const group = acc[name] || [];

    group.push(ckey);
    acc[name] = group;
    return acc;
  }, {} as any);

  return (
    <Box className={classes.box}>
      {hasChanges && (
        <>
          <Grid container justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={() => props.submit(props.operations)}>
                Submit changes
              </Button>
            </Grid>
          </Grid>
          {Object.keys(groupedOperations)
            .sort()
            .map(proofreaderName => {
              const operationKeys: string[] = groupedOperations[proofreaderName].sort();
              const proofreader = opProofreader(props.operations[operationKeys[0]]);

              return (
                <Box key={proofreaderName} className={classes.proofreaderGroup}>
                  <Typography variant="h6">{proofreader.name}</Typography>
                  {operationKeys.map(opKey => (
                    <Paper key={opKey} className={classes.card}>
                      <ChangeCard
                        showSummary={true}
                        op={props.operations[opKey]}
                        undo={() => props.resetChange(opKey)}
                        changeApplyMode={applyMode => props.changeModificationMode(opKey, applyMode)}
                        changeRepeatMode={repeatMode => props.changeRepeat(opKey, repeatMode)}
                      />
                    </Paper>
                  ))}
                </Box>
              );
            })}
        </>
      )}
    </Box>
  );
};

interface Props {
  operations: ChangeOps;
  displayDate: Date;
}

interface Dispatches {
  resetChange: (shiftKey: string) => void;
  changeModificationMode: (opKey: string, mode: ApplyMode) => void;
  changeRepeat: (opKey: string, repeat: Partial<RepeatMode>) => void;
  submit: (ops: ChangeOps) => void;
}

export default connect(
  ({ schedule: { view, edit } }: StoreInterface): Props => ({
    operations: edit.operations,
    displayDate: view.asOfDate
  }),
  (dispatch: Dispatch<AppsActions>): Dispatches => ({
    resetChange: shiftKey => dispatch(actions.resetChange(shiftKey)),
    changeModificationMode: (cKey, mode) => dispatch(actions.changeModificationMode(cKey, mode)),
    changeRepeat: (opKey, repeat) => dispatch(actions.changeRepeat(opKey, repeat)),
    submit: ops => dispatch(actions.submit(ops))
  })
)(ChangesList);

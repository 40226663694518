import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import { Dispatch } from 'redux';
import Grid from '@material-ui/core/Grid';

import { StoreInterface } from '../../../redux/store/store';
import { AppsActions } from '../../../redux/actions/actions';
import { scheduleActions } from '../view/actions';

const styles = makeStyles(() =>
  createStyles({
    root: {
      margin: '1em 0'
    }
  })
);

export const ToggleShowNoShiftsButton: React.FunctionComponent<PropsType> = (props: PropsType) => {
  let classes = styles();

  if (props.loading || !props.proofreadersExist) {
    return null;
  }

  return (
    <Grid container className={classes.root}>
      <Button color="primary" onClick={props.toggleShowNoShifts} disabled={props.disabled}>
        {props.showNoShifts ? 'Hide proofreaders without shifts' : 'Show all proofreaders'}
      </Button>
    </Grid>
  );
};

interface Props {
  disabled: boolean;
  loading: boolean;
  proofreadersExist: boolean;
  showNoShifts: boolean;
}

interface Dispatches {
  toggleShowNoShifts: () => void;
}

export type PropsType = Props & Dispatches;

const mapStateToProps = ({ schedule: { view } }: StoreInterface): Props => ({
  disabled: !!view.selectedColumn,
  loading: view.loading,
  proofreadersExist: view.proofreaders.length > 0,
  showNoShifts: view.showNoShifts
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  toggleShowNoShifts: () => dispatch(scheduleActions.toggleShowNoShifts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleShowNoShiftsButton);

import moment from 'moment';
import { push } from 'connected-react-router';
import { fork, join, put, takeEvery } from '@redux-saga/core/effects';

import appConfig from '../../../../config';
import { Row } from '../results.store';
import { GradeStats } from '../results.interface.api';
import { ActionType, resultsActions, StartLoading } from '../results.actions';
import { joinToRows } from '../_utils';
import { ProofreaderDetails } from '../../../../apis/proofreaders.api';
import { getProofreaders } from '../../../../redux/saga/get-proofreaders.saga';

import * as request from './request';

function* load(action: StartLoading) {
  const fromParam = moment.utc(action.payload.from).format('YYYY-MM-DD');
  const toParam = moment.utc(action.payload.to).format('YYYY-MM-DD');
  const dateParams = `from=${fromParam}&to=${toParam}`;

  yield put(push(`?${dateParams}`));

  const tasks = [
    yield fork(getProofreaders, { blocked: 'false' }, e => resultsActions.loadingFailure(e.message)),
    yield fork(request.get, `${appConfig.api.report.grades}?${dateParams}`, resultsActions.loadingFailure),
    yield fork(request.get, appConfig.api.permissions, resultsActions.loadingFailure)
  ];

  const [proofreaders, grades]: [ProofreaderDetails[], Record<string, GradeStats[]>] = yield join(tasks);

  const rows: Row[] = joinToRows(proofreaders, grades);

  yield put(resultsActions.loadingSuccess(rows));
}

export default function*() {
  yield takeEvery(ActionType.StartLoading, load);
}

import { Reducer } from 'redux';

import { INIT_PRACTICE_STORE, PracticeStoreInterface } from './practice.store';
import { PracticeActions, PracticeActionType } from './practice.actions';

export type PracticeReducer = Reducer<PracticeStoreInterface, PracticeActions>;
export const practiceReducer: PracticeReducer = (state = INIT_PRACTICE_STORE, action) => {
  switch (action.type) {
    case PracticeActionType.StartLoading:
      return { ...state, loadingDocuments: true, loadingJobs: true };

    case PracticeActionType.StopLoading:
      return { ...state, loadingDocuments: false, loadingJobs: false };

    case PracticeActionType.InitialLoadingFailure:
      return { ...state, docs: [], jobs: [], loadingDocuments: false, loadingJobs: false };

    case PracticeActionType.ProofreadersLoadingSuccess:
      return { ...state, proofreaders: action.payload.proofreaders };

    case PracticeActionType.DocsLoadingSuccess:
      return { ...state, documents: action.payload.docs };

    case PracticeActionType.DocsLoadingFailure:
      return { ...state, documents: [] };

    case PracticeActionType.UploadDocument:
      return { ...state, uploadDocument: action.payload.file, uploadingDocument: true };

    case PracticeActionType.DocUploadingSuccess:
      const withUploadedDocumentList = state.documents.concat(action.payload.document);
      return { ...state, documents: withUploadedDocumentList, uploadingDocument: false, uploadDocument: null };

    case PracticeActionType.DocUploadingFailure:
      return { ...state, uploadingDocument: false, uploadDocument: null };

    case PracticeActionType.DeleteDocument:
      return { ...state, deleteDocumentId: action.payload.id };

    case PracticeActionType.DocDeletionSuccess:
      const withoutDeletedDocumentList = state.documents.filter(doc => doc.id !== state.deleteDocumentId);
      return { ...state, documents: withoutDeletedDocumentList, deleteDocumentId: null };

    case PracticeActionType.DocDeletionFailure:
      return { ...state, deleteDocumentId: null };

    case PracticeActionType.JobsLoadingSuccess:
      return { ...state, jobs: action.payload.jobs };

    case PracticeActionType.JobsLoadingFailure:
      return { ...state, jobs: [] };

    case PracticeActionType.ReloadJobs:
      return { ...state, loadingJobs: true };

    case PracticeActionType.FilterJobs:
      const newFiltering = { ...state.jobsFiltering, [action.payload.key]: action.payload.val };
      return { ...state, loadingJobs: true, jobs: [], jobsFiltering: newFiltering };

    case PracticeActionType.OpenCreateJobPopup:
      const newPopup = { creatingJob: false, document: action.payload.document };
      return { ...state, createJobPopup: newPopup };

    case PracticeActionType.CloseCreateJobPopup:
      return { ...state, createJobPopup: null };

    case PracticeActionType.CreateJob: {
      const updatedPopup = state.createJobPopup ? { ...state.createJobPopup, creatingJob: true } : null;
      return { ...state, createJobPopup: updatedPopup };
    }

    case PracticeActionType.JobCreationSuccess:
      return { ...state, createJobPopup: null };

    case PracticeActionType.JobCreationFailure:
      const updatedPopup = state.createJobPopup ? { ...state.createJobPopup, creatingJob: false } : null;
      return { ...state, createJobPopup: updatedPopup };

    default:
      return state;
  }
};

import moment from 'moment';

import { ProofreaderRow as ApiProofreader, Shift as ApiShift } from '../../../apis/schedule.interface';
import { Milliseconds, TimeInterval } from '../../../utils/types';
import { ProofreaderRow as StoreProofreader } from '../view/store';
import { RepeatMode, RepeatType } from '../edit/store';
import { ShiftView } from '../ShiftView';
import { parse } from '../../../apis/proofreaders.api';

const ONE_HOUR: Milliseconds = 1000 * 60 * 60;

const toRepeatType = (period: string): RepeatType => {
  switch (period.toLowerCase()) {
    case 'once':
      return RepeatType.Once;
    case 'everyday':
      return RepeatType.Everyday;
    case 'weekday':
      return RepeatType.Weekday;
    case 'dayofweek':
      return RepeatType.DayOfWeek;
    default:
      throw new Error('Unknown RepeatType to parse: ' + period);
  }
};

const toRepeatMode = (s: ApiShift): RepeatMode => {
  const repeatType = toRepeatType(s.period);
  const from = moment.utc(s.startDate).toDate();
  const to = s.endDate
    ? moment
        .utc(s.endDate)
        .endOf('day')
        .toDate()
    : undefined;
  return { type: repeatType, from, to };
};

const convertShifts = (api: ApiProofreader): ShiftView[] =>
  api.plannedShifts.map(s => {
    const p = { id: api.proofreader.id, name: api.proofreader.name };
    return {
      original: {
        proofreader: p,
        begin: s.begin,
        end: s.end,

        shiftKey: s.key,
        seriesKey: `${s.scheduledId}`,

        repeat: toRepeatMode(s)
      }
    };
  });

export default (api: ApiProofreader, displayInterval: TimeInterval): StoreProofreader => ({
  ...parse(api.proofreader),
  shifts: convertShifts(api),
  totalHours: api.plannedShifts.reduce((acc, el) => acc + displayInterval.intersection(el).duration(), 0) / ONE_HOUR
});

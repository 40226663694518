import { call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { hideSpinner, showSpinner } from '../../App/app.actions';
import fetch from '../../../utils/fetch';
import appConfig from '../../../config';
import { StoreInterface } from '../../../redux/store/store';

import { actions, ActionType } from './actions';
import { api2Store, VotingSettings } from './store';
import { VotingSettings as ApiVotingSettings } from './api.interface';

function* loadAsync() {
  try {
    const settings: ApiVotingSettings[] = yield call(fetch.request.bind(fetch), `${appConfig.api.settings.voting}`);
    yield put(actions.loadingSuccess(settings.map(api2Store)));
  } catch (error) {
    yield put(actions.loadingFailure());
  }
}

function* loadVotingSettings() {
  try {
    yield put(showSpinner('load-voting-settings'));
    const task = yield fork(loadAsync);

    const actionTask = yield take([ActionType.LoadingFailure, ActionType.LoadingSuccess]);
    if (actionTask.type === ActionType.LoadingFailure) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('load-voting-settings'));
  }
}

function* updateVotingSettings() {
  try {
    yield put(showSpinner('update-voting-settings'));

    const settings: VotingSettings[] = yield select((store: StoreInterface) => store.settings.voting.settings);
    const settingsToSave = settings.map(s => ({
      type: s.type,
      votingRatePercent: s.votingRatePercent.updatedValue,
      requiredVotes: s.requiredVotes.updatedValue
    }));

    const requestConfig: RequestInit = {
      method: 'POST',
      body: JSON.stringify(settingsToSave)
    };

    const updatedSettings: ApiVotingSettings[] = yield call(
      fetch.request.bind(fetch),
      `${appConfig.api.settings.voting}`,
      requestConfig,
      'application/json'
    );

    yield put(actions.updateSuccess(updatedSettings.map(api2Store)));
  } catch (e) {
    yield put(actions.updateFailure());
  } finally {
    yield put(hideSpinner('update-voting-settings'));
  }
}

export function* watchVotingSettingsSaga() {
  yield takeLatest(ActionType.Load, loadVotingSettings);
  yield takeLatest(ActionType.CommitUpdate, updateVotingSettings);
}

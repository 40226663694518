import { OktaAuth, UserClaims } from '@okta/okta-auth-js';

import { AuthProfile } from './okta';

export enum AuthActionType {
  /* Remote actions */
  GetUserInfo = 'auth/get-user-info',
  Logout = 'auth/logout',

  /* Store updaters */
  StoreUser = 'auth/store-user',
  ResetUser = 'auth/reset-user'
}

export interface GetUserInfo {
  type: AuthActionType.GetUserInfo;
  payload: { getUser: () => Promise<UserClaims> };
}

export interface Logout {
  type: AuthActionType.Logout;
  payload: { authService: OktaAuth };
}

interface StoreUser {
  type: AuthActionType.StoreUser;
  payload: { profile?: AuthProfile };
}

type ResetUser = { type: AuthActionType.ResetUser };

export const actions = {
  getUserInfo: (getUser: () => Promise<UserClaims>): GetUserInfo => ({
    type: AuthActionType.GetUserInfo,
    payload: { getUser }
  }),
  logout: (authService: OktaAuth): Logout => ({
    type: AuthActionType.Logout,
    payload: { authService }
  }),
  storeUser: (profile?: AuthProfile) => ({
    type: AuthActionType.StoreUser,
    payload: { profile }
  }),
  resetUser: (): ResetUser => ({ type: AuthActionType.ResetUser })
};

export type AuthActions = GetUserInfo | Logout | StoreUser | ResetUser;

import { combineReducers } from 'redux';

import { SettingsActions, SettingsActionType } from './settings.actions';
import * as VotingSettingActions from './VotingSettings/actions';
import { settingsReducer as priceSettingsReducer } from './settings.reducer';
import votingSettingsReducer from './VotingSettings/reducer';

export * from './settings.store';

export type ActionType = SettingsActionType | VotingSettingActions.ActionType;
export type Action = SettingsActions | VotingSettingActions.Action;

export const settingsReducer = combineReducers({ price: priceSettingsReducer, voting: votingSettingsReducer });

import Home from '@material-ui/icons/Home';
import { NavLink } from 'react-router-dom';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import { AppRoute } from '../../routes';

import Dashboard from './components/Dashboard/Dashboard';

const route: AppRoute = {
  path: '/',
  component: Dashboard,
  parent: null,
  pathMatch: /^\/$/,
  breadcrumb: () => (
    <Typography color="textPrimary" className="AppBreadcrumbsLink" key="Breadcrumbs-indexRoute">
      <NavLink to="/">
        <Home className="AppBreadcrumbsIcon" />
        Home
      </NavLink>
    </Typography>
  ),
  private: true,
  exact: true
};

export default route;

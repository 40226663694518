import { call, cancel, fork, put, take } from 'redux-saga/effects';

import appConfig from '../../../../config';
import { actions, JobsListActionType, JobsListPerformJobAction } from '../jobs-list.actions';
import { hideSpinner, showSpinner } from '../../../App/app.actions';
import fetch from '../../../../utils/fetch';
import { Job } from '../../job.api.interface';
import convertJob from '../../jobs.api.converter';

function* request(url: string) {
  try {
    const config: Partial<Request> = { method: 'POST' };
    const updatedJob: Job = yield call(fetch.request.bind(fetch), url, config);
    yield put(actions.performJobActionSuccess(convertJob(updatedJob)));
  } catch (error) {
    yield put(actions.performJobActionFailure(error.message));
  }
}

export default function*(action: JobsListPerformJobAction) {
  const url = `${appConfig.api.jobsList}/${action.payload.jobId}/${action.payload.actionType}`;

  try {
    yield put(showSpinner('job-action'));

    const task = yield fork(request, url);

    const stopTaskAction = yield take([
      JobsListActionType.PerformJobActionSuccess,
      JobsListActionType.PerformJobActionFailure,
      JobsListActionType.StopLoading
    ]);

    if (stopTaskAction.type === JobsListActionType.StopLoading) {
      yield cancel(task);
    }

    yield put(actions.hideChangeJobAlert());
  } finally {
    yield put(hideSpinner('job-action'));
  }
}

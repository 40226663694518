import { AccessToken, IDToken, Tokens } from '@okta/okta-auth-js';

import appConfig from '../config';

import { UrlRelative } from './types';

export const OKTA_STORAGE_TOKEN = 'okta-token-storage';

export class Fetch {
  private tokens: Tokens | null = null;
  private abortController: AbortController | null = null;
  private abortSignal: AbortSignal | null = null;

  constructor() {
    this.tryGetToken();
  }

  request<T>(url: UrlRelative, config: Partial<Request> | RequestInit = {}, contentType?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.rawRequest(url, config, contentType)
        .then((response: Response) => {
          let contentType = (response.headers && response.headers.get('Content-Type')) || '';
          if (contentType.startsWith('application/json')) {
            return response.json();
          } else {
            return response.blob();
          }
        })
        .then(data => resolve(data))
        .catch(err => {
          reject(err);
        });
    });
  }

  rawRequest(url: UrlRelative, config: Partial<Request> | RequestInit = {}, contentType?: string): Promise<Response> {
    const authorization = this.tryGetToken();
    const headers = new Headers({
      'X-API-Version': '1'
    });

    if (contentType) {
      headers.append('Content-Type', contentType);
    }

    if (authorization) {
      headers.append('Authorization', authorization);
    }

    if (!this.abortSignal) {
      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;
    }

    const defaultConfig: Partial<Request> = {
      method: 'GET',
      cache: 'no-cache',
      referrer: 'no-referrer',
      headers,
      signal: this.abortSignal
    };

    const request = new Request(`${appConfig.api.server}${url}`, { ...defaultConfig, ...config });

    return new Promise((resolve, reject) => {
      window
        .fetch(request)
        .then(response => {
          if (response.status === 401) {
            window.location.reload();
          }

          if (response.ok) {
            resolve(response);
          } else {
            reject(new Error('Failed fetch response'));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Abort all current fetch requests
   */
  abort() {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortSignal = null;
  }

  private tryGetToken(): string | null {
    if (!this.tokens) {
      try {
        this.tokens = JSON.parse(window.localStorage.getItem(OKTA_STORAGE_TOKEN) || 'null');
      } catch (error) {
        this.tokens = null;
      }
    }
    return this.tokens ? `Bearer ${this.tokens.accessToken?.accessToken}` : null;
  }
}

const fetch = new Fetch();

export default fetch;

import { call, cancel, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';

import appConfig from '../../config';
import fetch from '../../utils/fetch';
import { hideSpinner, showSpinner } from '../App/app.actions';
import { StoreInterface } from '../../redux/store/store';
import { ProofreaderRow as ApiProofreader } from '../../apis/schedule.interface';
import { TimeInterval } from '../../utils/types';

import {
  scheduleActions,
  ScheduleViewActionType,
  ScheduleStartInitialLoadingAction,
  ScheduleStartLoadingAction
} from './view/actions';
import { calculateTimeFrame, convertProofreader } from './utils';

function* request(from: Date, to: Date) {
  try {
    const fromParam = moment.utc(from).format('YYYY-MM-DD');
    const toParam = moment.utc(to).format('YYYY-MM-DD');

    const url = `${appConfig.api.schedule.proofreaders}?from=${fromParam}&to=${toParam}`;
    const proofreaders: ApiProofreader[] = yield call(fetch.request.bind(fetch), url);

    /* TODO: this glitch should also be fixed on back-end. */
    const filteredProofreaders = proofreaders.filter(
      p => p.plannedShifts.length === 0 || !!p.plannedShifts.find(s => s.begin < to.getTime() && s.end > from.getTime())
    );

    yield put(
      scheduleActions.loadingSuccess(
        filteredProofreaders.map(p => convertProofreader(p, new TimeInterval(from.getTime(), to.getTime())))
      )
    );
  } catch (error) {
    yield put(scheduleActions.loadingFailure(error.message));
  }
}

function* scheduleStartAsync(action: ScheduleStartLoadingAction | ScheduleStartInitialLoadingAction) {
  try {
    yield put(showSpinner('load-schedule'));

    const { from, to } =
      action.type === ScheduleViewActionType.InitialLoading
        ? calculateTimeFrame(action.payload.asOfDate, action.payload.scale)
        : { from: action.payload.from, to: action.payload.to };

    const task = yield fork(request, from, to);

    const actionTask = yield take([
      ScheduleViewActionType.StopLoading,
      ScheduleViewActionType.LoadingSuccess,
      ScheduleViewActionType.LoadingFailure
    ]);

    if (actionTask.type === ScheduleViewActionType.StopLoading) {
      yield cancel(task);
    }
  } finally {
    yield put(hideSpinner('load-schedule'));
  }
}

function* hardReload() {
  const state: StoreInterface = yield select();

  const formattedAsOf = moment.utc(state.schedule.view.asOfDate).format('YYYY-MM-DD');
  yield put(push(`?asOf=${formattedAsOf}&scale=${state.schedule.view.scale.name}`));

  yield put(scheduleActions.startLoading(state.schedule.view.from, state.schedule.view.to));
}

function* softReload() {
  try {
    yield put(showSpinner('soft-reload-schedule'));

    const state: StoreInterface = yield select();

    yield fork(request, state.schedule.view.from, state.schedule.view.to);

    yield take([ScheduleViewActionType.LoadingSuccess, ScheduleViewActionType.LoadingFailure]);
  } finally {
    yield put(hideSpinner('soft-reload-schedule'));
  }
}

export default function* scheduleStartSaga() {
  const hardReloadActions = [
    ScheduleViewActionType.PreviousPeriod,
    ScheduleViewActionType.NextPeriod,
    ScheduleViewActionType.DailyScale,
    ScheduleViewActionType.WeeklyScale
  ];

  yield takeLatest([ScheduleViewActionType.StartLoading, ScheduleViewActionType.InitialLoading], scheduleStartAsync);

  /* Hard reload means complete redraw of the page */
  yield takeLatest(hardReloadActions, hardReload);

  /* Soft reload means update shifts without redrawing the page */
  yield takeEvery(ScheduleViewActionType.SoftLoad, softReload);
}

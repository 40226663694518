import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { AppsActions } from '../../../../redux/actions/actions';
import { NotificationInterface } from '../../notifications.interface';
import { notificationsClose } from '../../notifications.actions';
import { StoreInterface } from '../../../../redux/store/store';

interface Props {
  notifications: NotificationInterface[];
}

interface Dispatches {
  close: (notification: NotificationInterface) => void;
}

class AppNotifier extends React.Component<Props & Dispatches & WithSnackbarProps> {
  // Array of IDs of Displayed Notifications
  displayed: string[] = [];

  storeDisplayed = (key: string) => {
    this.displayed = [...this.displayed, key];
  };

  shouldComponentUpdate({ notifications }: Props) {
    if (!notifications || !notifications.length) {
      this.displayed = [];
      return false;
    }
    return notifications.reduce((prev, notification) => {
      if (notification.dismissed) {
        this.close(notification);
        return true;
      }
      return prev || !this.isShowSnack(notification);
    }, false);
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach((notification: NotificationInterface) => {
      if (this.displayed.includes(notification.key)) return;

      if (!notification.options) {
        notification.options = {};
      }
      if (notification.isClose) {
        notification.options.action = () => (
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => this.close(notification)}>
            <CloseIcon />
          </IconButton>
        );
      }
      this.props.enqueueSnackbar(notification.message, notification.options);
      this.storeDisplayed(notification.key);
    });
  }

  render() {
    return null;
  }

  private close(notification: NotificationInterface) {
    this.props.closeSnackbar(notification.options ? notification.options.key : notification.key);
    this.props.close(notification);
  }

  private isShowSnack(notification: NotificationInterface): boolean {
    return !!this.props.notifications.filter(presentNotification => presentNotification.key === notification.key)
      .length;
  }
}

const mapStateToProps = (store: StoreInterface) => ({
  notifications: store.notifications.notifications
});

const mapDispatchToProps = (dispatch: Dispatch<AppsActions>): Dispatches => ({
  close: (notification: NotificationInterface) => dispatch(notificationsClose(notification))
});

const comp = connect(mapStateToProps, mapDispatchToProps)(AppNotifier as any);

// TODO
export default withSnackbar(comp as any);

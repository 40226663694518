export type AppConfig = typeof APP_CONFIG;

export const APP_CONFIG = {
  mode: 'production',
  env: 'prod',
  domain: {
    host: 'admin.proofit.grammarly.io',
    localServer: 'https://admin.proofit.grammarly.io'
  },
  api: {
    server: 'https://proofit.grammarly.io/',
    jobsList: 'v2/api/jobs',
    createJob: 'v2/api/job/create',
    logs: 'v2/api/logs',
    permissions: 'v2/api/permissions',
    snippets: 'v2/api/snippets',
    proofreaders: {
      root: 'v2/api/proofreaders',
      scheduled: 'v2/api/proofreaders/scheduled',
      welcomeEmail: 'v2/api/proofreaders/{id}/sendWelcomeEmail',
      export: 'v2/api/proofreaders/export'
    },
    proofreadersGroup: {
      list: 'v2/api/groups',
      group: 'v2/api/group'
    },
    report: {
      grades: 'v2/api/report/grades',
      voterStats: 'v2/api/report/voter',
      shifts: 'v2/api/report/shifts'
    },
    settings: {
      getSettings: 'v2/api/settings',
      generalSettingsUpdate: 'v2/api/settings/general',
      availabilitiesUpdate: 'v2/api/settings/availabilities',
      pricesUpdate: 'v2/api/settings/prices',
      maxPricesUpdate: 'v2/api/settings/maxPrices',
      voting: 'v2/api/settings/voting'
    },
    status: {
      queues: 'v2/api/status/queues',
      tasks: 'v2/api/status/tasks'
    },
    onshiftProofreaders: {
      onshiftProofreaders: 'v2/api/dashboard'
    },
    voting: {
      create: 'v2/api/voting/{voteJobType}',
      counts: 'v2/api/voting/counts',
      root: 'v2/api/voting'
    },
    practice: {
      documents: 'v2/api/test-documents',
      runs: 'v2/api/test-document-runs'
    },
    corpora: {
      basic: 'v2/api/corpora',
      archives: 'v2/api/corpus-archives',
      stats: 'v2/api/corpus-queues-stats'
    },
    clarityReports: {
      list: 'v2/api/clarity/reports',
      download: 'v2/api/clarity/reports/{reportDate}/download'
    },
    schedule: {
      root: 'v2/api/schedule',
      proofreaders: 'v2/api/schedule/proofreaders'
    },
    whoAmI: 'v2/whoami'
  },
  okta: {
    baseUrl: 'https://grammarly.okta.com',
    clientId: '0oas5gkvrwbJucjqS0x7',
    issuer: 'https://grammarly.okta.com/oauth2/ausno8ul3tlEhS2Ck0x7'
  },
  denali: {
    server: 'https://app.grammarly.com/'
  },
  grammarlyUserId: '858662130' // tiffany.shieh@grammarly.com
};

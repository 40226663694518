import { capitalize } from 'lodash';

import { prettyPrint, VoteJobType, votingUnit } from '../../../model/voting/voteJobType';
import { assertNever } from '../../../utils/errors';

import { GradingResults, GuidelineGradingResult, Row } from './results.store';

export interface Dimension {
  voteJobType: VoteJobType;
  voteType: keyof GuidelineGradingResult;
  value: keyof GradingResults;
}

// TODO: re-configure TS compiler and make this a namespace
export const Dimension = {
  id: (dim: Dimension) => Object.values(dim).join('.'),

  buildDimensions: (rows: Row[]): Dimension[] =>
    Object.values(VoteJobType).flatMap(voteJobType => {
      const hasCompleteness = rows.some(row => (row.results[voteJobType]?.completeness?.sentences || 0) > 0);

      const voteTypeDimensions: (keyof GuidelineGradingResult)[] = hasCompleteness
        ? ['accuracy', 'completeness']
        : ['accuracy'];
      const valueDimensions: (keyof GradingResults)[] = ['sentences', 'validPercent'];

      return voteTypeDimensions.flatMap(voteType => valueDimensions.map(value => ({ voteJobType, voteType, value })));
    }),

  topLevelHeader: (dim: Dimension) => `${prettyPrint(dim.voteJobType)} ${capitalize(dim.voteType)}`,

  detailedHeader: ({ voteJobType, value }: Dimension) => {
    switch (value) {
      case 'sentences':
        return capitalize(votingUnit(voteJobType).plural);

      case 'validPercent':
        return 'Valid';

      default:
        assertNever(value);
    }
  },

  project: ({ voteJobType, voteType, value }: Dimension, row: Row) => row.results[voteJobType]?.[voteType]?.[value]
};

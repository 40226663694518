import { idfy } from './types';

export const isJavaInteger: (value: string) => boolean = value => {
  const isVisuallyValid = /^-?\d{1,10}$/.test(value);
  if (isVisuallyValid) {
    const parsed = Number(value);
    return parsed >= -2147483648 && parsed <= 2147483647;
  } else {
    return false;
  }
};

/* TODO: Replace isJavaInteger with isValidId across the application */
export const isValidId = (id: string): boolean => {
  const numericId = +idfy(id);

  /* There are no negative IDs  */
  /* Most of our IDs are integers so we need to check that value is within range */
  if (Number.isInteger(numericId)) {
    if (numericId > 0 && numericId <= 2147483647) {
      return true;
    }
  }

  return false;
};

export const isValidUserId = (id: string): boolean => {
  const numericId = +idfy(id);
  if (Number.isInteger(numericId)) {
    if (numericId > 0 && numericId <= Number.MAX_SAFE_INTEGER) {
      return true;
    }
  }

  return false;
};

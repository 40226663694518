import { assertNever } from '../../utils/errors';

export enum JobSource {
  Denali_editor = 'Denali_editor',
  OfficeAddIn = 'OfficeAddIn',
  Admin = 'Admin',
  Gmail = 'Gmail',
  CorpusUploader = 'CorpusUploader'
}

export const prettyPrint = (source: JobSource) => {
  switch (source) {
    case JobSource.Denali_editor:
      return 'Denali';
    case JobSource.OfficeAddIn:
      return 'Office add-in';
    case JobSource.Admin:
      return 'Admin panel';
    case JobSource.Gmail:
      return 'Gmail';
    case JobSource.CorpusUploader:
      return 'Uploader';
    default:
      assertNever(source);
  }
};

import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { DocumentContext, Job, UserInputs } from '../../../job.interface';
import { dialectToString, quotationTypeToString, spaceTypeToString } from '../../../jobs.api.converter';
import { localDateTimeUTCTooltip } from '../../../../../utils/date';

interface Props {
  job: Job;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      borderBottom: `${theme.spacing(2)}px solid transparent`
    },
    multilineNote: {
      whiteSpace: 'pre-wrap'
    },
    splitTable: {
      '&>tbody:not(:last-child)': {
        borderBottom: `${theme.spacing(2)}px solid transparent`
      }
    }
  })
);

const CancellationDetailsTable = ({ job }: Props) => {
  if (!job.cancelReason && !job.cancellationDetails) return null;

  return (
    <TableBody>
      <OptionalRow title="Cancel reason" value={job.cancelReason} />
      <OptionalRow title="Cancellation details" value={job.cancellationDetails} />
    </TableBody>
  );
};

function OptionalRow<T>({ title, value }: { title: string; value?: T }) {
  if (!value) return null;

  return (
    <TableRow>
      <TableCell align="left">{title}</TableCell>
      <TableCell align="left">{value}</TableCell>
    </TableRow>
  );
}

const DocumentContextTable = ({ context }: { context: DocumentContext | null }) => {
  const isEmpty = !(
    context?.domain ||
    context?.audience ||
    context?.formality ||
    context?.documentDialect ||
    context?.academicTextType ||
    context?.academicCitationFormat
  );
  if (isEmpty) return null;

  return (
    <TableBody>
      <OptionalRow title="Domain" value={context?.domain} />
      <OptionalRow title="Audience" value={context?.audience} />
      <OptionalRow title="Formality" value={context?.formality} />
      <OptionalRow title="Dialect (document)" value={context?.documentDialect} />
      <OptionalRow title="Academic text type" value={context?.academicTextType} />
      <OptionalRow title="Citation format" value={context?.academicCitationFormat} />
    </TableBody>
  );
};

export default ({ job }: Props) => {
  const classes = useStyles();

  if (!job) {
    return null;
  }

  const mapUserInputs = (userInputs: UserInputs) => {
    let res = [
      userInputs.nativeLanguage || '',
      dialectToString(userInputs.dialect),
      quotationTypeToString(userInputs.quotationType),
      spaceTypeToString(userInputs.spaceType)
    ];

    return res.filter(s => !!s).join(', ');
  };

  return (
    <Table size="small" className={classes.splitTable}>
      <TableBody>
        <TableRow>
          <TableCell align="left">Job ID</TableCell>
          <TableCell align="left">{job.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">User ID</TableCell>
          <TableCell align="left">{job.uid}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Document ID</TableCell>
          <TableCell align="left">{job.docId}</TableCell>
        </TableRow>
      </TableBody>

      <TableBody>
        <TableRow>
          <TableCell align="left">Downloaded</TableCell>
          <TableCell align="left">{job.downloaded ? localDateTimeUTCTooltip(job.downloaded) : 'No'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Credits payment status</TableCell>
          <TableCell align="left">{job.creditsPayment}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Price per word</TableCell>
          <TableCell align="left">{job.pricePerWord}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Job source</TableCell>
          <TableCell align="left">{job.source}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Payment verified</TableCell>
          <TableCell align="left">{job.paymentVerified ? 'Yes' : 'No'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">User inputs</TableCell>
          <TableCell align="left">{job.userInputs ? mapUserInputs(job.userInputs) : 'Not provided'}</TableCell>
        </TableRow>
        {job.userInputs && job.userInputs.noteToEditor && (
          <TableRow>
            <TableCell align="left">Note to editor</TableCell>
            <TableCell align="left" className={classes.multilineNote}>
              {job.userInputs.noteToEditor}
            </TableCell>
          </TableRow>
        )}
      </TableBody>

      <DocumentContextTable context={job.userInputs} />

      <CancellationDetailsTable job={job} />
    </Table>
  );
};

import { readParams } from './utils';
import { VoterResult } from './interface.api';

export interface Store {
  ready: boolean;
  loading: boolean;
  from: Date;
  to: Date;
  voterResults: VoterResult[];
}

export const initStore = (): Store => {
  const { from, to } = readParams();

  return { ready: false, loading: false, from, to, voterResults: [] };
};

import React, { FunctionComponent, useState } from 'react';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  uploading: boolean;
  createCorpusClickedHandler: () => void;
}

export const CorporaActionsGroup: FunctionComponent<any> = (props: Props) => (
  <div>
    <Tooltip title="Show help" placement="bottom">
      <span>
        <IconButton
          size="small"
          href="https://grammarly.atlassian.net/wiki/spaces/PROOF/pages/3368521334/How+to+create+a+corpus+in+Proofit+Admin+UI"
          target="_blank"
        >
          <HelpIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Tooltip title="Create a new corpus" placement="bottom">
      <span>
        <IconButton color="primary" size="small" disabled={props.uploading} onClick={props.createCorpusClickedHandler}>
          <AddIcon />
        </IconButton>
      </span>
    </Tooltip>
  </div>
);

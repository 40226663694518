import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Counts, VotingProgressTab } from '../progress.store';
import { StoreInterface } from '../../../../redux/store/store';
import { actions } from '../progress.actions';

const votingStatuses: VotingProgressTab[] = ['voting', 'proofreading', 'completed', 'cancelled'];

export default ({ counts }: { counts: Counts }) => {
  const votingStatusFilter = useSelector(({ voting }: StoreInterface) => voting.progress.selectedTab);

  const dispatch = useDispatch();

  return (
    <Tabs value={votingStatusFilter} onChange={(event, tab) => dispatch(actions.changeTab(tab))}>
      {votingStatuses.map(tab => {
        const gradingsCount = counts[tab] || 0;

        return <Tab key={tab} label={`${tab} (${gradingsCount})`} value={tab} disabled={gradingsCount === 0} />;
      })}
    </Tabs>
  );
};

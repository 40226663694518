import { Action, ActionType } from '../actions';
import { initStore } from '../store';

const settingsReducer = (state = initStore(), action: Action) => {
  switch (action.type) {
    case ActionType.LoadingSuccess:
    case ActionType.UpdateSuccess:
      return action.payload.settings;

    case ActionType.SetVotingRate:
      return state.map(s =>
        s.type === action.payload.type
          ? { ...s, votingRatePercent: { ...s.votingRatePercent, updatedValue: action.payload.value } }
          : s
      );

    case ActionType.SetRequiredVotes:
      return state.map(s =>
        s.type === action.payload.type
          ? { ...s, requiredVotes: { ...s.requiredVotes, updatedValue: action.payload.value } }
          : s
      );

    case ActionType.ResetUpdate:
    case ActionType.UpdateFailure:
      return state.map(setting => ({
        ...setting,
        votingRatePercent: { ...setting.votingRatePercent, updatedValue: setting.votingRatePercent.value },
        requiredVotes: { ...setting.requiredVotes, updatedValue: setting.requiredVotes.value }
      }));

    default:
      return state;
  }
};

export default settingsReducer;
